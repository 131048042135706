import React from "react";
import { connect } from "react-redux";

const FilterContact = (props) => {
    return (
        <div className="login-field-input">
            <select
                className="mm-field-input filter-dropdown"
                value={props.contactCategory}
                autoComplete="off"
                onChange={(event) =>
                    props.setContactCategory(event.target.value)
                }
            >
                <option value="">{props.form.language.FILTER_CONTACT}</option>
                {props.contactFilter.map((item) => {
                    return (
                        <React.Fragment>
                            {props.form.accountData?.contactList &&
                                props.form.accountData.contactList.map(
                                    (currentContact, index) => {
                                        if (item == currentContact.system.id) {
                                            return (
                                                <option
                                                    value={item}
                                                    key={index}
                                                >
                                                    {currentContact &&
                                                        currentContact
                                                            .privateData
                                                            .firstName +
                                                            " " +
                                                            currentContact
                                                                .privateData
                                                                .lastName}
                                                </option>
                                            );
                                        } else {
                                            return "";
                                        }
                                    }
                                )}
                        </React.Fragment>
                    );
                })}
            </select>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

export default connect(mapStateToProps)(FilterContact);
