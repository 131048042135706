/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import AddIcon from "@material-ui/icons/Add";
import { addAttribute } from "../../Actions/Form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import update from "immutability-helper";
import FilterTags from "../Common/FilterTags";
import { model } from "../../Models/Contacts";
import AddContact from "../Contacts/AddContact";
import { getTags, filterContacts } from "../Contacts/ContactTags";
import { useGetAPICallQuery } from "../../app/services/account";
import { entityTagList, sortContactAscending } from "../../Utility/Utility";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        boxShadow: "none",
        width: "100%",
    },
});

const GreenCheckbox = withStyles({
    root: {
        color: "#6bce90 !important",
        "&$checked": {
            color: "#6bce90 !important",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SelectContact = (props) => {
    const classes = useStyles();
    const [selectedTags, setSelectedTags] = React.useState([]);
    const [newContactCreated, setNewContactCreated] = React.useState("");

    const [filteredContact, setFilteredContact] = React.useState(
        props.form.accountData?.contactList || []
    );

    const contactList = useGetAPICallQuery("contact");
    const [tags, setTags] = React.useState(model);
    React.useEffect(() => {
        if (props.form.accountData.contactTags) {
            let options = tags.tags.options;
            props.form.accountData.contactTags.map((item) => {
                let filteredTag = options.filter(
                    (opt) => item === opt.value
                )[0];
                if (!filteredTag && item) {
                    options.push({
                        label: item,
                        value: item,
                    });
                }
            });
            setTags({
                ...model,
                tags: {
                    ...model.tags,
                    options: options,
                },
            });
        }
    }, []);

    React.useEffect(() => {
        if (contactList.data) {
            let oldContact = contactList.data?.contactList;
            if (props.form.selectedLegacy?.delivery?.contactID) {
                let contactSplit =
                    props.form.selectedLegacy.delivery.contactID.split(";");
                    if(newContactCreated){
                        contactSplit.push(newContactCreated);
                    }                    
                    setNewContactCreated("");
                if (contactSplit.length > 0) {
                    contactSplit.map((item) => {
                        if (item) {
                            contactList.data.contactList.map(
                                (contact, index) => {
                                    if (
                                        parseInt(item) ===
                                        parseInt(contact.system.id)
                                    ) {
                                        oldContact = update(oldContact, {
                                            [index]: {
                                                $set: {
                                                    ...contact,
                                                    checked: true,
                                                },
                                            },
                                        });
                                    }
                                    props.form.accountData.contactList.map(
                                        (con) => {
                                            if (
                                                parseInt(contact.system.id) ===
                                                    parseInt(con.system.id) &&
                                                con.checked
                                            ) {
                                                oldContact = update(
                                                    oldContact,
                                                    {
                                                        [index]: {
                                                            $set: {
                                                                ...con,
                                                                checked: true,
                                                            },
                                                        },
                                                    }
                                                );
                                            }
                                        }
                                    );
                                }
                            );
                        }
                    });
                }
            } else {
                let list = contactList.data?.contactList;
                list.map((item, index) => {
                    props.form.accountData.contactList.map((contact) => {
                        if (
                            parseInt(item.system.id) ===
                                parseInt(contact.system.id) &&
                            contact.checked
                        ) {
                            oldContact = update(oldContact, {
                                [index]: {
                                    $set: {
                                        ...contact,
                                        checked: true,
                                    },
                                },
                            });
                        }

                        
                    });

                    if(newContactCreated === item.system.id) {
                        oldContact = update(oldContact, {
                            [index]: {
                                $set: {
                                    ...item,
                                    checked: true,
                                },
                            },
                        });
                    }

                });
            }
            let contactTags = entityTagList(sortContactAscending(oldContact));
            if (props.form.accountData.contactTags) {
                let options = tags.tags.options;
                contactTags.map((item) => {
                    let filteredTag = options.filter(
                        (opt) => item === opt.value
                    )[0];
                    if (!filteredTag && item) {
                        options.push({
                            label: item,
                            value: item,
                        });
                    }
                });
                setTags({
                    ...oldContact,
                    tags: {
                        ...oldContact.tags,
                        options: options,
                    },
                });
            }
            setFilteredContact(sortContactAscending(oldContact));
            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    contactList: sortContactAscending(oldContact),
                    contactTags: contactTags,
                },
            });
        }
    }, [contactList.data]);

    const getContacts = (type) => {
        return (
            <React.Fragment>
                {props.form.contactFormPopup && (
                    <AddContact
                        open={props.form.contactFormPopup}
                        hideAddContact={hideAddContact}
                        addTitle={props.form.language.CONTACT_LABEL_CAPS}
                        query={contactList}
                        setNewContactCreated={(id)=>setNewContactCreated(id)}
                        isMessage
                    />
                )}
                {filteredContact?.map((item) => {
                    let checked = false;
                    if (props.form.selectedLegacy?.delivery?.contactID) {
                        let split =
                            props.form.selectedLegacy.delivery.contactID.split(
                                ":"
                            );
                        if (split.length > 0) {
                            split.map((media) => {
                                if (item && media == item.system.id) {
                                    checked = true;
                                }
                            });
                        }
                    }
                    // if (item.mediaType === type) {
                    return (
                        <FormControlLabel
                            style={{ display: "flex" }}
                            className="media-select"
                            control={
                                <GreenCheckbox
                                    checked={
                                        checked
                                            ? checked
                                            : item.checked
                                            ? item.checked
                                            : false
                                    }
                                    onChange={contactChangeHandler}
                                    name={
                                        item.privateData?.firstName +
                                        " " +
                                        item.privateData?.lastName
                                    }
                                    id={item.system.id}
                                />
                            }
                            label={
                                item.privateData?.firstName +
                                " " +
                                item.privateData?.lastName
                            }
                        />
                    );
                    // }
                })}
            </React.Fragment>
        );
    };
    const contactChangeHandler = (event) => {
        let oldfilteredContact = filteredContact;
        oldfilteredContact.map((item, index) => {
            if (parseInt(item.system.id) === parseInt(event.target.id)) {
                oldfilteredContact = update(oldfilteredContact, {
                    [index]: {
                        $set: { ...item, checked: event.target.checked },
                    },
                });
            }
        });

        let oldContact = props.form.accountData.contactList;

        oldContact.map((item, index) => {
            if (parseInt(item.system.id) === parseInt(event.target.id)) {
                oldContact = update(oldContact, {
                    [index]: {
                        $set: { ...item, checked: event.target.checked },
                    },
                });
            }
        });
        props.addAttribute({
            name: "accountData",
            value: {
                ...props.form.accountData,
                contactList: sortContactAscending(oldContact),
            },
        });
        setFilteredContact(sortContactAscending(oldfilteredContact));
    };
    const cancel = () => {
        props.openFutureContact();
    };

    const showAddContact = () => {
        props.addAttribute({
            name: "contactFormPopup",
            value: true,
        });
    };

    const hideAddContact = () => {
        props.addAttribute({
            name: "contactFormPopup",
            value: false,
        });
        props.addAttribute({
            name: "editContact",
            value: {},
        });
    };
    return (
        <Paper className={classes.root + " mm-login-container"}>
            <div className="" style={{ marginBottom: "16px" }}>
                <div className="mm-login-header display-flex-center">
                    {props.form.language.FUTURE_MESSAGE_S3}
                    <span
                        className="add-new-attachment"
                        style={{display: "flex"}}
                        onClick={showAddContact}
                    >
                        {`${props.form.language.CONTACT_LABEL_CAPS} ${props.form.language.ADD_TEXT}  `}
                        <AddIcon />
                    </span>
                </div>
            </div>

            <div style={{ marginBottom: "20px", marginLeft: "-5px" }}>
                <FilterTags
                    tags={tags.tags.options}
                    selectedTags={selectedTags}
                    updateTagList={(tag) => {
                        let data = [];
                        if (props.form.accountData.contactList) {
                            props.form.accountData.contactList.map(
                                (contact) => {
                                    
                                    if (tag.value && tag.value.length > 0) {
                                        tag.value.map((item) => {
                                            let filteredData = data.filter(item=> item.system.id === contact.system.id)[0];
                                            if (
                                                !filteredData && filterContacts(
                                                    item,
                                                    contact.meta.tags
                                                )
                                            ) {
                                                data.push(contact);
                                            }
                                        });
                                    } else {
                                        let filteredData = data.filter(item=> item.system.id === contact.system.id)[0];
                                        if(!filteredData){
                                            data.push(contact);
                                        }
                                        
                                    }
                                    
                                }
                            );
                        }

                        setFilteredContact(sortContactAscending(data));
                        setSelectedTags(tag);
                    }}
                />
            </div>

            {getContacts()}
            <div className="mm-input-submit" style={{ display: "flex" }}>

                <button
                    style={{
                        textAlign: "center",
                        width: "360px",
                        marginTop: "24px",
                        marginRight: "20px",
                    }}
                    className="primary-button-blue"
                    onClick={() => props.openFutureContact()}
                >
                    {props.form.language.SAVE_SELECTION}
                </button>
                <button
                    style={{
                        textAlign: "center",
                        width: "250px",
                        marginTop: "24px",
                        
                    }}
                    className="secondary-button"
                    onClick={cancel}
                >
                    {props.form.language.CANCEL}
                </button>
            </div>
        </Paper>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SelectContact)
);
