/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { connect } from 'react-redux';

const Transportation = (props) => {
    const [personName, setPersonName] = React.useState([]);
    const [personalData, setpersonalData] = React.useState({});
    const [isEdit, setEdit] = React.useState(false);
    const saveEwill = () => {
        let mediaListID = ';';
        if (personName.length > 0) {
            personName.map((media) => {
                mediaListID = mediaListID + media.system.id + ';';
            });
        }
        let json = {
            url: 'lastwishes',
            mode: 'merge',
            method: props.form.accountData.lastwishes.funeralPolis ? 'PUT' : 'POST',
            ...props.form.accountData.lastwishes,
            transportation: {
                additionalInfo: personalData.additionalInfo,
                funeralProcessionChoice: personalData.funeralProcessionChoice,
                transportationPreference: personalData.transportationPreference,
                followingCarsChoice: personalData.followingCarsChoice,
                numberOfCars: personalData.numberOfCars,
                followingCarContactID: mediaListID,
            },
        };
        props.handleRequest('update', json);
        setEdit(false);
    };
    const deleteEwill = () => {
        let data = {...props.form.accountData.lastwishes};
        delete data.transportation;
        let json = {
            url: 'lastwishes',
            mode: 'overwrite',
            method: 'PUT',
            ...data,
        };
        props.handleRequest('update', json, 'transportation');
    };

    React.useEffect(() => {
        if (
            props.form.accountData &&
            props.form.accountData.lastwishes &&
            props.form.accountData.lastwishes.transportation &&
            props.form.accountData.lastwishes.transportation.funeralProcessionChoice
        ) {
            let data = props.form.accountData.lastwishes.transportation;
            if (data && data.followingCarContactID) {
                let contact = data.followingCarContactID.split(';');
                if (contact.length > 0) {
                    let person = [];
                    contact.map((item) => {
                        if (
                            item &&
                            item !== ';' &&
                            props.form.accountData?.contactList?.length > 0
                        ) {
                            let x = props.form.accountData.contactList.filter(
                                (con) => con.system.id == item
                            );
                            person.push(x[0]);
                        }
                    });
                    setPersonName(person);
                }
            }
            setpersonalData(data);
        } else {
            setPersonName([]);
            setpersonalData({
                additionalInfo: '',
                funeralProcessionChoice: 'yes',
                transportationPreference: '',
                followingCarsChoice: 'yes',
                numberOfCars: '0',
                followingCarContactID: '',
            });
            setEdit(true);
        }
    }, [props.form.accountData && props.form.accountData.lastwishes]);

    const changeHandler = (event, personal) => {
        let data = {...personalData};
        data[event.target.name] = event.target.value;
        setpersonalData(data);
    };

    return (
        <React.Fragment>
            {!isEdit && (
                <React.Fragment>
                    <div
                        style={{ display: 'flex' }}
                        className='flex-direction-column input-container-column'
                    >
                        <div className='momentio-text-normal accordion-content-option-label'>
                            {props.form.language.TRANPORT_Q1}
                        </div>
                        <div className='momentio-text-bold momentio-ewill-value'>
                            {personalData.funeralProcessionChoice === 'yes'
                                ? props.form.language.YES_TEXT
                                : personalData.funeralProcessionChoice === 'no'
                                ? props.form.language.NONE_TEXT
                                : personalData.funeralProcessionChoice === 'nik'
                                ? props.form.language.NEXT_TO_KIN
                                : ''}
                        </div>
                    </div>

                    {personalData.funeralProcessionChoice === 'yes' && (
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.TRANPORT_Q2}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.transportationPreference}
                            </div>
                        </div>
                    )}

                    {personalData.funeralProcessionChoice === 'yes' && (
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.TRANPORT_Q3}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.followingCarsChoice === 'yes'
                                    ? props.form.language.YES_TEXT
                                    : personalData.followingCarsChoice === 'no'
                                    ? props.form.language.NONE_TEXT
                                    : personalData.followingCarsChoice === 'nik'
                                    ? props.form.language.NEXT_TO_KIN
                                    : ''}
                            </div>
                        </div>
                    )}

                    {personalData.funeralProcessionChoice === 'yes' &&
                        personalData.followingCarsChoice === 'yes' && (
                            <div
                                style={{ display: 'flex' }}
                                className='flex-direction-column input-container-column'
                            >
                                <div className='momentio-text-normal accordion-content-option-label'>
                                    {props.form.language.TRANPORT_Q4}
                                </div>
                                <div className='momentio-text-bold momentio-ewill-value'>
                                    {personalData.numberOfCars}
                                </div>
                            </div>
                        )}
                    {personalData.funeralProcessionChoice === 'yes' &&
                        personalData.followingCarsChoice === 'yes' && (
                            <div
                                style={{ display: 'flex' }}
                                className='flex-direction-column input-container-column'
                            >
                                <div className='momentio-text-normal accordion-content-option-label'>
                                    {props.form.language.TRANPORT_Q5}
                                </div>
                                <div className='momentio-text-bold momentio-ewill-value'>
                                    {personName.map((item) => {
                                        if (item) {
                                            return (
                                                <span style={{ marginRight: '10px' }}>
                                                    {item.privateData.firstName + ' ' + item.privateData.lastName}
                                                </span>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        )}
                    {personalData.funeralProcessionChoice === 'yes' && (
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.ADDITIONAL_TITLE}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.additionalInfo}
                            </div>
                        </div>
                    )}
                    <div className='secondary-alert-sm' onClick={() => setEdit(true)}>
                        {' '}
                        <i className='fa fa-pencil'></i>
                    </div>
                </React.Fragment>
            )}
            {isEdit && (
                <React.Fragment>
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.TRANPORT_Q1}
                        </div>
                        <div className=''>
                            <RadioGroup
                                className='mm-field-input'
                                style={{ flexDirection: 'row' }}
                                name={'funeralProcessionChoice'}
                                value={personalData.funeralProcessionChoice}
                                onChange={(event) => changeHandler(event, personalData)}
                            >
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio />}
                                    label={props.form.language.YES_TEXT}
                                />
                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio />}
                                    label={props.form.language.NONE_TEXT}
                                />

                                <FormControlLabel
                                    value={'nik'}
                                    control={<Radio />}
                                    label={props.form.language.NEXT_TO_KIN}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                    {personalData.funeralProcessionChoice === 'yes' && (
                        <div>
                            <div className='login-field-label login-field-label-height'>
                                {props.form.language.TRANPORT_Q2}
                            </div>
                            <div className='login-field-input'>
                                <input
                                    type='text'
                                    autoComplete="off"
                                    placeholder={props.form.language.TRANPORT_Q2}
                                    name='transportationPreference'
                                    value={personalData.transportationPreference}
                                    onChange={(event) => changeHandler(event, personalData)}
                                />
                            </div>
                        </div>
                    )}
                    {personalData.funeralProcessionChoice === 'yes' && (
                        <div>
                            <div className='login-field-label login-field-label-height'>
                                {props.form.language.TRANPORT_Q3}
                            </div>
                            <div className=''>
                                <RadioGroup
                                    className='mm-field-input'
                                    style={{ flexDirection: 'row' }}
                                    name={'followingCarsChoice'}
                                    value={personalData.followingCarsChoice}
                                    onChange={(event) => changeHandler(event, personalData)}
                                >
                                    <FormControlLabel
                                        value={'yes'}
                                        control={<Radio />}
                                        label={props.form.language.YES_TEXT}
                                    />
                                    <FormControlLabel
                                        value={'no'}
                                        control={<Radio />}
                                        label={props.form.language.NONE_TEXT}
                                    />
                                    <FormControlLabel
                                        value={'nik'}
                                        control={<Radio />}
                                        label={props.form.language.NEXT_TO_KIN}
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                    )}
                    {personalData.funeralProcessionChoice === 'yes' &&
                        personalData.followingCarsChoice === 'yes' && (
                            <div>
                                <div className='login-field-label login-field-label-height'>
                                    {props.form.language.TRANPORT_Q4}
                                </div>
                                <div className='login-field-input'>
                                    <input
                                        type='text'
                                        autoComplete="off"
                                        placeholder={props.form.language.TRANPORT_Q4}
                                        name='numberOfCars'
                                        value={personalData.numberOfCars}
                                        onChange={(event) => changeHandler(event, personalData)}
                                    />
                                </div>
                            </div>
                        )}
                    {personalData.funeralProcessionChoice === 'yes' &&
                        personalData.followingCarsChoice === 'yes' && (
                            <div>
                                <div className='login-field-label login-field-label-height'>
                                    {props.form.language.TRANPORT_Q5}
                                </div>
                                <div className='login-field-input'>
                                    <Autocomplete
                                        multiple
                                        autoComplete="off"
                                        size='small'
                                        options={
                                            props.form.accountData && props.form.accountData.contactList
                                                ? props.form.accountData.contactList
                                                : []
                                        }
                                        getOptionLabel={(option) =>
                                            option ? option.privateData?.firstName + ' ' + option.privateData?.lastName : ''
                                        }
                                        onChange={(event, values) => setPersonName(values)}
                                        defaultValue={personName}
                                        renderInput={(params) => (
                                            <TextField {...params} variant='standard' />
                                        )}
                                    />
                                </div>
                            </div>
                        )}{' '}
                    {personalData.funeralProcessionChoice === 'yes' && (
                        <div>
                            <div className='login-field-label login-field-label-height'>
                                {props.form.language.ADDITIONAL_TITLE}
                            </div>
                            <div className='login-field-input'>
                                <textarea
                                    style={{ height: 'auto' }}
                                    rows='4'
                                    autoComplete="off"
                                    type='text'
                                    placeholder={props.form.language.ADDITIONAL_TITLE}
                                    name='additionalInfo'
                                    value={personalData.additionalInfo}
                                    onChange={(event) => changeHandler(event, personalData)}
                                />
                            </div>
                        </div>
                    )}
                    <div>

                        <button
                            style={{
                                textAlign: 'center',
                                width: '150px',
                                marginTop: '24px',
                            }}
                            className='primary-button-blue'
                            onClick={() => saveEwill(personalData)}
                        >
                            {props.form.language.SAVE}
                        </button>
                        <button
                            style={{
                                textAlign: 'center',
                                width: '150px',
                                marginTop: '24px',
                                backgroundColor: '#F45B5B !important',
                                marginLeft: '15px',
                            }}
                            className='primary-button-red'
                            onClick={() => deleteEwill()}
                        >
                            {props.form.language.DELETE}
                        </button>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

export default connect(mapStateToProps)(Transportation);
