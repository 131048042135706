import Popper from "@material-ui/core/Popper";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React from "react";
import { connect } from "react-redux";
import { addAttribute } from "../../../../Actions/Form";

const InsuranceOverview = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const closePopper = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <div onMouseLeave={closePopper}>
        <React.Fragment>
          {props.form.selectedInsurance &&
            props.form.selectedInsurance.ID === props.data.ID && (
              <Popper open={open} anchorEl={anchorEl}>
                <div
                  className="menu-container"
                  onMouseLeave={() => closePopper(false)}
                  style={{
                    marginLeft: "-175px",
                    marginTop: "0px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="menu-options"
                    onClick={() => {
                      closePopper("bottom-end");
                      props.showAddInsurance(props.data);
                    }}
                  >
                    {props.form.language.EDIT}
                  </div>
                  <div
                    className="menu-options"
                    onClick={() => {
                      closePopper("bottom-end");
                      props.handleRequest("delete", props.data.ID);
                      props.addAttribute({
                        name: "selectedInsurance",
                        value: {},
                      });
                    }}
                  >
                    {props.form.language.DELETE}
                  </div>
                </div>
              </Popper>
            )}
        </React.Fragment>

        <div className="overviewContainer">
          <div
            className="overviewMenu"
            onClick={(event) => {
              handleClick(event);
              props.addAttribute({
                name: "selectedInsurance",
                value: props.data,
              });
            }}
          >
            <MoreHorizIcon className='show-menu-icon' />
          </div>
          <div
            className="momentio-text-bold"
            style={{ marginTop: "-5px", width: "100%" }}
          >
            <div style={{ display: "flex" }} className="input-container-column">
              <div
                className="accordion-content-option-label header24Medium"
                style={{ marginBottom: "10px" }}
              >
                {props.data.insuranceType === "travel"
                  ? props.form.language.POLICY_TRAVEL
                  : props.data.insuranceType === "life"
                  ? props.form.language.POLICY_LIFE
                  : props.data.insuranceType === "car"
                  ? props.form.language.POLICY_CAR
                  : props.data.insuranceType === "household"
                  ? props.form.language.HOUSE_LABEL
                  : props.data.insuranceType === "fire"
                  ? props.form.language.POLICY_FIRE
                  : props.data.insuranceType === "glass"
                  ? props.form.language.POLICY_GLASS
                  : props.data.insuranceType === "liability"
                  ? props.form.language.POLICY_LIABILITY
                  : props.data.insuranceType === "other"
                  ? props.form.language.TYPE_OTHER
                  : ""}
              </div>
            </div>

            <div style={{ display: "flex" }} className="input-container-column">
              <div className="momentio-text-normal accordion-content-option-label">
                {props.form.language.POLICY_INSURER}
              </div>
              <div className="momentio-text-bold" style={{ width: "70%" }}>
                {props.data.insurer}
              </div>
            </div>

            <div style={{ display: "flex" }} className="input-container-column">
              <div className="momentio-text-normal accordion-content-option-label">
                {props.form.language.POLICY_NAME}
              </div>
              <div className="momentio-text-bold" style={{ width: "70%" }}>
                {props.data.polisName}
              </div>
            </div>

            <div style={{ display: "flex" }} className="input-container-column">
              <div className="momentio-text-normal accordion-content-option-label">
                {props.form.language.POLICY_NUMBER}
              </div>
              <div className="momentio-text-bold" style={{ width: "70%" }}>
                {props.data.polisNumber}
              </div>
            </div>

            <div style={{ display: "flex" }} className="input-container-column">
              <div className="momentio-text-normal accordion-content-option-label">
                {props.form.language.EXTRA_INFO}
              </div>
              <div className="momentio-text-bold" style={{ width: "70%" }}>
                {props.data.remark}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addAttribute: (payload) => dispatch(addAttribute(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InsuranceOverview);
