/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// eslint-disable jsx-a11y/anchor-is-valid
// eslint-disable jsx-a11y/alt-text
// eslint-disable no-useless-escape
// eslint-disable no-useless-concat
// eslint-disable default-case
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { addAttribute, AddProfileAPI } from "../../../Actions/Form";
import AddContact from "../../Contacts/AddContact";
import ContactOverview from "../../Contacts/ContactOverview";
import {
    useMutationAPICallMutation,
    useGetAPICallQuery,
} from "../../../app/services/account";
import { getTags, filterContacts } from "../../Contacts/ContactTags";
import { sortContactAscending } from "../../../Utility/Utility";

const Contacts = (props) => {
    const contactList = useGetAPICallQuery("contact");
    const [updateAccount, { isLoading, error }] = useMutationAPICallMutation();

    React.useEffect(() => {
        if (contactList.data) {
            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    contactList: sortContactAscending(contactList.data.contactList),
                    // profile: props.form.accountData && props.form.accountData.profile ? props.form.accountData.profile : localStorage.getItem("profile")
                },
            });
            if (contactList.data.contactList?.length) {
                let data = [];
                contactList.data.contactList.map((contact) => {
                    if (filterContacts(props.type, contact.meta.tags)) {
                        data.push(contact);
                    }
                });
                setpersonalData(data);
            }
        }
    }, [contactList.data]);

    const [personalData, setpersonalData] = React.useState([]);
    const [currentType, setcurrentType] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const setDefault = async (type, value) => {
        let profileData = JSON.parse(
            JSON.stringify(props.form.accountData.profile)
        );
        if (profileData.settings) {
            switch (type) {
                case "partner":
                    profileData.settings.hasPartner = "no";
                    break;
                case "child":
                    profileData.settings.hasChildren = "no";
                    break;
                case "parent":
                    profileData.settings.hasParents = "no";
                    break;
                case "guardian":
                    profileData.settings.hasGuardian = "no";
                    break;
                case "admin":
                    profileData.settings.hasCareGivers = "no";
                    break;
            }
            if (profileData.privateData.birthDate === "") {
                delete profileData.privateData.birthDate;
            } else {
                profileData.privateData.birthDate = moment(
                    profileData.privateData.birthDate,
                    "DD-MM-YYYY"
                ).format("YYYY-MM-DD");
            }

            let contactJson = {
                url: "account",
                method: "PUT",
                username: profileData.settings["username"],
                settings: {
                    username: profileData.settings.username,
                    languageISOCode: profileData.settings.languageISOCode,
                    hasGuardian: profileData.settings.hasGuardian
                        ? profileData.settings.hasGuardian
                        : "yes",
                    hasParents: profileData.settings.hasParents
                        ? profileData.settings.hasParents
                        : "yes",
                    hasPartner: profileData.settings.hasPartner
                        ? profileData.settings.hasPartner
                        : "yes",
                    hasChildren: profileData.settings.hasChildren
                        ? profileData.settings.hasChildren
                        : "yes",
                    hasCareGivers: profileData.settings.hasCareGivers
                        ? profileData.settings.hasCareGivers
                        : "yes",
                    hasSocialMedia: profileData.settings.hasSocialMedia
                    ? profileData.settings.hasSocialMedia
                    : "no",
                },
                privateData: profileData.privateData,
                contact: profileData.contact,
                physicalAddress: profileData.physicalAddress,
                //...profileData,
            };
            await updateAccount(contactJson)
                .unwrap()
                .then((response) => {
                    handleSuccess(response);
                });
        }
    };

    const handleSuccess = (response) => {
        const profileData = {
            ...props.form.accountData.profile,
            settings: response.settings,
        };
        props.addAttribute({
            name: "accountData",
            value: {
                ...props.form.accountData,
                profile: profileData,
            },
        });
        localStorage.setItem("profile", JSON.stringify(profileData));

    };
    const showAddContact = (type, contact) => {
        //  contact.birthDate = responseFormatDate(contact.birthDate);
        setcurrentType(type);
        setTimeout(() => {
            if (contact) {
                props.addAttribute({
                    name: "contactFormPopup",
                    value: true,
                });
            } else {
                props.addAttribute({
                    name: "editContact",
                    value: {},
                });
                props.addAttribute({
                    name: "contactFormPopup",
                    value: true,
                });
            }
        }, 300);
    };

    const hideAddContact = () => {
        props.addAttribute({
            name: "contactFormPopup",
            value: false,
        });
        props.addAttribute({
            name: "editContact",
            value: {},
        });
    };
    return (
        <React.Fragment>
            {isLoading && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            {props.type === props.form.ewillStep &&
                props.form.contactFormPopup && (
                    <AddContact
                        contactRole={props.type}
                        open={props.form.contactFormPopup}
                        hideAddContact={hideAddContact}
                        addTitle={props.data.name}
                        query={contactList}
                        getCompleted={()=>props.getCompleted()}
                    />
                )}
            {personalData && personalData.length > 0 ? (
                personalData.map((data) => {
                    if (filterContacts(props.type, data.meta.tags)) {
                        return (
                            <ContactOverview
                                showAddContact={() => {
                                    showAddContact(props.form.ewillStep, data);
                                }}
                                getcom
                                data={data}
                                addTitle={props.data.name}
                                type={props.type}
                                query={contactList}
                            />
                        );
                    }
                })
            ) : (
                <React.Fragment></React.Fragment>
            )}
            <span
                style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "8px",
                }}
                
                
            >
                <span className="" >
                    <span
                        className="ewill-secondary-alert-sm"
                    >
                        <AddIcon  id={props.type} onClick={(event) => {
                    if (
                        event.currentTarget.id === props.form.ewillStep
                    ) {
                        showAddContact(props.form.ewillStep);
                    }
                }}/>
                    </span>
                </span>
                <span
                    className="momentio-text-normal"
                    style={{ marginLeft: "15px" }}
                >
                    {personalData.length > 0
                        ? props.data.addAnotherMsg
                        : props.data.addFirstMsg}
                </span>
                {props.data.hasData &&
                    personalData.length < 1 &&
                    props.form.accountData &&
                    props.form.accountData.profile &&
                    props.form.accountData.profile.settings &&
                    props.form.accountData.profile.settings[
                        props.data.hasData
                    ] !== "no" && (
                        <button
                            style={{ marginLeft: "24px" }}
                            className="secondary-button"
                            onClick={() => {
                                setDefault(props.type);
                            }}
                        >
                            {props.data.noCategoryMsg}
                        </button>
                    )}
            </span>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
        AddProfileAPI: (payload, type) =>
            dispatch(AddProfileAPI(payload, type)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
