/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { addAttribute } from "../../Actions/Form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AddTags from "../Common/AddTags";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        boxShadow: "none",
        width: "100%",
    },
});

const SelectMedia = (props) => {
    const classes = useStyles();
    const [newTag, setNewTag] = React.useState("");

    return (
        <Paper className={classes.root + " mm-login-container"}>
            <div className="" style={{ marginBottom: "20px" }}>
                <div className="mm-login-header">
                    {props.form.language.FUTURE_MESSAGE_S5}
                </div>
            </div>
            <AddTags
                tags={props.tags}
                selectedTags={props.selectedTags}
                openFutureTags={() => props.openFutureTags()}
                updateTagList={( tags) => props.updateTagList(tags)}
            />
            <div className="mm-input-submit" style={{ display: "flex" }}>

                <button
                    style={{
                        textAlign: "center",
                        width: "360px",
                        marginTop: "24px",
                        marginRight: "20px",
                    }}
                    className="primary-button-blue"
                    onClick={props.openFutureTags}
                >
                    {" "}
                    {props.form.language.SAVE}
                </button>
                <button
                    style={{
                        textAlign: "center",
                        width: "250px",
                        marginTop: "24px",
                        
                    }}
                    className="secondary-button"
                    onClick={props.openFutureTags}
                >
                    {props.form.language.CANCEL}
                </button>
            </div>
        </Paper>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SelectMedia)
);
