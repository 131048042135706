/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import {CircularProgress, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { useMutationAPICallMutation,useGetAPICallQuery } from "../../app/services/account";
import { sortContactAscending } from "../../Utility/Utility";

const ChangeNextToKin = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [changeHeir, setChangeHeir] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [personName, setPersonName] = React.useState([]);
    const contactList = useGetAPICallQuery("contact");

    const hideChangeHeir = () => {
        setChangeHeir(false);
        setNextToKin();
    };
    const [updateAccount] = useMutationAPICallMutation();
    const saveHeir = async () => {
        await contactList.data?.contactList?.map(async contact => {
            if(contact.settings.isHeir === "yes" ) {
                let heir = personName.filter(person =>  person.system.id === contact.system.id);
                if(heir.length > 0) {
                    await syncHeir(contact.system.id, "yes");
                } else {
                    await syncHeir(contact.system.id, "no");
                }
            } else {
                let heir = personName.filter(person =>  person.system.id === contact.system.id);
                if(heir.length > 0) {
                    await syncHeir(contact.system.id, "yes");
                }
            }
        });
        await setTimeout(() => {
            contactList.refetch();
            hideChangeHeir();
            setLoading(false);
        }, 4000);
        
    }
    const syncHeir = async (id, isHeir) => {
        setLoading(true);
        let contactData = contactList.data.contactList.filter(item=>id === item.system.id)[0];
        var contactJson = {
            url: `contact/${id}`,
            method: "PUT",
            ...contactData,
            settings: {
                ...contactData.settings,
                isHeir: isHeir,
            },
        };

        await updateAccount(contactJson)
            .unwrap()
            .then((response) => {
                handleSuccess(response);
            })
            .catch(() => {
                // setLoading(false);
                
            });
        //setLoading(false);
    };

    const setNextToKin = () => {
        if (
            contactList &&
            contactList.data &&
            contactList.data.contactList
        ) {
            let person = [];
            contactList.data.contactList.map(item => {
                if(item.settings.isHeir === "yes") {
                    person.push(item);
                }
            });
            setPersonName(person);
        } else {
            setPersonName([]);
        }
    }

    React.useEffect(() => {
        setNextToKin()
    }, [contactList.data?.contactList]);

    return (
        <React.Fragment>
            {loading && (
                <div
                    className="overlay-momentio"
                    style={{ zIndex: 1000000000000 }}
                >
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}

            <div
                style={{ display: 'flex' }}
                className='flex-direction-column input-container-column'
            >
                <div className='secondary-alert-sm' onClick={() => setChangeHeir(true)} style={{marginTop: '-7px'}}>
                        <i className='fa fa-pencil'></i>
                    </div>
                <div className='momentio-text-normal accordion-content-option-label'>
                    {props.form.language.MYCONTACT_S25}
                </div>
                <div className='momentio-text-bold momentio-ewill-value'>
                    {personName.map((item) => {
                        if (item) {
                            return (
                                <span style={{ marginRight: '10px' }}>
                                    {item.privateData.firstName + ' ' + item.privateData.lastName},
                                </span>
                            );
                        }
                    })}
                </div>
            </div>
            <Dialog
                open={changeHeir}
                fullScreen={fullScreen}
                disableEscapeKeyDown={true}
                onClose={hideChangeHeir}
                aria-labelledby="responsive-dialog-title"
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <div
                        className="mm-form-container"
                    >
                        <div
                            className="mm-login-container"
                            style={{ boxShadow: "none" }}
                        >
                            <div className="mm-login-header">
                                {props.form.language.CHANGE_NEXTTOKIN}
                            </div>

                            <div>
                                <div className='login-field-label login-field-label-height'>
                                    {props.form.language.MYCONTACT_S25}
                                </div>
                                <div className='login-field-input'>
                                    <Autocomplete
                                        multiple
                                        size='small'
                                        options={
                                            contactList.data?.contactList
                                                ? sortContactAscending(contactList.data.contactList.filter(item=>item.contact.email))
                                                : []
                                        }
                                        getOptionLabel={(option) =>
                                            option ? option.privateData?.firstName + ' ' + option.privateData?.lastName : ''
                                        }
                                        onChange={(event, values) => setPersonName(values)}
                                        defaultValue={personName}
                                        renderInput={(params) => (
                                            <TextField {...params} variant='standard' />
                                        )}
                                    />
                                </div>
                            </div>
                            <div
                                className="mm-input-submit"
                                style={{
                                    display: "flex",
                                    marginBottom: "15px",
                                    justifyContent: "center",
                                }}
                            >
                                
                                <button
                                    style={{
                                        textAlign: "center",
                                        width: "150px",
                                        marginTop: "24px",
                                        marginRight: "20px",
                                    }}
                                    className="primary-button-blue"
                                    onClick={() => {
                                        saveHeir();
                                    }}
                                >
                                    {props.form.language.SAVE}
                                </button>
                                <button
                                    style={{
                                        textAlign: "center",
                                        marginTop: "24px",
                                        
                                    }}
                                    className="secondary-button"
                                    onClick={hideChangeHeir}
                                >
                                    {props.form.language.CANCEL}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps)(ChangeNextToKin);
