/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// eslint-disable no-eval
import axios from "axios";
import {
  addAttribute,
  isLoading,
  logOut,
  setcurrentAction,
} from "./Form";
import { decryptMessage, encryptMessage } from "../Constants/decryption";
import * as globalDut from "../Constants/i18n/dut";
import * as globalENU from "../Constants/i18n/enu";
import { getAPIBaseUrl } from "../Utility/Utility";
import moment from "moment"; 

export const triggersave = (function_name, input) => {
  return (dispatch, getState) => {
    dispatch(eval(function_name + "(" + JSON.stringify(input) + ")"));
  };
};

export const register = input =>{
  return (dispatch, getState) =>{
    let json = {
      username: input.Email.value,
      firstname: input.Firstname.value,
      lastname: input.Lastname.value,
      countryCode: input.Country.value,
      password: input.Password.value,
      languageCode: getState().form.currentLanguage,
      phone: input.Phone.value,
    };
    dispatch(restAPI(json, "register"));
  }
}

export const media = (input) => {
  return (dispatch, getState) => {
    // input = JSON.parse(input)
    let json = {
      name: input.name ? input.name.value : "",
      description: input.description ? input.description.value : "",
      ID: input.ID ? input.ID.value : "",
    };
    dispatch(restAPI(json, "media"));
    //input, dataType, targetAction, targetURL
  };
};
export const UploadUrl = (input) => {
  return (dispatch, getState) => {
    // input = JSON.parse(input)
    let json = {
      name: input.name ? input.name.value : "",

      fileName: input.fileName ? input.fileName.value : "",
      description: input.description ? input.description.value : "",
      mediaContentType: input.mediaContentType
        ? input.mediaContentType.value
        : "",
    };
    dispatch(restAPI(json, "UploadUrl", "uploadUrl", "UploadUrl"));
    //input, dataType, targetAction, targetURL
  };
};
export const accessWay = (input, request, query) => {
  return (dispatch, getState) => {
    // input = JSON.parse(input)
    let json = {
      url: request.url,
      method: request.method,
      system : {
        id: input.ID,
      },
      general: {
        name: input.remark ? input.remark.value : "",
        contactID: input.accessContactID
        ? (input.accessContactID.value + ";")
        : "",
      },
      accessWay: {
        accessWayType: "person",
        location: input.location ? input.location.value : "",
      },
    };
    dispatch(restAPI(json, "accessWay"));
  };
};

export const contacts = (contactData) => {
  return (dispatch, getState) => {

    const props  = getState();
    // input = JSON.parse(input)
    var contactJson = {
      url:
      contactData.ID.value
              ? `contact/${contactData.ID.value}`
              : 'contact',
      method: contactData && contactData.ID.value ? 'PUT' : 'POST',
      meta: {
          tags: '',
          additionalInfo: '',
      },
      contact: {
          email: contactData.email.value,
          phone: contactData.phone.value,
      },
      physicalAddress: {
          countryISOCode: contactData.countryISOCode.value,
          street: contactData.street.value,
          zipCode: contactData.zipCode.value,
          city: contactData.city.value,
      },
      privateData: {
          firstName: contactData.firstName.value,
          lastName: contactData.lastName.value,
          birthDate: moment(contactData.birthDate.value, 'DD-MM-YYYY').format(
              'YYYY-MM-DD'
          ),
      },
      settings: {
          isHeir: 'yes',
          inform: contactData.inform.value,
          informType: contactData.informType.value,
          languageISOCode: props.form?.accountData?.profile?.settings?.languageISOCode,
      },
  };
    dispatch(restAPI(contactJson, "contactList", "", `contact/${contactData.ID.value}`));
  };
};


export const messages = (input) => {
  return (dispatch, getState) => {
    // input = JSON.parse(input)
    let json = {
      contractType: input.contractType ? input.contractType.value : "",
      contractNumber: input.contractNumber ? input.contractNumber.value : "",
      customerNumber: input.customerNumber ? input.customerNumber.value : "",
      additionalInfo: input.additionalInfo ? input.additionalInfo.value : "",
      name: input.name ? input.name.value : "",
      ID: input.ID ? input.ID.value : "",
    };
    dispatch(restAPI(json, "contract"));
  };
};

export const contract = (input) => {
  return (dispatch, getState) => {
    // input = JSON.parse(input)
    let json = {
      contractType: input.contractType ? input.contractType.value : "",
      contractNumber: input.contractNumber ? input.contractNumber.value : "",
      customerNumber: input.customerNumber ? input.customerNumber.value : "",
      additionalInfo: input.additionalInfo ? input.additionalInfo.value : "",
      name: input.name ? input.name.value : "",
      ID: input.ID ? input.ID.value : "",
    };
    dispatch(restAPI(json, "contract"));
  };
};

export const financial = (input) => {
  return (dispatch, getState) => {
    let json = {
      financialType: input.financialType ? input.financialType.value : "",
      paymentPeriod: input.paymentPeriod ? input.paymentPeriod.value : "",
      paymentAmount: input.paymentAmount ? input.paymentAmount.value : "",
      name: input.name ? input.name.value : "",
      accountNumber: input.accountNumber ? input.accountNumber.value : "",
      nameInvestment: input.nameInvestment ? input.nameInvestment.value : "",
      remark: input.remark ? input.remark.value : "",
      financialContactID: input.financialContactID
        ? input.financialContactID.value
        : "",
      ID: input.ID ? input.ID.value : "",
    };
    dispatch(restAPI(json, "financial"));
  };
};

export const password = (input) => {
  return (dispatch, getState) => {
    let json = {
      accessType: input.accessType ? input.accessType.value : "",
      remark: input.remark ? input.remark.value : "",
      location: input.location ? input.location.value : "",
      accessContactID: input.accessContactID
        ? parseInt(input.accessContactID.value)
        : "",
      ID: input.ID ? input.ID.value : "",
      name: input.name ? input.name.value : "",
      userName: input.userName ? input.userName.value : "",
      additionalInfo: input.additionalInfo ? input.additionalInfo.value : "",
    };
    dispatch(restAPI(json, "password"));
  };
};

export const house = (input) => {
  return (dispatch, getState) => {
    let json = {
      houseType: input.houseType ? input.houseType.value : "",
      remark: input.remark ? input.remark.value : "",
      contactID: input.contactID ? input.contactID.value : "",
      mortgager: input.mortgager ? input.mortgager.value : "",
      polisNumber: input.polisNumber ? input.polisNumber.value : "",
      ID: input.ID ? input.ID.value : "",
    };

    dispatch(restAPI(json, "house"));
  };
};

export const insurance = (input) => {
  return (dispatch, getState) => {
    let json = {
      insuranceType: input.insuranceType.value,
      externalNumber: input.externalNumber.value,
      externalName: input.externalName.value,
      remark: input.remark ? input.remark.value : "",
      externalCompany: input.externalCompany ? input.externalCompany.value : "",
      ID: input.ID ? input.ID.value : "",
    };

    dispatch(restAPI(json, "insurance"));
  };
};

export const membership = (input) => {
  return (dispatch, getState) => {
    let json = {
      membershipType: input.membershipType.value,
      externalCompany: input.externalCompany
        ? input.externalCompany.value
        : "test",
      externalNumber: input.externalNumber.value,
      externalName: input.externalName.value,
      ID: input.ID ? input.ID.value : "",
    };
    dispatch(restAPI(json, "membership"));
  };
};

export const socialMedia = (input) => {
  return (dispatch, getState) => {
    let json = {
      socialMediaType: input.socialMediaType.value,
      userName: input.userName.value,
      ID: input.ID.value,
    };
    dispatch(restAPI(json, "socialMedia"));
  };
};

export const lwFuneralPolis = (input) => {
  return (dispatch, getState) => {
    let json = {
      hasPolis: input.hasPolis.value,
      name: input.hasPolis.value === "yes" ? input.name.value : "",
      polisNumber:
        input.hasPolis.value === "yes" ? input.polisNumber.value : "",
      remark: input.remark ? input.remark.value : "",
      ID: input.ID.value,
    };

    dispatch(restAPI(json, "lwFuneralPolis"));
  };
};
export const lwNotification = (input) => {
  return (dispatch, getState) => {
    let json = {
      informContactType: input.informContactType.value,
      funeralCard: input.funeralCard.value,
      message: input.message.value,
      location: input.location.value,
      advert: input.advert.value,
      ID: input.ID.value,
    };

    dispatch(restAPI(json, "lwNotification"));
  };
};

export const lwCarriers = (input) => {
  return (dispatch, getState) => {
    let json = {
      pallBearersChoice: input.pallBearersChoice
        ? input.pallBearersChoice.value
        : "",
      pallBearersContactListID: input.pallBearersContactListID
        ? input.pallBearersContactListID.value
        : "",
      pallBearersInformed: input.pallBearersInformed
        ? input.pallBearersInformed.value
        : "",
      additionalInfo: input.additionalInfo ? input.additionalInfo.value : "",
      remark: input.remark ? input.remark.value : "",
      ID: input.ID ? input.ID.value : "",
    };

    dispatch(restAPI(json, "lwCarriers"));
  };
};

export const lwOrganisation = (input) => {
  return (dispatch, getState) => {
    let json = {
      organizer: input.organizer.value,
      ceremony: input.ceremony.value,
      lyingState: input.lyingState.value,
      location: input.location ? input.location.value : "",
      coffinState: input.coffinState.value,
      condolanceType: input.condolanceType.value,
      remark: input.remark ? input.remark.value : "",
      contactListID: input.contactListID ? input.contactListID.value : "",
      ID: input.ID.value,
    };
    dispatch(restAPI(json, "lwOrganisation"));
  };
};

export const lwFuneralType = (input) => {
  return (dispatch, getState) => {
    let json = {
      funeralSpecial:
        input.typeOfFuneral.value === "militaryHonour"
          ? "militaryHonour"
          : "none",
      typeOfFuneral:
        input.typeOfFuneral.value === "militaryHonour"
          ? "none"
          : input.typeOfFuneral.value,
      funeralWay: input.funeralWay ? input.funeralWay.value : "",
      graveType: input.graveType ? input.graveType.value : "",
      ashDestinationType: input.ashDestinationType
        ? input.ashDestinationType.value
        : "",
      location: input.location ? input.location.value : "",
      coffinType: input.coffinType ? input.coffinType.value : "",
      remark: input.remark ? input.remark.value : "",
      ID: input.ID.value,
    };

    dispatch(restAPI(json, "lwFuneralType"));
  };
};

export const lwMusic = (input) => {
  return (dispatch, getState) => {
    let json = {
      musicPreference: input.musicPreference.value,
      musicChoice: input.musicChoice.value,
      remark: input.remark ? input.remark.value : "",
      ID: input.ID.value,
    };

    dispatch(restAPI(json, "lwMusic"));
  };
};

export const lwSpeaker = (input) => {
  return (dispatch, getState) => {
    let json = {
      speakersInformed: input.speakersInformed.value,
      speakerChoice: input.speakerChoice.value,
      speakerContactListID: input.speakerContactListID
        ? input.speakerContactListID.value
        : "",
      remark: input.remark ? input.remark.value : "",
      ID: input.ID.value,
    };

    dispatch(restAPI(json, "lwSpeaker"));
  };
};

export const lwTransportation = (input) => {
  return (dispatch, getState) => {
    let json = {
      funeralProcessionChoice: input.funeralProcessionChoice.value,
      transportationPreference: input.transportationPreference.value,
      followingCarsChoice: input.followingCarsChoice.value,
      numberOfCars: input.numberOfCars ? input.numberOfCars.value : "",
      followingCarContactListID: input.followingCarContactListID
        ? input.followingCarContactListID.value
        : "",
      remark: input.remark ? input.remark.value : "",
      ID: input.ID.value,
    };

    dispatch(restAPI(json, "lwTransportation"));
  };
};

export const lwFlowers = (input) => {
  return (dispatch, getState) => {
    let json = {
      graveFlowerType: input.graveFlowerType.value,
      coffinFlowerType: input.coffinFlowerType.value,
      flowersOnGrave: input.flowersOnGrave.value,
      flowersOnCoffin: input.flowersOnCoffin.value,
      dontWant: input.dontWant ? input.dontWant.value : "",
      remark: input.remark ? input.remark.value : "",
      ID: input.ID.value,
    };

    dispatch(restAPI(json, "lwFlowers"));
  };
};

export const lwMeal = (input) => {
  return (dispatch, getState) => {
    let json = {
      cofeeTableChoice: input.cofeeTableChoice.value,
      mealChoice: input.mealChoice.value,
      mealPreference: input.mealPreference.value,
      remark: input.remark ? input.remark.value : "",
      ID: input.ID.value,
    };

    dispatch(restAPI(json, "lwMeal"));
  };
};

export const lwMedia = (input) => {
  return (dispatch, getState) => {
    let json = {
      allowVideo: input.allowVideo.value,
      allowPhoto: input.allowPhoto.value,
      remark: input.remark ? input.remark.value : "",
      ID: input.ID.value,
    };
    dispatch(restAPI(json, "lwMedia"));
  };
};

export const lwMemories = (input) => {
  return (dispatch, getState) => {
    let json = {
      remembrancePoems: input.remembrancePoems.value,
      remark: input.remark ? input.remark.value : "",
      ID: input.ID.value,
    };
    dispatch(restAPI(json, "lwMemories"));
  };
};
export const restAPI = (input, dataType, targetAction, targetURL) => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    let action = "";
    if (targetAction) {
      action = targetAction;
    } else {
      if (input.ID) {
        action = "update";
      } else {
        action = "insert";
      }
    }

    const currentLanguage =
      getState().form.currentLanguage == "US" ? globalENU : globalDut;
    dispatch(setcurrentAction(dataType));

    let data = {
      action: action,
      dataType: dataType,
      timestamp: new Date().getTime(),
      element: input,
    };
    if (targetAction === "uploadUrl") {
      data.fileName = input.fileName;
    }
    //  };
      let url = getAPIBaseUrl();
      if (targetURL) {
        url = url + targetURL;
      } else {
        url = url + "Alter";
      }

      axios({
        method: input.method ? input.method : "POST",
        data: input,
        url: url,
        headers: {
          "Content-Type": "application/json",
          'Authorization': getState().form.token,
        },
      })
        .then(async (response) => {
          if (response.data.statusCode == 401 || response.data.statusCode == 403) {
            alert(
              currentLanguage.INVALID_SESSION
            );
            dispatch(
              addAttribute({
                name: "isSessionExpired",
                value: true,
              })
            );
            dispatch(logOut());
          } else if (response.data.statusCode != 200 && response.data.statusCode != 201) {
            alert(currentLanguage.ERROR_SAVING);
            dispatch(isLoading(false));
          } else if (response.data == null) {
            dispatch(isLoading(false));
            alert(currentLanguage.ERROR_SAVING);
          } else {

              if (targetAction === "uploadUrl") {
                var x = {};
                const formData = new FormData();
                Object.keys(JSON.parse(response.data).url.fields).forEach(
                  (key) => {
                    formData.append(
                      key,
                      JSON.parse(response.data).url.fields[key]
                    );
                  }
                );

                //   let fileInput =  document.getElementById("input__file");
                // Actual file has to be appended last.
                let fileInput = document.getElementById("uploadFile");
                // Actual file has to be appended last.
                formData.append("file", fileInput.files[0]);

                const xhr = new XMLHttpRequest();
                xhr.open("POST", JSON.parse(response.data).url.url, true);

                xhr.send(formData);
                xhr.onload = function () {
                  if (this.status === 204) {
                    console.log("success");
                    dispatch(restAPI({}, "List", "list", "List"));
                  } else {
                    console.log(this);
                  }
                };
              } else {
                let accountData = getState().form.accountData;
                if (targetAction === "list") {
                  document.getElementById("uploadFile").value = "";
                  accountData = JSON.parse(response.data);
                } else {
                  accountData[dataType] = response.data[dataType];
                }

                dispatch(
                  addAttribute({
                    name: "accountData",
                    value: accountData,
                  })
                );
              }

            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          alert(currentLanguage.ERROR_SAVING);
          dispatch(isLoading(false));
        });
  };
};
