/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import {
    AddProfileAPI,
    addAttribute,
    setLanguage,
} from "../../../Actions/Form";
import { EWillPersonal } from "../../../Models/Personal";
import ReactFlagsSelect from "react-flags-select";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMutationAPICallMutation } from "../../../app/services/account";

const Personal = (props) => {
    const [updateAccount, { isLoading, error }] = useMutationAPICallMutation();
    const saveEwill = async (data) => {
        let input = {};
        Object.keys(data).map((item) => {
            input[item] = {
                value: data[item],
            };
        });
        const languageISOCode =
            input.languageISOCode && input.languageISOCode.value === "US"
                ? "EN"
                : input.languageISOCode.value;
        let contactJson = {
            url: "account",
            method: "PUT",
            username: props.form.accountData.profile.settings.username,
            contact: {
                email: input["email"] ? input["email"].value : "",
                phone: input["phone"] ? input["phone"].value : "",
            },
            physicalAddress: {
                countryISOCode: input.countryISOCode
                    ? input.countryISOCode.value
                    : "",
                street: input.street ? input.street.value : "",
                zipCode: input.zipCode ? input.zipCode.value : "",
                city: input.city ? input.city.value : "",
            },
            privateData: {
                firstName: input.firstName ? input.firstName.value : "",
                lastName: input.lastName ? input.lastName.value : "",

                placeOfBirth: input["placeOfBirth"]
                    ? input["placeOfBirth"].value
                    : "",
                martialState: input.martialStateType
                    ? input.martialStateType.value
                    : "single",
                donorCodicil: input.donorCodicil
                    ? input.donorCodicil.value
                    : "no",
                religion: input.religion ? input.religion.value : "single",
            },
            settings: {
                username: props.form.accountData.profile.settings.username,
                languageISOCode: languageISOCode,
                hasGuardian: props.form.accountData.profile.settings.hasGuardian
                    ? props.form.accountData.profile.settings.hasGuardian
                    : "yes",
                hasParents: props.form.accountData.profile.settings.hasParents
                    ? props.form.accountData.profile.settings.hasParents
                    : "yes",
                hasPartner: props.form.accountData.profile.settings.hasPartner
                    ? props.form.accountData.profile.settings.hasPartner
                    : "yes",
                hasChildren: props.form.accountData.profile.settings.hasChildren
                    ? props.form.accountData.profile.settings.hasChildren
                    : "yes",
                hasCareGivers: 'yes',
                    
            },
        };
        if (changedPassword) {
            contactJson.settings.readOnlyPassword =
                input.readOnlyPassword && input.readOnlyPassword.value
                    ? input.readOnlyPassword.value
                    : props.form.accountData.profile.settings.readOnlyPassword;
        }
        if (input.birthDate && input.birthDate.value) {
            contactJson.privateData.birthDate =
                input.birthDate && input.birthDate.value
                    ? moment(input.birthDate.value, "DD-MM-YYYY").format(
                          "YYYY-MM-DD"
                      )
                    : "";
        }

        await updateAccount(contactJson)
            .unwrap()
            .then((response) => {
                handleSuccess(response);
            });
        setEdit(false);
    };

    const handleSuccess = (response) => {
        setChangedPassword(false);
        const profileData = {
            ...response,
            privateData: {
                ...response.privateData,
                birthDate: response.privateData.birthDate
                    ? moment(
                          response.privateData.birthDate,
                          "YYYY-MM-DD"
                      ).format("DD-MM-YYYY")
                    : "",
            },
        };
        props.addAttribute({
            name: "accountData",
            value: {
                ...props.form.accountData,
                profile: {
                    ...props.form.accountData.profile,
                    ...profileData,
                },
            },
        });

        props.addAttribute({
            name: "currentLanguage",
            value: response.settings.languageISOCode.toUpperCase(),
        });
        window.global =
            response.settings.languageISOCode.toUpperCase() === "NL"
                ? "globalDUT"
                : "globalENU";
        localStorage.setItem("language", response.settings.languageISOCode);
        props.updateLanguage(response.settings.languageISOCode);
        //document.cookie = `token=${response.token}`;
        localStorage.setItem("profile", JSON.stringify(profileData));
    };
    const [changedPassword, setChangedPassword] = React.useState(false);
    React.useEffect(() => {
        if (props.form.accountData && props.form.accountData.profile) {
            populateData();
        }
    }, [props.form.accountData && props.form.accountData.profile]);

    const populateData = (isEdit) => {
        const profile = props.form.accountData.profile;
        if(profile.settings) {
            const language =
            profile.settings.languageISOCode === "EN"
                ? "US"
                : profile.settings.languageISOCode;
        let data = {
            firstName: profile.privateData.firstName,
            lastName: profile.privateData.lastName,
            street: profile.physicalAddress.street,
            zipCode: profile.physicalAddress.zipCode,
            city: profile.physicalAddress.city,
            email: profile.contact.email,
            phone: profile.contact.phone,
            placeOfBirth: profile.privateData.placeOfBirth,
            birthDate: profile.privateData.birthDate,
            martialState: profile.privateData.martialState,
            donorCodicil: profile.privateData.donorCodicil,
            religion: profile.privateData.religion,
            countryISOCode: profile.physicalAddress.countryISOCode,
            languageISOCode: language,
            // readOnlyPassword: isEdit ? "" : profile.settings.readOnlyPassword,
            readOnlyPassword: profile.settings.readOnlyPassword,
        };
        setpersonalData(data);
        }
        
    };
    const changeHandler = (event, code) => {
        let data = { ...personalData };
        if (code) {
            data[code] = event;
        } else {
            data[event.target.name] = event.target.value;
        }
        if (event?.target?.name === "readOnlyPassword") {
            setChangedPassword(true);
        }
        setpersonalData(JSON.parse(JSON.stringify(data)));
    };
    const [personalData, setpersonalData] = React.useState({});

    const [isEdit, setEdit] = React.useState(false);

    return (
        <React.Fragment>
            {isLoading && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            {personalData && (
                <React.Fragment>
                    {!isEdit && (
                        <div
                            className="secondary-alert-sm"
                            onClick={() => {
                                setEdit(true);
                                populateData(true);
                            }}
                        >
                            {" "}
                            <i className="fa fa-pencil"></i>
                        </div>
                    )}
                    <React.Fragment>
                        {EWillPersonal.controls.map((control) => {
                            if (isEdit) {
                                if (
                                    (control.type === "text" ||
                                        control.type === "password") &&
                                    !control.hidden
                                ) {
                                    return (
                                        <div>
                                            <div className="login-field-label login-field-label-height">
                                                {
                                                    props.form.language[
                                                        control.label
                                                    ]
                                                }
                                                {control.mandatory && <span style={{ color: "red" }}>*</span>}{" "}
                                            </div>
                                            <div className="login-field-input">
                                                <input
                                                    type={control.type}
                                                    autocomplete={control.type ==="password" ? "new-password": "off"}
                                                    role="presentation"
                                                    placeholder={
                                                        control.placeholder
                                                            ? control.placeholder
                                                            : props.form
                                                                  .language[
                                                                  control.label
                                                              ]
                                                    }
                                                    name={control.name}
                                                    value={
                                                        personalData[
                                                            control.name
                                                        ]
                                                    }
                                                    onChange={(event) =>
                                                        changeHandler(event)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                } else if (
                                    control.type === "select" &&
                                    !control.hidden
                                ) {
                                    return (
                                        <div>
                                            <div className="login-field-label login-field-label-height">
                                                {
                                                    props.form.language[
                                                        control.label
                                                    ]
                                                }
                                            </div>
                                            <div className="login-field-input">
                                                <select
                                                    className="mm-field-input "
                                                    autoComplete="off"
                                                    placeholder={
                                                        control.placeholder
                                                            ? control.placeholder
                                                            : props.form
                                                                  .language[
                                                                  control.label
                                                              ]
                                                    }
                                                    name={control.name}
                                                    value={
                                                        personalData[
                                                            control.name
                                                        ]
                                                    }
                                                    onChange={(event) =>
                                                        changeHandler(event)
                                                    }
                                                >
                                                    {control.options.length >
                                                        0 &&
                                                        control.options.map(
                                                            (option) => {
                                                                if (option) {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                option.value
                                                                            }
                                                                        >
                                                                            {
                                                                                props.form.language[option.label]
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            }
                                                        )}
                                                </select>
                                            </div>
                                        </div>
                                    );
                                } else if (control.type === "language") {
                                    return (
                                        <div>
                                            <div className="login-field-label login-field-label-height">
                                                {props.form.language.LOGIN_S9}
                                            </div>
                                            <div className="selected-flag-field">
                                                <ReactFlagsSelect
                                                    selected={
                                                        personalData[
                                                            control.name
                                                        ]?.toUpperCase() || "NL"
                                                    }
                                                    countries={["NL", "US"]}
                                                    customLabels={{
                                                        NL: "Netherlands",
                                                        US: "English",
                                                    }}
                                                    name={control.name}
                                                    placeholder={
                                                        props.form.language
                                                            .LOGIN_S9
                                                    }
                                                    onSelect={
                                                        (event) =>
                                                            changeHandler(
                                                                event,
                                                                control.name
                                                            )
                                                        // changeHandler(event, "language")
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                } else if (control.type === "country") {
                                    return (
                                        <div>
                                            <div className="login-field-label login-field-label-height">
                                                {props.form.language.LOGIN_S17}
                                            </div>
                                            <div className="login-field-input">
                                                <ReactFlagsSelect
                                                    selected={
                                                        personalData[
                                                            control.name
                                                        ]
                                                    }
                                                    name={control.name}
                                                    placeholder={
                                                        props.form.language
                                                            .LOGIN_S17
                                                    }
                                                    onSelect={
                                                        (event) =>
                                                            changeHandler(
                                                                event,

                                                                control.name
                                                            )
                                                        //changeHandler(event, "countryISOCode")
                                                    }
                                                    searchable={true}
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                            } else {
                                if (!control.hidden) {
                                    return (
                                        <div
                                            style={{ display: "flex" }}
                                            className="input-container-column"
                                        >
                                            <div
                                                style={{ maxWidth: "165px" }}
                                                className="momentio-text-normal accordion-content-option-label"
                                            >
                                                {
                                                    props.form.language[
                                                        control.label
                                                    ]
                                                }
                                                {control.mandatory && <span style={{ color: "red" }}>*</span>}
                                            </div>
                                            <div className="momentio-text-bold momentio-ewill-value">
                                                {control.options &&
                                                control.options.length > 0 ? (
                                                    control.options.map(
                                                        (option) => {
                                                            if (
                                                                option &&
                                                                option.value ===
                                                                    personalData[
                                                                        control
                                                                            .name
                                                                    ]
                                                            ) {
                                                                return (
                                                                    <span>
                                                                        {
                                                                            props.form.language[option.label]
                                                                        }
                                                                    </span>
                                                                );
                                                            }
                                                        }
                                                    )
                                                ) : (
                                                    <span>
                                                        {control.type ===
                                                        "country"
                                                            ? personalData[
                                                                  control.name
                                                              ]?.toUpperCase()
                                                            : control.type ===
                                                              "language"
                                                            ? personalData[
                                                                  control.name
                                                              ] === "US"
                                                                ? "EN"
                                                                : personalData[
                                                                      control
                                                                          .name
                                                                  ]?.toUpperCase()
                                                            : personalData[
                                                                  control.name
                                                              ]}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    );
                                }
                            }
                        })}
                        {isEdit && (
                            <div>
                                
                                <button
                                    style={{
                                        textAlign: "center",
                                        width: "150px",
                                        marginTop: "24px",
                                        marginRight: "20px",
                                    }}
                                    className="primary-button-blue"
                                    onClick={() => saveEwill(personalData)}
                                >
                                    {props.form.language.SAVE}
                                </button>
                                <button
                                    style={{
                                        textAlign: "center",
                                        marginTop: "24px",
                                        
                                        height: "54px",
                                    }}
                                    className="secondary-button"
                                    onClick={() => {
                                        setEdit(false);
                                        populateData();
                                    }}
                                >
                                    {props.form.language.CANCEL}
                                </button>
                            </div>
                        )}
                    </React.Fragment>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        AddProfileAPI: (payload, type) =>
            dispatch(AddProfileAPI(payload, type)),
        addAttribute: (payload) => dispatch(addAttribute(payload)),
        updateLanguage: (payload) => dispatch(setLanguage(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Personal);
