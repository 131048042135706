import React from "react";
import { connect } from "react-redux";
import { loginAccount } from "../../Actions/Form";
import AuthFooter from "../Common/AuthFooter";
import AuthHeader from "../Common/AuthHeader";
import SideMenu from "../Common/SideMenu";
import Personal from "../Safe/PersonalInformation/Personal";
import ChangeNextToKin from "./ChangeNextToKin";
import ChangePassword from "./ChangePassword";
import MySusbscription from "./MySusbscription";

const Files = (props) => {
    return (
        <React.Fragment>
            <AuthHeader />
            <div className="momentio-dashboard-container">
                <SideMenu view="mysafe" />
                <div className="auth-content">
                    <div className="auth-content-subheader">
                        <div className="content-one">
                            {props.form.language.LOGIN_S14}
                        </div>
                    </div>
                    <div className="ewill">
                        <div className=" profile-container">
                            <MySusbscription />
                        </div>
                        <div style={{borderBottom: '1px solid #23315540 '}}></div>
                        <div className="profile-container">
                            <div className="n_header-3" style={{ marginBottom: '16px'}}>
                                {props.form.language.PERSONAL_INFO_LABEL}
                            </div>

                            <Personal />
                        </div>
                        <div style={{borderBottom: '1px solid #23315540 '}}></div>
                        <div className=" profile-container">
                            <ChangeNextToKin />
                        </div>
                        <div style={{borderBottom: '1px solid #23315540 '}}></div>
                        <div className=" profile-container">
                            <ChangePassword />
                        </div>
                    </div>
                </div>
            </div>
            <AuthFooter />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(loginAccount(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Files);
