/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import Dialog from "@material-ui/core/Dialog";
import AddIcon from "@material-ui/icons/Add";
import { CircularProgress } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import SocialMediaOverview from "./SocialMediaOverview";
import { addAttribute } from "../../../../Actions/Form";
import { useMutationAPICallMutation } from "../../../../app/services/account";

const SocialMedia = (props) => {
    const saveEwill = (data) => {
        let input = {};
        Object.keys(data).map((item) => {
            input[item] = {
                value: data[item],
            };
        });
        let json = {
            url: input.ID.value ? `important/${input.ID.value}` : "important",
            method: input.ID.value ? "PUT" : "POST",
            system: {
                id: input.ID.value,
            },
            general: {
                importantType: "socialMedia",
                name: input.name ? input.name.value : "",
                contactID: input.contactID ? input.contactID.value + ";" : "",
            },
            meta: {
                additionalInfo: input.remark ? input.remark.value : "",
                tags: "tag1;tag2",
            },
            socialMedia: {
                socialMediaType: input.socialAccountType
                    ? input.socialAccountType.value
                    : "",
                username: input.userName ? input.userName.value : "",
            },
        };
        props.handleRequest("update", json);
        setaddNew(false);
    };
    const [updateAccount, { isLoading, error }] = useMutationAPICallMutation();
    const [addNew, setaddNew] = React.useState(false);

    const [newRecord, setnewRecord] = React.useState({
        socialAccountType: "",
        userName: "",
        ID: "",
    });

    React.useEffect(() => {
        if (props.form.accountData && props.form.accountData.importantList) {
            let data = props.form.accountData.importantList.filter((item) => {
                return item.socialMedia;
            });
            let socialMedia = [];
            data.map((item) => {
                socialMedia.push({
                    socialAccountType: item.socialMedia.socialMediaType,
                    userName: item.socialMedia.username,
                    ID: item.system.id,
                });
            });
            setpersonalData(socialMedia);
        } else {
            setpersonalData([]);
        }
    }, [props.form.accountData && props.form.accountData.importantList]);

    const setDefault = async () => {
        let profileData = JSON.parse(
            JSON.stringify(props.form.accountData.profile)
        );
        if (profileData.settings) {
            profileData.settings.hasSocialMedia = "no";
            if (profileData.privateData.birthDate === "") {
                delete profileData.privateData.birthDate;
            } else {
                profileData.privateData.birthDate = moment(
                    profileData.privateData.birthDate,
                    "DD-MM-YYYY"
                ).format("YYYY-MM-DD");
            }

            let contactJson = {
                url: "account",
                method: "PUT",
                username: profileData.settings["username"],
                settings: {
                    username: profileData.settings.username,
                    languageISOCode: profileData.settings.languageISOCode,
                    hasGuardian: profileData.settings.hasGuardian
                        ? profileData.settings.hasGuardian
                        : "yes",
                    hasParents: profileData.settings.hasParents
                        ? profileData.settings.hasParents
                        : "yes",
                    hasPartner: profileData.settings.hasPartner
                        ? profileData.settings.hasPartner
                        : "yes",
                    hasChildren: profileData.settings.hasChildren
                        ? profileData.settings.hasChildren
                        : "yes",
                    hasCareGivers: profileData.settings.hasCareGivers
                        ? profileData.settings.hasCareGivers
                        : "yes",
                    hasSocialMedia: profileData.settings.hasSocialMedia
                        ? profileData.settings.hasSocialMedia
                        : "no",
                },
                privateData: profileData.privateData,
                contact: profileData.contact,
                physicalAddress: profileData.physicalAddress,
                //...profileData,
            };
            await updateAccount(contactJson)
                .unwrap()
                .then((response) => {
                    handleSuccess(response);
                });
        }
    };
    const handleSuccess = (response) => {
        const profileData = {
            ...props.form.accountData.profile,
            settings: response.settings,
        };
        props.addAttribute({
            name: "accountData",
            value: {
                ...props.form.accountData,
                profile: profileData,
            },
        });
        localStorage.setItem("profile", JSON.stringify(profileData));
    };

    const changeHandler = (event, personal) => {
        let data = personalData;
        let newData = newRecord;
        newData[event.target.name] = event.target.value;
        setnewRecord(newData);
        setpersonalData(JSON.parse(JSON.stringify(data)));
    };
    const showAddSocial = (finance) => {
        setaddNew(true);
        if (finance) {
            let newData = {};
            Object.keys(finance).map((item) => {
                newData[item] = finance[item];
            });
            setnewRecord(newData);
            props.addAttribute({
                name: "selectedSocial",
                value: finance,
            });
        } else {
            setnewRecord({
                socialAccountType: "",
                userName: "",
                ID: "",
            });
            props.addAttribute({
                name: "selectedSocial",
                value: {},
            });
        }
    };
    const hideEdit = () => {
        setaddNew(false);
    };
    const [personalData, setpersonalData] = React.useState([]);

    return (
        <React.Fragment key={props.key}>
            {isLoading && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            {addNew === true && (
                <Dialog
                    open={addNew}
                    fullScreen={props.fullScreen}
                    disableEscapeKeyDown={true}
                    onClose={hideEdit}
                    aria-labelledby="responsive-dialog-title"
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                        }}
                    >
                        <div className="mm-form-container">
                            <div
                                className="mm-login-container"
                                style={{ boxShadow: "none" }}
                            >
                                <div className="mm-login-header">
                                    {props.form.selectedSocial &&
                                    props.form.selectedSocial.ID
                                        ? props.form.language.SOCIAL_MEDIA_Q2
                                        : props.form.language.SOCIAL_MEDIA_ADD}
                                </div>
                                <div>
                                    <div className="login-field-label login-field-label-height">
                                        {props.form.language.SOCIAL_MEDIA_Q2}
                                    </div>
                                    <div className="login-field-input">
                                        <select
                                            className="mm-field-input "
                                            autoComplete="off"
                                            name={"socialAccountType"}
                                            value={newRecord.socialAccountType}
                                            onChange={(event) =>
                                                changeHandler(event, newRecord)
                                            }
                                        >
                                            <option value="">
                                                {
                                                    props.form.language
                                                        .SELECT_OPTION
                                                }
                                            </option>
                                            <option value="twitter">
                                                Twitter
                                            </option>
                                            <option value="facebook">
                                                Facebook
                                            </option>
                                            <option value="instagram">
                                                Instagram
                                            </option>
                                            <option value="youtube">
                                                Youtube
                                            </option>
                                            <option value="tikTok">
                                                TikTok
                                            </option>
                                            <option value="pinterest">
                                                Pinterest
                                            </option>
                                            <option value="snapchat">
                                                Snapchat
                                            </option>
                                            <option value="linkedIn">
                                                LinkedIn
                                            </option>
                                            <option value="other">
                                                {props.form.language.TYPE_OTHER}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div>
                                    <div className="login-field-label login-field-label-height">
                                        {props.form.language.SOCIAL_MEDIA_Q1}
                                    </div>
                                    <div className="login-field-input">
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder={
                                                props.form.language
                                                    .SOCIAL_MEDIA_Q1
                                            }
                                            name="userName"
                                            value={newRecord.userName}
                                            onChange={(event) =>
                                                changeHandler(event, newRecord)
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    className="mm-input-submit"
                                    style={{
                                        display: "flex",
                                        marginBottom: "15px",
                                        justifyContent: "center",
                                    }}
                                >
                                    <button
                                        style={{
                                            textAlign: "center",
                                            width: "150px",
                                            marginTop: "24px",
                                            marginRight: "20px",
                                        }}
                                        className="primary-button-blue"
                                        onClick={() => {
                                            saveEwill(newRecord);
                                            hideEdit();
                                        }}
                                    >
                                        {props.form.language.SAVE}
                                    </button>
                                    <button
                                        style={{
                                            textAlign: "center",
                                            marginTop: "24px",
                                        }}
                                        className="secondary-button"
                                        onClick={hideEdit}
                                    >
                                        {props.form.language.CANCEL}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
            {personalData &&
                personalData.length > 0 &&
                personalData.map((data) => {
                    return (
                        <SocialMediaOverview
                            data={data}
                            key={`social${data.ID}`}
                            showAddSocial={() => showAddSocial(data)}
                            handleRequest={(type, data) =>
                                props.handleRequest(type, data)
                            }
                        />
                    );
                })}
            <div style={{ flexDirection: "row", display: "flex" }}>
                <span
                    id={props.type}
                    onClick={(event) => {
                        showAddSocial();
                    }}
                    style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "8px",
                    }}
                >
                    <span className="">
                        <span className="ewill-secondary-alert-sm">
                            <AddIcon />
                        </span>
                    </span>
                    <span
                        className="momentio-text-normal"
                        style={{ marginLeft: "15px" }}
                    >
                        {personalData.length > 0
                            ? props.form.language.SOCIAL_MEDIA_ADD_ANOTHER
                            : props.form.language.SOCIAL_MEDIA_ADD_SINGLE}
                    </span>
                </span>
                {personalData.length < 1 &&
                    props.form.accountData?.profile?.settings
                        ?.hasSocialMedia !== "no" && (
                        <button
                            style={{ marginLeft: "24px" }}
                            className="secondary-button"
                            onClick={() => {
                                setDefault();
                            }}
                        >
                            {props.form.language.NO_SOCIAL_MEDIA}
                        </button>
                    )}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SocialMedia);
