/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// eslint-disable jsx-a11y/anchor-is-valid
// eslint-disable jsx-a11y/alt-text
// eslint-disable no-useless-escape
// eslint-disable no-duplicate-props

import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import ReactFlagsSelect from "react-flags-select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    AddContactAPI,
    DeleteContactAPI,
    addAttribute,
} from "../../Actions/Form";
import {
    useMutationAPICallMutation,
    useDeleteAPICallMutation,
} from "../../app/services/account";
import moment from "moment";
import { validateField, responseHandler } from "../../Utility/Utility";
import { model } from "../../Models/Contacts";
import AddTagsSM from "../Common/AddTagsSM";

const AddContact = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    let currentLanguage = props.form.currentLanguage;
    React.useEffect(() => {
        let newData = JSON.parse(JSON.stringify(model));
        newData.countryISOCode.value = currentLanguage;
        if (props.form.editContact && props.form.editContact.ID) {
            Object.keys(newData).map((contact) => {
                if (contact === "tags") {
                    let currentTags = props.form.editContact.tags;
                    newData.tags.value = [];
                    if (currentTags.split(";")?.length > 1) {
                        currentTags.split(";").map((tag) => {
                            if (tag) {
                                if (tag === "child") {
                                    newData.birthDate.validations = {
                                        required: true,
                                    };
                                }
                                let filter = model.tags.options.filter(
                                    (item) => item.value === tag
                                )[0];
                                if (!filter) {
                                    newData.tags.options.push({
                                        label: tag,
                                        value: tag,
                                    });
                                }
                                newData.tags.value.push(tag);
                            }
                        });
                    }
                } else {
                    newData[contact].value = props.form.editContact[contact];
                }
            });
        }
        if (props.form.accountData.contactTags) {
            props.form.accountData.contactTags.map((item) => {
                let filteredTag = newData.tags.options.filter(
                    (opt) => item === opt.value
                )[0];
                if (!filteredTag && item) {
                    newData.tags.options.push({
                        label: item,
                        value: item,
                    });
                }
            });
        }
        if (props.informType) {
            newData.informType.value = props.informType;
        }
        if (props.contactRole) {
            newData.tags.value.push(props.contactRole);
            if (props.contactRole === "child") {
                newData.birthDate.validations = { required: true };
                newData.birthDate.valid = false;
            }
        }
        setcontactData(newData);
    }, []);

    const [contactData, setcontactData] = React.useState(model);

    const changeHandler = async (event, values) => {
        let newData = JSON.parse(JSON.stringify(contactData));
        if (event.currentTarget.name === "isHeir") {
            newData[event.currentTarget.name].value = event.currentTarget
                .checked
                ? "yes"
                : "no";
        } else if (event.currentTarget.name === "Country") {
            newData[event.currentTarget.name].value = event;
        } else if (!event.currentTarget.name) {
            newData["tags"].value = values;
            if (
                values.indexOf("child") > -1 &&
                newData.birthDate.value === ""
            ) {
                newData.birthDate.valid = false;
            }
        } else if (newData[event.target.name]) {
            newData[event.target.name].value = event.target.value;
        }

        // setcontactData(newData);
        //let validation = await validateField(newData, props.form.language);
        setcontactData(newData);
    };

    const [updateAccount, { isLoading }] = useMutationAPICallMutation();
    const [deleteAccount, { isLoading: isDeleting }] =
        useDeleteAPICallMutation();
    async function submit(newData, action) {
        let isFormValid = await validateField(newData, props.form.language);
        setcontactData(isFormValid.formatData);
        let isValid = true;
        Object.keys(contactData).map((data) => {
            if (
                !contactData[data].hidden &&
                contactData[data].valid === false
            ) {
                isValid = false;
            }
        });
        if(contactData.phone.value) {
            const regex = contactData.phone.value.includes("+") ? new RegExp("^[+][0-9]+$") : new RegExp("^[0-9]+$") ;
            if(!regex.test(contactData.phone.value)) {
                isValid = false;
                isFormValid.validationMsg = isFormValid.validationMsg + "\n" + `${props.form.language.INVALID_TEXT} ${props.form.language.MYCONTACT_S16}`
            }
        }
        if (isValid) {
            var contactJson = {
                url:
                    props.form.editContact && props.form.editContact.ID
                        ? `contact/${props.form.editContact.ID}`
                        : "contact",
                method:
                    props.form.editContact && props.form.editContact.ID
                        ? "PUT"
                        : "POST",
                meta: {
                    tags: contactData.tags.value.join(";") + ";",
                    additionalInfo: "",
                },
                contact: {
                    
                    phone: contactData.phone.value,
                },
                physicalAddress: {
                    countryISOCode: contactData.countryISOCode.value,
                    street: contactData.street.value,
                    zipCode: contactData.zipCode.value,
                    city: contactData.city.value,
                },
                privateData: {
                    firstName: contactData.firstName.value,
                    lastName: contactData.lastName.value,
                    proof: contactData.proof.value,
                },
                settings: {
                    isHeir: props.form.editContact && props.form.editContact.ID ? contactData.isHeir.value : 'no',
                    inform: contactData.inform.value,
                    informType: contactData.informType.value,
                    languageISOCode: props.form?.accountData?.profile?.settings
                        ? props.form?.accountData?.profile?.settings
                              ?.languageISOCode
                        : "NL",
                },
            };
            if (contactData.birthDate.value) {
                contactJson.privateData.birthDate = contactData.birthDate.value
                    ? moment(contactData.birthDate.value, "DD-MM-YYYY").format(
                          "YYYY-MM-DD"
                      )
                    : "";
            }
            if(contactData.email.value) {
                contactJson.contact.email = contactData.email.value;
            }

            props.addAttribute({
                name: "isLoading",
                value: true,
            });

            await updateAccount(contactJson)
                .unwrap()
                .then((response) => {
                    handleSuccess(response);
                })
                .catch(() => {
                    props.addAttribute({
                        name: "isLoading",
                        value: false,
                    });
                });

            props.addAttribute({
                name: "isLoading",
                value: false,
            });
        } else {
            alert(isFormValid.validationMsg);
        }
    }
    const handleDelete = async () => {
        await deleteAccount({
            url: `contact/${props.form.editContact.ID}`,
            method: "DELETE",
        })
            .unwrap()
            .then((response) => {
                handleSuccess(response);
            });
    };

    const handleSuccess = (response) => {
        if (response.statusCode === 201) {
            if (response.system?.id && props.setNewContactCreated) {
                props.setNewContactCreated(response.system.id);
            }
            props.query.refetch();
            if(props.getCompleted) {
                props.getCompleted();
            }
            setTimeout(() => {
                props.addAttribute({
                    name: "editContact",
                    value: {},
                });
                props.addAttribute({
                    name: "contactFormPopup",
                    value: false,
                });
            }, 100);
        } else {
            responseHandler(response, props.form.language);
        }
    };

    const changeLanguage = (code) => {
        let newData = JSON.parse(JSON.stringify(contactData));
        newData.countryISOCode.value = code;

        setcontactData(newData);
    };

    return (
        <React.Fragment>
            {(isLoading ||
                isDeleting ||
                props.query?.isLoading ||
                props.query?.isFetching) && (
                <div
                    className="overlay-momentio"
                    style={{ zIndex: 1000000000000 }}
                >
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <Dialog
                fullScreen={fullScreen}
                open={props.open}
                disableEscapeKeyDown={true}
                onClose={props.hideAddContact}
                aria-labelledby="responsive-dialog-title"
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <div
                        className="mm-form-container add-contact"
                        style={{
                            height: "auto",
                            opacity: 1,
                        }}
                    >
                        <div className="mm-login-container">
                            <div className="mm-login-header">
                                {props.form.editContact &&
                                props.form.editContact.ID
                                    ? `${
                                          props.form.language.EDIT_TEXT
                                      } ${props.addTitle?.toLowerCase()}`
                                    : props.form.currentLanguage === "NL"
                                    ? `${props.addTitle} ${props.form.language.ADD_TEXT}`
                                    : `${
                                          props.form.language.ADD_TEXT
                                      } ${props.addTitle?.toLowerCase()}`}
                            </div>
                            <div>
                                <div
                                    className="login-field-label login-field-label-height"
                                    style={{
                                        width: "100%",
                                        textAlign: "left",
                                        marginBottom: "16px",
                                    }}
                                >
                                    {props.form.language.SELECT_TAG_CONTACT}
                                </div>
                                <div>
                                    <AddTagsSM
                                        tags={contactData.tags.options}
                                        selectedTags={contactData.tags}
                                        updateTagList={(tags) => {
                                            let isValid = true;
                                            let validations = {};
                                            if (
                                                tags.value.indexOf("child") >
                                                    -1 &&
                                                contactData.birthDate.value ===
                                                    ""
                                            ) {
                                                isValid = false;
                                                validations = {
                                                    required: true,
                                                };
                                            }
                                            setcontactData({
                                                ...contactData,
                                                tags: tags,
                                                birthDate: {
                                                    ...contactData.birthDate,
                                                    valid: isValid,
                                                    validations: validations,
                                                },
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="display-flex form-field">
                                <div className="login-field-label">
                                    {props.form.language.LOGIN_S7}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                </div>
                                <div className="login-field-input">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder={
                                            props.form.language.LOGIN_S7
                                        }
                                        className="mm-field-input "
                                        name="firstName"
                                        value={contactData.firstName.value}
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>
                            <div className="display-flex form-field">
                                <div className="login-field-label login-field-label-height">
                                    {props.form.language.LOGIN_S8}
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className="login-field-input">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder={
                                            props.form.language.LOGIN_S8
                                        }
                                        className="mm-field-input "
                                        name="lastName"
                                        value={contactData.lastName.value}
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>
                            <div className="display-flex form-field">
                                <div className="login-field-label login-field-label-height">
                                    {props.form.language.MYCONTACT_S14}
                                    {contactData.tags.value?.indexOf("child") >
                                        -1 && (
                                        <span style={{ color: "red" }}>*</span>
                                    )}
                                </div>
                                <div className="login-field-input">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder="dd-mm-yyyy"
                                        name="birthDate"
                                        value={contactData["birthDate"].value}
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>
                            <div className="display-flex form-field">
                                <div className="login-field-label login-field-label-height">
                                    {props.form.language.MYCONTACT_S11}
                                </div>
                                <div className="login-field-input">
                                    <input
                                        type="email"
                                        autoComplete="off"
                                        placeholder={
                                            props.form.language.MYCONTACT_S11
                                        }
                                        name="email"
                                        value={contactData["email"].value}
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>
                            <div className="display-flex form-field">
                                <div className="login-field-label login-field-label-height">
                                    {props.form.language.MYCONTACT_S16}
                                </div>
                                <div className="login-field-input">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder={
                                            props.form.language.MYCONTACT_S16
                                        }
                                        name="phone"
                                        value={contactData["phone"].value}
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>

                            <div className="display-flex form-field">
                                <div className="login-field-label login-field-label-height">
                                    {props.form.language.MYCONTACT_S13}
                                </div>
                                <div className="login-field-input">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder={
                                            props.form.language.MYCONTACT_S13
                                        }
                                        name="street"
                                        value={contactData.street.value}
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>
                            <div className="display-flex form-field">
                                <div className="login-field-label login-field-label-height">
                                    {props.form.language.POSTCODE}
                                </div>
                                <div className="login-field-input">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder={
                                            props.form.language.POSTCODE
                                        }
                                        name="zipCode"
                                        value={contactData.zipCode.value}
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>
                            <div className="display-flex form-field">
                                <div className="login-field-label login-field-label-height">
                                    {props.form.language.MYCONTACT_S17}
                                </div>
                                <div className="login-field-input">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder={
                                            props.form.language.MYCONTACT_S17
                                        }
                                        name="city"
                                        value={contactData.city.value}
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>
                            
                            <div className="display-flex form-field language-form-field">
                                <div className="login-field-label login-field-label-height language-field-label">
                                    {props.form.language.MYCONTACT_S18}
                                    <span style={{ color: "red" }}>*</span>
                                </div>
                                <div className="login-field-input">
                                    <ReactFlagsSelect
                                        selected={
                                            contactData.countryISOCode.value
                                        }
                                        name="countryISOCode"
                                        onSelect={changeLanguage}
                                        searchable={true}
                                    />
                                </div>
                            </div>
                            <div className="display-flex form-field">
                                <div className="login-field-label login-field-label-height">
                                    {props.form.language.CONTACT_INFORM_TYPE}
                                </div>
                                <div className="login-field-input">
                                    <select
                                        className="mm-field-input "
                                        id="contactType"
                                        name="informType"
                                        autoComplete="off"
                                        value={contactData["informType"].value}
                                        onChange={changeHandler}
                                    >
                                        <option value={"phone"}>
                                            {
                                                props.form.language
                                                    .CONTACT_INFORM_TYPE_PHONE
                                            }
                                        </option>
                                        <option value={"personal"}>
                                            {
                                                props.form.language
                                                    .CONTACT_INFORM_TYPE_PERSONAL
                                            }
                                        </option>
                                        <option value={"post"}>
                                            {
                                                props.form.language
                                                    .CONTACT_INFORM_TYPE_POST
                                            }
                                        </option>
                                        <option value={"email"}>
                                            {
                                                props.form.language
                                                    .CONTACT_INFORM_TYPE_EMAIL
                                            }
                                        </option>
                                        <option value={"none"}>
                                            {props.form.language.NONE_TEXT}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            {/*<div className="display-flex form-field">
                                <div className="login-field-label login-field-label-height heir-field-label">
                                    {props.form.language.MYCONTACT_S25}
                                </div>
                                <div className="login-field-input contact-heir">
                                    <FormControlLabel
                                        style={{ marginBottom: "1px" }}
                                        control={
                                            <Checkbox
                                                checked={
                                                    contactData.isHeir.value ===
                                                    "yes"
                                                        ? true
                                                        : false
                                                }
                                                style={{
                                                    paddingLeft: 0,
                                                }}
                                                name="isHeir"
                                                value={
                                                    contactData.isHeir.value ===
                                                    "yes"
                                                        ? true
                                                        : false
                                                }
                                                onChange={(event) =>
                                                    changeHandler(event, null)
                                                }
                                            />
                                        }
                                    />
                                </div>
                            </div>
                            {contactData?.isHeir?.value === "yes" && (
                                <div className="display-flex form-field">
                                    <div className="login-field-label login-field-label-height">
                                        {props.form.language.MYCONTACT_S26}
                                    </div>
                                    <div className="login-field-input">
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder={
                                                props.form.language
                                                    .MYCONTACT_S26
                                            }
                                            name="proof"
                                            value={contactData.proof.value}
                                            onChange={changeHandler}
                                        />
                                    </div>
                                </div>
                            )} */}

                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "15px",
                                    justifyContent: "space-between",
                                }}
                                className="register-consent"
                            >
                                <button
                                    style={{
                                        textAlign: "center",
                                        marginTop: "24px",
                                        width: "46%",
                                    }}
                                    className="primary-button-blue"
                                    onClick={() => {
                                        if (
                                            props.form.editContact &&
                                            props.form.editContact.ID
                                        ) {
                                            submit(contactData, "update");
                                        } else {
                                            submit(contactData, "insert");
                                        }
                                    }}
                                >
                                    {props.form.language.SAVE}
                                </button>
                                <button
                                    style={{
                                        textAlign: "center",
                                        marginTop: "24px",
                                        width: "46%",
                                    }}
                                    className="secondary-button"
                                    onClick={props.hideAddContact}
                                >
                                    {props.form.language.CANCEL}
                                </button>
                            </div>
                            {props.form.editContact &&
                                props.form.editContact.ID && (
                                    <div
                                        className="contact-delete"
                                        style={{
                                            font: "normal normal 800 18px/30px Raleway",
                                            marginLeft: "40%",
                                        }}
                                    >
                                        <span
                                            className="contacts-add-link"
                                            style={{
                                                color: "#F45B5B",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => handleDelete()}
                                        >
                                            {`${props.form.language.DELETE} contact`}
                                        </span>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        AddContactAPI: (payload, type) =>
            dispatch(AddContactAPI(payload, type)),
        DeleteContactAPI: (id) => dispatch(DeleteContactAPI(id)),
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddContact)
);
