import React from 'react';
import { connect } from 'react-redux';
import moment from "moment"

const Files = (props) => {
    return (
        <React.Fragment>
            <div className='alerts-container' style={{right: props.isMobile ? 0 : "unset"}}>
                {
                    props.alertList?.data?.systemMessageList?.map(message => {
                        return <div className='menu-options'>
                            <div className='n_chapeau_b'>{moment(message.delivery.publishDate, 'YYYY-MM-DD').format("Do MMMM YYYY")}</div>
                            <div className='n_header-4'>{message.data.title}</div>
                            <div className='n_caption'>{message.data.message}</div>
                        </div>
                    })
                }
                </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

export default connect(mapStateToProps)(Files);
