/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */

import axios from "axios";
import { addAttribute, isLoading, logOut } from "../../Actions/Form";
import { decryptMessage, encryptMessage } from "../../Constants/decryption";
import * as globalDut from "../../Constants/i18n/dut";
import * as globalENU from "../../Constants/i18n/enu";
import { getAPIBaseUrl } from "../../Utility/Utility";

export const DeleteContactAPI = (input, dataType) => {
  return async (dispatch, getState) => {
    console.log(dataType);
    dispatch(isLoading(true));
    const currentLanguage =
      getState().form.currentLanguage == "US" ? globalENU : globalDut;

    let contactJson = {
      encryptionKey: getState().form.Id.eId,
      initialisationVector: getState().form.Id.eIv,
      ID: input,
    };

    let data = {
      action: "delete",
      dataType: dataType,
      timestamp: new Date().getTime(),
      element: contactJson,
    };
    //  };
    let output = await encryptMessage(
      "",
      getState().form.Id.eIv,
      getState().form.Id.eId,
      JSON.stringify(data)
    );

    setTimeout(function () {
      let requestJson = {
        instruction: "action",
        token: getState().form.accountData.token,
      };

      requestJson.data = output;

      let url = getAPIBaseUrl() + "Alter";
      axios({
        method: "POST",
        data: requestJson,
        url: url,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(async (response) => {
          if (response.data.statusCode == 401) {
            alert(
              currentLanguage.INVALID_SESSION
            );
            dispatch(
              addAttribute({
                name: "isSessionExpired",
                value: true,
              })
            );
            dispatch(logOut());
          } else if (response.data.statusCode != 200) {
            alert(currentLanguage.ERROR_DELETE);
            dispatch(isLoading(false));
          } else if (response.data.data == null) {
            dispatch(isLoading(false));
            alert(currentLanguage.ERROR_DELETE);
          } else {
            let decryptedData = await decryptMessage(
              "",
              getState().form.Id.eIv,
              getState().form.Id.eId,
              response.data.data
            );
            setTimeout(() => {
              //console.log(decryptedData)
              let accountData = getState().form.accountData;
              accountData[dataType] = JSON.parse(decryptedData)[dataType];
              dispatch(
                addAttribute({
                  name: "accountData",
                  value: accountData,
                })
              );
            }, 500);

            dispatch(isLoading(false));
          }
          dispatch(isLoading(false));
        })
        .catch((error) => {
          alert(currentLanguage.ERROR_DELETE);
          dispatch(isLoading(false));
        });
    }, 500);
  };
};
