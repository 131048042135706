/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import { Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import TextSnippetOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import update from "immutability-helper";
import React from "react";
import { connect } from "react-redux";
import { addAttribute, loginAccount } from "../../Actions/Form";
import AuthFooter from "../Common/AuthFooter";
import AuthHeader from "../Common/AuthHeader";
import SideMenu from "../Common/SideMenu";
import FutureMessage from "./FutureMessage";
import {
    useGetAPICallQuery,
    useDeleteAPICallMutation,
} from "../../app/services/account";
import moment from "moment";
import { getTagLabel } from "../Contacts/ContactTags";
import MessagePreview from "./Preview";
import { entityTagList, sortAscending, getContactName, sortContactAscending } from "../../Utility/Utility";
import FilterContacts from "../Common/FilterContacts";

const Files = (props) => {
    const messageListQuery = useGetAPICallQuery("message");
    const [skip, setSkip] = React.useState(true);
    const [skipMedia, setSkipMedia] = React.useState(true);
    const contactList = useGetAPICallQuery("contact", { skip: skip });
    
    const mediaList = useGetAPICallQuery("media", { skip: skipMedia });

    const [openPreview, setOpenPreview] = React.useState(false);
    const [contactFilter, setContactFilter] = React.useState([]);
    const [previewData, setPreviewData] = React.useState({});

    const [category, setCategory] = React.useState("");
    const [contactCategory, setContactCategory] = React.useState("");
    
    const changeHandler = (event) => {
        setCategory(event.target.value);
    };
    React.useEffect(() => {
        if (!messageListQuery.isLoading && !messageListQuery.error) {
            setSkip(false);
        }
    }, [messageListQuery.data]);

    React.useEffect(() => {
        if (!contactList.isLoading && !contactList.error) {
            setSkipMedia(false);
        }
    }, [contactList.data]);

    const options = [
        {
            label: "CONTACT_TYPE_FINANCIAL",
            value: "financial",
        },
        {
            label: "MESSAGE_PERSONAL",
            value: "personal",
        },
        {
            label: "MESSAGE_INSTRUCTION",
            value: "instruction",
        },
        {
            label: "MESSAGE_FAREWELL",
            value: "farewell",
        },
    ];

    React.useEffect(() => {
        if (
            mediaList.data &&
            mediaList.data.mediaList &&
            messageListQuery.data &&
            messageListQuery.data.messageList &&
            contactList.data &&
            contactList.data.contactList &&
            !props.form.futureFormPopup
        ) {
            let contactTags = entityTagList(sortContactAscending(contactList.data.contactList));
            let mediaTags = entityTagList(mediaList.data.mediaList);
            let messageTags = entityTagList(messageListQuery.data.messageList);

            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    mediaList: sortAscending(mediaList.data.mediaList),
                    message: messageListQuery.data.messageList,
                    contactList: sortContactAscending(contactList.data.contactList),
                    contactTags: contactTags,
                    mediaTags: mediaTags,
                    messageTags: messageTags,
                },
            });
        }
    }, [mediaList.data && messageListQuery.data && contactList.data]);

    const showfutureFormPopup = () => {
        props.addAttribute({
            name: "contactTreeData",
            value: [],
        });
        props.addAttribute({
            name: "selectedMessage",
            value: null,
        });
        props.addAttribute({
            name: "futureFormPopup",
            value: true,
        });
    };

    const editfutureFormPopup = (selectedMessage) => {
        props.addAttribute({
            name: "contactTreeData",
            value: [],
        });
        props.addAttribute({
            name: "selectedMessage",
            value: selectedMessage,
        });

        props.addAttribute({
            name: "futureFormPopup",
            value: true,
        });
    };

    const [deleteAccount, { isLoading }] = useDeleteAPICallMutation();

    const handleDelete = async (ID) => {
        await deleteAccount({
            url: `message/${ID}`,
            method: "DELETE",
        })
            .unwrap()
            .then((response) => {
                messageListQuery.refetch();
            });
    };

    const hidefutureFormPopup = () => {
        props.addAttribute({
            name: "futureFormPopup",
            value: false,
        });
        props.addAttribute({
            name: "editFutureMessage",
            value: {},
        });

        let oldMedia = mediaList.data?.mediaList;
        let oldContact = contactList.data?.contactList;
        if (props.form.accountData.mediaList) {
            props.form.accountData.mediaList.map((item, index) => {
                oldMedia = update(oldMedia, {
                    [index]: {
                        $set: { ...item, checked: false },
                    },
                });
            });
        }

        if (props.form.accountData.contactList) {
            props.form.accountData.contactList.map((item, index) => {
                oldContact = update(oldContact, {
                    [index]: {
                        $set: {
                            ...item,
                            checked: false,
                        },
                    },
                });
            });
        }
        props.addAttribute({
            name: "accountData",
            value: {
                ...props.form.accountData,
                mediaList: sortAscending(oldMedia),
                contactList: sortContactAscending(oldContact),
            },
        });

        props.addAttribute({
            name: "selectedMessage",
            value: null,
        });
    };

    const getreceiversContactListID = (receiversContactListID) => {
        if (props.form.accountData && props.form.accountData.contactList) {
            let splitContact = receiversContactListID
                ? receiversContactListID.split(";")
                : [];
            let contactListId = [];
            splitContact.map((item) => {
                if (item) {
                    contactListId.push(item);
                    if(contactFilter.indexOf(item) < 0) {
                        setContactFilter([...contactFilter, item]);

                    }
                }
            });

            return (
                <span>
                    {contactListId.map((item, index) => {
                        let currentContact =
                            props.form.accountData.contactList.filter(
                                (contact) => item == contact.system.id
                            )[0];
                        if (currentContact) {
                            return (
                                <>
                                    {currentContact &&
                                        currentContact.privateData.firstName +
                                            " " +
                                            currentContact.privateData.lastName}
                                    {index === contactListId.length - 1
                                        ? ""
                                        : ", "}
                                </>
                            );
                        } else {
                            return "";
                        }
                    })}
                </span>
            );
        }
    };
    const handleChange = (panel) => (event, isExpanded) => {
        //window.scrollTo(0, 100);
        props.addAttribute({
            name: "currentMessage",
            value: isExpanded ? panel.system.id : false,
        });
    };

    const getFileType = (desc) => {
        let format = desc.split(".");
        return format.length > 0 ? format[format.length - 1].toLowerCase() : "";
    };

    const getMediaList = (mediaListID) => {
        if (props.form.accountData && props.form.accountData?.mediaList) {
            let splitMedia = mediaListID.split(";");
            let currMediaList = [];
            splitMedia.map((item) => {
                if (item) {
                    currMediaList.push(item);
                }
            });
            return (
                <Grid container spacing={2}>
                    {currMediaList.length > 0 &&
                        currMediaList.map((item) => {
                            let currentMedia =
                                props.form.accountData.mediaList.filter(
                                    (media) => item == media.system.id
                                )
                                    ? props.form.accountData.mediaList.filter(
                                          (media) => item == media.system.id
                                      )[0]
                                    : {};
                                let fileType = currentMedia && currentMedia.data ? getFileType(currentMedia.data.originalFileName) : "";

                            return (
                                <Grid item xs={12} md={12} lg={6}>
                                    <div className="message-grid">
                                        {currentMedia && currentMedia.data && (
                                            <React.Fragment>
                                                <div className="message-image-container">
                                                {fileType &&
                                                    (fileType === "png" ||
                                                        fileType === "jpg" ||
                                                        fileType === "jpeg" ||
                                                        fileType === "svg" ||
                                                        fileType === "gif") ? (
                                                        <img
                                                            src={currentMedia?.system?.signedUrl}
                                                            alt=""
                                                        />
                                                    )  : (fileType === "mp3" ||
                                                    fileType === "cpm" ||
                                                    fileType === "ogg" ||
                                                    fileType === "wav" ||
                                                    fileType === "aiff" ||
                                                    fileType === "acc" ||
                                                    fileType === "m4a" ||
                                                    fileType === "wma" ||
                                                    fileType === "alac") ? (
                                                      <audio style={{height: "100px", width: "160px"}} controls>
                                                          <source
                                                              src={currentMedia?.system?.signedUrl}
                                                              type="audio/ogg"
                                                          />
                                                          Your browser does not support the audio
                                                          element.
                                                      </audio>
                                                  ) : (fileType === "mp4" ||
                                                    fileType === "mov" ||
                                                    fileType === "avi" ||
                                                    fileType === "flv" ||
                                                    fileType === "wmv" ||
                                                    fileType === "webm" ||
                                                    fileType === "mpg" ||
                                                    fileType === "mp2" ||
                                                    fileType === "mpeg"
                                                    ||
                                                    fileType === "mpe" ||
                                                    fileType === "mpv" ||
                                                    fileType === "ogg" ||
                                                    fileType === "swf") ? (
                                                        <video width="160" height="160" controls>
                                                            <source src={currentMedia?.system?.signedUrl} type="video/mp4" />
                                                            Your browser does not support HTML video.
                                                        </video>
                                                    ) : (
                                                        <TextSnippetOutlinedIcon />
                                                    )}
                                                </div>
                                                <div className="message-image-desc">
                                                <a
                                                    style={{ color: "inherit" }}
                                                    href={
                                                        currentMedia?.system
                                                            ?.signedUrl
                                                    }
                                                    target="_blank"
                                                    download
                                                >
                                                    <CloudDownloadOutlined />
                                                </a>
                                                    <div className="momentio-text-bold">
                                                        {" "}
                                                        {currentMedia.data.name}
                                                    </div>
                                                    <div className="momentio-text-normal">
                                                        {/*getTagLabel(
                                                            currentMedia.meta
                                                                .tags,
                                                            props,
                                                            model.tags.options
                                                        )*/}
                                                        {
                                                            currentMedia.meta
                                                            .additionalInfo
                                                        }
                                                    </div>
                                                    <div className="momentio-text-normal"></div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </Grid>
                            );
                        })}
                    {currMediaList.length === 0 && (
                        <div style={{ padding: "10px" }}></div>
                    )}
                </Grid>
            );
        }
    };

    const getDescription = (desc) => {
        let format = desc.split("/");
        return format.length > 0 ? format[format.length - 1] : desc;
    };
    return (
        <React.Fragment>
            {props.form.futureFormPopup && (
                <FutureMessage
                    open={props.form.futureFormPopup}
                    //hidefutureFormPopup={hidefutureFormPopup}
                    messageListQuery={messageListQuery}
                />
            )}
            {(messageListQuery.isLoading ||
                isLoading ||
                messageListQuery.isFetching ||
                contactList.isLoading ||
                contactList.isFetching ||
                mediaList.isLoading 
                ||
                mediaList.isFetching
                ) && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}

            <AuthHeader />
            <div className="momentio-dashboard-container">
                <SideMenu view="mysafe" />
                {openPreview && (
                    <MessagePreview
                        setOpenPreview={() => setOpenPreview(false)}
                        row={previewData}
                    />
                )}
                {!openPreview && (
                    <div className="auth-content">
                        <div className="auth-content-subheader">
                            <div className="content-one" style={{ display: "flex", marginRight: "10px" }}>
                                <div>{props.form.language.DASHBOARD_S26}</div>
                                <span
                                    className="primary-alert-blue-sm showSM"
                                    onClick={showfutureFormPopup}
                                    style={{ marginTop: "-2px", marginLeft: "20px" }}
                                >
                                    <AddIcon />
                                </span>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                                className="files-container-column"
                            >
                                <FilterContacts contactFilter={contactFilter} setContactCategory={(item)=> setContactCategory(item)} contactCategory={contactCategory} />
                                <div className="login-field-input">
                                    <select
                                        className="mm-field-input filter-dropdown"
                                        value={category}
                                        autoComplete="off"
                                        onChange={(event) =>
                                            changeHandler(event)
                                        }
                                    >
                                        <option value="">
                                            {
                                                props.form.language
                                                    .MYFILE_FILTER_CATEGORY
                                            }
                                        </option>
                                        {props.form.accountData &&
                                        messageListQuery.data &&
                                        messageListQuery.data.messageList &&
                                            props.form.accountData
                                                .messageTags &&
                                            entityTagList(
                                                messageListQuery.data
                                                    .messageList
                                            ).map((opt) => {
                                                let filteredTags =
                                                    options.filter(
                                                        (item) =>
                                                            item.value === opt
                                                    )[0];
                                                return (
                                                    <option value={opt}>
                                                        {filteredTags
                                                            ? props.form
                                                                  .language[
                                                                  filteredTags
                                                                      .label
                                                              ]
                                                            : opt}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </div>
                                <button
                                    className="primary-button-blue hideSM"
                                    onClick={showfutureFormPopup}
                                    style={{ textAlign: "center" }}
                                >
                                    {props.form.language.FUTURE_MESSAGE_S1}
                                </button>
                                
                            </div>
                        </div>

                        <div className="messages ewill">
                            <Accordion className="contactAccordion hideXS">
                                <AccordionSummary
                                    aria-controls="panel1bh-content"
                                    id="panel-moment-header"
                                    disabled={true}
                                    expandIcon={
                                        <ExpandMoreIcon
                                            style={{ visibility: "hidden" }}
                                            disabled={true}
                                        />
                                    }
                                >
                                    <Typography
                                        className="contacts-table-header"
                                        style={{
                                            paddingLeft: "20px !important",
                                        }}
                                    >
                                        <span>
                                            {props.form.language.FUTURE_MESSAGE_S6.toUpperCase()}
                                        </span>
                                    </Typography>
                                    <Typography className="contacts-table-header">
                                        <span>
                                            {
                                                props.form.language
                                                    .DELIVERY_MOMENT
                                            }
                                        </span>
                                    </Typography>
                                </AccordionSummary>
                            </Accordion>
                            {messageListQuery &&
                                messageListQuery.data &&
                                messageListQuery.data.messageList.map((row) => {
                                    if (
                                        (category === "" && contactCategory === "") ||
                                        ((category !== "" && row.meta.tags.includes(category)) ||
                                        (contactCategory !== "" && row.delivery.contactID.includes(contactCategory)) )
                                        
                                    )
                                        return (
                                            <Accordion
                                                expanded={
                                                    props.form
                                                        .currentMessage ===
                                                    row.system.id
                                                }
                                                onChange={handleChange(row)}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                    className="header-container-sm"
                                                >
                                                    <Typography className="contact-summary-header header-sm">
                                                        <span className="contactListSection mm-login-header message-title-sm">
                                                            {row.data.title}
                                                        </span>
                                                    </Typography>
                                                    <Typography className="contact-summary-header header-sm hideXS">
                                                        <span className="contactListSection message-delivery-sm">
                                                            <span className="">
                                                                {row.data
                                                                    .messageDate
                                                                    ? `${moment(
                                                                          row
                                                                              .data
                                                                              .messageDate,
                                                                          "YYYY-MM-DD"
                                                                      ).format(
                                                                          "DD-MM-YYYY"
                                                                      )}`
                                                                    : props.form
                                                                          .language
                                                                          .FUTURE_MESSAGE_DATE_UNDECIDED}
                                                            </span>
                                                        </span>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    style={{
                                                        padding:
                                                            "33px 32px 32px 32px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                        className="input-container-column"
                                                    >
                                                        <div
                                                            style={{
                                                                maxWidth:
                                                                    "165px",
                                                            }}
                                                            className="momentio-text-normal accordion-content-option-label"
                                                        >
                                                            {
                                                                props.form
                                                                    .language
                                                                    .MESSAGE_FOR
                                                            }
                                                        </div>
                                                        <div
                                                            className="momentio-text-bold"
                                                            style={{
                                                                width: "70%",
                                                            }}
                                                        >
                                                            {getreceiversContactListID(
                                                                row.delivery
                                                                    .contactID
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                        className="input-container-column"
                                                    >
                                                        <div
                                                            style={{
                                                                maxWidth:
                                                                    "165px",
                                                                
                                                            }}
                                                            className="momentio-text-normal accordion-content-option-label"
                                                        >
                                                            {
                                                                props.form
                                                                    .language
                                                                    .MESSAGE_DESC
                                                            }
                                                        </div>
                                                        <div
                                                            className="momentio-text-bold"
                                                            style={{
                                                                width: "70%",
                                                                whiteSpace: "pre-wrap"
                                                            }}
                                                        >
                                                            {row.data.message}
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                        className="input-container-column"
                                                    >
                                                        <div
                                                            style={{
                                                                maxWidth:
                                                                    "165px",
                                                            }}
                                                            className="momentio-text-normal accordion-content-option-label"
                                                        >
                                                             {props.form.language.CATEGORY_LABEL}
                                                        </div>
                                                        <div
                                                            className="momentio-text-bold"
                                                            style={{
                                                                width: "70%",
                                                            }}
                                                        >
                                                            {getTagLabel(
                                                                row.meta.tags,
                                                                props,
                                                                options
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                margin: "16px 0",
                                                            }}
                                                            className="header24Medium headerAttachment"
                                                        >
                                                            {
                                                                props.form
                                                                    .language
                                                                    .MESSAGE_ATTACHMENT
                                                            }
                                                        </div>
                                                    </div>
                                                    {getMediaList(
                                                        row.data.mediaID
                                                    )}
                                                    <div className="edit-message-icon">
                                                        <button
                                                            className="primary-button-yellow"
                                                            style={{
                                                                marginRight:
                                                                    "10px",
                                                            }}
                                                            onClick={() => {
                                                                setPreviewData(
                                                                    row
                                                                );
                                                                setOpenPreview(
                                                                    true
                                                                );
                                                                window.scrollTo(
                                                                    0,
                                                                    0
                                                                );
                                                            }}
                                                        >
                                                            {props.form.language.PREVIEW_TEXT}
                                                        </button>

                                                        <div
                                                            className="secondary-alert-sm"
                                                            style={{
                                                                marginRight:
                                                                    "10px",
                                                            }}
                                                            onClick={() =>
                                                                editfutureFormPopup(
                                                                    row
                                                                )
                                                            }
                                                        >
                                                            {" "}
                                                            <i className="fa fa-pencil"></i>
                                                        </div>
                                                        <div
                                                            className="secondary-alert-sm"
                                                            onClick={() =>
                                                                handleDelete(
                                                                    row.system
                                                                        .id
                                                                )
                                                            }
                                                        >
                                                            {" "}
                                                            <i className="fa fa-trash"></i>
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                })}
                        </div>
                    </div>
                )}
            </div>
            <AuthFooter />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(loginAccount(payload)),
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Files);
