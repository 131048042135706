import Popper from '@material-ui/core/Popper';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React from 'react';
import { connect } from 'react-redux';
import { addAttribute } from '../../../../Actions/Form';
import { DeleteContactAPI } from '../../actions';

const SocialMediaOverview = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const closePopper = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <React.Fragment key={`social${props.data.ID}`}>
            <div onMouseLeave={closePopper}>
                <React.Fragment>
                    {props.form.selectedSocial && props.form.selectedSocial.ID === props.data.ID && (
                        <Popper open={open} anchorEl={anchorEl}>
                            <div
                                className='menu-container'
                                onMouseLeave={() => closePopper(false)}
                                style={{
                                    marginLeft: '-175px',
                                    marginTop: '0px',
                                    cursor: 'pointer',
                                }}
                            >
                                <div
                                    className='menu-options'
                                    onClick={() => {
                                        closePopper('bottom-end');
                                        props.showAddSocial(props.data);
                                    }}
                                >
                                    {props.form.language.EDIT}
                                </div>
                                <div
                                    className='menu-options'
                                    onClick={() => {
                                        closePopper('bottom-end');
                                        props.handleRequest('delete', props.data.ID);
                                        props.addAttribute({
                                            name: 'selectedSocial',
                                            value: {},
                                        });
                                    }}
                                >
                                    {props.form.language.DELETE}
                                </div>
                            </div>
                        </Popper>
                    )}
                </React.Fragment>

                <div className='overviewContainer'>
                    <div
                        className='overviewMenu'
                        onClick={(event) => {
                            handleClick(event);
                            props.addAttribute({
                                name: 'selectedSocial',
                                value: props.data,
                            });
                        }}
                    >
                        <MoreHorizIcon className='show-menu-icon' />
                    </div>
                    <div
                        className='momentio-text-bold'
                        style={{ marginTop: '-5px', width: '100%' }}
                    >
                        <span style={{ display: 'inline-block', width: '40%' }}>
                            {props.data.socialAccountType === 'twitter'
                                ? 'Twitter'
                                : props.data.socialAccountType === 'facebook'
                                ? 'Facebook'
                                : props.data.socialAccountType === 'instagram'
                                ? 'Instagram'
                                : props.data.socialAccountType === 'youtube'
                                ? 'Youtube'
                                : props.data.socialAccountType === 'tikTok'
                                ? 'TikTok'
                                : props.data.socialAccountType === 'pinterest'
                                ? 'Pinterest'
                                : props.data.socialAccountType === 'snapchat'
                                ? 'Snapchat'
                                : props.data.socialAccountType === 'linkedIn'
                                ? 'LinkedIn'
                                : props.data.socialAccountType === 'other'
                                ? props.form.language.TYPE_OTHER
                                : ''}
                        </span>
                        <span
                            style={{
                                display: 'inline-block',
                                width: '40%',
                                wordBreak: 'break-word',
                            }}
                        >
                            {props.data.userName}
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
        DeleteContactAPI: (input, name) => dispatch(DeleteContactAPI(input, name)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SocialMediaOverview);
