import Popper from "@material-ui/core/Popper";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React from "react";
import { connect } from "react-redux";
import { addAttribute } from "../../../../Actions/Form";

const DebtOverview = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const closePopper = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <React.Fragment>
            <div onMouseLeave={closePopper}>
                <React.Fragment>
                    {props.form.selectedDebt &&
                        props.form.selectedDebt.ID === props.data.ID && (
                            <Popper open={open} anchorEl={anchorEl}>
                                <div
                                    className="menu-container"
                                    onMouseLeave={() => closePopper(false)}
                                    style={{
                                        marginLeft: "-175px",
                                        marginTop: "0px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <div
                                        className="menu-options"
                                        onClick={() => {
                                            closePopper("bottom-end");
                                            props.showAddDebt(props.data);
                                        }}
                                    >
                                        {props.form.language.EDIT}
                                    </div>
                                    <div
                                        className="menu-options"
                                        onClick={() => {
                                            closePopper("bottom-end");
                                            props.handleRequest(
                                                "delete",
                                                props.data.ID
                                            );
                                            props.addAttribute({
                                                name: "selectedDebt",
                                                value: {},
                                            });
                                        }}
                                    >
                                        {props.form.language.DELETE}
                                    </div>
                                </div>
                            </Popper>
                        )}
                </React.Fragment>

                <div className="overviewContainer">
                    <div
                        className="overviewMenu"
                        onClick={(event) => {
                            handleClick(event);
                            props.addAttribute({
                                name: "selectedDebt",
                                value: props.data,
                            });
                        }}
                    >
                        <MoreHorizIcon className="show-menu-icon" />
                    </div>
                    <div
                        className="momentio-text-bold"
                        style={{ marginTop: "-5px", width: "100%" }}
                    >
                        <div
                            style={{ display: "flex" }}
                            className="input-container-column"
                        >
                            <div className="momentio-text-normal accordion-content-option-label">
                                {props.form.language.DEBT_TEXT}
                            </div>
                            <div
                                className="momentio-text-bold"
                                style={{ width: "70%" }}
                            >
                                {props.data.hasDebt === "yes"
                                    ? props.form.language.YES_TEXT
                                    : props.form.language.NONE_TEXT}
                            </div>
                        </div>
                        {props.data.hasDebt === "yes" && (
                            <div
                                style={{ display: "flex" }}
                                className="input-container-column"
                            >
                                <div className="momentio-text-normal accordion-content-option-label">
                                    {props.form.language.DEBT_COMMENT}
                                </div>
                                <div
                                    className="momentio-text-bold"
                                    style={{ width: "70%" ,whiteSpace: "pre-wrap"}}
                                >
                                    {props.data.remark}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DebtOverview);
