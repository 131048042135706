//Translation in English(eng)


//LoginSection
export const LOGIN_S1 = "Log in to your account";
export const LOGIN_S2 = "Email Address";
export const LOGIN_S3 = "Password";
export const LOGIN_S4 = "Reset password";
export const LOGIN_S5 = "Create account";
export const LOGIN_S6 = "Name";
export const LOGIN_S7 = "First name";
export const LOGIN_S8 = "Last Name";
export const LOGIN_S9 = "Select language";
export const LOGIN_S10 = "I agree to the";
export const LOGIN_S11 = "We never share your data with third parties. Your data is safe with us!";
export const LOGIN_S18 = "or";
export const LOGIN_S12 = "I already have an account";
export const LOGIN_S13 = "Phone number";
export const LOGIN_S14 = "My profile";
export const LOGIN_S15 = "Settings";
export const LOGIN_S16 = "Logout";
export const LOGIN_S17 = "Select your country";
export const LOGIN_S19 = "terms and conditions";
export const READONLY_PASSWORD = "Password for heir";
export const AGREEMENT_LABEL = "Agreement acceptance";
export const OR_TEXT = "or";

//Create account - Create account
//Who are you?
//E-mail address
//Password
//phone number
//Select your language
//Select your country
//I agree to the Terms and Conditions
//NB! We will never share or sell your information to anyone, it's safe with us!
//Create account
//I already have an account



//Menu
export const SIDEMENU_S1 = "Inheritance";
export const SIDEMENU_S2 = "Account";
export const SIDEMENU_SUPPORT = "Support";


//Dashboard
export const DASHBOARD_S1 = "Welcome to your personal page";
export const DASHBOARD_S2 = "Here you will find an overview of your data and useful tips to get started right away.";



//Are we still using this?
export const DASHBOARD_S3 = "START NOW";
export const DASHBOARD_S4 = "Send your first memories to the future.";
export const DASHBOARD_S5 = "The message can be edited and/or deleted at any time.";
export const DASHBOARD_S6 = "Start the wizard";
export const DASHBOARD_S7 = "TO DO";
export const DASHBOARD_S8 = "We have four quick questions we'd like to ask you.";
export const DASHBOARD_S9 = "After these questions we have a better picture of your personal situation. Then we do not have to ask you questions that are not applicable to your situation.";
export const DASHBOARD_S10 = "Add contact";
export const DASHBOARD_S11 = "What is a Confidant?";
export const DASHBOARD_S12 = "Why is this necessary?";
export const DASHBOARD_S13 = "Complete your account with important information";
export const DASHBOARD_S14 = "But the pain in check homework cupidatat will be a pleasure that cillum avoid the pain, but do football.";
export const DASHBOARD_S15 = "Top up my account";
export const DASHBOARD_S16 = "Why is this necessary?";
export const DASHBOARD_S17 = "What happens to my data?";
//Are we stil using this?



//Kluis intro pagina - Mysafe intro page
export const MYSAFE_S1 = "Upload a file";
export const MYSAFE_S2 = "Securely store important files in your vault so that you and your loved ones can always access them.";
export const MYSAFE_S3 = "Record your personal situation in just a few minutes.";
export const MYSAFE_S4 = "Create a wish";
export const MYSAFE_S5 = "Don't leave your next of kin with lorum ipsum. Duis aute irure dolor in reprehend erit in voluptate velit esse cillum dolore eu fugiat sed do.";
export const MYSAFE_S6 = "Add Account";
export const MYSAFE_S7 = "Ensure next of kin can find necessary information such as passwords, insight into subscriptions and contracts and use of social media.";
export const MYSAFE_S8 = "Complete Data";



//Mijn Kluis - MYSAFE
export const DASHBOARD_S18 = "My safe";
export const DASHBOARD_S19 = "Files";
export const DASHBOARD_S20 = "Accounts";
export const DASHBOARD_S21 = "Wishes";
export const DASHBOARD_S22 = "Important";
export const DASHBOARD_S23 = "Personal situation";
export const DASHBOARD_S24 = "Important information";
export const DASHBOARD_S25 = "Contacts";
export const DASHBOARD_S26 = "Memories";
export const DASHBOARD_S27 = "Personal situation";
export const DASHBOARD_S28 = "Dashboard";

//Persoonlijke gegevens / Personal information
export const PERSONAL_INFO_LABEL = "Personal information";
export const PERSONAL_INFO_KIDS = "Children";
export const PERSONAL_INFO_GUARDIANS = "Guardians";
export const NO_GUARDIANS_MESSAGE = "I don't have a guardian";
export const SINGLE_GUARDIANS_MESSAGE = "Add Guardian";
export const MULTIPLE_GUARDIANS_MESSAGE = "Add another guardian";
export const NO_ADMIN_MESSAGE = "I don't have an administrator";
export const SINGLE_ADMIN_MESSAGE = "Add administrator";
export const MULTIPLE_ADMIN_MESSAGE = "Add another administrator";
export const NO_PATNER_MESSAGE = "I don't have a partner";
export const SINGLE_PARTNER_MESSAGE = "Add partner";
export const MULTIPLE_PARTNER_MESSAGE = "Add another partner";
export const CREATE_TAG_LABEL = "Create your own category";
export const NO_CHILD_MESSAGE = "I have no children";
export const SINGLE_CHILD_MESSAGE = "Add child";
export const MULTIPLE_CHILD_MESSAGE = "Add another child";
export const NO_PARENTS_MESSAGE = "I have no parents anymore";
export const SINGLE_PARENTS_MESSAGE = "Add parent";
export const MULTIPLE_PARENTS_MESSAGE = "Add another parent";
export const NUMBER_TEXT = "Number";
export const NO_EXECUTER_MESSAGE = "I don't have executer"
export const NO_SOCIAL_MEDIA = "I don't have account"
export const SINGLE_EXECUTER_MESSAGE = "Add executer";
export const MULTIPLE_EXECUTER_MESSAGE = "Add another executer";

//Marital Status
export const SINGLE = "Single";
export const LIVING_TOGETHER = "Living together";
export const REGISTERED = "Registered partnership";
export const MARRIED = "Married";

//Donor codicil
export const DONORCODICIL_YES = "Yes, I am a registered donor";
export const DONORCODICIL_NO = "No, I have indicated that I will not be a donor";
export const DONORCODICIL_FAMILY = "My partner or family decides after my death";
export const DONORCODICIL_CONTACT = "I designate one person to decide after my death";

export const REGISTRATION_CODE_ERROR= "Please enter the registration code";
export const IMPORTANT_INFO_LABEL = "Important information";
export const PASSWORD_LABEL = "Passwords";
export const STRONG_PASSWORD_ERROR = "Use a strong password (minimum 8 characters) or phrase with at least 1 uppercase letter, lowercase letter, number, and punctuation mark";
export const START_WIZARD = "Start the wizard";
export const STOP_WIZARD = "Stop the wizard";
export const ADD_ITEM = "Add an item";
export const PASSWORD_INFO = "It is important that you let us know where your passwords are stored so that next of kin can access your computer and telephone to arrange necessary matters. With a handy wizard you can fill in this information.";
export const PASSWORD_SAVE_DATA = "Save and verify data";
export const PASSWORD_Q1 = "Password manager data";
export const PASSWORD_Q2 = "Name of password manager";
export const PASSWORD_Q3 = "Enter name here";
export const PASSWORD_Q4 = "What is the username?";
export const PASSWORD_Q5 = "Enter your username here";
export const PASSWORD_Q6 = "You wrote it down somewhere";
export const PASSWORD_Q7 = "Where did you record it?";
export const PASSWORD_Q8 = "For example computer or writing";
export const PASSWORD_Q9 = " Where can it be found?";
export const PASSWORD_Q10 = "For example closet or in the safe";
export const PASSWORD_Q11 = "I wrote the data in a different way";
export const PASSWORD_Q12 = "Tell me how you did this";
export const PASSWORD_Q13 = "For example, a location, PIN code from your phone or password from the computer to access the password manager.";
export const PASSWORD_Q14 = "For example saved in your Google account";
export const PASSWORD_Q15 = "Where did you save passwords?";
export const PASSWORD_Q16 = "Your data entered";
export const PASSWORD_Q17 = "Contact for passwords";
export const PASSWORD_PREVIEW = "Click on save if the data has been entered correctly. If you want to change something afterwards you can right click on the 3 dots.";
export const PASSWORD_MANAGER_OPTION_1 = "In a password manager";
export const PASSWORD_MANAGER_OPTION_2 = "I wrote it down";

export const ACCESS_LABEL = "Means of access";


export const FINANCIAL_ADD_SINGLE = "Add financial data";
export const FINANCIAL_ADD = "Add financial";
export const FINANCIAL_EDIT = "Edit financial";

export const BANKING_LABEL = "Bank account";
export const BANK_NAME = "Bank name";
export const ACCOUNT_NUMBER = "Account number";

export const ALIMONY_LABEL = "Alimony";
export const CONTACT_PERSON_TITLE = "Contact person";
export const AMOUNT_PER = "Amount per";
export const AMOUNT_PER_MONTH = "Amount per month";

export const INVESTMENT_LABEL = "Investment";
export const INVESTMENT_DESC = "Investment description";
export const INVESTMENT_INFO = "Investment information";

export const HOUSE_ADD = "Add house";
export const HOUSE_ADD_MULTIPLE = "Add another house";
export const HOUSE_ADD_SINGLE = "Add house";
export const HOUSE_EDIT = "Edit house";

export const DEBT = "Debts";
export const DEBT_TEXT = "Do you have any debts?";
export const DEBT_COMMENT = "Comment";
export const DEBT_ADD = "Add Debts";
export const DEBT_ADD_MULTIPLE = "Add another debt";
export const DEBT_ADD_SINGLE = "Add debts";
export const DEBT_EDIT = "Edit debts";

export const INSURANCE_LABEL= "Insurance"
export const INSURANCE_Q1 = "Insurance type";
export const INSURANCE_ADD = "Add insurances";
export const INSURANCE_EDIT = "Edit insurances";
export const INSURANCE_ADD_MULTIPLE = "Add another insurance";
export const INSURANCE_ADD_SINGLE = "Add insurance";

export const SUBSCRIPTION_LABEL = "Subscriptions";
export const MEMBERSHIP_EDIT = "Edit subscriptions";
export const MEMBERSHIP_ADD = "Add subscriptions";
export const MEMBERSHIP_ADD_MULTIPLE = "Add another subscriptions";
export const MEMBERSHIP_ADD_SINGLE = "Add subscriptions";

export const SPORTS_TITLE = "Sports"
export const CULTURAL_TITLE = "Cultural";
export const CINEMA_TITLE = "Cinema";
export const ADDITIONAL_TITLE = "Additional information";

export const POLICY_NAME = "Policy Name";
export const POLICY_NUMBER = "Policy Number";
export const POLICY_INSURER= "Insurer";
export const POLICY_CAR= "Car";
export const POLICY_LIFE= "Life Insurance";
export const POLICY_TRAVEL= "Travel";
export const POLICY_GLASS= "Glass";
export const POLICY_LIABILITY= "Liability Insurance";
export const POLICY_FIRE= "Fire"

export const HOUSE_TYPE = "Type of house";
export const HOUSE_LABEL = "House";
export const HOUSE_RENTAL="Rental House";
export const HOUSE_OWN ="Own House";
export const LANDLORD_NAME="Landlord Name";
export const MORGAGE_COMPANY="Mortgage lender";

export const LOCATION_LABEL = "Location";

export const CONTRACT_LABEL = "Contracts";
export const CONTRACT_ADD_MULTIPLE = "Add another contract";
export const CONTRACT_ADD = "Add contract";
export const CONTRACT_EDIT = "Edit contract";
export const CONTRACT_NUMBER = "Contract number";
export const CONTRACT_CUSTOMER_NUM = "Customer number";
export const NAME = "Name";
export const IS_APPLICABLE = "If applicable";
export const SELECT_OPTION = "Select";
export const SELECT_OPTION_PROPERTY = "Possessions";
export const SELECT_OPTION_LEGAL = "Legal";
export const SELECT_OPTION_SERVICE = "Services";

export const POLICY_LABEL = "Policy";
export const TYPE_OTHER = "Other";
export const POSTCODE = "Postal Code";

export const SOCIAL_MEDIA_ADD_ANOTHER = "Add another social media";
export const SOCIAL_MEDIA_ADD_SINGLE = "Add social media";
export const SOCIAL_MEDIA_Q1 = "User name";
export const SOCIAL_MEDIA_Q2 = "Which social media platforms do you use?";
export const SOCIAL_MEDIA_EDIT = "Edit social Media";
export const SOCIAL_MEDIA_ADD = "Add social media";

export const EXTRA_INFO = "Extra information";

//Files
export const MYFILE_S1 = "Add file";
export const MYFILE_FILTER_CATEGORY = "Category";
export const FILTER_CONTACT = "Contacts";
export const MYFILE_SELECTED = "Selected file";
export const MYFILE_UPLOAD = "Upload file";
export const CATEGORY_LABEL = "Category";
export const DESCRIPTION_LABEL = "Description";
export const ADDED_ON = "ADDED ON";
export const SHARED_WITH = "SHARED WITH";
export const EDIT_FILE = "Edit file";

//Contacten
export const MYCONTACT_S1 = "Emergency";
export const MYCONTACT_S2 = "Family";
export const MYCONTACT_S3 = "Friends";
export const MYCONTACT_S4 = "Create group";
export const MYCONTACT_S5 = "All contacts";
export const MYCONTACT_S6 = "Private";
export const MYCONTACT_S7 = "Financial";
export const MYCONTACT_S8 = "Health";
export const MYCONTACT_S9 = "Other";
export const MYCONTACT_S10 = "Name";
export const MYCONTACT_S11 = "Email Address";
export const MYCONTACT_S12 = "Phone";
export const MYCONTACT_S13 = "Address";
export const MYCONTACT_S14 = "Date of Birth";
export const MYCONTACT_S15 = "Contact Role";
export const MYCONTACT_S16 = "Phone";
export const MYCONTACT_S17 = "City";
export const MYCONTACT_S18 = "Country";
export const MYCONTACT_S19 = "Birthplace";
export const MYCONTACT_S20 = "BSN number"
export const MYCONTACT_S21= "Marital status"
export const MYCONTACT_S22= "Donor codicil"
export const MYCONTACT_S23= "Language"
export const MYCONTACT_S24 = "Religion"
export const MYCONTACT_S25 = "Next to kin";
export const MYCONTACT_S26 = "Note";
export const CANCEL = "Cancel";
export const SAVE = "Save";
export const EDIT_CONTACT = "Edit contact";
export const ADD_DESCIPTION = "Add short description";

//Herinneringen
export const FUTURE_MESSAGE_S1 = "Create memory";
export const FUTURE_MESSAGE_S2 = "Recipient";
export const FUTURE_MESSAGE_S3 = "Select contact";
export const FUTURE_MESSAGE_S4 = "Add a photo, video or other file";
export const FUTURE_MESSAGE_S5 = "Select attachment";
export const FUTURE_MESSAGE_S6 = "Title";
export const FUTURE_MESSAGE_S7 = "Keep it short";
export const FUTURE_MESSAGE_S8 = "Your message";
export const FUTURE_MESSAGE_S9 = "What makes this moment so special…";
export const FUTURE_MESSAGE_S10 = "When do you want to send the memory?";
export const FUTURE_MESSAGE_S11 = "Message type";

export const FUTURE_MESSAGE_DATE_UNDECIDED = "Date unknown yet";
export const SENT_MESSAGE_TITLE = "Shared Memories";
export const RECIEVED_MESSAGE_TITLE = "Received Memories";
export const MESSAGE_FROM = "From";
export const VIEW_MESSAGE = "View memory";
export const DELETE_MESSAGE = "Delete memory";
export const DOWNLOAD_MEDIA = "Download media";
export const NOTIFICATION_S1 = "Notifications";

export const MESSAGE_ACCEPTANCE= "You can accept all messages from";
export const MESSAGE_ACCEPTANCE_SUFFIX = "view after acceptance"
export const MESSAGE_RECIEVED_FROM = "You have received a message from";
export const ACCEPT_MESSAGE_TEXT = "Accept Message"
export const DECLINE_MESSAGE = "Decline";
export const PERSONAL_MESSAGE = "Personal Message";

export const PREVIEW_TEXT = "Preview";
export const PREVIEW_TEXT_LEGACY = "Preview";
export const LEGACY_TITLE_TEXT = "Title";
export const MOMENTIO_LEGACY = "A Momentio Legacy";
export const FUTURE_DATE_VALIDATION = "Please enter a future date";

export const QUESTION_LABEL = "QUESTION";
export const SAVE_GOTO = "Save and go to question 2";
export const MESSAGE_STEP = "To question";
export const ADD_TAGS = "Add a category";
export const SELECT_TAG = "Select a category";
export const SELECT_TAG_FILE = "Add category to this file";
export const SELECT_TAG_CONTACT = "Add category to this contact";
export const TAG_EXISTS = "Category is already in use";
export const QUESTION_LABEL_OF = "of";
export const PREVIEW_MESSAGE_TITLE = "A Momentio Memory";
export const PREVIEW_DELIVERY_TEXT = "Delivery";
export const PREVIEW_FROM_TEXT = "From";

//Contacten
export const DELETE = "Delete";
export const EDIT = "Edit";
export const ADD = "Add";
export const CONTACT_LABEL = "contact";
export const CONTACT_LABEL_CAPS = "Contact";
export const CONTACT_TYPE_EMERGENCY = "Emergency";
export const CONTACT_TYPE_PRIVATE = "Private";
export const CONTACT_TYPE_FAMILY = "Family";
export const CONTACT_TYPE_HEALTHCARE = "Health";
export const CONTACT_TYPE_FINANCIAL = "Financial";
export const CONTACT_TYPE_FRIEND = "Friend";
export const CONTACT_INFORM_TEXT = "Inform upon death";
export const CONTACT_INFORM_TYPE = "Inform upon death";
export const CONTACT_INFORM_TYPE_PHONE = "Yes, by phone";
export const CONTACT_INFORM_TYPE_EMAIL = "Yes, via email";
export const CONTACT_INFORM_TYPE_POST = "Yes, via post";
export const CONTACT_INFORM_TYPE_PERSONAL = "Yes, personal";
export const CONTACT_ROLE_TYPE_PARTNER = "Partner";
export const CONTACT_ROLE_TYPE_PARENT = "Parent";
export const CONTACT_ROLE_TYPE_CHILD = "Child";
export const CONTACT_ROLE_TYPE_GUARDIAN = "Guardian";
export const CONTACT_ROLE_TYPE_ADMIN = "Administrator";
export const CONTACT_ROLE_TYPE_TRUSTEE = "Trustee";
export const CONTACT_ROLE_TYPE_EMPLOYER = "Employer";
export const CONTACT_ROLE_TYPE_CONSULT = "Tax consultant";
export const CONTACT_ROLE_TYPE_GP = "General practitioner";
export const CONTACT_ROLE_TYPE_PSYCH = "Psychologist";
export const CONTACT_ROLE_TYPE_SPEAKER = "Funeral speaker";
export const CONTACT_ROLE_TYPE_NOTARY = "Notary";
export const CONTACT_ROLE_TYPE_EXECUTIONAR = "Executor";
export const CONTACT_ROLE_TYPE_COMPANY = "Funeral director";
export const CONTACT_ROLE_TYPE_MUNICIPAL = "Municipality";
export const CONTACT_ROLE_TYPE_CLERK = "Clerk";
export const NONE_TEXT = "No";
export const YES_TEXT = "Yes";

//Laatste wensen
export const FUNERAL_POLICY = "Funeral policy";
export const RECORD_POLICY = "Record last wishes";
export const FUNERAL_TYPE = "Funeral type";
export const CONTRACT = "Contract";
export const FUNERAL_POLICY_Q1 = "Do you have a funeral policy?";
export const FUNERAL_POLICY_Q2 = "Please record my wishes in momentio";
export const FUNERAL_POLICY_Q3 = "No and don't want to record anything now";
export const FUNERAL_POLICY_Q4 = "Funeral organization name";
export const FUNERAL_POLICY_Q5 = "Do you wish to record last wishes in Momentio?";
export const FUNERAL_Q1 = "Funeral type";
export const FUNERAL_Q2 = "Public";
export const FUNERAL_Q3 = "Closed circle";
export const FUNERAL_Q4 = "With some form of military honor";
export const FUNERAL_Q5 = "Do you want to be buried or cremated?";
export const FUNERAL_Q6 = "Buried";
export const FUNERAL_Q7 = "Bury";
export const FUNERAL_Q8 = "Cremated";
export const FUNERAL_Q9 = "Where do you want to be buried?";
export const FUNERAL_Q10 = "Foundation grave";
export const FUNERAL_Q11 = "State grave";
export const FUNERAL_Q12 = "Private Grave";
export const FUNERAL_Q13 = "Where do you want to be cremated?";
export const FUNERAL_Q14 = "What should be done with the ashes?";
export const FUNERAL_Q15 = "Scattering";
export const FUNERAL_Q16 = "Chest";
export const FUNERAL_Q17 = "Default";
export const FUNERAL_Q18 = "Other";
export const FUNERAL_Q20 = "funeral";

export const NOTIFICATION_LABEL = "Notification";
export const NOTIFICATION_Q1 = "Notification";
export const NOTIFICATION_Q2 = "Bereavement card";
export const NOTIFICATION_Q3 = "Mourning card text";
export const NOTIFICATION_Q4 = "Advertisement";
export const NOTIFICATION_Q5 = "Where to place?";
export const NOTIFICATION_ADD = "Add another person";
export const DOWNLOAD_CONTACT = "Download addresses file";
export const NEXT_TO_KIN = "Next of kin";

export const SPEAKER_LABEL = "Speakers";
export const SPEAKER_Q1 = "Do you want speakers?";
export const SPEAKER_Q2 = "Who";
export const SPEAKER_Q3 = "Have people been notified?";

export const TRANSPORT_LABEL = "Transportation";
export const TRANPORT_Q1 ="Do you want a funeral procession ?"
export const TRANPORT_Q2 ="What mode of transportation?"
export const TRANPORT_Q3 = "Do you want following cars";
export const TRANPORT_Q4 = "Number of cars"
export const TRANPORT_Q5 = "Who are allowed in the follow cars";

export const ORGANISATION_LABEL = "Organization";
export const ORGANISATION_Q1 = "I want to entrust the funeral to";
export const ORGANISATION_Q2 = "Ceremony";
export const ORGANISATION_Q3 = "Name of the church";
export const ORGANISATION_Q4 = "Disclosure";
export const ORGANISATION_Q5 = "Coffin during laying out";
export const ORGANISATION_Q6 = "Condolence";
export const ORGANISATION_Q7 = "Determine next of kin";
export const ORGANISATION_Q8 = "Registry";
export const ORGANISATION_Q10 = "Closed";
export const ORGANISATION_Q11 = "Open";
export const ORGANISATION_Q12 = "In funeral home";
export const ORGANISATION_Q13 = "Funeral Association";
export const ORGANISATION_Q14 = "Spiritual";
export const ORGANISATION_Q15 = "Condolence register";
export const ORGANISATION_Q16 = "Personal relation";
export const ORGANISATION_Q17 = "Choice of funeral";

export const MUSIC_LABEL = "Music";
export const MUSIC_Q1 = "Do you want music at the funeral";
export const MUSIC_Q2 = "What music";
export const MUSIC_Q3 = "Live Music";

export const MEMORIES_LABEL = "Memory";
export const MEMORIES_Q1 = "Memories";
export const MEMORIES_Q2 = "If desired, you can save the lyrics for poems in your safe.";

export const MEDIA_LABEL = "Media";
export const MEDIA_Q1 = "Video recording allowed?";
export const MEDIA_Q2 = "Photos allowed";

export const MEAL_LABEL = "Meal";
export const MEAL_Q1 = "Do you want a coffee table?";
export const MEAL_Q2 = "Meal before/after funeral";
export const MEAL_Q3 = "Meal preference";

export const FLOWER_LABEL = "Flowers";
export const FLOWER_Q1 = "Do you want flowers on the coffin?";
export const FLOWER_Q2 = "What kind of flowers?";
export const FLOWER_Q3 = "Do you want flowers at the grave?";
export const FLOWER_Q5 = "What do you absolutely not want?";

export const CARRIER_LABEL = "Carriers";
export const CARRIER_Q1 = "Do you want carriers?";
export const CARRIER_Q2 = "Yes, from the funeral home";
export const CARRIER_Q3 = "Yes, by next of kin";
export const CARRIER_Q4 = "Names of the carriers";
export const CARRIER_Q5 = "Are the people aware?";
export const CARRIER_Q6 = "Particulars";

export const ACCESS_EDIT = "Edit Key";
export const ACCESS_ADD = "Add Key";
export const ACCESS_ADD_MULTIPLE = "Add another key";
export const ACCESS_ADD_SINGLE = "Add key";
export const MANDATORY_FIELD = "is required field";
export const INVALID_FIELD= "incorrect format";
export const INVALID_TEXT= "Incorrect";
export const ADD_TEXT = "Add";
export const EDIT_TEXT= "Edit";
export const REGISTER_NOTE = "NOTE!";

export const IMAGE_TEXT = "Image";

export const SAVE_SELECTION = "Save selection"
export const SAVE_MESSAGE = "Save";
export const DELIVERY_MOMENT = "DATE";
export const MESSAGE_FOR = "Who is it for";
export const MESSAGE_DESC = "Message";
export const MESSAGE_ATTACHMENT = "Attachments";

export const MESSAGE_PERSONAL = "Personal";
export const MESSAGE_INSTRUCTION = "Instruction";
export const MESSAGE_FAREWELL = "Farewell";

export const TESTAMENT_CART = "Personal and card";
export const TELEPHONE_TEXT = "Phone";
export const CARD_TEXT = "Card";
export const DO_NOT_INFORM = "Do not inform";

export const COMPLETED_TEXT = 'Completed';

//error messages
export const BAD_REQUEST = "Bad request";
export const ACCOUNT_BLOCKED = "Your account has been blocked";
export const ACCOUNT_NOT_FOUND = "Your account was not found";
export const ACCOUNT_INACTIVE = "Your account is inactive";
export const INCORRECT_CREDENTIAL = "Your username and/or password is incorrect";
export const INCORRECT_CREDENTIAL_READONLY = "Password is incorrect";
export const INCORRECT_CODE = "Code is incorrect";
export const NO_MEMBERSHIP = "Your account has no subscription";
export const CODE_SENDING_ERROR = "Unable to send or generate code";
export const UNKNOWN_ERROR = "Something went wrong";
export const INVALID_SESSION_ERROR = "Session expired";
export const LIMIT_REACHED = "Not accepted, limit has been reached";
export const NO_LAST_WISHES ="No last wishes found";
export const FORBIDDEN_ERROR = "Impossible, last wish data already exists";
export const OBJECT_NOT_FOUND = "Object not found";
export const NOT_FOUND = "Not found";
export const ERROR_GENERAL = "Error, please reload the page and start over";
export const INVALID_INPUT = "Invalid input, please try again";
export const READ_ONLY_ERROR_MESSAGE = "You have read only permissions and cannot make any edits"
export const ERROR_SAVING = "Error: Unable to save"
export const ERROR_DELETE = "Error: Unable to delete"
export const RegisterError = "Problem registering, please try again later";
export const RegisterErrorEmailExists = "Email address already exists, use forgot password to reset it";
export const INVALID_SESSION = "You have been automatically logged out, please log in again to continue"
export const RegisterErrorIncorrectCode = "Incorrect activation code.";
export const RegisterErrorPhoneExists = "Phone number is already in use";
export const unableToLoginErrorMessage = "Login failed, please try again with correct credentials";
export const unableToMagicLoginErrorMessage = "Login failed, try to login with email and password";

//Dashboard Notifications
export const DASHBOARD_NOTIFICATION__FIRST_FILE = "Thank you for uploading your first files. We recommend that you save important files (such as contracts) in the digital vault.";
export const DASHBOARD_NOTIFICATION_CONTACT_PHONE = "Some contacts do not have a phone number yet. If next of kin need to be called, it helps if you enter phone numbers."
export const DASHBOARD_NOTIFICATION_CONTACT_ADDRESS = "Some contacts do not yet have a (postal) address. Unburden your next of kin and fill in the missing information."
export const DASHBOARD_NOTI_FILE_UPLOAD = "In addition to all information, it is possible to save files such as letters, photos or videos securely."
export const DASHBOARD_NOTI_IMPORTANT_INFO = "Completely fill important information to 100%";
export const DASHBOARD_NOTI_PERSONAL_INFO = "Complete personal information to 100%.";
export const NEXT_TIP = "Next Tip"
export const UPDATE_NOW = "Update Now"
export const DASHBOARD_NOTIFICATION_CONTACT = "You have not added any contacts yet. We recommend that you start by adding contacts."
export const DASHBOARD_NOTIFICATION_FILE = "Your digital vault is still empty. We recommend that you start uploading important files. Don't forget to add tags to the files for easy retrieval."
export const DASHBOARD_NOTI_MESSAGE ="You have not yet created a reminder for the future. Share beautiful memories and create a message for an important moment."
export const DASHBOARD_NOTI_MESSAGE_SECOND ="Congratulations on creating your first message! Future messages can easily be created as a draft without an addressee or delivery date."
export const DASHBOARD_NOTI_MESSAGE_THIRD = "Great! You're about to send your third message. For even more inspiration, check out our tutorial and blog."
export const YOUR_DATA = "Your information"
export const PROGRESS = "Progress"

//Legacy
export const LEGACY_BENIFICIARY = "Beneficiary";
export const LEGACY_TITLE = "Legacy";
export const SEND_LEGACY = "Add Legacy";
export const LEGACY_FOR = "Who is the beneficiary?";
export const LEGACY_FILTER_CATEGORY = "Category";
export const LEGACY_DESC_TITLE = "What do you want to leave behind?"

//Subscription
export const SUBSCRIPTION_RESULT = "Subscription Status";
export const SUBSCRIPTION_RESULT_OPEN = "Open";
export const SUBSCRIPTION_RESULT_PAID = "Paid";
export const SUBSCRIPTION_RESULT_CANCELLED= "Cancelled";
export const SUBSCRIPTION_RESULT_FAILED= "failed";
export const PAYMENT_STATUS = "Payment";
export const Subscribe = "Subscribe";
export const ANNUAL_FEE = "Yearly"
export const CURRENCY = "€"
export const SUBSCRIPTION_TEXT = "Subscription";
export const CURRENT_SUBSCRIPTION = "Current subscription"
export const SUBSCRIPTION_ONE = "Subscription 1"
export const SUBSCRIPTION_TWO = "Subscription 2"
export const SUBSCRIPTION_THREE = "Subscription 3"
export const SUBSCRIPTION_FOUR = "Subscription 4"
export const PLACE_ORDER = "Place Order"
export const MY_SUBSCRIPTION = "My subscription";
export const UPGRADE_ACCOUNT = "Upgrade subscription";
export const BASIC_SUBSCRIPTION = "Basic";
export const BETTER_SUBSCRIPTION = "Better";
export const PERFECT_SUBSCRIPTION = "Perfect";
export const FREE_SUBSCRIPTION = "Free";
export const READONLY_SUBSCRIPTION = "READ ONLY";
export const EXPIRY_SUBSCRIPTION = "EXPIRES IN";
export const EXPIRY_SUBSCRIPTION_WEEKS = "DAYS";
export const POPULAR_SUBSCRIPTION = "MOST CHOSEN";
export const SUBSCRIPTION_SAFE = "Safe";
export const SUBSCRIPTION_MEMORIES = "Memories";
export const SUBSCRIPTION_LASTWISH = "Last wishes";
export const SUBSCRIPTION_LEGACY = "Legacy";
export const SUBSCRIPTION_SUPPORT = "Support relatives";
export const SUBSCRIPTION_UNLIMITED = "unlimited";
export const SUBSCRIPTION_MESSAGE = "Choose 1 of the subscriptions below to get started right away. You can also upgrade a subscription in the meantime. Let's take care of each other together.";
export const SUBSCRIPTION_BASIC_BUTTON = "Choose basic";
export const SUBSCRIPTION_BETTER_BUTTON = "Choose better";
export const SUBSCRIPTION_PREMIUM_BUTTON = "Choose perfect";
export const SUBSCRIPTION_PAYMENT_HINT = "Quarterly Payment";
export const SUBSCRIPTION_PAYMENT_HINT_PREMIUM = "Payment once, after 10 years € 50 per year";
export const SUBSCRIPTION_HINT_PREMIUM = "*After 5 years € 75 yearly";
export const ONE_TIME_TEXT = "One-time";

export const MEMBER_SINCE = "Member since";
export const VALID_UNTIL = "Valid until";
export const ACCOUNT_EXPIRY = "Account expiration";

export const VALID_UNTIL_DISCLAIMER = "The 'valid until' date indicates until when the subscription has been paid. During this period the account holder can use the platform normally. After this date the account holder can still use the platform temporarily in read mode. renewal of the subscription moves the 'valid until' date by another year."

export const ACCOUNT_EXPIRY_DISCLAIMER = "The 'expiry date' is the period between the expiration of the subscription ('valid until') and the deletion of the account. After the 'expiry date' the account will no longer be available and all data will be deleted, including the messages to be delivered in the future.";

//Login/Registry/Password reset
export const LOGIN_INFORMATION = "Login Details";
export const CHANGE_PASSWORD = "Change password";
export const CHANGE_EMAIL = "Change email address";
export const DOWNLOAD_ACCOUNT = "Download or delete your account"
export const DOWNLOAD_ACCOUNT_TEXT = "Here you can download an archive with all your data or take the first step to delete your account."

export const CHANGE_NEXTTOKIN = 'Change next to kin';
export const REGISTER_EMAIL_HEADER = "You have received an email!";
export const REGISTER_SMS_HEADER = "You have received an SMS!";
export const REGISTER_SMS_RESEND = "Resend SMS";
export const REGISTER_EMAIL_RESEND = "Resend Email";
export const REGISTER_CODE_RESEND = "No code received?";
export const REGISTER_EMAIL_HELP = "We'd like to verify your email address, so we've sent a code to "
export const REGISTER_SMS_HELP = "We would like to verify your phone number, so we have sent a code to "
export const REGISTER_EMAIL_PLACEHOLDER = "Enter the verification code";
export const REGISTER_SMS_BUTTON = "Verify phone number";
export const REGISTER_EMAIL_BUTTON = "Verify email address";
export const REGISTRATION_SUCCESS = "Registration successful. You can now login to your account"

export const PASSWORD_CHANGED = "Password has been changed"
export const LOGGED_IN_AS = "LOGGED IN AS";

//Website
export const COMPANY_NAME = "Momentio";
export const MAIN_MENU_S1 = "Memories";
export const MAIN_MENU_S2 = "E-will";
export const MAIN_MENU_S3 = "Donate";
export const MAIN_MENU_S4 = "About us";
export const MAIN_MENU_S5 = "Sign Up";
export const MAIN_MENU_LOGIN = "Login";
export const HOME_PAGE_S1 = "Your memories are in good hands forever?";
export const HOME_PAGE_S2 = "Start capturing now";
export const HOME_PAGE_S3 = "Or find out first";
export const HOME_PAGE_S4 = "how it works";
export const HOME_PAGE_S5 = "HOW DOES MOMENTIO WORK";
export const HOME_PAGE_S6 = "Momentio is a safe place for your precious memories and documents that you can share with your loved ones now and in the future. Minimize the administrative burden and let your loved one focus on remembering you.";
export const HOME_PAGE_S7 = "Create your archive";
export const HOME_PAGE_S8 = "In your archive you record those things that are important to your loved ones. For example, in case you are no longer there. Think about:";
export const HOME_PAGE_S9 = "Videos";
export const HOME_PAGE_S10 = "Photos";
export const HOME_PAGE_S11 = "Sound recordings";
export const HOME_PAGE_S12 = "And much more…";
export const HOME_PAGE_S13 = "Create archive";
export const HOME_PAGE_S14 = "Create your contact list";
export const HOME_PAGE_S15 = "Here are the contact details of the important contacts you have defined. You determine who and when has access to your archive, wishes or vault. For example:";
export const HOME_PAGE_S16 = "Your partner";
export const HOME_PAGE_S17 = "Your children";
export const HOME_PAGE_S18 = "Your Administrator";
export const HOME_PAGE_S19 = "Your notary";
export const HOME_PAGE_S20 = "And much more…";
export const HOME_PAGE_S21 = "Your contact list";
export const HOME_PAGE_S22 = "Share your memories";
export const HOME_PAGE_S23 = "You choose who receives a (digital) reminder and when. This way you can share precious memories and messages in the future. For example:";
export const HOME_PAGE_S24 = "A birthday message";
export const HOME_PAGE_S25 = "Your will";
export const HOME_PAGE_S26 = "That one nice picture together";
export const HOME_PAGE_S27 = "A personal letter";
export const HOME_PAGE_S28 = "And much more…";
export const HOME_PAGE_S29 = "Share your archive";
export const HOME_PAGE_S30 = "WHAT DO OTHERS FIND";
export const HOME_PAGE_S31 = "'Thanks to Momentio I can still send my wife a flower later.'";
export const HOME_PAGE_S32 = "– Bart van Achtereeckte";
export const HOME_PAGE_S33 = "FROM OUR BLOG";
export const HOME_PAGE_S34 = "The latest developments";
export const HOME_PAGE_S35 = "TIPS & TRICS";
export const HOME_PAGE_S36 = "Record a personal message";
export const HOME_PAGE_S37 = "It's easier said than done. Leave a message or record a video for someone when you are gone.";
export const HOME_PAGE_S38 = "HOW DOES THAT WORK";
export const HOME_PAGE_S39 = "What is and does an administrator?";
export const HOME_PAGE_S40 = "More than half of young people have never thought about their own funeral. And if they have thought about their funeral wishes, they are usually not discussed with others. Only 21% have discussed their ideas with parents or friends. Less than 1% has recorded the funeral wishes on paper, on the computer or with a funeral insurer. Now there is finally a platform to record these wishes in an accessible manner...Momentio.";
export const HOME_PAGE_S41 = "PRODUCT";
export const HOME_PAGE_S42 = "Social media after your death: Delete or not?";
export const HOME_PAGE_S43 = "85 percent of Dutch people between the ages of 18 and 30 have not (yet) arranged what happens to their social media accounts after death. There are therefore many options. Leave all accounts, delete them, temporarily to exist, to have a last personal update posted by a relative... Do you already know?";
export const HOME_PAGE_S44 = "To our blog";
export const HOME_PAGE_S45 = "SIGN UP";
export const HOME_PAGE_S46 = "Start your personal archive today!";
export const HOME_PAGE_S47 = "Register now ";
export const HOME_PAGE_S48 = "Check out our first";
export const HOME_PAGE_S49 = "packages";
export const HOME_PAGE_S50 = "Create a valuable archive and share it with your loved ones.";
export const HOME_PAGE_S51 = "And much more…";
export const HOME_PAGE_S52 = "View more comments";

// Footer
export const FOOTER_S1 = "Memories";
export const FOOTER_S2 = "Capture Memories";
export const FOOTER_S3 = "Last Wishes";
export const FOOTER_S4 = "Your Last Wishes";
export const FOOTER_S5 = "E-testament";
export const FOOTER_S6 = "My will";
export const FOOTER_S7 = "Momentio";
export const FOOTER_S8 = "Our Philosophy";
export const FOOTER_S9 = "Disclaimer";
export const FOOTER_S10 = "Terms and conditions";
export const FOOTER_S11 = "© Copyright 2023";
export const FOOTER_SUPPORT = "Support";
export const FOOTER_S21 = "Future messages loved ones";
export const FOOTER_S41 = "Your digital vault";
export const FOOTER_S61 = "Your notary";
export const FOOTER_S81 = "Donations";
export const FOOTER_S82 = "FAQ";
export const FOOTER_S83 = "Contact";

export const FOOTER_FACEBOOK_URL = "https://www.facebook.com/momentio.nl";
export const FOOTER_YOUTUBE_URL = "https://www.youtube.com/@Momentio_nl";
export const FOOTER_TWITTER_URL = "https://x.com/momentio_nl";
export const FOOTER_INSTA_URL = "https://www.instagram.com/momentio.nl";

export const MOMENTIO_BASE_URL = "https://www.momentio.nl";
