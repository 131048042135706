/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { connect } from "react-redux";
import { addAttribute } from "../../../Actions/Form";
import AuthFooter from "../../Common/AuthFooter";
import AuthHeader from "../../Common/AuthHeader";
import SideMenu from "../../Common/SideMenu";
import Contacts from "./Contacts";
import Personal from "./Personal";
import { getTags, filterContacts } from "../../Contacts/ContactTags";

const useStyles = makeStyles((theme) => ({
    container: {
        boxShadow: "none !important",
    },
}));

const PersonalInfo = (props) => {
    const EWillModel = {
        name: props.form.language.DASHBOARD_S27,
        completed: 0,
        category: "personal",
        subtitles: [
            {
                name: props.form.language.PERSONAL_INFO_LABEL,
                category: "personal",
                text: "personal",
                scroll: 70,
            },
            {
                name: props.form.language.CONTACT_ROLE_TYPE_PARTNER,
                category: "partner",
                text: "partner",
                hasData: "hasPartner",
                noCategoryMsg: props.form.language.NO_PATNER_MESSAGE,
                addAnotherMsg: props.form.language.MULTIPLE_PARTNER_MESSAGE,
                addFirstMsg: props.form.language.SINGLE_PARTNER_MESSAGE,
                scroll: 70 + 80,
            },
            {
                name: props.form.language.PERSONAL_INFO_KIDS,
                category: "child",
                text: "kind",
                hasData: "hasChildren",
                noCategoryMsg: props.form.language.NO_CHILD_MESSAGE,
                addAnotherMsg: props.form.language.MULTIPLE_CHILD_MESSAGE,
                addFirstMsg: props.form.language.SINGLE_CHILD_MESSAGE,
                scroll: 70 + 160,
            },
            {
                name: props.form.language.CONTACT_ROLE_TYPE_PARENT,
                text: "ouder",
                category: "parent",
                hasData: "hasParents",
                noCategoryMsg: props.form.language.NO_PARENTS_MESSAGE,
                addAnotherMsg: props.form.language.MULTIPLE_PARENTS_MESSAGE,
                addFirstMsg: props.form.language.SINGLE_PARENTS_MESSAGE,
                scroll: 70 + 240,
            },
            {
                name: props.form.language.PERSONAL_INFO_GUARDIANS,
                text: "voogd",
                category: "guardian",
                noCategoryMsg: props.form.language.NO_GUARDIANS_MESSAGE,
                addAnotherMsg: props.form.language.MULTIPLE_GUARDIANS_MESSAGE,
                addFirstMsg: props.form.language.SINGLE_GUARDIANS_MESSAGE,
                hasData: "hasGuardian",
                scroll: 70 + 320,
            },
            {
                name: props.form.language.CONTACT_ROLE_TYPE_ADMIN,
                text: "admin",
                category: "admin",
                noCategoryMsg: props.form.language.NO_ADMIN_MESSAGE,
                addAnotherMsg: props.form.language.MULTIPLE_ADMIN_MESSAGE,
                addFirstMsg: props.form.language.SINGLE_ADMIN_MESSAGE,
                hasData: "hasCareGivers",
                scroll: 70 + 400,
            },
            {
                name: props.form.language.CONTACT_ROLE_TYPE_EXECUTIONAR,
                text: "testamentary executioner",
                category: "testamentary executioner",
                noCategoryMsg: props.form.language.NO_EXECUTER_MESSAGE,
                addAnotherMsg: props.form.language.MULTIPLE_EXECUTER_MESSAGE,
                addFirstMsg: props.form.language.SINGLE_EXECUTER_MESSAGE,
                scroll: 70 + 480,
            },
        ],
    };

    const classes = useStyles();
    React.useEffect(() => {
        getCompleted(true);
    }, [props.form.accountData?.profile || props.form.accountData?.contactList]);

    const getCompleted = (isLoad) => {
        let data = mainSection;
        let personalCompleted = 0;
        let profileData = {};
        let pcompletedList = [];
        if (props.form.accountData && props.form.accountData.profile) {
            profileData = props.form.accountData.profile;
            personalCompleted = personalCompleted + (100/7);
            data.subtitles[0].completed = true;
        }
        data.subtitles[1].completed = false;
        data.subtitles[2].completed = false;
        data.subtitles[3].completed = false;
        data.subtitles[4].completed = false;
        data.subtitles[5].completed = false;
        data.subtitles[6].completed = false;
        if (
            props.form.accountData &&
            props.form.accountData.contactList &&
            props.form.accountData.contactList.length > 0
        ) {
            props.form.accountData.contactList.map((contact) => {
                if (filterContacts("partner", contact.meta.tags)) {
                    //   personalCompleted = personalCompleted + 20;
                    if (pcompletedList.indexOf("partner") < 0) {
                        pcompletedList.push("partner");
                    }
                    data.subtitles[1].completed = true;
                } else if (filterContacts("child", contact.meta.tags)) {
                    if (pcompletedList.indexOf("child") < 0) {
                        pcompletedList.push("child");
                    }
                    //  personalCompleted = personalCompleted + 20;
                    data.subtitles[2].completed = true;
                } else if (filterContacts("parent", contact.meta.tags)) {
                    if (pcompletedList.indexOf("parent") < 0) {
                        pcompletedList.push("parent");
                    }
                    //  personalCompleted = personalCompleted + 20;
                    data.subtitles[3].completed = true;
                } else if (filterContacts("guardian", contact.meta.tags)) {
                    if (pcompletedList.indexOf("guardian") < 0) {
                        pcompletedList.push("guardian");
                    }
                    data.subtitles[4].completed = true;
                }
                else if (filterContacts("admin", contact.meta.tags)) {
                    if (pcompletedList.indexOf("admin") < 0) {
                        pcompletedList.push("admin");
                    }
                    data.subtitles[5].completed = true;
                } else if (filterContacts("testamentary executioner", contact.meta.tags)) {
                    if (pcompletedList.indexOf("testamentary executioner") < 0) {
                        pcompletedList.push("testamentary executioner");
                    }
                    data.subtitles[6].completed = true;
                }
                
            });
        }
        if (profileData && profileData?.settings?.hasPartner == "no") {
            //   personalCompleted = personalCompleted + 20;
            if (pcompletedList.indexOf("partner") < 0) {
                pcompletedList.push("partner");
            }
            data.subtitles[1].completed = true;
        }
        if (profileData && profileData?.settings?.hasChildren === "no") {
            if (pcompletedList.indexOf("child") < 0) {
                pcompletedList.push("child");
            }
            //  personalCompleted = personalCompleted + 20;
            data.subtitles[2].completed = true;
        }
        if (profileData && profileData?.settings?.hasParents === "no") {
            if (pcompletedList.indexOf("parent") < 0) {
                pcompletedList.push("parent");
            }
            //  personalCompleted = personalCompleted + 20;
            data.subtitles[3].completed = true;
        }
        if (profileData && profileData?.settings?.hasGuardian === "no") {
            if (pcompletedList.indexOf("guardian") < 0) {
                pcompletedList.push("guardian");
            }
            data.subtitles[4].completed = true;
        }
        if (profileData && profileData?.settings?.hasCareGivers === "no") {
            if (pcompletedList.indexOf("admin") < 0) {
                pcompletedList.push("admin");
            }
            data.subtitles[5].completed = true;
        }

        if (props.form.accountData) {
            personalCompleted = (100/7) * pcompletedList.length + personalCompleted;
            data.completed = parseInt(personalCompleted);
        }
        if (isLoad) {
            setmainSections(JSON.parse(JSON.stringify(data)));
        } else {
            return data.completed;
        }
    };

    const [mainSection, setmainSections] = React.useState(EWillModel);

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        window.scrollTo(0, panel.scroll);
        setExpanded(isExpanded ? panel.category : false);
        props.addAttribute({
            name: "ewillStep",
            value: isExpanded ? panel.category : false,
        });
    };
    const getContent = (data) => {
        switch (data.category) {
            case "personal":
                return <Personal />;
            case "partner":
            case "child":
            case "parent":
            case "guardian":
            case "admin":
            case "testamentary executioner":
                return props.form.accountData?.profile?.limits?.accessList?.contact ? <Contacts type={data.category} data={data} getCompleted={()=> getCompleted()}/> : "";
            default:
                return "";
        }
    };
    return (
        <React.Fragment>
            {props.form.isLoading && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <AuthHeader />
            <div className="momentio-dashboard-container">
                <SideMenu view="mysafe" />
                <div className="auth-content">
                    <div>
                        <div className="auth-content-subheader">
                            <div className="content-one">
                                {mainSection.name}
                            </div>
                            <div className="mm-login-header">
                                {" "}
                                {props.form.language.COMPLETED_TEXT + " " + getCompleted() + "%"}
                            </div>
                        </div>
                        <div className="ewill">
                            {mainSection.subtitles &&
                                mainSection.subtitles.map((subtitle) => {
                                    if((subtitle.category !== "personal" && props.form.accountData?.profile?.limits?.accessList?.contact) || 
                                    subtitle.category === "personal"){
                                        return (
                                            <Accordion
                                                className={classes.container}
                                                expanded={
                                                    props.form.ewillStep ===
                                                    subtitle.category
                                                }
                                                onChange={handleChange(subtitle)}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel1bh-content"
                                                    expandIcon={<ExpandMoreIcon />}
                                                    id="panel-moment-header"
                                                >
                                                    <div className="mm-login-header">
                                                        {subtitle.completed ? (
                                                            <CheckCircleIcon className="task-completed" />
                                                        ) : (
                                                            <CheckCircleIcon className="task-not-completed" />
                                                        )}
                                                        {subtitle.name}
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {getContent(subtitle)}
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    }
                                    
                                })}
                        </div>
                    </div>
                </div>
            </div>
            <AuthFooter />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
