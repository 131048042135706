// eslint-disable jsx-a11y/anchor-is-valid
// eslint-disable jsx-a11y/alt-text
// eslint-disable no-useless-escape
import React, { useEffect } from "react";
import image1 from "../Images/Schermafbeelding 2021-01-11 om 18.14.16.png";
import image2 from "../Images/Schermafbeelding 2021-01-11 om 18.18.33.png";
import image3 from "../Images/flowers-pink.jpg";
import image4 from "../Images/Canyonlands-National-Park-Utah-The-Mandagies-44.jpg";
import image5 from "../Images/Schermafbeelding 2021-01-11 om 18.15.04.png";
import image6 from "../Images/sunglasses.jpg";
import image7 from "../Images/56cc7729069096e5ef8f832f62d03e68.jpg";
import image8 from "../Images/sunrise-pier.jpg";
import image9 from "../Images/7de337f53151b59e321fffc4d0c5d2bf.jpg";
import image10 from "../Images/Schermafbeelding 2021-01-11 om 18.20.04.png";
import image11 from "../Images/Schermafbeelding 2021-01-11 om 18.19.36.png";
import image12 from "../Images/704911.jpg";
import image13 from "../Images/Schermafbeelding 2021-01-11 om 18.16.52.png";
import image14 from "../Images/00-Top-10-family-holiday-destinations-951x512.jpg";
import image15 from "../Images/bd551983344360864aaa54ef11c9f923.jpg";
import image16 from "../Images/familie-book.jpg";
import demo from "../Images/my-mementio.mp4";
import cloud from "../Images/cloud_PNG24.png";
import posterImage from "../Images/guy-flowers.jpg";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { resetForm, setLanguage } from '../Actions/Form';
//import { Link, Redirect } from "react-router-dom";

const HomePage = (props) => {
  useEffect(()=>{
    // props.resetForm();
    let lang = localStorage.getItem('language');
    localStorage.clear();
    window.global = lang === "NL" ? "globalDUT" : "globalENU";
    localStorage.setItem('language', lang);
    props.updateLanguage(lang);
},[])

  const endVideo = (e) => {
    document.getElementById("playpause").checked = false;
  };

  const playVid = (event) => {
    document
      .getElementById("momentioDemoVid")
      .addEventListener("ended", endVideo, false);
    let vid = document.getElementById("momentioDemoVid");
    if (event.target.checked === true) {
      vid.play();
    } else {
      vid.pause();
    }
  };

  return (
    <React.Fragment>
      <Header />

      <div className="momentioHome">
        <div
          className="content-container container"
          style={{ marginTop: "70px" }}
        >
          <div style={{ width: "100%", position: "relative" }}>
            <img alt="img" className="momentio-cloud-bg" src={cloud} />
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              paddingTop: "70px",
            }}
          >
            <div className="content-grid-50">
              <div className=" content-one">{props.form.language.HOME_PAGE_S1}</div>
              <div>
                <button
                  style={{ height: "54px" }}
                  className="primary-button-blue"
                >
                  {props.form.language.HOME_PAGE_S2}
                </button>
              </div>
              <div
                style={{ margin: "28px 0" }}
                className="momentio-get-start-text"
              >
                {props.form.language.HOME_PAGE_S3}
                <a className="momentio-blog-link" style={{ color: "#3b3dbb" }}>
                  {props.form.language.HOME_PAGE_S4}
                </a>
              </div>
              <div className="image-container">
                <div className="image-section-1">
                  {" "}
                  <div className="image">
                    <img alt="img" src={image1} />
                  </div>{" "}
                  <div className="image">
                    <img alt="img" src={image2} />
                  </div>{" "}
                </div>
                <div className="image-section-2">
                  {" "}
                  <div className="image">
                    <img alt="img" src={image3} />
                  </div>{" "}
                  <div className="image">
                    <img alt="img" src={image4} />
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className="image">
                    <img alt="img" src={image5} />
                  </div>{" "}
                  <div className="image" style={{ height: "160px" }}>
                    <img alt="img" src={image6} />
                  </div>{" "}
                  <div className="image">
                    <img alt="img" src={image7} />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="content-grid-50-sm"
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <div className="image-container">
                <div className="image-section-3 ">
                  {" "}
                  <div className="image">
                    <img alt="img" src={image8} />
                  </div>{" "}
                  <div className="image">
                    <img alt="img" src={image9} />
                  </div>{" "}
                </div>
                <div className="image-section-4 image-xl">
                  {" "}
                  <div className="image" style={{ height: "160px" }}>
                    <img alt="img" src={image10} />
                  </div>{" "}
                  <div className="image">
                    <img alt="img" src={image11} />
                  </div>{" "}
                  <div className="image" style={{ height: "160px" }}>
                    <img alt="img" src={image12} />
                  </div>
                  <div className="image" style={{ height: "160px" }}>
                    <img alt="img" src={image13} />
                  </div>
                </div>
                <div className="image-section-3 image-xl">
                  {" "}
                  <div className="image" style={{ height: "160px" }}>
                    <img alt="img" src={image14} />
                  </div>{" "}
                  <div className="image">
                    <img alt="img" src={image15} />
                  </div>{" "}
                  <div className="image" style={{ height: "160px" }}>
                    <img alt="img" src={image16} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "94px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "66%" }} className="width75">
              <div className="text1">{props.form.language.HOME_PAGE_S5}</div>
              <div className="text2">{props.form.language.HOME_PAGE_S50}</div>
              <div className="text3" style={{ marginTop: "28px" }}>
                {props.form.language.HOME_PAGE_S6}
              </div>
            </div>
          </div>
          <div className="text4" style={{ marginTop: "53px" }}>
            <div className="text5">
              <div className="union-icon-container">
                <div className="union-icon union-icon-1"></div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <div
                    style={{
                      font: "normal normal 800 24px/35px Raleway-ExtraBold",
                      letterSpacing: "0px",
                      color: "#233155",
                    }}
                  >
                    {`1. ${props.form.language.HOME_PAGE_S7}`}
                  </div>
                  <div
                    className="momentio-tips-content-desc"
                    style={{
                      color: "#233155",
                      marginTop: "25px",
                      marginBottom: "14px",
                    }}
                  >
                    {props.form.language.HOME_PAGE_S8}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S9}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S10}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S11}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S51}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S12}
                  </div>
                  <div
                    style={{
                      font: "normal normal 800 18px/30px Raleway-ExtraBold",
                      letterSpacing: 0,
                      color: "#3B3DBB",
                      marginTop: "30px",
                    }}
                  >
                    <a className="momentio-blog-link" href="">
                      {props.form.language.HOME_PAGE_S13}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="text5">
              <div className="union-icon-container">
                <div className="union-icon union-icon-2"></div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <div
                    style={{
                      font: "normal normal 800 24px/35px Raleway-ExtraBold",
                      letterSpacing: "0px",
                      color: "#233155",
                    }}
                  >
                    {`2. ${props.form.language.HOME_PAGE_S14}`}
                  </div>
                  <div
                    className="momentio-tips-content-desc"
                    style={{
                      color: "#233155",
                      marginTop: "25px",
                      marginBottom: "14px",
                    }}
                  >
                    {props.form.language.HOME_PAGE_S15}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S16}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S17}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S18}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S19}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S20}
                  </div>
                  <div
                    style={{
                      font: "normal normal 800 18px/30px Raleway-ExtraBold",
                      letterSpacing: 0,
                      color: "#3B3DBB",
                      marginTop: "30px",
                    }}
                  >
                    <a href="" className="momentio-blog-link">
                      {" "}
                      {props.form.language.HOME_PAGE_S21}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="text5" style={{ paddingRight: 0 }}>
              <div className="union-icon-container">
                <div className="union-icon union-icon-3"></div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <div
                    style={{
                      font: "normal normal 800 24px/35px Raleway-ExtraBold",
                      letterSpacing: "0px",
                      color: "#233155",
                    }}
                  >
                    {`3. ${props.form.language.HOME_PAGE_S22}`}
                  </div>
                  <div
                    className="momentio-tips-content-desc"
                    style={{
                      color: "#233155",
                      marginTop: "25px",
                      marginBottom: "14px",
                    }}
                  >
                    {props.form.language.HOME_PAGE_S23}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S24}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S25}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S26}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S27}
                  </div>
                  <div className="momentio-home-tick">
                    <span></span>
                    {props.form.language.HOME_PAGE_S28}
                  </div>
                  <div
                    style={{
                      font: "normal normal 800 18px/30px Raleway-ExtraBold",
                      letterSpacing: 0,
                      color: "#3B3DBB",
                      marginTop: "30px",
                    }}
                  >
                    <a className="momentio-blog-link" href="">
                      {" "}
                      {props.form.language.HOME_PAGE_S29}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="momentio-home-demo-container"
            style={{ marginTop: "71px" }}
          >
            <div className="momentio-home-demo-video">
              <div className="playpause">
                <input
                  type="checkbox"
                  value="None"
                  id="playpause"
                  name="check"
                  onChange={playVid}
                />
                <label htmlFor="playpause"></label>
              </div>

              <video
                width="320"
                height="240"
                id="momentioDemoVid"
                poster={posterImage}
              >
                <source src={demo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="momentio-home-demo-link ">
              <div>
                <div className="moment-video-header">
                  {" "}
                  {props.form.language.HOME_PAGE_S30}
                </div>
                <div className="moment-video-description">
                  {props.form.language.HOME_PAGE_S31}
                </div>
                <div className="moment-video-user">
                  {props.form.language.HOME_PAGE_S32}
                </div>
                <div>
                  {" "}
                  <button className="moment-video-navigate">
                    {props.form.language.HOME_PAGE_S52}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div style={{ clear: "both" }}></div>

          <div style={{ marginTop: "34px" }}>
            <div className="momentio-blog-header">
              {props.form.language.HOME_PAGE_S33}
            </div>
            <div className="momentio-blog-title">
              {props.form.language.HOME_PAGE_S34}
            </div>
            <div className="text4 momentio-home-tips">
              <div className="momentio-home-tips-container">
                <div>
                  {" "}
                  <img alt="img" src={image10} />
                </div>
                <div className="momentio-tips-content-container">
                  <div className="momentio-tips-content-header">
                    {props.form.language.HOME_PAGE_S35}
                  </div>
                  <div className="momentio-tips-content-title">
                    {props.form.language.HOME_PAGE_S36}
                  </div>
                  <div className="momentio-tips-content-desc">
                    {props.form.language.HOME_PAGE_S37}{" "}
                  </div>
                </div>
              </div>
              <div className="momentio-home-tips-container">
                <div>
                  <img alt="img" src={image10} />
                </div>
                <div className="momentio-tips-content-container">
                  <div className="momentio-tips-content-header">
                    {" "}
                    {props.form.language.HOME_PAGE_S38}
                  </div>
                  <div className="momentio-tips-content-title">
                    {props.form.language.HOME_PAGE_S39}
                  </div>
                  <div className="momentio-tips-content-desc">
                    {props.form.language.HOME_PAGE_S40}
                  </div>
                </div>
              </div>
              <div className="momentio-home-tips-container">
                <div>
                  {" "}
                  <img alt="img" src={image10} />
                </div>
                <div className="momentio-tips-content-container">
                  <div className="momentio-tips-content-header">
                    {props.form.language.HOME_PAGE_S41}{" "}
                  </div>
                  <div className="momentio-tips-content-title">
                    {props.form.language.HOME_PAGE_S42}
                  </div>
                  <div className="momentio-tips-content-desc">
                    {props.form.language.HOME_PAGE_S43}
                  </div>
                </div>
              </div>
            </div>
            <div className="momentio-blog-link">
              {props.form.language.HOME_PAGE_S44}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="momentio-home-begin">
              <div className="momentio-home-begin-title">
                {props.form.language.HOME_PAGE_S45}
              </div>
              <div className="momentio-home-begin-desc">
                {props.form.language.HOME_PAGE_S46}
              </div>
              <div>
                <Link className="header-menu-item" to="/register">
                  <button className="primary-button-yellow momentio-home-begin-btn">
                    {props.form.language.HOME_PAGE_S47}{" "}
                  </button>
                </Link>
              </div>

              <div className="momentio-home-begin-link momentio-get-start-text">
                {props.form.language.HOME_PAGE_S48}
                <a className="momentio-blog-link" style={{ color: "#fff" }}>
                  {props.form.language.HOME_PAGE_S49}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};

const mapDispatchToProps = (dispatch) => {
  return {
      resetForm: (payload) => dispatch(resetForm(payload)),
      updateLanguage: (payload) => dispatch(setLanguage(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));
