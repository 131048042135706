/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// eslint-disable jsx-a11y/anchor-is-valid
// eslint-disable jsx-a11y/alt-text
// eslint-disable no-useless-escape
// eslint-disable no-useless-concat
// eslint-disable no-duplicate-props
import axios from 'axios';
import React from 'react';
import * as actionTypes from '../Constants/actionTypes';
import { decryptMessage, encryptMessage } from '../Constants/decryption';
import { encryptMessageRSA } from '../Constants/encryption';
import * as globalDut from '../Constants/i18n/dut';
import * as globalENU from '../Constants/i18n/enu';
import { useHistory } from 'react-router-dom';
import { getCookie, getRandomKeyValues, deleteCookie, setCookieInit } from '../Utility/Cookies';
import { getAPIBaseUrl, requestFormatDate, logoutHandler } from '../Utility/Utility';
import { useLogoutQuery } from '../app/services/account';
import moment from 'moment';

export const resetForm = () => {
    return {
        type: actionTypes.RESET_FORM,
    };
};

export const LogoutMethod = (props) => {
    const {currentData, isFetching, isError} = useLogoutQuery(props.data, { skip: props.skip });
    if (!props.skip && (currentData || isError)) {
        window.scrollTo(0, 0);
        let lang = localStorage.getItem('language');
        localStorage.clear();
        window.global = lang === "NL" ? "globalDUT" : "globalENU";
        localStorage.setItem('language', lang);
        props.history.push('login');
    }
    return <></>;
};

export const addAttribute = (payload) => {
    return {
        type: actionTypes.ADD_ATTRIBUTE,
        payload: payload,
    };
};

export const setLanguage = (language) => {
    return {
        type: actionTypes.SET_LANGUAGE,
        payload: language,
    };
};

export const logout1 = (language) => {
    return {
        type: "logOut",
        payload: "",
    };
};

export const logOut = () => {
    return (dispatch) => {
        //setLogoff(true);
        dispatch(resetForm());
        deleteCookie('newEncryptionKey');
        deleteCookie('newIV');
        document.getElementById('rsaInput').value = '';
        document.getElementById('rsaOutput').value = '';
        localStorage.clear();
        window.scrollTo(0, 0);
    };
};

export const setcurrentAction = (data) => {
    return {
        type: actionTypes.ACTIVE_STATE,
        payload: data,
    };
};

export const loginError = (data) => {
    return {
        type: actionTypes.LOGIN_FAILED,
        payload: data,
    };
};

export const loginSuccess = (data) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        payload: data,
    };
};

export const setActiveUser = (data) => {
    return {
        type: actionTypes.ACTIVE_USER,
        payload: data,
    };
};

export const isLoading = (data) => {
    return {
        type: actionTypes.IS_LOADING,
        payload: data,
    };
};

export const getAjaxCall = () => {};

export const forgotPassword = (input, isCodeGenerated, history) => {
    return async (dispatch, getState) => {
        const currentLanguage = getState().form.language;

        dispatch(isLoading(true));
        let data = {};
        let method = 'POST';
        let url = getAPIBaseUrl() + 'account/forgotPassword';
        if (isCodeGenerated) {
            data = {
                username: input.Username.value,
                password: input.Password.value,
                code: input.code.value?.replaceAll('-', ''),
            };
        } else {
            url = url + `/${input.Username.value}`;
            method = 'GET';
        }
        axios({
            method: method,
            data: data,
            url: url,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(async (response) => {
                if (response.data.statusCode != 200 && response.data.statusCode != 201) {
                    alert(currentLanguage.ERROR_SAVING);
                } else {
                    if (!isCodeGenerated) {
                        dispatch(
                            addAttribute({
                                name: 'isForgotCode',
                                value: true,
                            })
                        );
                    } else {
                        logoutHandler();
                        alert(currentLanguage.PASSWORD_CHANGED);
                        history.push('login');
                    }
                }
                dispatch(isLoading(false));
            })
            .catch((error) => {
                dispatch(isLoading(false));
                alert(currentLanguage.ERROR_SAVING);
            });
    };
};

export const loginAccount = (input) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        const currentLanguage = getState().form.currentLanguage == 'US' ? globalENU : globalDut;
        dispatch(setcurrentAction('Login'));
        let data = {
            username: input.Username.value,
            password: input.Password.value,
        };
        let url = getAPIBaseUrl() + 'account/login';
        axios({
            method: 'POST',
            data: data,
            url: url,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(async (response) => {
                if (response.data.statusCode != 200 && response.data.statusCode != 201) {
                    dispatch(loginError(currentLanguage.unableToLoginErrorMessage));
                    alert(currentLanguage.unableToLoginErrorMessage);
                } else if (response.data == null) {
                    dispatch(loginError(currentLanguage.unableToLoginErrorMessage));
                    alert(currentLanguage.unableToLoginErrorMessage);
                } else {
                    dispatch(isLoading(false));
                    dispatch(
                        addAttribute({
                            name: 'currentLanguage',
                            value: response.data.settings.languageISOCode.toUpperCase(),
                        })
                    );
                    dispatch(setLanguage(response.data.settings.languageISOCode.toUpperCase()));
                    window.global =
                        response.data.settings.languageISOCode.toUpperCase() === 'NL'
                            ? 'globalDUT'
                            : 'globalENU';
                    if (response.data.privateData.birthDate) {
                        response.data.privateData.birthDate = moment(
                            response.data.privateData.birthDate,
                            'YYYY-MM-DD'
                        ).format('DD-MM-YYYY');
                    }
                    dispatch(
                        addAttribute({
                            name: 'accountData',
                            value: {
                                profile: response.data,
                            },
                        })
                    );
                    dispatch(loginSuccess(true));
                    dispatch(setActiveUser(true));
                    /*let decryptedData = await decryptMessage(
              "",
              newIV,
              newEncryptionKey,
              response.data.data
            );
            setTimeout(() => {
              dispatch(isLoading(false));
              let accountData = JSON.parse(decryptedData);
              if (
                accountData["profile"] &&
                accountData["profile"][0]
              ) {
                dispatch(
                  addAttribute({
                    name: "currentLanguage",
                    value: accountData["profile"][0].languageISOCode.toUpperCase(),
                  })
                );
                dispatch(setLanguage(accountData["profile"][0].languageISOCode.toUpperCase()))
                window.global = accountData["profile"][0].languageISOCode.toUpperCase() === "NL" ? "globalDUT" : "globalENU";
                if(accountData["profile"][0].birthDate){
                  accountData["profile"][0].birthDate = moment(
                    accountData["profile"][0].birthDate,
                    "YYYY-MM-DD"
                  ).format("DD-MM-YYYY");
                }               
              }
              if (accountData["contact"].length > 0) {
                accountData["contact"].map((item) => {
                  if (item.birthDate) {
                    item.birthDate = moment(
                      item.birthDate,
                      "YYYY-MM-DD"
                    ).format("DD-MM-YYYY");
                  }
                });
              }
              dispatch(
                addAttribute({
                  name: "accountData",
                  value: accountData,
                })
              );
              dispatch(loginSuccess(true));
              dispatch(setActiveUser(true));
            }, 500);*/
                }
                dispatch(isLoading(false));
            })
            .catch((error) => {
                dispatch(isLoading(false));
                alert(currentLanguage.unableToLoginErrorMessage);
                dispatch(loginError(currentLanguage.unableToLoginErrorMessage));
            });
    };
};

export const registerAccount = (
    history,
    input,
    isRegistered,
    isSubmitCode,
    isSubmitCodeSMS,
    isEmailValidation
) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        const currentLanguage = getState().form.currentLanguage == 'US' ? globalENU : globalDut;
        dispatch(setcurrentAction('Login'));
        let newEncryptionKey = getCookie('newEncryptionKey');
        let newIV = getCookie('newIV');

        let data = {};

        if (isRegistered) {
            isRegistered = false;
            isSubmitCode = true;
            data = {
                timestamp: '' + new Date().getTime(),
                email: input.Email.value,
                languageCode: input.Country.value,
            };
        } else if (isSubmitCode) {
            if (isSubmitCodeSMS) {
                isRegistered = false;
            } else {
                isEmailValidation = true;
                isRegistered = true;
            }

            isSubmitCode = false;
            data = {
                email: input.Email.value,
                code: input.code.value?.replaceAll('-', ''),
            };
            dispatch(
                addAttribute({
                    name: 'Id',
                    value: {
                        eId: newEncryptionKey,
                        eIv: newIV,
                    },
                })
            );
        } else {
            isRegistered = true;
            isSubmitCode = false;
            data = {
                email: input.Email.value,
                firstName: input.Firstname.value,
                lastName: input.Lastname.value,
                countryISOCode: input.countryISOCode.value,
                password: input.Password.value,
                languageISOCode: input.Country.value,
                phone: input.Phone.value,
            };
        }
        dispatch(
            addAttribute({
                name: 'Id',
                value: {
                    eId: newEncryptionKey,
                    eIv: newIV,
                },
            })
        );

        let url = getAPIBaseUrl() + 'account/register';
        axios({
            method: 'POST',
            data: data,
            url: url,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(async (response) => {
                if (response.data.statusCode != 200 && response.data.statusCode != 201) {
                    dispatch(isLoading(false));
                    let errorMessage = currentLanguage.RegisterError;
                    if (response.data.errorCode) {
                        switch (response.data.errorCode) {
                            case 'emailExists':
                                errorMessage = currentLanguage.RegisterErrorEmailExists;
                                dispatch(loginError(errorMessage));
                                alert(errorMessage);
                                break;
                            case 'phoneExists':
                                errorMessage = currentLanguage.RegisterErrorPhoneExists;
                                dispatch(loginError(errorMessage));
                                alert(errorMessage);
                                break;
                            case 'incorrectCode':
                                errorMessage = currentLanguage.RegisterErrorIncorrectCode;
                                dispatch(loginError(errorMessage));
                                alert(errorMessage);
                                break;
                            default:
                                alert(errorMessage);
                                break;
                        }
                    } else {
                        alert(errorMessage);
                    }
                } else if (response.data.statusCode === 200 || response.data.statusCode === 201) {
                    if (isRegistered) {
                        dispatch(registerAccount(history, input, isRegistered));
                        if (isEmailValidation) {
                            dispatch(
                                addAttribute({
                                    name: 'isEmailValidation',
                                    value: true,
                                })
                            );
                        }
                    } else if (isSubmitCode) {
                        dispatch(isLoading(false));
                        dispatch(
                            addAttribute({
                                name: 'isSubmitCode',
                                value: true,
                            })
                        );
                    } else {
                        dispatch(isLoading(false));
                        dispatch(logOut());
                        alert(currentLanguage.REGISTRATION_SUCCESS);
                        history.push('login');
                    }
                }
            })
            .catch((error) => {
                dispatch(isLoading(false));
            });
    };
};

export const AddContactAPI = (input, action) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        const currentLanguage = getState().form.currentLanguage == 'US' ? globalENU : globalDut;
        dispatch(setcurrentAction('AddContact'));

        let contactJson = {
            encryptionKey: getState().form.Id.eId,
            initialisationVector: getState().form.Id.eIv,
            contactType: input['contactType'] ? input['contactType'].value : '',
            firstName: input.firstName ? input.firstName.value : '',
            lastName: input.lastName ? input.lastName.value : '',
            countryISOCode: input.countryISOCode ? input.countryISOCode.value : '',
            city: input.city ? input.city.value : '',
            zipcode: input.zipcode ? input.zipcode.value : '',
            phone: input['phone'] ? input['phone'].value : '',
            email: input['email'] ? input['email'].value : '',
            inform: input.inform ? input.inform.value : '',
            informType: input['informType'] ? input['informType'].value : '',
            mobile: input['mobile'] ? input['mobile'].value : '',
            street: input.street ? input.street.value : '',
            contactRole: input['contactRole'] ? input['contactRole'].value : '',
            birthDate:
                input.birthDate && input.birthDate.value
                    ? moment(input.birthDate.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
                    : '',
            languageISOCode: getState().form.currentLanguage.toLowerCase(),
        };

        if (action === 'update') {
            contactJson.ID = input.ID ? input.ID.value : getState().form.editContact.ID;
        }

        let data = {
            action: action,
            dataType: 'contact',
            timestamp: new Date().getTime(),
            element: contactJson,
        };
        //  };
        let output = await encryptMessage(
            '',
            getState().form.Id.eIv,
            getState().form.Id.eId,
            JSON.stringify(data)
        );

        setTimeout(function () {
            let requestJson = {
                instruction: 'action',
                token: getState().form.accountData.token,
            };

            requestJson.data = output;

            let url = getAPIBaseUrl() + 'Alter';
            axios({
                method: 'POST',
                data: requestJson,
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response.data.statusCode == 401) {
                        alert(currentLanguage.INVALID_SESSION);
                        dispatch(
                            addAttribute({
                                name: 'isSessionExpired',
                                value: true,
                            })
                        );
                        dispatch(logOut());
                    } else if (response.data.statusCode != 200) {
                        alert(currentLanguage.ERROR_SAVING);
                        dispatch(isLoading(false));
                    } else if (response.data.data == null) {
                        dispatch(isLoading(false));
                        alert(currentLanguage.ERROR_SAVING);
                    } else {
                        let decryptedData = await decryptMessage(
                            '',
                            getState().form.Id.eIv,
                            getState().form.Id.eId,
                            response.data.data
                        );
                        setTimeout(() => {
                            let accountData = getState().form.accountData;
                            accountData['contact'] = JSON.parse(decryptedData).contact;
                            if (accountData['contact'].length > 0) {
                                accountData['contact'].map((item) => {
                                    if (item.birthDate) {
                                        item.birthDate = moment(
                                            item.birthDate,
                                            'YYYY-MM-DD'
                                        ).format('DD-MM-YYYY');
                                    }
                                });
                            }
                            dispatch(
                                addAttribute({
                                    name: 'accountData',
                                    value: accountData,
                                })
                            );
                        }, 500);
                        dispatch(
                            addAttribute({
                                name: 'contactFormPopup',
                                value: false,
                            })
                        );
                        dispatch(
                            addAttribute({
                                name: 'editContact',
                                value: {},
                            })
                        );
                        dispatch(isLoading(false));
                    }
                    dispatch(isLoading(false));
                })
                .catch((error) => {
                    let errorMessage = currentLanguage.RegisterError;
                    alert(currentLanguage.ERROR_SAVING);
                    dispatch(isLoading(false));
                });
        }, 500);
    };
};

export const AddMessageAPI = (input, action) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        const currentLanguage = getState().form.currentLanguage == 'US' ? globalENU : globalDut;
        dispatch(setcurrentAction('AddMessage'));
        let mediaList = getState().form.accountData.mediaList.filter(
            (data) => data.checked === true
        );
        let mediaListID = ';';
        if (mediaList.length > 0) {
            mediaList.map((media) => {
                mediaListID = mediaListID + media.ID + ';';
            });
        }

        let contactJson = {
            encryptionKey: getState().form.Id.eId,
            initialisationVector: getState().form.Id.eIv,
            name: input.name.value,
            scheduleID:
                getState().form.accountData.schedule && getState().form.accountData.schedule[0]
                    ? getState().form.accountData.schedule[0].ID
                    : '',

            messageContentType: input.messageContentType.value,
            mediaListID: mediaListID,
            content: input.content.value,
            //messageType: input.messageType.value,
            deliveryDate: input.delivery.value,
            deliveryTime: input.deliveryTime.value,
            timezoneType: 'GMT',
            // receiversContactListID: receiversContactListID;
            //deliveryWayType: "email",
            /* deliveryWayType:
        getState().form.accountData.schedule &&
        getState().form.accountData.schedule[0]
          ? getState().form.accountData.schedule[0].deliveryWayType
          : "",*/
            // activationType: "code",
            /*activationType:
        getState().form.accountData.schedule &&
        getState().form.accountData.schedule[0]
          ? getState().form.accountData.schedule[0].activationType
          : "",*/
            //  scheduleStateType: "active",
            contactType: 'selected',
            // contactType: getState().form.selectedcontactsNodes[0].label,
            /*  scheduleStateType:
        getState().form.accountData.schedule &&
        getState().form.accountData.schedule[0]
          ? getState().form.accountData.schedule[0].scheduleStateType
          : "",*/
        };

        let selectedNodes = [];
        let receiversContactListID = ';';
        let contactTypeListID = ';';

        getState().form.selectedcontactsNodes.map((item) => {
            let split = item.split('.')[1];

            if (split) {
                selectedNodes.push(split);
                receiversContactListID = receiversContactListID + split + ';';
            } else {
                selectedNodes.push(item);
                //contactJson.contactType = item;
                contactTypeListID = contactTypeListID + item.split('.')[0] + ';';
            }
        });
        contactJson.receiversContactListID = receiversContactListID;
        contactJson.contactType = contactTypeListID;

        /* if (contactType) {
      contactJson.contactType =
        getState().form.selectedcontactsNodes &&
        getState().form.selectedcontactsNodes[0]
          ? getState().form.selectedcontactsNodes[0].label
          : "";
    } else {
      contactJson.contactID =
        getState().form.selectedcontactsNodes &&
        getState().form.selectedcontactsNodes[0] &&
        getState().form.selectedcontactsNodes[0].ID
          ? getState().form.selectedcontactsNodes[0].ID
          : 0;
    }*/
        if (action === 'update') {
            contactJson.ID = input.ID ? input.ID.value : getState().form.selectedMessage.ID;
        }
        let data = {
            action: action,
            dataType: 'message',
            timestamp: new Date().getTime(),
            element: contactJson,
        };
        //  };
        let output = await encryptMessage(
            '',
            getState().form.Id.eIv,
            getState().form.Id.eId,
            JSON.stringify(data)
        );

        setTimeout(function () {
            let requestJson = {
                instruction: 'action',
                token: getState().form.accountData.token,
            };

            requestJson.data = output;

            let url = getAPIBaseUrl() + 'Alter';
            axios({
                method: 'POST',
                data: requestJson,
                url: url,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(async (response) => {
                    if (response.data.statusCode == 401) {
                        alert(currentLanguage.INVALID_SESSION);
                        dispatch(
                            addAttribute({
                                name: 'isSessionExpired',
                                value: true,
                            })
                        );
                        dispatch(logOut());
                    } else if (response.data.statusCode != 200) {
                        alert(currentLanguage.ERROR_SAVING);
                        dispatch(isLoading(false));
                    } else if (response.data.data == null) {
                        dispatch(isLoading(false));
                        alert(currentLanguage.ERROR_SAVING);
                    } else {
                        let decryptedData = await decryptMessage(
                            '',
                            getState().form.Id.eIv,
                            getState().form.Id.eId,
                            response.data.data
                        );
                        setTimeout(() => {
                            //console.log(decryptedData)
                            let accountData = getState().form.accountData;
                            accountData['message'] = JSON.parse(decryptedData).message;
                            dispatch(
                                addAttribute({
                                    name: 'accountData',
                                    value: accountData,
                                })
                            );
                        }, 500);
                        dispatch(
                            addAttribute({
                                name: 'futureFormPopup',
                                value: false,
                            })
                        );
                        dispatch(
                            addAttribute({
                                name: 'selectedcontactsNodes',
                                value: [],
                            })
                        );
                        dispatch(
                            addAttribute({
                                name: 'contactTreeData',
                                value: [],
                            })
                        );
                        dispatch(isLoading(false));
                    }
                    dispatch(isLoading(false));
                })
                .catch((error) => {
                    let errorMessage = currentLanguage.RegisterError;
                    alert(currentLanguage.ERROR_SAVING);
                    dispatch(isLoading(false));
                });
        }, 500);
    };
};

export const AddProfileAPI = (input, action) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        const currentLanguage = getState().form.currentLanguage == 'US' ? globalENU : globalDut;
        dispatch(setcurrentAction('AddProfile'));

        let url = getAPIBaseUrl() + 'account';
        axios({
            method: 'PUT',
            data: input,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState().form.accountData.profile.token,
            },
        })
            .then(async (response) => {
                if (response.data.statusCode == 401) {
                    alert(currentLanguage.INVALID_SESSION);
                    dispatch(
                        addAttribute({
                            name: 'isSessionExpired',
                            value: true,
                        })
                    );
                    dispatch(logOut());
                } else if (response.data.statusCode != 200 && response.data.statusCode != 201) {
                    alert(currentLanguage.ERROR_SAVING);
                    dispatch(isLoading(false));
                } else if (response.data == null) {
                    dispatch(isLoading(false));
                    alert(currentLanguage.ERROR_SAVING);
                } else {
                    //console.log(decryptedData)

                    let accountData = getState().form.accountData;
                    accountData['profile'] = {
                        ...accountData['profile'],
                        contact: response.data.contact,
                        physicalAddress: response.data.physicalAddress,
                        privateData: response.data.privateData,
                        settings: response.data.settings,
                    };
                    if (
                        accountData['profile'] &&
                        accountData['profile'].privateData &&
                        accountData['profile'].privateData.birthDate
                    ) {
                        accountData['profile'].privateData.birthDate = moment(
                            accountData['profile'].privateData.birthDate,
                            'YYYY-MM-DD'
                        ).format('DD-MM-YYYY');
                    }

                    dispatch(isLoading(false));

                    dispatch(
                        addAttribute({
                            name: 'accountData',
                            value: accountData,
                        })
                    );
                    dispatch(
                        addAttribute({
                            name: 'contactFormPopup',
                            value: false,
                        })
                    );
                    dispatch(
                        addAttribute({
                            name: 'editContact',
                            value: {},
                        })
                    );
                    dispatch(isLoading(false));
                }
                dispatch(isLoading(false));
            })
            .catch((error) => {
                let errorMessage = currentLanguage.RegisterError;
                alert(currentLanguage.ERROR_SAVING);
                dispatch(isLoading(false));
            });
    };
};

export const DeleteContactAPI = (input, action) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        const currentLanguage = getState().form.currentLanguage == 'US' ? globalENU : globalDut;
        dispatch(setcurrentAction('DeleteContact'));

        let contactJson = {
            encryptionKey: getState().form.Id.eId,
            initialisationVector: getState().form.Id.eIv,
            ID: input,
        };

        let data = {
            action: 'delete',
            dataType: 'contact',
            timestamp: new Date().getTime(),
            element: contactJson,
        };
        //  };
        let output = await encryptMessage(
            '',
            getState().form.Id.eIv,
            getState().form.Id.eId,
            JSON.stringify(data)
        );

        setTimeout(function () {
            let requestJson = {
                instruction: 'action',
                token: getState().form.accountData.token,
            };

            requestJson.data = output;

            let url = getAPIBaseUrl() + 'Alter';
            axios({
                method: 'POST',
                data: requestJson,
                url: url,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(async (response) => {
                    if (response.data.statusCode == 401) {
                        alert(currentLanguage.INVALID_SESSION);
                        dispatch(
                            addAttribute({
                                name: 'isSessionExpired',
                                value: true,
                            })
                        );
                        dispatch(logOut());
                    } else if (response.data.statusCode != 200) {
                        alert(currentLanguage.ERROR_DELETE);
                        dispatch(isLoading(false));
                    } else if (response.data.data == null) {
                        dispatch(isLoading(false));
                        alert(currentLanguage.ERROR_DELETE);
                    } else {
                        let decryptedData = await decryptMessage(
                            '',
                            getState().form.Id.eIv,
                            getState().form.Id.eId,
                            response.data.data
                        );
                        setTimeout(() => {
                            //console.log(decryptedData)
                            let accountData = getState().form.accountData;
                            accountData['contact'] = JSON.parse(decryptedData).contact;
                            if (accountData['contact'].length > 0) {
                                accountData['contact'].map((item) => {
                                    if (item.birthDate) {
                                        item.birthDate = moment(
                                            item.birthDate,
                                            'YYYY-MM-DD'
                                        ).format('DD-MM-YYYY');
                                    }
                                });
                            }

                            dispatch(
                                addAttribute({
                                    name: 'accountData',
                                    value: accountData,
                                })
                            );
                            dispatch(
                                addAttribute({
                                    name: 'contactFormPopup',
                                    value: false,
                                })
                            );
                            dispatch(
                                addAttribute({
                                    name: 'editContact',
                                    value: {},
                                })
                            );
                        }, 500);

                        dispatch(isLoading(false));
                    }
                    dispatch(isLoading(false));
                })
                .catch((error) => {
                    let errorMessage = currentLanguage.RegisterError;
                    alert(currentLanguage.ERROR_DELETE);
                    dispatch(isLoading(false));
                });
        }, 500);
    };
};
