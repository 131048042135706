/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { addAttribute } from "../../Actions/Form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import update from "immutability-helper";
import AddIcon from "@material-ui/icons/Add";
import { filterMedia } from "../Contacts/ContactTags";
import { model } from "../../Models/Media";
import FilterTags from "../Common/FilterTags";
import { useGetAPICallQuery } from "../../app/services/account";
import AddFile from "../Safe/AddFile";
import { entityTagList, sortAscending } from "../../Utility/Utility";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        boxShadow: "none",
        width: "100%",
    },
});

const GreenCheckbox = withStyles({
    root: {
        color: "#6bce90 !important",
        "&$checked": {
            color: "#6bce90 !important",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SelectMedia = (props) => {
    const classes = useStyles();
    const mediaList = useGetAPICallQuery("media");
    const [selectedTags, setSelectedTags] = React.useState([]);
    const [addNew, setaddNew] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const [filteredMedia, setFilteredMedia] = React.useState(
        props.form.accountData?.mediaList || []
    );
    const [newMediaCreated, setNewMediaCreated] = React.useState("");

    const [tags, setTags] = React.useState(model);
    React.useEffect(() => {
        if (props.form.accountData.mediaTags) {
            let options = tags.tags.options;
            props.form.accountData.mediaTags.map((item) => {
                let filteredTag = options.filter(
                    (opt) => item === opt.value
                )[0];
                if (!filteredTag && item) {
                    options.push({
                        label: item,
                        value: item,
                    });
                }
            });
            setTags({
                ...model,
                tags: {
                    ...model.tags,
                    options: options,
                },
            });
        }
    }, []);

    React.useEffect(() => {
        if (mediaList.data) {
            let oldMedia = mediaList.data?.mediaList;
            if (props.form.selectedLegacy) {
                let split = props.form.selectedLegacy.data.mediaID.split(";");
                if(newMediaCreated){
                    split.push(newMediaCreated);
                }                    
                setNewMediaCreated("");
                if (split.length > 0) {
                    split.map((item) => {
                        if (item) {
                            mediaList.data.mediaList.map((media, index) => {
                                if (
                                    parseInt(item) === parseInt(media.system.id)
                                ) {
                                    oldMedia = update(oldMedia, {
                                        [index]: {
                                            $set: { ...media, checked: true },
                                        },
                                    });
                                }
                                props.form.accountData.mediaList.map(
                                    (contact) => {
                                        if (
                                            parseInt(media.system.id) ===
                                                parseInt(contact.system.id) &&
                                            contact.checked
                                        ) {
                                            oldMedia = update(oldMedia, {
                                                [index]: {
                                                    $set: {
                                                        ...contact,
                                                        checked: true,
                                                    },
                                                },
                                            });
                                        }
                                    }
                                );
                            });
                        }
                    });
                }
            } else {
                let list = mediaList.data?.mediaList;
                list.map((item, index) => {
                    props.form.accountData.mediaList.map((contact) => {
                        if (
                            parseInt(item.system.id) ===
                                parseInt(contact.system.id) &&
                            contact.checked
                        ) {
                            oldMedia = update(oldMedia, {
                                [index]: {
                                    $set: {
                                        ...contact,
                                        checked: true,
                                    },
                                },
                            });
                        }
                    });

                    if(newMediaCreated === item.system.id) {
                        oldMedia = update(oldMedia, {
                            [index]: {
                                $set: {
                                    ...item,
                                    checked: true,
                                },
                            },
                        });
                    }
                });
            }
            setFilteredMedia(sortAscending(oldMedia));
            let mediaTags = entityTagList(oldMedia);
            if (props.form.accountData.mediaTags) {
                let options = tags.tags.options;
                mediaTags.map((item) => {
                    let filteredTag = options.filter(
                        (opt) => item === opt.value
                    )[0];
                    if (!filteredTag && item) {
                        options.push({
                            label: item,
                            value: item,
                        });
                    }
                });
                setTags({
                    ...oldMedia,
                    tags: {
                        ...oldMedia.tags,
                        options: options,
                    },
                });
            }
            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    mediaList: sortAscending(oldMedia),
                    mediaTags: mediaTags,
                },
            });
        }
    }, [mediaList.data]);

    const hideEdit = () => {
        props.addAttribute({
            name: "editFile",
            value: {},
        });
        setaddNew(false);
        setFile(null);
    };

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
        setaddNew(true);
    };

    const getMedia = (type) => {
        return (
            <React.Fragment>
                {filteredMedia?.map((item) => {
                    let checked = false;
                    if (props.form.selectedLegacy) {
                        let split =
                            props.form.selectedLegacy.data.mediaID.split(":");
                        if (split.length > 0) {
                            split.map((media) => {
                                if (item && media == item.system.id) {
                                    checked = true;
                                }
                            });
                        }
                    }
                    return (
                        <FormControlLabel
                            style={{ display: "flex" }}
                            className="media-select"
                            control={
                                <GreenCheckbox
                                    checked={
                                        checked
                                            ? checked
                                            : item.checked
                                            ? item.checked
                                            : false
                                    }
                                    onChange={mediaChangeHandler}
                                    name={item.data.name}
                                    id={item.system.id}
                                />
                            }
                            label={item.data.name}
                        />
                    );
                })}
            </React.Fragment>
        );
    };
    const mediaChangeHandler = (event) => {
        let oldfilteredMedia = filteredMedia;

        oldfilteredMedia.map((item, index) => {
            if (parseInt(item.system.id) === parseInt(event.target.id)) {
                oldfilteredMedia = update(oldfilteredMedia, {
                    [index]: {
                        $set: { ...item, checked: event.target.checked },
                    },
                });
            }
        });
        let oldMedia = props.form.accountData.mediaList;
        oldMedia.map((item, index) => {
            if (parseInt(item.system.id) === parseInt(event.target.id)) {
                oldMedia = update(oldMedia, {
                    [index]: {
                        $set: { ...item, checked: event.target.checked },
                    },
                });
            }
        });
        props.addAttribute({
            name: "accountData",
            value: {
                ...props.form.accountData,
                mediaList: sortAscending(oldMedia),
            },
        });
        setFilteredMedia(sortAscending(oldfilteredMedia));
    };
    return (
        <Paper className={classes.root + " mm-login-container"}>
            {addNew && (
                <AddFile
                    hideEdit={() => hideEdit()}
                    addNew={addNew}
                    file={file}
                    query={mediaList}
                    setNewMediaCreated={(id)=>setNewMediaCreated(id)}
                    isMessage
                />
            )}
            <div className="" style={{ marginBottom: "20px" }}>
                <div className="mm-login-header display-flex-center">
                    {props.form.language.FUTURE_MESSAGE_S5}
                    <span
                        className="add-new-attachment"
                        style={{display: "flex"}}
                        onClick={() =>
                            document.getElementById("uploadFile").click()
                        }
                    >
                        {`${props.form.language.MYFILE_S1}  `} <AddIcon />
                    </span>
                </div>
            </div>
            <input
                style={{ display: "none" }}
                type="file"
                autoComplete="off"
                id="uploadFile"
                onChange={handleOnChange}
            />

            <div style={{ marginBottom: "20px", marginLeft: "-5px" }}>
                <FilterTags
                    tags={model.tags.options}
                    selectedTags={selectedTags}
                    updateTagList={(tag) => {
                        let data = [];
                        props.form.accountData.mediaList.map((media) => {
                            if (tag.value && tag.value.length > 0) {
                                tag.value.map((item) => {
                                    let filteredData = data.filter(item=> item.system.id === media.system.id)[0];
                                    if (
                                        !filteredData && filterMedia(
                                            item,
                                            media.meta.tags
                                        )
                                    ) {
                                        data.push(media);
                                    }
                                });
                            } else {
                                let filteredData = data.filter(item=> item.system.id === media.system.id)[0];
                                if(!filteredData){
                                    data.push(media);
                                }
                                
                            }
                        });
                        setFilteredMedia(sortAscending(data));
                        setSelectedTags(tag);
                    }}
                />
            </div>

            {getMedia()}
            <div className="mm-input-submit" style={{ display: "flex" }}>

                <button
                    style={{
                        textAlign: "center",
                        width: "360px",
                        marginTop: "24px",
                        marginRight: "20px",
                    }}
                    className="primary-button-blue"
                    onClick={props.openFutureMedia}
                >
                    {" "}
                    {props.form.language.SAVE}
                </button>
                <button
                    style={{
                        textAlign: "center",
                        width: "250px",
                        marginTop: "24px",
                        
                    }}
                    className="secondary-button"
                    onClick={props.openFutureMedia}
                >
                    {props.form.language.CANCEL}
                </button>
            </div>
        </Paper>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SelectMedia)
);
