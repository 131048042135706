import React from 'react';

export const getContactRole = (role, props) => {
        
    switch (role) {
        case 'partner':
            return props.form.language.CONTACT_ROLE_TYPE_PARTNER;
        case 'parent':
            return props.form.language.CONTACT_ROLE_TYPE_PARENT;
        case 'child':
            return props.form.language.CONTACT_ROLE_TYPE_CHILD;
        case 'guardian':
            return props.form.language.CONTACT_ROLE_TYPE_GUARDIAN;
        case 'admin':
            return props.form.language.CONTACT_ROLE_TYPE_ADMIN;
        case 'employer':
            return props.form.language.CONTACT_ROLE_TYPE_EMPLOYER;
        case 'tax consultant':
            return props.form.language.CONTACT_ROLE_TYPE_CONSULT;
        case 'general practitionert':
            return props.form.language.CONTACT_ROLE_TYPE_GP;
        case 'Psycholoog':
            return props.form.language.CONTACT_ROLE_TYPE_PSYCH;
        case 'speaker':
            return props.form.language.CONTACT_ROLE_TYPE_SPEAKER;
        case 'notary':
            return props.form.language.CONTACT_ROLE_TYPE_NOTARY;
        case 'testamentary executioner':
            return props.form.language.CONTACT_ROLE_TYPE_EXECUTIONAR;
        case 'emergency':
            return props.form.language.CONTACT_TYPE_EMERGENCY;
        case 'private':
            return props.form.language.CONTACT_TYPE_PRIVATE;
        case 'healthcare':
            return props.form.language.CONTACT_TYPE_HEALTHCARE;
        case 'family':
            return props.form.language.CONTACT_TYPE_FAMILY;
        case 'financial':
            return props.form.language.CONTACT_TYPE_FINANCIAL;
        case 'friend':
            return props.form.language.CONTACT_TYPE_FRIEND;
        default:
            return role;
    }
};

export const getTags = (tagList, props) => {
    let tags = tagList.split(';');
    if(tags.length){
        tags = tags.filter(index=>index)
        return (
            <div>
                {tags.map((tag, index)=>{
                    if(tag){
                        return (
                            <span>
                                {`${getContactRole(tag, props)}`}{index+1 === tags.length ? "" : ", "}

                            </span>
                        )
                    }
                    
                })}
            </div>                
        )            
    }  else {
        return '';
    }      
}

export const getTagLabel = (tagList, props, options) => {
    let tags = tagList.split(";");
    if (tags.length) {
        tags = tags.filter(index=>index)
        return (
            <div>
                {tags.map((tag, index) => {
                    if (tag) {
                        let filter = options?.filter(
                            (item) => item.value === tag
                        )[0];
                        return (
                            <span>
                                {`${
                                    filter
                                        ? props.form.language[filter.label]
                                        : tag
                                }`}
                                {index+1 === tags.length ? "" : ", "}
                            </span>
                        );
                    }
                })}
            </div>
        );
    } else {
        return "";
    }
};

export const filterContacts = (currentTag, tagList) => {
    const tags = tagList.split(';');
    let showTag = false;
    if (tags.length) {
        tags.map((tag, index)=>{
            if (currentTag === 'family' && (tag === 'family' || tag === 'partner' || tag === 'guardian' || tag === 'parent'
            || tag === 'child')) {
                showTag = true;
            } else if(tag && currentTag === tag) {
                showTag = true;
            }            
        })   
    }
    return showTag;    
}


export const filterMedia = (currentTag, tagList) => {
    const tags = tagList.split(';');
    let showTag = false;
    if (tags.length) {
        tags.map((tag, index)=>{
            if(tag && currentTag === tag) {
                showTag = true;
            }                
        })   
    }
    return showTag;    
}