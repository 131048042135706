// eslint-disable jsx-a11y/anchor-is-valid
// eslint-disable jsx-a11y/alt-text

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
    addAttribute,
    resetForm,
    logout1,
    setLanguage,
} from "../../Actions/Form";
import NotificationsOutlinedIcon from "@material-ui/icons//NotificationsOutlined";
import closeIcon from "../../Images/close-icon.svg";
import accountImg from "../../Images/account-no-picture.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Badge from "@material-ui/core/Badge";
import { useLogoutQuery, useGetAPICallQuery } from "../../app/services/account";
import { getCookieByName } from "../../Utility/Cookies";
import {updateBrowserLaguage} from "../../Utility/Utility";
import HeaderSubscription from "./HeaderSubscription";
import Notification from "../Alerts/Notification";

const AuthHeader = (props) => {
    const history = useHistory();
    const toggleMenu = (isOpen) => {
        setOpen(isOpen);
        setOpenAlerts(false);
    };

    const toggleAlert = (isOpen) => {
        setOpen(false);
        setOpenAlerts(isOpen);
    };

    const [skip, setSkip] = React.useState(true);
    const [openMenu, setOpen] = React.useState(false);
    const [openAlert, setOpenAlerts] = React.useState(false);
    const alertList = useGetAPICallQuery("systemmessage", {skip: !props.form.accountData?.profile?.limits?.accessList?.message});
    const query = useLogoutQuery(props.form?.accountData?.profile?.settings?.username, {
        skip,
    });
    useEffect(() => {
        let currentToken = localStorage.getItem("token");
        if (!currentToken) {
            alert(props.form.language.INVALID_SESSION_ERROR);
            window.scrollTo(0, 0);
            props.resetForm();
            let lang = localStorage.getItem('language');
            localStorage.clear();
            updateBrowserLaguage(lang, props);
            window.location.href = "/login";
        }
        if(localStorage.getItem("profile")) {
            var profileDaata =
            props.form.accountData && props.form.accountData.profile
                ? props.form.accountData.profile
                : JSON.parse(localStorage.getItem("profile"));
            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    profile: {
                        ...profileDaata,
                    },
                },
            });
            props.updateLanguage(localStorage.getItem("language"));
        }
        
    }, []);
    useEffect(() => {
        if (query.data || query.error) {
            window.scrollTo(0, 0);
            props.resetForm();
            let lang = localStorage.getItem('language');
            localStorage.clear();
            updateBrowserLaguage(lang, props);
            window.location.href = "/login";
        }
    }, [query.data || query.error]);
    useEffect(() => {
        var profileDaata =
            props.form.accountData && props.form.accountData.profile
                ? props.form.accountData.profile
                : JSON.parse(localStorage.getItem("profile"));
        props.addAttribute({
            name: "accountData",
            value: {
                ...props.form.accountData,
                profile: {
                    ...profileDaata,
                },
            },
        });
        props.updateLanguage(localStorage.getItem("language"));
    }, [props.form.accountData && localStorage.getItem("profile")]);
    return (
        <div className="fixed-header-container">
            {query.isLoading && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <React.Fragment>
                {props.form.isSessionExpired && <Redirect to="/" />}
            </React.Fragment>
            <div className="header-container auth-header-container">
                <div className="header-title-section">
                    <div>
                        <Link className="header-title-item" to="/dashboard">
                            {props.form.language.COMPANY_NAME}
                        </Link>
                    </div>
                    <div className="header-menu-item menu-item-sm">
                        {props.form.hideMobileMenu && (
                            <div
                                className="header-menu-item"
                                style={{ padding: "0 10px" }}
                                onClick={() => toggleAlert(!openAlert)}
                            >
                                <div>
                                    <Badge
                                        badgeContent={
                                            alertList?.data?.systemMessageList
                                                ? alertList?.data
                                                      ?.systemMessageList.length
                                                : 0
                                        }
                                        color="secondary"
                                        className="alert-badge"
                                    >
                                        <div className="secondary-alert-sm">
                                            <NotificationsOutlinedIcon />
                                        </div>
                                    </Badge>
                                </div>
                                {openAlert && (
                                    <div className="menu-wrapper">
                                        <Notification
                                            alertList={alertList}
                                            isMobile
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {props.form.hideMobileMenu && (
                            <button
                                className="secondary-button-sm menu-button"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    toggleAlert(false);
                                    props.addAttribute({
                                        name: "hideMobileMenu",
                                        value: false,
                                    });
                                }}
                            >
                                <span className="menu-button-icon"></span>Menu
                            </button>
                        )}
                        {!props.form.hideMobileMenu && (
                            <div className="secondary-alert-sm">
                                {" "}
                                <img
                                    src={closeIcon}
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        props.addAttribute({
                                            name: "hideMobileMenu",
                                            value: true,
                                        });
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className="header-menu-section"
                    style={{ justifyContent: "flex-end" }}
                >
                    <div
                        className="header-menu-item"
                        style={{
                            padding: "15px 10px",
                            textAlign: "right",
                            height: "100%",
                            boxSizing: "border-box",
                        }}
                    >
                        <div
                            className="momentio-tips-content-header"
                            style={{ textAlign: "inherit" }}
                        >
                            <HeaderSubscription />
                        </div>
                        {props.form.accountData &&
                            props.form.accountData.profile?.privateData && (
                                <div className="usernameFirst">
                                    {`${props.form.accountData.profile.privateData.firstName} ${props.form.accountData.profile.privateData.lastName}`}
                                </div>
                            )}
                    </div>
                    <div
                        className="header-menu-item"
                        style={{ padding: "0 10px" }}
                        onClick={() => toggleMenu(!openMenu)}
                    >
                        <div>
                            <img src={accountImg} />
                        </div>
                        {openMenu && (
                            <div className="menu-wrapper">
                                <div className="menu-container">
                                    <div className="menu-options">
                                        <Link to="/profile">
                                            {props.form.language.LOGIN_S14}
                                        </Link>
                                    </div>
                                    <div
                                        className="menu-options"
                                        onClick={() => {
                                            setSkip(false);
                                            //props.logout1();
                                        }}
                                    >
                                        {props.form.language.LOGIN_S16}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div
                        className="header-menu-item"
                        style={{ padding: "0 10px" }}
                        onClick={() => toggleAlert(!openAlert)}
                    >
                        <div>
                            <Badge
                                badgeContent={
                                    alertList?.data?.systemMessageList
                                        ? alertList?.data?.systemMessageList
                                              .length
                                        : 0
                                }
                                color="secondary"
                                className="alert-badge"
                            >
                                <div className="secondary-alert-sm">
                                    <NotificationsOutlinedIcon />
                                </div>
                            </Badge>
                        </div>
                        {openAlert && (
                            <div className="menu-wrapper">
                                <Notification alertList={alertList} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
        resetForm: () => dispatch(resetForm()),
        logout1: () => dispatch(logout1()),
        updateLanguage: (payload) => dispatch(setLanguage(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthHeader);
