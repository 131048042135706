/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { addAttribute } from "../../Actions/Form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";
import FilterTags from "./FilterTags";
import AddIcon from "@material-ui/icons/Add";

const AddTagsSM = (props) => {
    const [newTag, setNewTag] = React.useState("");
    const [showAddTag, setShowAddTag] = React.useState(false);

    return (
        <div>
            <div style={{marginBottom: '20px', marginLeft: "-5px"}}>
            <FilterTags
                tags={props.tags}
                selectedTags={props.selectedTags}
                updateTagList={(tag) => props.updateTagList(tag)}
            />
            {!showAddTag && (
                <>
                    <div
                        className="secondary-alert-sm"
                        style={{
                            display: "inline-flex",
                            margin: "0 5px",
                        }}
                        onClick={() => setShowAddTag(true)}
                    >
                        <AddIcon />
                    </div>
                    <span
                        style={{ marginLeft: "5px" }}
                        className="login-field-label login-field-label-height"
                    >
                        {props.form.language.ADD_TAGS}
                    </span>
                </>
            )}
            </div>
            
            {showAddTag && (
                <div style={{background: "#F1F6F9 0% 0% no-repeat padding-box",
                    padding: "20px 0"}}>
                    <div
                        className="login-field-input"
                        style={{
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "100%",
                        }}
                    >
                        <span
                             style={{ marginLeft: "10px", width: "auto" }}
                             className="login-field-label"
                        >
                            {props.form.language.ADD_TAGS}
                        </span>
                        <span
                            className="momentio-blog-link"
                            style={{
                                margin: "0 7px 0 15px",
                                textDecoration: "underline",
                            }}
                            onClick={() => {
                                setNewTag("");
                                setShowAddTag(false);
                            }}
                        >
                            {props.form.language.CANCEL}
                        </span>
                    </div>

                    <div
                        className="login-field-input"
                        style={{
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "100%",
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Max 20 characters"
                            name="name"
                            value={newTag}
                            maxLength={20}
                            autoComplete="off"
                            onChange={(event) => setNewTag(event.target.value)}
                            style={{
                                width: "90%",
                            }}
                        />
                        <div
                            className="secondary-alert-sm"
                            style={{
                                display: "inline-flex",
                                margin: "15px 5px 0px 5px",
                            }}
                            onClick={() => {
                                let tags = props.selectedTags.value
                                    ? props.selectedTags.value
                                    : [];
                                if (tags.indexOf(newTag) < 0 && newTag !== "") {
                                    tags.push(newTag);
                                    // props.updateTagList(newTag);
                                    props.updateTagList({
                                        ...props.selectedTags,
                                        options: [
                                            ...props.selectedTags.options,
                                            { label: newTag, value: newTag },
                                        ],
                                        value: tags,
                                    });
                                    setNewTag("");
                                    setShowAddTag(false);
                                } else if (newTag !== "") {
                                    alert(props.form.language.TAG_EXISTS);
                                }
                            }}
                        >
                            <AddIcon />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddTagsSM)
);
