/* eslint-disable no-unused-vars */

import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./Main";

const App = (props) => {
  return (
    <React.Fragment>
      <div>
        <Main />
      </div>
    </React.Fragment>
  );
};

export default withRouter(App);
