import React from "react";
import { connect } from "react-redux";

const AuthFooter = (props) => {
    return (
        <div>
            <div className="momentio-footer-content" style={{ border: "none" }}>
                <div className="footer-separator"></div>
                <div className="text4 momentio-footer-company  auth-header-container">
                    <div>
                        <div className="momentio-footer-company-name">
                            {" "}
                            Momentio
                        </div>
                        <div className="momentio-footer-company-copyright">
                            {" "}
                            {props.form.language.FOOTER_S11}{" "}
                            <span>
                                <a
                                    href={`${props.form.language.MOMENTIO_BASE_URL}/algmenevoorwaarden`}
                                    target="_blank"
                                >
                                    {props.form.language.FOOTER_S10}
                                </a>
                            </span>
                            <span>
                                <a
                                    href={`${props.form.language.MOMENTIO_BASE_URL}/disclaimer`}
                                    target="_blank"
                                >
                                    {props.form.language.FOOTER_S9}
                                </a>
                            </span>
                        </div>
                    </div>
                    <div
                        className=""
                        style={{ display: "flex", flexFlow: "row" }}
                    >
                        <div className="media-icon-container">
                            <a
                                href={props.form.language.FOOTER_YOUTUBE_URL}
                                target="_blank"
                                className="display-flex-media"
                            >
                                <i
                                    className="momentio-youtube-link"
                                    title="Youtube"
                                ></i>
                            </a>
                        </div>
                        <div className="media-icon-container">
                            <a
                                href={props.form.language.FOOTER_FACEBOOK_URL}
                                target="_blank"
                                className="display-flex-media"
                            >
                                <i
                                    className="momentio-facebook-link"
                                    title="Facebook"
                                ></i>
                            </a>
                        </div>
                        <div className="media-icon-container">
                            <a
                                href={props.form.language.FOOTER_TWITTER_URL}
                                target="_blank"
                                className="display-flex-media"
                            >
                                <i
                                    className="momentio-insta-link"
                                    title="Twitter"
                                ></i>
                            </a>
                        </div>
                        <div className="media-icon-container">
                            <a
                                href={props.form.language.FOOTER_INSTA_URL}
                                target="_blank"
                                className="display-flex-media"
                            >
                                <i
                                    className="momentio-snapchat-link"
                                    title="Instagram"
                                ></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

export default connect(mapStateToProps)(AuthFooter);
