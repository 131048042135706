//LOGIN
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

//FORGOT PASSWORD
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

//REGISTER
export const REGISTER_FAILED = "REGISTER_FAILED";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

//COMMON
export const ACTIVE_STATE = "ACTIVE_STATE";
export const FORM_VALID = "FORM_VALID";
export const FORM_INVALID = "FORM_INVALID";
export const UPDATE_STATE = "UPDATE_STATE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const ACTIVE_USER = "ACTIVE_USER";
export const RESET_FORM = "RESET_FORM";
export const ADD_ATTRIBUTE = "ADD_ATTRIBUTE";
export const IS_LOADING = "IS_LOADING";
