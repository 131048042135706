export const model = {
    tags: {
        value: [],
        valid: true,
        text: 'MYCONTACT_S15',
        options: [
            {
                label: 'CONTACT_ROLE_TYPE_PARTNER',
                value: 'partner',
            },
            {
                label: 'CONTACT_ROLE_TYPE_PARENT',
                value: 'parent',
            },
            {
                label: 'CONTACT_ROLE_TYPE_CHILD',
                value: 'child',
            },
            {
                label: 'CONTACT_ROLE_TYPE_GUARDIAN',
                value: 'guardian',
            },
            {
                label: 'CONTACT_ROLE_TYPE_ADMIN',
                value: 'admin',
            },            
            {
                label: 'CONTACT_ROLE_TYPE_EMPLOYER',
                value: 'employer',
            },
            {
                label: 'CONTACT_ROLE_TYPE_GP',
                value: 'general practitioner',
            },
            {
                label: 'CONTACT_ROLE_TYPE_EXECUTIONAR',
                value: 'testamentary executioner',
            },
            {
                label: 'CONTACT_TYPE_EMERGENCY',
                value: 'emergency',
            },
            {
                label: 'CONTACT_TYPE_FAMILY',
                value: 'family',
            },
            {
                label: 'CONTACT_TYPE_FRIEND',
                value: 'friend',
            },

        ],
    },
    contactType: {
        value: 'other',
        text: 'Contact type',
        valid: true,
        options: [
            {
                label: 'CONTACT_TYPE_EMERGENCY',
                value: 'emergency',
            },
            {
                label: 'CONTACT_TYPE_PRIVATE',
                value: 'private',
            },
            {
                label: 'CONTACT_TYPE_FAMILY',
                value: 'family',
            },
            {
                label: 'CONTACT_TYPE_HEALTHCARE',
                value: 'healthcare',
            },
            {
                label: 'CONTACT_TYPE_FINANCIAL',
                value: 'financial',
            },
            {
                label: 'CONTACT_TYPE_FRIEND',
                value: 'friend',
            },
            {
                label: 'TYPE_OTHER',
                value: 'other',
            },
        ],
    },
    birthDate: {
        value: '',
        valid: true,
        text: 'MYCONTACT_S14',

    },
    firstName: {
        value: '',
        text: 'LOGIN_S7',
        validations: { required: true },
        valid: false,
    },
    lastName: {
        value: '',
        text: 'LOGIN_S8',
        validations: { required: true },
        valid: false,
    },
    email: {
        text: 'MYCONTACT_S11',
        value: '',
        //validations: { required: true },
        valid: true,
    },
    phone: {
        text: 'MYCONTACT_S16',
        value: '',
        valid: true,
    },
    street: {
        value: '',
        valid: true,
    },
    zipCode: {
        value: '',
        valid: true,
    },
    city: {
        value: '',
        valid: true,
    },
    inform: {
        value: 'yes',
        valid: false,
        validations: { required: true },
    },
    mobile: {
        value: '',
        valid: true,
    },
    informType: { value: 'phone', valid: true },
    countryISOCode: {
        value: 'NL',
        valid: true,
    },
    ID: {
        value: '',
        valid: true,
    },
    isHeir: {
        value: 'no',
        valid: true,
    },
    proof: {
        value: '',
        valid: true,
    }
};
