export const LastWishesModel = {
    name: 'FOOTER_S3',
    completed: 0,
    category: 'lastWishes',
    stepCount: 9,
    subtitles: [
        {
            seq: 0,
            name: 'FUNERAL_POLICY',
            category: 'lwFuneralPolis',
            scroll: 70,
        },
        {
            seq: 0,
            name: 'RECORD_POLICY',
            category: 'lwRecordPolis',
            scroll: 70,
        },
        {
            seq: 1,
            name: 'NOTIFICATION_LABEL',
            category: 'lwNotification',
            hidden: true,
            scroll: 70 + 80,
        },
        {
            seq: 2,
            name: 'ORGANISATION_LABEL',
            category: 'lwOrganisation',
            hidden: true,
            scroll: 70 + 160,
        },
        {
            seq: 3,
            name: 'FUNERAL_TYPE',
            category: 'lwFuneralType',
            hidden: true,
            scroll: 70 + 240,
        },
        {
            seq: 4,
            name: 'MUSIC_LABEL',
            hidden: true,
            category: 'lwMusic',
            scroll: 70 + 320,
        },
        {
            seq: 5,
            name: 'SPEAKER_LABEL',
            hidden: true,
            category: 'lwSpeaker',
            scroll: 70 + 400,
        },
        {
            seq: 6,
            name: 'TRANSPORT_LABEL',
            hidden: true,
            category: 'lwTransportation',
            scroll: 70 + 480,
        },
        {
            seq: 7,
            name: 'CARRIER_LABEL',
            hidden: true,
            category: 'lwCarriers',
            scroll: 70 + 560,
        },
        {
            seq: 8,
            name: 'FLOWER_LABEL',
            hidden: true,
            category: 'lwFlowers',
            scroll: 70 + 640,
        },
        {
            seq: 9,
            name: 'MEAL_LABEL',
            hidden: true,
            category: 'lwMeal',
            scroll: 70 + 720,
        },
        {
            name: 'MEMORIES_LABEL',
            hidden: true,
            category: 'lwMemories',
            scroll: 70 + 800,
        },
        {
            name: 'MEDIA_LABEL',
            hidden: true,
            category: 'lwMedia',
            scroll: 70 + 880,
        },
    ],
};
