  ///   let publicKey = "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA1DMzTMVhTJ7XtYJ/t1939wcxWgiBOiTHSzPJ8+64+QKdyZgb/sXvSTz36lemR++Kt2PZmXcqx/tmxRHqSMys2MhNHS4/xJ1vCt84xvwOtsGNrpE+VAzUfCcFhqRSOIFunTqly2gv8zUP7yPfkfIgGRX8T1UWFdxT072zOp7/o0Bw5g1mhUgjzn10uA4nz6rx2srBGPTLcyn8J9GYvMOA7H+Dl42dUt8XUAP6zswxBYHI9EGKGb1KvBh+b8AHzw0M1yV3ImalH9RGk7GnpRzI1MGO36c8UmHyL8B9Jj4biWQouAl5aKuUW/2zI5rQMx/52PVzeq6TM5AEPa/avzkviMTUlnDIZwzAjcZk0OGFR9P71wuhBLDQ/J7wZGBGla0IEpy7KZKZ9qiYvOxdpotByQNNoAyiHzL3G36Cz5Z3pWQWbtaSkNhSxXF6VFQjmSR7bTVcbadWu8UTBS+n5jAsN2hIPweKZbn/vD6Lnq1NLPUw2epaiMj6UgDuVNC2Tj2PfKRmAWF1jLORY/txb/N4OjLpB9kwQopSHUzcopnwL5smpEpplOa31512Oowjhn/emveBtDLhOL//qHSJZvYBgXBmGKHx4RObO188u5bUPiGYLX87FqXbWf1BaDvZMD5a4fqtvyjthobM3B0h5fmoeOthslbVcD4g7U7Sx0KwWHkCAwEAAQ==";

     let publicKey = 
  //START REPLACE PUBLIC KEY
  "-----BEGIN PUBLIC KEY-----\n" + 
  "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAxSoDhF01Wc9wXYe5DckP\n"+
  "LBYK6Qu2nxb7zvzhv3hr//sQNjjKsNy+QEhiALy65xEhJd4ffTRXPejpe/1deo2x\n"+
  "cym+KZqEuROfNf8AN53/amXHjZuXOCgPmZB2RdmqOdpPMGTgLBGPk9ozfLomoYQ9\n"+
  "U0v0EaJKe7RKQmsNvbw+fhhGbUF6w/3cqr3+yuyvUkeFUp7lZIBqysx4nn1hNk5K\n"+
  "m071noSDbGMSN8Ng0va25yW6fmLmRWSUFCZK51nMZz4tjiRKBnWc1h0sgIzRv7UI\n"+
  "8bMEXtPuJT3Axyxzt/agNFyZnLJfSkbwOiqNTDgx3DTkxPqY7kxMENm2zvjSAvWs\n"+
  "zyG72Kf14AjMP5ZtPShySEAhNoIVB2+v+8sV2viqWLU36aOgKfaGwqsP0vhWtnQa\n"+
  "gsf1urBt7w4ExH7tyrYGXJJlHrVNGeaZQ1igZE9SL6OPfrGzIN8hi8gB3f9RNqa9\n"+
  "OykBHzA/E3zhQyKLUMcHvotXfHWwD1FKDLxQy4IQB47PtxwNx1BX9Sfk66w2rYtn\n"+
  "CyNQkSlDnXDycK6/UNd7ObJ8C6sESpYHCeODDvOIRUs6aCqp6jcffbFUXxo7lbLv\n"+
  "h9haJ4nCx6X8AfPrb/T0uLDbk5m+1LoYATDSg8tRvVitQYtGI85KFbBACKKgLPPf\n"+
  "/1HKWO24RGAuCYJ/Qrg2T+ECAwEAAQ==\n"+
  "-----END PUBLIC KEY-----" 
//END REPLACE PUBLIC KEY
    
    function getMessageEncoding(rsaInput) {
      //  const messageBox = document.getElementById("rsaInput");
      //  let message = messageBox.value;
        let message = rsaInput;
        let enc = new TextEncoder();
        message = enc.encode(message);
        return message;
    }

    export function getSpkiDer(){
        let spkiPem = publicKey;
        const pemHeader = "-----BEGIN PUBLIC KEY-----";
        const pemFooter = "-----END PUBLIC KEY-----";
        var pemContents = spkiPem.substring(pemHeader.length, spkiPem.length - pemFooter.length);
        var binaryDerString = window.atob(pemContents);
        return str2ab(binaryDerString);
    }

    function str2ab(str) {
        const buf = new ArrayBuffer(str.length);
        const bufView = new Uint8Array(buf);
        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }

    function ab2str(buf) {
        return String.fromCharCode.apply(null, new Uint8Array(buf));
    }

    export async function importPublicKey() {
        return await window.crypto.subtle.importKey(
            "spki",
            getSpkiDer(),
            {
                name: "RSA-OAEP",
                hash: "SHA-256",
            },
            true,
            ["encrypt"]
        );
    }

    export async function encryptMessageRSA(key, input) {
            const pub =  await importPublicKey();

            let encoded = getMessageEncoding(input);
    
            let ciphertext = await window.crypto.subtle.encrypt(
                {
                    name: "RSA-OAEP"
                },
                pub,
                encoded
            );
    
            const encryptedBase64 = window.btoa(ab2str(ciphertext));
               // window.rsaOutput = encryptedBase64;
          let rsaOutput = document.getElementById("rsaOutput");
            rsaOutput.value = encryptedBase64;
            return encryptedBase64;
       
    } 

    window.crypto.subtle.generateKey(
        {
            name: "RSA-OAEP",
            modulusLength: 4096,
            publicExponent: new Uint8Array([1, 0, 1]),
            hash: "SHA-256",
        },
        true,
        ["encrypt", "decrypt"]
    )
    /*.then((keyPair) => {
       
        const encryptButton = document.getElementById("rsaButton");
        encryptButton.addEventListener("click", () => {
        
            encryptMessageRSA(keyPair.publicKey);
        });
    });*/

