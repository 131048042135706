/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import Dialog from "@material-ui/core/Dialog";
import AddIcon from "@material-ui/icons/Add";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";
import { connect } from "react-redux";
import { addAttribute } from "../../../../Actions/Form";
import DebtOverview from "./DebtOverview";

const Debt = (props) => {
    const saveEwill = (data) => {
        let input = {};
        Object.keys(data).map((item) => {
            input[item] = {
                value: data[item],
            };
        });
        let json = {
            url: input.ID.value ? `important/${input.ID.value}` : "important",
            method: input.ID.value ? "PUT" : "POST",
            system: {
                id: input.ID.value,
            },
            general: {
                importantType: "debt",
            },
            meta: {
                additionalInfo: input.hasDebt.value === "yes" ? input.remark.value : "",
            },
            debt: {
                hasDebt: input.hasDebt ? input.hasDebt.value : "",
            },
        };
        props.handleRequest("update", json);
        setaddNew(false);
    };

    const [addNew, setaddNew] = React.useState(false);

    const [newRecord, setnewRecord] = React.useState({
        hasDebt: "",
        remark: "",
        ID: "",
    });

    React.useEffect(() => {
        if (props.form.accountData && props.form.accountData.importantList) {
            let data = props.form.accountData.importantList.filter((item) => {
                return item.debt;
            });
            let debt = [];
            data.map((item) => {
                debt.push({
                    hasDebt: item.debt.hasDebt,
                    remark: item.meta.additionalInfo,
                    ID: item.system.id,
                });
            });

            setpersonalData(debt);
        } else {
            setpersonalData([]);
        }
    }, [props.form.accountData && props.form.accountData.importantList]);

    const changeHandler = (event, personal) => {
        let data = personalData;
        let newData = newRecord;
        newData[event.target.name] = event.target.value;
        setnewRecord(newData);
        setpersonalData(JSON.parse(JSON.stringify(data)));
    };
    const [personalData, setpersonalData] = React.useState([]);

    const showAddDebt = (finance) => {
        setaddNew(true);
        if (finance) {
            let newData = {};
            Object.keys(finance).map((item) => {
                newData[item] = finance[item];
            });
            setnewRecord(newData);
            props.addAttribute({
                name: "selectedDebt",
                value: finance,
            });
        } else {
            setnewRecord({
                remark: "",
                hasDebt: "",
                ID: "",
            });
            props.addAttribute({
                name: "selectedDebt",
                value: {},
            });
        }
    };
    const hideEdit = () => {
        setaddNew(false);
    };
    return (
        <React.Fragment key={props.key}>
            {addNew === true && (
                <Dialog
                    open={addNew}
                    fullScreen={props.fullScreen}
                    disableEscapeKeyDown={true}
                    onClose={hideEdit}
                    aria-labelledby="responsive-dialog-title"
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                        }}
                    >
                        <div className="mm-form-container">
                            <div
                                className="mm-login-container"
                                style={{ boxShadow: "none" }}
                            >
                                <div className="mm-login-header">
                                    {props.form.selectedDebt &&
                                    props.form.selectedDebt.ID
                                        ? props.form.language.DEBT_EDIT
                                        : props.form.language.DEBT_ADD}
                                </div>
                                <div>
                                    <div className="login-field-label login-field-label-height">
                                        {props.form.language.DEBT_TEXT}
                                    </div>
                                    <div className="login-field-input">
                                        <RadioGroup
                                            className="mm-field-input"
                                            style={{ flexDirection: "row" }}
                                            name={"hasDebt"}
                                            value={newRecord.hasDebt}
                                            onChange={(event) =>
                                                changeHandler(event, newRecord)
                                            }
                                        >
                                            <FormControlLabel
                                                value={"yes"}
                                                control={<Radio />}
                                                label={
                                                    props.form.language.YES_TEXT
                                                }
                                            />
                                            <FormControlLabel
                                                value={"no"}
                                                control={<Radio />}
                                                label={
                                                    props.form.language
                                                        .NONE_TEXT
                                                }
                                            />
                                        </RadioGroup>
                                    </div>
                                </div>
                                {newRecord.hasDebt === "yes" && (
                                    <div>
                                        <div className="login-field-label login-field-label-height">
                                            {props.form.language.DEBT_COMMENT}
                                        </div>
                                        <div className="login-field-input">
                                            <textarea
                                                style={{ height: "auto" }}
                                                rows="4"
                                                type="text"
                                                autoComplete="off"
                                                placeholder={
                                                    props.form.language
                                                        .DEBT_COMMENT
                                                }
                                                name="remark"
                                                value={newRecord.remark}
                                                onChange={(event) =>
                                                    changeHandler(
                                                        event,
                                                        newRecord
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                <div
                                    className="mm-input-submit"
                                    style={{
                                        display: "flex",
                                        marginBottom: "15px",
                                        justifyContent: "center",
                                    }}
                                >
                                    <button
                                        style={{
                                            textAlign: "center",
                                            width: "150px",
                                            marginTop: "24px",
                                            marginRight: "20px",
                                        }}
                                        className="primary-button-blue"
                                        onClick={() => {
                                            saveEwill(newRecord);
                                            hideEdit();
                                        }}
                                    >
                                        {props.form.language.SAVE}
                                    </button>
                                    <button
                                        style={{
                                            textAlign: "center",
                                            marginTop: "24px",
                                        }}
                                        className="secondary-button"
                                        onClick={hideEdit}
                                    >
                                        {props.form.language.CANCEL}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
            {personalData &&
                personalData.length > 0 &&
                personalData.map((data) => {
                    return (
                        <DebtOverview
                            key={`debt${data.ID}`}
                            data={data}
                            showAddDebt={() => showAddDebt(data)}
                            handleRequest={(type, data) =>
                                props.handleRequest(type, data)
                            }
                        />
                    );
                })}
            {personalData.length < 1 && (
                <span
                    id={props.type}
                    onClick={(event) => {
                        showAddDebt();
                    }}
                    style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "8px",
                    }}
                >
                    <span className="">
                        <span className="ewill-secondary-alert-sm">
                            <AddIcon />
                        </span>
                    </span>
                    <span
                        className="momentio-text-normal"
                        style={{ marginLeft: "15px" }}
                    >
                        {personalData.length > 0
                            ? props.form.language.DEBT_ADD_MULTIPLE
                            : props.form.language.DEBT_ADD_SINGLE}
                    </span>
                </span>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Debt);
