/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CallIcon from "@material-ui/icons/LocalPhoneOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { connect } from "react-redux";
import { addAttribute } from "../../Actions/Form";
import FutureMessage from "../Messages/FutureMessage";
import AddIcon from "@material-ui/icons/Add";
import AddContact from "./AddContact";
import moment from "moment";
import { useGetAPICallQuery } from "../../app/services/account";
import update from "immutability-helper";
import { getTags, filterContacts } from "./ContactTags";
import { entityTagList, sortAscending, sortContactAscending } from "../../Utility/Utility";
import { model } from "../../Models/Contacts";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    container: {
        boxShadow: "none !important",
    },
    red: {
        color: "red",
    },
    accordionDetail: {
        flexDirection: "row",
        paddingLeft: "32px",
        paddingRight: "40px",
        paddingBottom: "50px",
        justifyContent: "space-between",
        position: "relative",
        [theme.breakpoints.down("md")]: {
            paddingBottom: "20px",
            flexDirection: "column",
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingBottom: "20px",
        },
    },
    editSection: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        position: "absolute",
        right: "32px",
        bottom: "55px",
        marginTop: "15px",
        [theme.breakpoints.down("md")]: {
            position: "relative",
            right: "0",
            bottom: "0",
        },
    },
    accordionDetailSM: {
        display: "flex",
        padding: "0px 5px 2px 0px",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    headerL: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    hideXS: {
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    headerSM: {
        display: "none",
        [theme.breakpoints.down("sm")]: {
            display: "inline-block",
        },
    },
}));

const Mysafe = (props) => {
    const classes = useStyles();
    const contactList = useGetAPICallQuery("contact");
    const mediaList = useGetAPICallQuery("media");
    const [category, setCategory] = React.useState("");
    const changeHandler = (event) => {
        setCategory(event.target.value);
    };
const options = model.tags.options;
    React.useEffect(() => {
        if (contactList.data && !props.isMessage) {
            let oldMedia = mediaList.data?.mediaList;
            let oldContact = contactList.data?.contactList;

            if (props.form.selectedMessage || props.isMessage) {
                let split = props.form.selectedMessage.data.mediaID.split(";");
                if (split.length > 0) {
                    split.map((item) => {
                        if (item) {
                            mediaList.data.mediaList.map((media, index) => {
                                if (
                                    parseInt(item) === parseInt(media.system.id)
                                ) {
                                    oldMedia = update(oldMedia, {
                                        [index]: {
                                            $set: { ...media, checked: true },
                                        },
                                    });
                                }
                            });
                        }
                    });
                }

                let contactSplit =
                    props.form.selectedMessage.delivery.contactID.split(";");
                if (contactSplit.length > 0) {
                    contactSplit.map((item) => {
                        if (item) {
                            contactList.data.contactList.map(
                                (contact, index) => {
                                    if (
                                        parseInt(item) ===
                                        parseInt(contact.system.id)
                                    ) {
                                        oldContact = update(oldContact, {
                                            [index]: {
                                                $set: {
                                                    ...contact,
                                                    checked: true,
                                                },
                                            },
                                        });
                                    }
                                }
                            );
                        }
                    });
                }
            }

            let contactTags = entityTagList(sortContactAscending(oldContact));
            let mediaTags = entityTagList(oldMedia);
            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    mediaList: sortAscending(oldMedia),
                    contactList: sortContactAscending(oldContact),
                    contactTags: contactTags,
                    mediaTags: mediaTags,
                },
            });
        }
    }, [contactList.data, props.form.contactFormPopup]);

    const showAddContact = () => {
        props.addAttribute({
            name: "contactFormPopup",
            value: true,
        });
    };

    const hideAddContact = () => {
        props.addAttribute({
            name: "contactFormPopup",
            value: false,
        });
        props.addAttribute({
            name: "editContact",
            value: {},
        });
    };

    const editContact = (input) => {
        let contactJson = {
            contactType: "",
            firstName: input.privateData.firstName,
            lastName: input.privateData.lastName,
            countryISOCode: input.physicalAddress.countryISOCode,
            city: input.physicalAddress.city,
            zipCode: input.physicalAddress.zipCode,
            street: input.physicalAddress.street,
            phone: input.contact.phone,
            email: input.contact.email,
            inform: input.settings.inform,
            informType: input.settings.informType,
            tags: input.meta.tags,
            birthDate: input.privateData.birthDate
                ? moment(input.privateData.birthDate, "YYYY-MM-DD").format(
                      "DD-MM-YYYY"
                  )
                : "",
            languageISOCode:input.settings.languageISOCode,
            ID: input.system.id,
            isHeir: input.settings.isHeir,
            proof: input.privateData.proof
        };
        props.addAttribute({
            name: "editContact",
            value: contactJson,
        });
        props.addAttribute({
            name: "contactFormPopup",
            value: true,
        });
    };
    const showfutureFormPopup = (contact) => {
        let requestData = {
            meta: {
                tags: ";",
                additionalInfo: "additional info",
            },
            data: {
                messageType: "memory",
                title: "",
                message: "",
                mediaID: ";",
            },
            location: {
                countryISOCode: "NL",
            },
            delivery: {
                contactID: ";" + contact.system.id,
            },
        };
        props.addAttribute({
            name: "selectedMessage",
            value: requestData,
        });
        props.addAttribute({
            name: "futureFormPopup",
            value: true,
        });
    };
    const hidefutureFormPopup = () => {
        props.addAttribute({
            name: "futureFormPopup",
            value: false,
        });
        props.addAttribute({
            name: "selectedMessage",
            value: null,
        });
    };

    const handleChange = (id) => (event, isExpanded) => {
        props.addAttribute({
            name: "currentContact",
            value: isExpanded ? id : false,
        });
    };

    return (
        <React.Fragment>
            {(props.form.isLoading ||
                contactList.isLoading 
                ||
                 contactList.isFetching
                ) && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            {props.form.contactFormPopup && (
                <AddContact
                    open={props.form.contactFormPopup}
                    hideAddContact={hideAddContact}
                    contactType={props.contactType}
                    contactRole={props.contactRole}
                    addTitle={props.addTitle}
                    query={contactList}
                />
            )}
            {props.form.futureFormPopup && (
                <FutureMessage
                    open={props.form.futureFormPopup}
                    hidefutureFormPopup={hidefutureFormPopup}
                />
            )}
            <div
                className="auth-content-subheader"
            >
                <div className="content-one">{props.form.language.DASHBOARD_S25}</div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                    className="files-container-column"
                >
                    <div className="login-field-input">
                        <select
                            className="mm-field-input "
                            value={category}
                            autoComplete="off"
                            onChange={(event) => changeHandler(event)}
                            style={{
                                margin: "0 24px 0 0",
                                width: "200px",
                            }}
                        >
                            <option value="">
                                {props.form.language.MYFILE_FILTER_CATEGORY}
                            </option>
                            {props.form.accountData &&
                                props.form.accountData.contactTags &&
                                contactList.data &&
                                contactList.data.contactList &&
                                entityTagList(
                                    contactList.data.contactList
                                ).map((opt) => {
                                    let filteredTags = options.filter(
                                        (item) => item.value === opt
                                    )[0];
                                    return (
                                        <option value={opt}>
                                            {filteredTags
                                                ? props.form.language[
                                                      filteredTags.label
                                                  ]
                                                : opt}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                    <button
                        className="primary-button-blue hideSM"
                        style={{ textAlign: "center" }}
                        onClick={showAddContact}
                    >
                        {props.form.currentLanguage === "NL"
                            ? `${props.addTitle} ${props.form.language.ADD_TEXT}`
                            : `${
                                  props.form.language.ADD_TEXT
                              } ${props.addTitle.toLowerCase()}`}
                    </button>
                    <span
                        className="primary-alert-blue-sm showSM"
                        onClick={showAddContact}
                        style={{ marginTop: "-2px" }}
                    >
                        <AddIcon />
                    </span>
                </div>
            </div>
            <div
                style={{
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    borderRadius: "24px",
                }}
                className="contacts"
            >
                <Accordion
                    className={
                        classes.container +
                        " contactAccordion " +
                        classes.hideXS
                    }
                >
                    <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel-moment-header"
                        disabled={true}
                        expandIcon={
                            <ExpandMoreIcon style={{ visibility: "hidden" }} />
                        }
                    >
                        <Typography
                            className="contacts-table-header"
                            style={{ paddingLeft: "20px !important" }}
                        >
                            <span>
                                {props.form.language.MYCONTACT_S10.toUpperCase()}
                            </span>
                        </Typography>
                        <Typography className="contacts-table-header">
                            <span className={classes.headerL}>
                                {props.form.language.MYCONTACT_S11.toUpperCase()}
                            </span>
                            <MailOutlineIcon
                                className={`${classes.headerSM}`}
                            />
                        </Typography>
                        <Typography className="contacts-table-header">
                            <span className={classes.headerL}>
                                {props.form.language.MYCONTACT_S12.toUpperCase()}
                            </span>
                            <CallIcon className={`${classes.headerSM}`} />
                        </Typography>
                        <Typography className="contacts-table-header">
                            <span className={classes.headerL}>
                                {props.form.language.MYCONTACT_S13.toUpperCase()}
                            </span>

                            <HomeIcon className={`${classes.headerSM}`} />
                        </Typography>
                    </AccordionSummary>
                </Accordion>
                {props.form.accountData &&
                    props.form.accountData.contactList &&
                    props.form.accountData.contactList.map((row) => {
                        if (
//                            !props.contactType ||
  //                          filterContacts(props.contactType, row.meta.tags) || 
                            row.meta.tags.includes(category) ||
                                        category === ""
                        ) {
                            return (
                                <Accordion
                                    className={
                                        classes.container + " contactAccordion"
                                    }
                                    expanded={
                                        props.form.currentContact ===
                                        row.system.id
                                    }
                                    onChange={handleChange(row.system.id)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className="contact-summary-header contact-profile-icon">
                                            <span className="contactListSection mm-login-header">
                                                {row.privateData.firstName +
                                                    " " +
                                                    row.privateData.lastName}
                                            </span>
                                        </Typography>
                                        <Typography
                                            className="contact-summary-header"
                                            style={{ paddingLeft: "40px" }}
                                        >
                                            <MailOutlineIcon
                                                className={
                                                    row.contact.email
                                                        ? ""
                                                        : classes.red
                                                }
                                            />
                                        </Typography>
                                        <Typography
                                            className="contact-summary-header"
                                            style={{ paddingLeft: "20px" }}
                                        >
                                            <CallIcon
                                                className={
                                                    row.contact.phone
                                                        ? ""
                                                        : classes.red
                                                }
                                            />
                                        </Typography>
                                        <Typography
                                            className="contact-summary-header"
                                            style={{ paddingLeft: "20px" }}
                                        >
                                            <HomeIcon
                                                className={
                                                    row.physicalAddress.street
                                                        ? ""
                                                        : classes.red
                                                }
                                            />
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        className={`${classes.accordionDetail}`}
                                    >
                                        <div className={`contact-section`}>
                                            <div
                                                className={
                                                    classes.accordionDetailSM
                                                }
                                            >
                                                <div className="contacts-headers">
                                                    {props.form.language.CATEGORY_LABEL}
                                                </div>
                                                <div className="contacts-content">
                                                    {getTags(
                                                        row.meta.tags,
                                                        props
                                                    )}
                                                </div>
                                            </div>
                                            {row.contactType === "family" && (
                                                <div
                                                    className={
                                                        classes.accordionDetailSM
                                                    }
                                                >
                                                    <div className="contacts-headers">
                                                        {
                                                            props.form.language
                                                                .MYCONTACT_S14
                                                        }
                                                    </div>
                                                    <div className="contacts-content">
                                                        {
                                                            row.privateData
                                                                .birthDate
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    classes.accordionDetailSM
                                                }
                                            >
                                                <div className="contacts-headers">
                                                    {
                                                        props.form.language
                                                            .MYCONTACT_S11
                                                    }
                                                </div>
                                                <div className="contacts-content">
                                                    {row.contact.email
                                                        ? row.contact.email
                                                        : ""}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    classes.accordionDetailSM
                                                }
                                            >
                                                <div className="contacts-headers">
                                                    {
                                                        props.form.language
                                                            .MYCONTACT_S16
                                                    }
                                                </div>
                                                <div className="contacts-content">
                                                    {row.contact.phone
                                                        ? row.contact.phone
                                                        : ""}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    classes.accordionDetailSM
                                                }
                                            >
                                                <div className="contacts-headers">
                                                    {
                                                        props.form.language
                                                            .MYCONTACT_S13
                                                    }
                                                </div>
                                                <div className="contacts-content">
                                                    {row.physicalAddress.street
                                                        ? row.physicalAddress
                                                              .street
                                                        : ""}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    classes.accordionDetailSM
                                                }
                                            >
                                                <div className="contacts-headers">
                                                    {
                                                        props.form.language
                                                            .POSTCODE
                                                    }
                                                </div>
                                                <div className="contacts-content">
                                                    {
                                                        row.physicalAddress
                                                            .zipCode
                                                    }
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    classes.accordionDetailSM
                                                }
                                            >
                                                <div className="contacts-headers">
                                                    {
                                                        props.form.language
                                                            .MYCONTACT_S17
                                                    }
                                                </div>
                                                <div className="contacts-content">
                                                    {row.physicalAddress.city}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    classes.accordionDetailSM
                                                }
                                            >
                                                <div className="contacts-headers">
                                                    {
                                                        props.form.language
                                                            .MYCONTACT_S18
                                                    }
                                                </div>
                                                <div className="contacts-content">
                                                    {
                                                        row.physicalAddress
                                                            .countryISOCode
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.editSection}>
                                            <button
                                                className="secondary-button-sm"
                                                onClick={() =>
                                                    showfutureFormPopup(row)
                                                }
                                            >
                                                {
                                                    props.form.language
                                                        .FUTURE_MESSAGE_S1
                                                }
                                            </button>
                                            <div
                                                style={{ marginLeft: "16px" }}
                                                className="secondary-alert-sm"
                                                onClick={() => editContact(row)}
                                            >
                                                <i className="fa fa-pencil"></i>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        }
                    })}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Mysafe);
