/*  eslint-disable array-callback-return */
// eslint-disable no-dupe-keys
/* eslint-disable no-unused-vars */

import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { connect } from 'react-redux';
import PasswordOverview from './PasswordOverview';
import { addAttribute } from '../../../../Actions/Form';

const AccessWay = (props) => {
    const saveEwill = (data) => {
        let input = {};
        Object.keys(data).map((item) => {
            input[item] = {
                value: data[item],
            };
        });
        let json = {
          url: input.ID.value ? `important/${input.ID.value}` : 'important',
            method: input.ID.value ? 'PUT' : 'POST',
            system: {
                id: input.ID.value,
            },
            general: {
                importantType: 'password',
                name: input.name ? input.name.value : '',
                contactID: input.accessContactID && input.accessContactID.value !== ";" ? input.accessContactID.value + ';' : '',
            },
            meta: {
                additionalInfo: input.additionalInfo ? input.additionalInfo.value : '',
                tags: 'tag1;tag2',
            },
            password: {
                passwordType: input.accessType ? input.accessType.value : '',
                location: input.location ? input.location.value : '',
                username: input.userName ? input.userName.value : '',
            },
        };
        props.handleRequest('update', json);
        setaddNew(false);
        setValue(0);
    };

    const [addNew, setaddNew] = React.useState(false);

    const [newRecord, setnewRecord] = React.useState({
        accessType: '',
        location: '',
        accessContactID: '',
        ID: '',
        name: '',
        userName: '',
        additionalInfo: '',
    });

    React.useEffect(() => {
        if (props.form.accountData && props.form.accountData.importantList) {
            let data = props.form.accountData.importantList.filter((item) => {
                return item.password;
            });
            let password = [];
            data.map((item) => {
                password.push({
                    accessType: item.password.passwordType,
                    accessContactID: item.general.contactID,
                    location: item.password.location,
                    userName: item.password.username,
                    ID: item.system.id,
                    name: item.general.name,
                    additionalInfo: item.meta.additionalInfo,
                });
            });
            setpersonalData(password);
        } else {
            setpersonalData([]);
        }
    }, [props.form.accountData && props.form.accountData.importantList]);

    const changeHandler = (event, personal) => {
        let data = personalData;

        let newData = newRecord;
        newData[event.target.name] = event.target.value;
        setnewRecord(newData);
        setpersonalData(JSON.parse(JSON.stringify(data)));
    };
    const [personalData, setpersonalData] = React.useState([]);
    const hideEdit = () => {
        setaddNew(false);
    };
    const showAddPassword = (finance) => {
        setaddNew(true);
        if (finance) {
            let newData = {};
            Object.keys(finance).map((item) => {
                newData[item] = finance[item];
            });
            setnewRecord(newData);
            props.addAttribute({
                name: 'selectedPassword',
                value: finance,
            });
        } else {
            setnewRecord({
                accessType: '',
                additionalInfo: '',
                location: '',
                accessContactID: '',
                ID: '',
                name: '',
                userName: '',
            });
            props.addAttribute({
                name: 'selectedPassword',
                value: {},
            });
        }
    };
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
      <React.Fragment key={props.key}>
            {addNew === true && (
                <Dialog
                    open={addNew}
                    fullScreen={props.fullScreen}
                    disableEscapeKeyDown={true}
                    onClose={hideEdit}
                    aria-labelledby='responsive-dialog-title'
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                    >
                        <div
                            className='mm-form-container'
                        >
                            {value === 2 ? (
                                <div className='mm-login-container' style={{ boxShadow: 'none' }}>
                                    <PasswordOverview
                                        data={newRecord}
                                        isPreview={true}
                                        showAddPassword={() => showAddPassword()}
                                    />
                                    <div>
                                        <button
                                            style={{
                                                textAlign: 'center',
                                                width: '100%',
                                                marginTop: '24px',
                                            }}
                                            className='primary-button-blue'
                                            onClick={() => saveEwill(newRecord)}
                                        >
                                            {props.form.language.SAVE}
                                        </button>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '15px',
                                            font: 'normal normal 800 18px/30px Raleway',
                                        }}
                                    >
                                        <span
                                            className='contacts-add-link'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setValue(0);
                                                setaddNew(false);
                                            }}
                                        >
                                            {props.form.language.STOP_WIZARD}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className='mm-login-container'
                                    style={{ boxShadow: 'none', paddingTop: '10px' }}
                                >
                                    <div
                                        style={{
                                            font: 'normal normal 600 18px/64px Raleway',
                                            letterSpacing: '1.8px',
                                            color: '#23315580',
                                        }}
                                    >
                                        {`${props.form.language.QUESTION_LABEL} ${
                                            parseInt(value) + 1
                                        } ${props.form.language.QUESTION_LABEL_OF} 2`}
                                    </div>
                                    <Tabs
                                        style={{ height: '16px', minHeight: 'unset' }}
                                        value={value}
                                        indicatorColor='primary'
                                        textColor='primary'
                                        onChange={handleChange}
                                    >
                                        <Tab
                                            style={{
                                                height: '16px',
                                                minHeight: 'unset',
                                                borderRadius: '8px 0px 0px 8px',
                                                background: '#6BCE90 0% 0% no-repeat padding-box',
                                                width: '48%',
                                            }}
                                            label=''
                                        />
                                        <Tab
                                            style={{
                                                height: '16px',
                                                minHeight: 'unset',
                                                borderRadius: ' 0px 8px 8px 0px ',
                                                opacity: value === 1 ? 1 : 0.1,
                                                background:
                                                    value === 1
                                                        ? '#6BCE90 0% 0% no-repeat padding-box'
                                                        : '#233155 0% 0% no-repeat padding-box',

                                                marginLeft: '4PX',
                                                width: '48%',
                                            }}
                                            label=''
                                        />
                                    </Tabs>
                                    {value === 0 ? (
                                        <div>
                                            <div
                                                className='login-field-label login-field-label-height'
                                                style={{ marginBottom: '5px' }}
                                            >
                                                {props.form.language.PASSWORD_Q15}
                                            </div>
                                            <div className=''>
                                                <RadioGroup
                                                    className='mm-field-input'
                                                    style={{ flexDirection: 'column' }}
                                                    name={'accessType'}
                                                    value={newRecord.accessType}
                                                    onChange={(event) => changeHandler(event)}
                                                >
                                                    <FormControlLabel
                                                        value={'passwordManager'}
                                                        control={<Radio />}
                                                        label={
                                                            props.form.language
                                                                .PASSWORD_MANAGER_OPTION_1
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        value={'paper'}
                                                        control={<Radio />}
                                                        label={
                                                            props.form.language
                                                                .PASSWORD_MANAGER_OPTION_2
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        value={'others'}
                                                        control={<Radio />}
                                                        label={props.form.language.FUNERAL_Q18}
                                                    />
                                                </RadioGroup>
                                            </div>
                                            <div>
                                                <button
                                                    style={{
                                                        textAlign: 'center',
                                                        width: '100%',
                                                        marginTop: '24px',
                                                        opacity:
                                                            newRecord.accessType === '' ? 0.1 : 1,
                                                    }}
                                                    className='primary-button-blue'
                                                    onClick={() => setValue(1)}
                                                >
                                                    {`${props.form.language.SAVE_GOTO} 2`}
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            {newRecord.accessType === 'passwordManager' && (
                                                <div className='mm-login-header login-field-label-height'>
                                                    {props.form.language.PASSWORD_Q1}
                                                </div>
                                            )}

                                            {newRecord.accessType === 'passwordManager' && (
                                                <div>
                                                    <div className='login-field-label login-field-label-height'>
                                                        {props.form.language.PASSWORD_Q2}
                                                    </div>
                                                    <div className='login-field-input'>
                                                        <input
                                                            type='text'
                                                            autoComplete="off"
                                                            placeholder={
                                                                props.form.language.PASSWORD_Q3
                                                            }
                                                            name='name'
                                                            value={newRecord['name']}
                                                            onChange={(event) =>
                                                                changeHandler(event)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {newRecord.accessType === 'passwordManager' && (
                                                <div>
                                                    <div className='login-field-label login-field-label-height'>
                                                        {props.form.language.PASSWORD_Q4}
                                                    </div>
                                                    <div className='login-field-input'>
                                                        <input
                                                            type='text'
                                                            autoComplete="off"
                                                            placeholder={
                                                                props.form.language.PASSWORD_Q5
                                                            }
                                                            name='userName'
                                                            value={newRecord['userName']}
                                                            onChange={(event) =>
                                                                changeHandler(event)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {newRecord.accessType === 'passwordManager' && (
                                                <div>
                                                    <div className='login-field-label login-field-label-height'>
                                                        {props.form.language.CONTACT_PERSON_TITLE}
                                                    </div>
                                                    <div className='login-field-input'>
                                                        <select
                                                            className='mm-field-input '
                                                            autoComplete="off"
                                                            placeholder={
                                                                props.form.language.PASSWORD_Q17
                                                            }
                                                            name={'accessContactID'}
                                                            value={newRecord['accessContactID']}
                                                            onChange={(event) =>
                                                                changeHandler(event)
                                                            }
                                                        >
                                                            <option value=''>
                                                                {props.form.language.IS_APPLICABLE}
                                                            </option>
                                                            {props.contactList?.map(
                                                                    (contact) => {
                                                                        if (contact) {
                                                                            return (
                                                                                <option
                                                                                    value={
                                                                                        contact.system.id
                                                                                    }
                                                                                >
                                                                                    {contact.privateData.firstName +
                                                                                        ' ' +
                                                                                        contact.privateData.lastName}
                                                                                </option>
                                                                            );
                                                                        }
                                                                    }
                                                                )}
                                                        </select>
                                                    </div>
                                                </div>
                                            )}
                                            {newRecord.accessType === 'passwordManager' && (
                                                <div>
                                                    <div className='login-field-label login-field-label-height'>
                                                        {props.form.language.ADDITIONAL_TITLE}
                                                    </div>
                                                    <div className='login-field-input'>
                                                        <textarea
                                                            style={{ height: 'auto' }}
                                                            rows='5'
                                                            autoComplete="off"
                                                            type='text'
                                                            placeholder={
                                                                props.form.language.PASSWORD_Q13
                                                            }
                                                            name='additionalInfo'
                                                            value={newRecord.additionalInfo}
                                                            onChange={(event) =>
                                                                changeHandler(event)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {newRecord.accessType === 'paper' && (
                                                <div className='mm-login-header login-field-label-height'>
                                                    {props.form.language.PASSWORD_Q6}
                                                </div>
                                            )}
                                            {newRecord.accessType === 'paper' && (
                                                <div>
                                                    <div className='login-field-label login-field-label-height'>
                                                        {props.form.language.PASSWORD_Q7}
                                                    </div>
                                                    <div className='login-field-input'>
                                                        <input
                                                            type='text'
                                                            autoComplete="off"
                                                            placeholder={
                                                                props.form.language.PASSWORD_Q8
                                                            }
                                                            name='location'
                                                            value={newRecord['location']}
                                                            onChange={(event) =>
                                                                changeHandler(event)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {newRecord.accessType === 'paper' && (
                                                <div>
                                                    <div className='login-field-label login-field-label-height'>
                                                        {props.form.language.PASSWORD_Q9}
                                                    </div>
                                                    <div className='login-field-input'>
                                                        <input
                                                            type='text'
                                                            autoComplete="off"
                                                            placeholder={
                                                                props.form.language.PASSWORD_Q10
                                                            }
                                                            name='additionalInfo'
                                                            value={newRecord['additionalInfo']}
                                                            onChange={(event) =>
                                                                changeHandler(event)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {newRecord.accessType === 'others' && (
                                                <div className='mm-login-header login-field-label-height'>
                                                    {props.form.language.PASSWORD_Q11}
                                                </div>
                                            )}
                                            {newRecord.accessType === 'others' && (
                                                <div>
                                                    <div className='login-field-label login-field-label-height'>
                                                        {props.form.language.PASSWORD_Q12}
                                                    </div>
                                                    <div className='login-field-input'>
                                                        <textarea
                                                            style={{ height: 'auto' }}
                                                            rows='5'
                                                            autoComplete="off"
                                                            type='text'
                                                            placeholder={
                                                                props.form.language.PASSWORD_Q14
                                                            }
                                                            name='location'
                                                            value={newRecord['location']}
                                                            onChange={(event) =>
                                                                changeHandler(event)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div>
                                                <button
                                                    style={{
                                                        textAlign: 'center',
                                                        width: '100%',
                                                        marginTop: '24px',
                                                    }}
                                                    className='primary-button-blue'
                                                    onClick={() => setValue(2)}
                                                >
                                                    {props.form.language.PASSWORD_SAVE_DATA}
                                                </button>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '15px',
                                            font: 'normal normal 800 18px/30px Raleway',
                                        }}
                                    >
                                        <span
                                            className='contacts-add-link'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setValue(0);
                                                setaddNew(false);
                                            }}
                                        >
                                            {props.form.language.STOP_WIZARD}
                                        </span>
                                    </div>
                                </div>
                            )}{' '}
                        </div>
                    </div>
                </Dialog>
            )}
            {personalData &&
                personalData.length > 0 &&
                personalData.map((data) => {
                    return (
                        <PasswordOverview
                            data={data}
                            showAddPassword={() => showAddPassword(data)}
                            handleRequest={(type, data) => props.handleRequest(type, data)}
                            contactList={props.contactList}
                        />
                    );
                })}
            {personalData.length < 1 ? (
                <div>
                    <div className='momentio-text-normal' style={{ width: '95%' }}>
                        {props.form.language.PASSWORD_INFO}
                    </div>
                    <button
                        style={{
                            textAlign: 'center',
                            marginTop: '24px',
                        }}
                        className='secondary-button'
                        onClick={() => showAddPassword()}
                    >
                        {props.form.language.START_WIZARD}
                    </button>
                </div>
            ) : (
                <span
                    id={props.type}
                    onClick={() => showAddPassword()}
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '8px',
                    }}
                >
                    <span className=''>
                        <span className='ewill-secondary-alert-sm'>
                            <AddIcon />
                        </span>
                    </span>
                    <span className='momentio-text-normal' style={{ marginLeft: '15px' }}>
                        {props.form.language.ADD_ITEM}
                    </span>
                </span>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccessWay);
