import React from "react";
import { connect } from "react-redux";
import moment from "moment";

const HeaderSubscription = (props) => {
    const getSubscriptionName = () => {
        const isReadOnly = props.form.accountData?.profile?.readOnlyMode;
        const expiryDate = props.form.accountData?.profile?.limits?.membership
            ?.membershipEndDate
            ? moment().diff(
                  props.form.accountData?.profile?.limits?.membership
                      ?.membershipEndDate,
                  "days"
              ) // 1
            : "";

        if (expiryDate > 13) {
            return `${props.form.language.EXPIRY_SUBSCRIPTION} ${expiryDate} ${props.form.language.EXPIRY_SUBSCRIPTION_WEEKS}`;
        } else if (isReadOnly) {
            return props.form.language.READONLY_SUBSCRIPTION;
        } else {
            if (
                props.form.accountData?.profile?.limits?.membership
                    ?.membershipID
            ) {
                const membershipID =
                    props.form.accountData?.profile?.limits?.membership
                        ?.membershipID;
                switch (membershipID) {
                    case 2:
                        return (props.form.language.BASIC_SUBSCRIPTION).toUpperCase();
                    case 3:
                        return props.form.language.BETTER_SUBSCRIPTION.toUpperCase();
                    case 4:
                        return props.form.language.PERFECT_SUBSCRIPTION.toUpperCase();
                    default:
                        return props.form.language.FREE_SUBSCRIPTION.toUpperCase();
                }
            }
        }

        return props.form.language.FREE_SUBSCRIPTION;
    };
    return <>{getSubscriptionName()}</>;
};

const mapStateToProps = (state) => {
    return { ...state };
};

export default connect(mapStateToProps)(HeaderSubscription);
