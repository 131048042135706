/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import { connect } from 'react-redux';

const FuneralType = (props) => {
    const [personalData, setpersonalData] = React.useState({});
    const [isEdit, setEdit] = React.useState(false);
    const saveEwill = () => {
        let json = {
            url: 'lastwishes',
            mode: 'merge',
            method: props.form.accountData.lastwishes.funeralPolis ? 'PUT' : 'POST',
            ...props.form.accountData.lastwishes,
            funeralType: {
              "funeralSpecial": personalData.funeralSpecial,      
              "funeralWay": personalData.funeralWay,
              "location": personalData.location,
              "coffinType": personalData.coffinType,
              "typeOfFuneral": personalData.typeOfFuneral,
              "graveType": personalData.graveType,
              "ashDestinationType": personalData.ashDestinationType,
              "inscription": personalData.inscription,
              "additionalInfo": personalData.additionalInfo,
            },
        };
        props.handleRequest('update', json);
        setEdit(false);
    };
    const deleteEwill = () => {        
        let data = {...props.form.accountData.lastwishes};
        delete data.funeralType
        let json = {
          url: 'lastwishes',
          mode: 'overwrite',
          method: 'PUT',
          ...data
      };
        props.handleRequest('update', json, 'funeralType');
    };

    React.useEffect(() => {
        if (
            props.form.accountData &&
            props.form.accountData.lastwishes &&
            props.form.accountData.lastwishes.funeralType &&
            props.form.accountData.lastwishes.funeralType.typeOfFuneral
        ) {
            let data = props.form.accountData.lastwishes.funeralType;
            setpersonalData(data);
        } else {
            setpersonalData({
                typeOfFuneral: '',
                funeralWay: '',
                graveType: '',
                ashDestinationType: '',
                location: '',
                coffinType: '',
                additionalInfo: '',
                ID: '',
                inscription: '',
            });
            setEdit(true);
        }
    }, [props.form.accountData && props.form.accountData.lastwishes]);

    const changeHandler = (event, personal) => {
        let data = {...personalData};
        data[event.target.name] = event.target.value;
        setpersonalData(data);
    };

    return (
        <React.Fragment>
            {isEdit && (
                <div>
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.FUNERAL_Q1}
                        </div>
                        <div className='login-field-input'>
                            <select
                                className='mm-field-input '
                                id='contactType'
                                name='typeOfFuneral'
                                autoComplete="off"
                                value={personalData['typeOfFuneral']}
                                onChange={(event) => changeHandler(event, personalData)}
                            >
                                <option value=''>{props.form.language.SELECT_OPTION}</option>
                                <option value={'public'}>{props.form.language.FUNERAL_Q2}</option>
                                <option value={'selectgroup'}>
                                    {props.form.language.FUNERAL_Q3}
                                </option>
                                <option value={'militaryHonour'}>
                                    {props.form.language.FUNERAL_Q4}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.FUNERAL_Q5}
                        </div>

                        <div className=''>
                            <RadioGroup
                                className='mm-field-input'
                                style={{ flexDirection: 'row' }}
                                name={'funeralWay'}
                                value={personalData.funeralWay}
                                onChange={(event) => changeHandler(event, personalData)}
                            >
                                <FormControlLabel
                                    value={'burried'}
                                    control={<Radio />}
                                    label={props.form.language.FUNERAL_Q6}
                                />

                                <FormControlLabel
                                    value={'cremated'}
                                    control={<Radio />}
                                    label={props.form.language.FUNERAL_Q8}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                    {personalData.funeralWay === 'burried' && (
                        <div>
                            <div className='login-field-label login-field-label-height'>
                                {props.form.language.FUNERAL_Q9}
                            </div>
                            <div className=''>
                                <RadioGroup
                                    className='mm-field-input'
                                    style={{ flexDirection: 'row' }}
                                    name={'graveType'}
                                    value={personalData.graveType}
                                    onChange={(event) => changeHandler(event, personalData)}
                                >
                                    <FormControlLabel
                                        value={'private'}
                                        control={<Radio />}
                                        label={props.form.language.FUNERAL_Q12}
                                    />
                                    <FormControlLabel
                                        value={'foundation'}
                                        control={<Radio />}
                                        label={props.form.language.FUNERAL_Q10}
                                    />

                                    <FormControlLabel
                                        value={'state'}
                                        control={<Radio />}
                                        label={props.form.language.FUNERAL_Q11}
                                    />
                                    
                                </RadioGroup>
                            </div>
                        </div>
                    )}
                    {personalData.funeralWay === 'cremated' && (
                        <div>
                            <div className='login-field-label login-field-label-height'>
                                {props.form.language.FUNERAL_Q13}
                            </div>
                            <div className='login-field-input'>
                                <input
                                    type='text'
                                    autoComplete="off"
                                    placeholder={props.form.language.FUNERAL_Q13}
                                    name='location'
                                    value={personalData.location}
                                    onChange={(event) => changeHandler(event, personalData)}
                                />
                            </div>
                        </div>
                    )}
                    {personalData.funeralWay === 'cremated' && (
                        <div>
                            <div className='login-field-label login-field-label-height'>
                                {props.form.language.FUNERAL_Q14}
                            </div>
                            <div className=''>
                                <RadioGroup
                                    className='mm-field-input'
                                    style={{ flexDirection: 'row' }}
                                    name={'ashDestinationType'}
                                    value={personalData.ashDestinationType}
                                    onChange={(event) => changeHandler(event, personalData)}
                                >
                                    <FormControlLabel
                                        value={'scattering'}
                                        control={<Radio />}
                                        label={props.form.language.FUNERAL_Q15}
                                    />

                                    <FormControlLabel
                                        value={'location'}
                                        control={<Radio />}
                                        label={props.form.language.FUNERAL_Q7}
                                    />
                                    <FormControlLabel
                                        value={'nik'}
                                        control={<Radio />}
                                        label={props.form.language.NEXT_TO_KIN}
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                    )}
                    {personalData.funeralWay === 'burried' && (
                        <div>
                            <div className='login-field-label login-field-label-height'>
                                {props.form.language.FUNERAL_Q16}
                            </div>
                            <div className=''>
                                <RadioGroup
                                    className='mm-field-input'
                                    style={{ flexDirection: 'row' }}
                                    name={'coffinType'}
                                    value={personalData.coffinType}
                                    onChange={(event) => changeHandler(event, personalData)}
                                >
                                    <FormControlLabel
                                        value={'standard'}
                                        control={<Radio />}
                                        label={props.form.language.FUNERAL_Q17}
                                    />

                                    <FormControlLabel
                                        value={'others'}
                                        control={<Radio />}
                                        label={props.form.language.FUNERAL_Q18}
                                    />
                                    <FormControlLabel
                                        value={'nik'}
                                        control={<Radio />}
                                        label={props.form.language.NEXT_TO_KIN}
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                    )}
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.ADDITIONAL_TITLE}
                        </div>
                        <div className='login-field-input'>
                            <textarea
                                style={{ height: 'auto' }}
                                rows='4'
                                autoComplete="off"
                                type='text'
                                placeholder={props.form.language.ADDITIONAL_TITLE}
                                name='additionalInfo'
                                value={personalData.additionalInfo}
                                onChange={(event) => changeHandler(event, personalData)}
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            style={{
                                textAlign: 'center',
                                width: '150px',
                                marginTop: '24px',
                            }}
                            className='primary-button-blue'
                            onClick={() => saveEwill()}
                        >
                            {props.form.language.SAVE}
                        </button>
                        <button
                            style={{
                                textAlign: 'center',
                                width: '150px',
                                marginTop: '24px',
                                backgroundColor: '#F45B5B !important',
                                marginLeft: '15px',
                            }}
                            className='primary-button-red'
                            onClick={() => deleteEwill()}
                        >
                            {props.form.language.DELETE}
                        </button>
                    </div>
                </div>
            )}
            {!isEdit && (
                <React.Fragment>
                    <div
                        style={{ display: 'flex' }}
                        className='flex-direction-column input-container-column'
                    >
                        <div className='momentio-text-normal accordion-content-option-label'>
                            {props.form.language.FUNERAL_Q20}
                        </div>
                        <div className='momentio-text-bold momentio-ewill-value'>
                            {personalData.typeOfFuneral === 'public'
                                ? props.form.language.FUNERAL_Q2
                                : personalData.typeOfFuneral === 'selectgroup'
                                ? props.form.language.FUNERAL_Q3
                                : personalData.typeOfFuneral === 'militaryHonour'
                                ? props.form.language.FUNERAL_Q4
                                : ''}
                        </div>
                    </div>
                    <div
                        style={{ display: 'flex' }}
                        className='flex-direction-column input-container-column'
                    >
                        <div className='momentio-text-normal accordion-content-option-label'>
                            {props.form.language.FUNERAL_Q5}
                        </div>
                        <div className='momentio-text-bold momentio-ewill-value'>
                            {personalData.funeralWay === 'burried'
                                ? props.form.language.FUNERAL_Q6
                                : personalData.funeralWay === 'cremated'
                                ? props.form.language.FUNERAL_Q8
                                : ''}
                        </div>
                    </div>

                    {personalData.funeralWay === 'burried' && (
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.FUNERAL_Q9}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.graveType === 'foundation'
                                    ? props.form.language.FUNERAL_Q10
                                    : personalData.graveType === 'state'
                                    ? props.form.language.FUNERAL_Q11
                                    : personalData.graveType === 'private'
                                    ? props.form.language.FUNERAL_Q12
                                    : ''}
                            </div>
                        </div>
                    )}

                    {personalData.funeralWay === 'cremated' && (
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.FUNERAL_Q13}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.location}
                            </div>
                        </div>
                    )}

                    {personalData.funeralWay === 'cremated' && (
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.FUNERAL_Q14}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.ashDestinationType === 'scattering'
                                    ? props.form.language.FUNERAL_Q15
                                    : personalData.ashDestinationType === 'location'
                                    ? props.form.language.FUNERAL_Q7
                                    : personalData.ashDestinationType === 'nik'
                                    ? props.form.language.NEXT_TO_KIN
                                    : ''}
                            </div>
                        </div>
                    )}

                    {personalData.funeralWay === 'burried' && (
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.FUNERAL_Q16}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.coffinType === 'standard'
                                    ? props.form.language.FUNERAL_Q17
                                    : personalData.coffinType === 'others'
                                    ? props.form.language.FUNERAL_Q18
                                    : personalData.coffinType === 'nik'
                                    ? props.form.language.NEXT_TO_KIN
                                    : ''}
                            </div>
                        </div>
                    )}
                    <div
                        style={{ display: 'flex' }}
                        className='flex-direction-column input-container-column'
                    >
                        <div className='momentio-text-normal accordion-content-option-label'>
                            {props.form.language.ADDITIONAL_TITLE}
                        </div>
                        <div className='momentio-text-bold momentio-ewill-value'>
                            {personalData.additionalInfo}
                        </div>
                    </div>
                    <div className='secondary-alert-sm' onClick={() => setEdit(true)}>
                        {' '}
                        <i className='fa fa-pencil'></i>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps)(FuneralType);
