import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { accountApi } from "../../app/services/account";
import TextSnippetOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import moment from "moment";


const MessagePreview = (props) => {
    const [media, setMedia] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    useEffect(() => {
        getSharedMediaURL();
    }, []);

    const getSharedMediaURL = async (item) => {
        if (props.row.data.mediaID) {
            
            let split = props.row.data.mediaID.split(";");
            if (split.length > 0) {
                let mediaLists = [...media];
                
                await split.map(async (item) => {
                    if (item) {
                        setLoading(true);
                        //let x = props.getAPICall(`media/shared/${item}`);
                        var x = await props.getAPICall(`media/shared/${item}`);
                        if (x.data.mediaList.length > 0) {
                            mediaLists.push(x.data.mediaList[0]);
                        }
                        setLoading(false);
                    }
                });
                setMedia(mediaLists);
            } 
        }
    };

    const getFileType = (desc) => {
        let format = desc.split(".");
        return format.length > 0 ? format[format.length - 1].toLowerCase() : "";
    };
    const getMediaList = () => {
        if (media && media.length > 0) {
            return (
                <Grid container spacing={2}>
                    {media.length > 0 &&
                        media.map((item) => {
                            let currentMedia = item;
                            let fileType =
                                currentMedia && currentMedia.data
                                    ? getFileType(
                                          currentMedia.data.originalFileName
                                      )
                                    : "";

                            return (
                                <Grid item xs={12} md={12} lg={12}>
                                    <div>
                                        {currentMedia && currentMedia.data && (
                                            <React.Fragment>
                                                <div
                                                    className="message-image-container"
                                                    style={{
                                                        height:
                                                            fileType ===
                                                                "mp3" ||
                                                            fileType ===
                                                                "cpm" ||
                                                            fileType ===
                                                                "ogg" ||
                                                            fileType ===
                                                                "wav" ||
                                                            fileType ===
                                                                "aiff" ||
                                                            fileType ===
                                                                "acc" ||
                                                            fileType ===
                                                                "wma" ||
                                                            fileType === "alac"
                                                                ? "auto"
                                                                : "450px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    {fileType &&
                                                    (fileType === "png" ||
                                                        fileType === "jpg" ||
                                                        fileType === "jpeg" ||
                                                        fileType === "svg" ||
                                                        fileType === "gif") ? (
                                                        <img
                                                            style={{
                                                                height: "450px",
                                                                borderRadius:
                                                                    "8px",
                                                                maxWidth:
                                                                    "100%",
                                                            }}
                                                            src={
                                                                currentMedia
                                                                    ?.system
                                                                    ?.signedUrl
                                                            }
                                                            alt=""
                                                        />
                                                    ) : fileType === "mp3" ||
                                                      fileType === "cpm" ||
                                                      fileType === "ogg" ||
                                                      fileType === "wav" ||
                                                      fileType === "aiff" ||
                                                      fileType === "acc" ||
                                                      fileType === "m4a" ||
                                                      fileType === "wma" ||
                                                      fileType === "alac" ? (
                                                        <audio
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            controls
                                                        >
                                                            <source
                                                                src={
                                                                    currentMedia
                                                                        ?.system
                                                                        ?.signedUrl
                                                                }
                                                                type="audio/ogg"
                                                            />
                                                            Your browser does
                                                            not support the
                                                            audio element.
                                                        </audio>
                                                    ) : fileType === "mp4" ||
                                                      fileType === "mov" ||
                                                      fileType === "avi" ||
                                                      fileType === "flv" ||
                                                      fileType === "wmv" ||
                                                      fileType === "webm" ||
                                                      fileType === "mpg" ||
                                                      fileType === "mp2" ||
                                                      fileType === "mpeg" ||
                                                      fileType === "mpe" ||
                                                      fileType === "mpv" ||
                                                      fileType === "ogg" ||
                                                      fileType === "swf" ? (
                                                        <video
                                                            controls
                                                            style={{
                                                                height: "450px",
                                                                borderRadius:
                                                                    "8px",
                                                                maxWidth:
                                                                    "100%",
                                                                width: "inherit",
                                                            }}
                                                        >
                                                            <source
                                                                src={
                                                                    currentMedia
                                                                        ?.system
                                                                        ?.signedUrl
                                                                }
                                                                type="video/mp4"
                                                            />
                                                            Your browser does
                                                            not support HTML
                                                            video.
                                                        </video>
                                                    ) : (
                                                        <TextSnippetOutlinedIcon
                                                            style={{
                                                                height: "450px",
                                                                borderRadius:
                                                                    "8px",
                                                                maxWidth:
                                                                    "100%",
                                                                width: "inherit",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    className="message-image-desc"
                                                    style={{
                                                        marginBottom: "45px",
                                                        paddingLeft: 0,
                                                    }}
                                                >
                                                    <div className="mm-login-header">
                                                        {" "}
                                                        {currentMedia.data.name}
                                                    </div>
                                                    <div className="momentio-text-normal">
                                                        {
                                                            currentMedia.meta
                                                                .additionalInfo
                                                        }
                                                    </div>
                                                    <div className="momentio-text-normal"></div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </Grid>
                            );
                        })}
                    {media.length === 0 && (
                        <div style={{ padding: "10px" }}></div>
                    )}
                </Grid>
            );
        }
    };
    return (
        <div className="auth-content messagePreview">
            {loading && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <div className="auth-content-subheader ">
                <div className="header-title-section-login">
                    {props.form.language.PREVIEW_MESSAGE_TITLE}
                </div>
            </div>
            <div className="messages ewill">
                <div>
                    <div className="n_paragraph">
                        <div className="messageDate">
                            {props.row.data.messageDate
                                ? `${props.form.language.PREVIEW_DELIVERY_TEXT}: ${moment(
                                      props.row.data.messageDate,
                                      "YYYY-MM-DD"
                                  ).format("DD MMMM YYYY")} `
                                : props.form.language
                                      .FUTURE_MESSAGE_DATE_UNDECIDED + " "}
                        </div>{" "}
                        <div className="hideXS messageDate"> | </div>
                        <div>{` ${props.form.language.PREVIEW_FROM_TEXT}: ${props.row.system.sharerName}`}</div>
                    </div>
                    <div className="content-one">{props.row.data.title} </div>

                    <div className="header24Medium" style={{whiteSpace: "pre-wrap"}}>
                        {props.row.data.message}
                    </div>
                </div>
                {getMediaList()}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAPICall: (payload) =>
            dispatch(accountApi.endpoints.getAPICall.initiate(payload)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MessagePreview)
);
