import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Link} from "react-router-dom";

export const GetSuggestions = (props) => {
    let personal = props.suggestions.filter((item) => item.name === "personal");
    let important = props.suggestions.filter(
        (item) => item.name === "important"
    );
    let lastwishes = props.suggestions.filter(
        (item) => item.name === "lastwishes"
    );
    let ewill = props.suggestions.filter((item) => item.name === "ewill");
    let contactAddress = props.suggestions.filter(
        (item) => item.name === "contactAddress"
    );
    let contactPhone = props.suggestions.filter(
        (item) => item.name === "contactPhone"
    );
    let contract = props.suggestions.filter(
        (item) => item.name === "contracts"
    );
    let file = props.suggestions.filter((item) => item.name === "file");
    let contact = props.suggestions.filter((item) => item.name === "contact");
    let message = props.suggestions.filter((item) => item.name === "message");
    let secondmessage = props.suggestions.filter(
        (item) => item.name === "secondmessage"
    );
    let thirdmessage = props.suggestions.filter(
        (item) => item.name === "thirdmessage"
    );
    const [currentSuggestion, setcurrentSuggestion] = useState({});

    useEffect(() => {
        if (personal.length && personal[0].completed < 100) {
            return setcurrentSuggestion(personal[0]);
        } else if (important.length > 0 && important[0].completed < 100) {
            return setcurrentSuggestion(important[0]);
        } else if (lastwishes.length) {
            return setcurrentSuggestion(lastwishes[0]);
        } else if (ewill.length) {
            return setcurrentSuggestion(ewill[0]);
        } else if (contact.length) {
            return setcurrentSuggestion(contact[0]);
        } else if (contactAddress.length) {
            return setcurrentSuggestion(contactAddress[0]);
        } else if (contactPhone.length) {
            return setcurrentSuggestion(contactPhone[0]);
        } else if (file.length) {
            return setcurrentSuggestion(file[0]);
        } else if (contract.length) {
            return setcurrentSuggestion(contract[0]);
        } else if (message.length) {
            return setcurrentSuggestion(message[0]);
        } else if (secondmessage.length) {
            return setcurrentSuggestion(secondmessage[0]);
        } else if (thirdmessage.length) {
            return setcurrentSuggestion(thirdmessage[0]);
        }
    }, [props.suggestions]);

    return (
        <>
            {currentSuggestion.name &&
                suggestionSection(
                    currentSuggestion,
                    props.suggestions,
                    props.setDashboardSuggestions,
                    props
                )}
        </>
    );
};

const deleteSuggestion = (
    suggestions,
    suggestionsLlist,
    setDashboardSuggestions
) => {
    let newList = [...suggestionsLlist].filter(
        (e) => e.name !== suggestions.name
    );
    setDashboardSuggestions(newList);
};

export const suggestionSection = (
    suggestions,
    suggestionsLlist,
    setDashboardSuggestions,
    props
) => {
    return (
        <div
            style={{
                background: "white",
            }}
            className="dashboard-overview-container"
        >
            <Grid
                xs="12"
                sm="12"
                lg="12"
                container
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Grid xs="12" sm="12" md="6" lg="7" item>
                    <div
                        className="n_header-3"
                        style={{
                            width: "auto",
                            height: "auto",
                        }}
                    >
                        {suggestions.title}
                    </div>
                    <div className="n_paragraph">{suggestions.message}</div>
                </Grid>
                <Grid
                    xs="12"
                    sm="12"
                    md="6"
                    lg="5"
                    item
                    style={{ textAlign: "right", paddingLeft: "5px" }}
                >
                    <button className="primary-button-blue">
                        <Link to={`/${suggestions.link}`}>
                            {props.form.language.UPDATE_NOW}
                        </Link>
                    </button>
                    <span
                        className="add-new-attachment"
                        style={{ paddingLeft: "24px", textDecoration: "underline" }}
                        onClick={() =>
                            deleteSuggestion(
                                suggestions,
                                suggestionsLlist,
                                setDashboardSuggestions
                            )
                        }
                    >
                        {props.form.language.NEXT_TIP}
                    </span>
                </Grid>
            </Grid>
        </div>
    );
};
