/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React from "react";
import { addAttribute } from "../../Actions/Form";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Close from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

const MessageAttachment = (props) => {
    return (
        <div>
            {props.value === 1 && (
                <>
                    <div className="login-field-label login-field-label-height">
                        {props.form.language.LEGACY_FOR}
                    </div>
                    <div
                        className="selectedContactChip"
                    >
                        {props.form.accountData.contactList?.map((data) => {
                            if (data.checked) {
                                return (
                                    <li key={data.key}>
                                        <Chip
                                            deleteIcon={
                                                <Close
                                                    style={{
                                                        width: "10px",
                                                        color: "#233155",
                                                    }}
                                                />
                                            }
                                            label={
                                                data.privateData?.firstName +
                                                " " +
                                                data.privateData?.lastName
                                            }
                                            onDelete={props.handleDelete(
                                                data,
                                                "contact"
                                            )}
                                            className="message-chip"
                                        />
                                    </li>
                                );
                            }
                        })}
                        <div
                            className="secondary-alert-sm"
                            style={{
                                display: "inline-flex",
                            }}
                            onClick={props.openFutureContact}
                        >
                            <AddIcon />
                        </div>

                        {props.form.accountData.contactList?.filter(
                            (data) => data.checked === true
                        ) &&
                        props.form.accountData.contactList?.filter(
                            (data) => data.checked === true
                        ).length > 0 ? (
                            ""
                        ) : (
                            <span
                                style={{ marginLeft: "10px", marginTop: "0" }}
                                className="login-field-label login-field-label-height"
                            >
                                {" "}
                                {props.form.language.FUTURE_MESSAGE_S3}
                            </span>
                        )}
                    </div>
                </>
            )}
            {props.value === 0 && (
                <>
                    <div className="login-field-label login-field-label-height">
                        {props.form.language.FUTURE_MESSAGE_S4}
                    </div>
                    <div
                        className="selectedContactChip"
                    >
                        {props.form.accountData.mediaList?.map((data) => {
                            if (data.checked) {
                                return (
                                    <li key={data.key}>
                                        <Chip
                                            label={data.data.name}
                                            deleteIcon={
                                                <Close
                                                    style={{
                                                        width: "10px",
                                                        color: "#233155",
                                                    }}
                                                />
                                            }
                                            onDelete={props.handleDelete(
                                                data,
                                                "media"
                                            )}
                                            className="message-chip"
                                        />
                                    </li>
                                );
                            }
                        })}
                        <div
                            className="secondary-alert-sm"
                            style={{
                                display: "inline-flex",
                            }}
                            onClick={props.openFutureMedia}
                        >
                            <AddIcon />
                        </div>
                        {props.form.accountData.mediaList?.filter(
                            (data) => data.checked === true
                        ) &&
                        props.form.accountData.mediaList?.filter(
                            (data) => data.checked === true
                        ).length > 0 ? (
                            ""
                        ) : (
                            <span
                                style={{ marginLeft: "10px", marginTop: "0" }}
                                className="login-field-label login-field-label-height"
                            >
                                {" "}
                                {props.form.language.FUTURE_MESSAGE_S5}
                            </span>
                        )}
                    </div>
                    <div className="login-field-label login-field-label-height">
                        {props.form.language.ADD_TAGS}
                    </div>
                    <div
                        className="selectedContactChip"
                    >
                        {props.tags.value?.map((data) => {
                            let selectedTag = props.tags.options.filter(
                                (item) => item.value === data
                            )[0];
                            return (
                                <li key={data.key}>
                                    <Chip
                                        deleteIcon={
                                            <Close
                                                style={{
                                                    width: "10px",
                                                    color: "#233155",
                                                }}
                                            />
                                        }
                                        label={
                                            props.form.language[
                                                selectedTag.label
                                            ]
                                                ? props.form.language[
                                                      selectedTag.label
                                                  ]
                                                : selectedTag.label
                                        }
                                        onDelete={() => {
                                            let tags = [...props.tags.value];
                                            tags.splice(
                                                tags.indexOf(selectedTag.value),
                                                1
                                            );
                                            props.updateTagList({
                                                ...props.tags,
                                                value: tags,
                                            });
                                        }}
                                        className="message-chip"
                                    />
                                </li>
                            );
                        })}
                        <div
                            className="secondary-alert-sm"
                            style={{
                                display: "inline-flex",
                            }}
                            onClick={props.openFutureTags}
                        >
                            <AddIcon />
                        </div>
                        <span
                            style={{ marginLeft: "10px", marginTop: "0" }}
                            className="login-field-label login-field-label-height"
                        >
                            {props.tags.value &&
                                props.tags.value.length < 1 &&
                                props.form.language.SELECT_TAG}
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MessageAttachment)
);
