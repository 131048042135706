/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { connect } from 'react-redux';
import { addAttribute } from '../../../../Actions/Form';
import InsuranceOverview from './InsuranceOverview';

const Insurance = (props) => {
    const saveEwill = (data) => {
        let input = {};
        Object.keys(data).map((item) => {
            input[item] = {
                value: data[item],
            };
        });
        let json = {
          url: input.ID.value ? `important/${input.ID.value}` : 'important',
            method: input.ID.value ? 'PUT' : 'POST',
            system: {
                id: input.ID.value,
            },
            general: {
                importantType: 'insurance',
                name: input.name ? input.name.value : '',
                contactID: input.contactID ? input.contactID.value + ';' : '',
            },
            meta: {
                additionalInfo: input.remark ? input.remark.value : '',
                tags: 'tag1;tag2',
            },
            insurance: {
                insuranceType: input.insuranceType ? input.insuranceType.value : '',
                insurer: input.insurer ? input.insurer.value : '',
                polisNumber: input.polisNumber ? input.polisNumber.value : '',
                polisName: input.polisName ? input.polisName.value : '',
            },
        };
        props.handleRequest('update', json);
        setaddNew(false);
    };

    const [addNew, setaddNew] = React.useState(false);

    const [newRecord, setnewRecord] = React.useState({
        insuranceType: '',
        polisNumber: '',
        polisName: '',
        insurer: '',
        remark: '',
        ID: '',
    });

    React.useEffect(() => {
        if (props.form.accountData && props.form.accountData.importantList) {
            let data = props.form.accountData.importantList.filter((item) => {
                return item.insurance;
            });
            let insurance = [];
            data.map((item) => {
                insurance.push({
                    insuranceType: item.insurance.insuranceType,
                    insurer: item.insurance.insurer,
                    polisNumber: item.insurance.polisNumber,
                    polisName: item.insurance.polisName,
                    ID: item.system.id,
                    remark: item.meta.additionalInfo,
                });
            });
            insurance.sort(function (x, y) {
                let a = x.insuranceType,
                    b = y.insuranceType;
                return a == b ? 0 : a > b ? 1 : -1;
            });
            setpersonalData(insurance);
        } else {
            setpersonalData([]);
        }
    }, [props.form.accountData && props.form.accountData.importantList]);

    const changeHandler = (event, personal) => {
        let data = personalData;

        let newData = newRecord;
        newData[event.target.name] = event.target.value;
        setnewRecord(newData);
        setpersonalData(JSON.parse(JSON.stringify(data)));
    };

    const showAddInsurance = (finance) => {
        setaddNew(true);
        if (finance) {
            let newData = {};
            Object.keys(finance).map((item) => {
                newData[item] = finance[item];
            });
            setnewRecord(newData);
            props.addAttribute({
                name: 'selectedInsurance',
                value: finance,
            });
        } else {
            setnewRecord({
                insuranceType: '',
                polisNumber: '',
                polisName: '',
                insurer: '',
                remark: '',
                ID: '',
            });
            props.addAttribute({
                name: 'selectedInsurance',
                value: {},
            });
        }
    };
    const hideEdit = () => {
        setaddNew(false);
    };
    const [personalData, setpersonalData] = React.useState([]);

    return (
      <React.Fragment key={props.key}>
            {addNew === true ? (
                <Dialog
                    open={addNew}
                    fullScreen={props.fullScreen}
                    disableEscapeKeyDown={true}
                    onClose={hideEdit}
                    aria-labelledby='responsive-dialog-title'
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                    >
                        <div
                            className='mm-form-container'
                        >
                            <div className='mm-login-container' style={{ boxShadow: 'none' }}>
                                <div className='mm-login-header'>
                                    {props.form.selectedInsurance && props.form.selectedInsurance.ID
                                        ? props.form.language.INSURANCE_EDIT
                                        : props.form.language.INSURANCE_ADD}
                                </div>
                                <div>
                                    <div className='login-field-label login-field-label-height'>
                                        {props.form.language.INSURANCE_Q1}
                                    </div>
                                    <div className='login-field-input'>
                                        <select
                                            className='mm-field-input '
                                            autoComplete="off"
                                            name={'insuranceType'}
                                            value={newRecord.insuranceType}
                                            onChange={(event) => changeHandler(event, newRecord)}
                                        >
                                            <option value=''>
                                                {props.form.language.SELECT_OPTION}
                                            </option>
                                            <option value='travel'>
                                                {props.form.language.POLICY_TRAVEL}
                                            </option>
                                            <option value='life'>
                                                {props.form.language.POLICY_LIFE}
                                            </option>
                                            <option value='car'>
                                                {props.form.language.POLICY_CAR}
                                            </option>
                                            <option value='household'>
                                                {props.form.language.HOUSE_LABEL}
                                            </option>
                                            <option value='fire'>
                                                {props.form.language.POLICY_FIRE}
                                            </option>
                                            <option value='glass'>
                                                {props.form.language.POLICY_GLASS}
                                            </option>
                                            <option value='liability'>
                                                {props.form.language.POLICY_LIABILITY}
                                            </option>
                                            <option value='other'>
                                                {props.form.language.TYPE_OTHER}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <div className='login-field-label login-field-label-height'>
                                        {props.form.language.POLICY_INSURER}
                                    </div>
                                    <div className='login-field-input'>
                                        <input
                                            type='text'
                                            autoComplete="off"
                                            placeholder={props.form.language.POLICY_INSURER}
                                            name='insurer'
                                            value={newRecord.insurer}
                                            onChange={(event) => changeHandler(event, newRecord)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className='login-field-label login-field-label-height'>
                                        {props.form.language.POLICY_NAME}
                                    </div>
                                    <div className='login-field-input'>
                                        <input
                                            type='text'
                                            autoComplete="off"
                                            placeholder={props.form.language.POLICY_NAME}
                                            name='polisName'
                                            value={newRecord.polisName}
                                            onChange={(event) => changeHandler(event, newRecord)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className='login-field-label login-field-label-height'>
                                        {props.form.language.POLICY_NUMBER}
                                    </div>
                                    <div className='login-field-input'>
                                        <input
                                            type='text'
                                            autoComplete="off"
                                            placeholder={props.form.language.POLICY_NUMBER}
                                            name='polisNumber'
                                            value={newRecord.polisNumber}
                                            onChange={(event) => changeHandler(event, newRecord)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className='login-field-label login-field-label-height'>
                                        {props.form.language.EXTRA_INFO}
                                    </div>
                                    <div className='login-field-input'>
                                        <textarea
                                            style={{ height: 'auto' }}
                                            rows='4'
                                            type='text'
                                            autoComplete="off"
                                            placeholder={props.form.language.EXTRA_INFO}
                                            name='remark'
                                            value={newRecord.remark}
                                            onChange={(event) => changeHandler(event, newRecord)}
                                        />
                                    </div>
                                </div>
                                <div
                                    className='mm-input-submit'
                                    style={{
                                        display: 'flex',
                                        marginBottom: '15px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    
                                    <button
                                        style={{
                                            textAlign: 'center',
                                            width: '150px',
                                            marginTop: '24px',
                                            marginRight: '20px',
                                        }}
                                        className='primary-button-blue'
                                        onClick={() => {
                                            saveEwill(newRecord);
                                            hideEdit();
                                        }}
                                    >
                                        {props.form.language.SAVE}
                                    </button>
                                    <button
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '24px',
                                            
                                        }}
                                        className='secondary-button'
                                        onClick={hideEdit}
                                    >
                                        {props.form.language.CANCEL}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            ) : (
                ''
            )}
            {personalData && personalData.length > 0
                ? personalData.map((data) => {
                      return (
                          <InsuranceOverview
                          key={`insurance${data.ID}`}
                              data={data}
                              showAddInsurance={() => showAddInsurance(data)}
                              handleRequest={(type, data) => props.handleRequest(type, data)}
                          />
                      );
                  })
                : ''}
            <span
                id={props.type}
                onClick={(event) => {
                    showAddInsurance();
                }}
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '8px',
                }}
            >
                <span className=''>
                    <span className='ewill-secondary-alert-sm'>
                        <AddIcon />
                    </span>
                </span>
                <span className='momentio-text-normal' style={{ marginLeft: '15px' }}>
                    {personalData.length > 0
                        ? props.form.language.INSURANCE_ADD_MULTIPLE
                        : props.form.language.INSURANCE_ADD_SINGLE}
                </span>
            </span>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Insurance);
