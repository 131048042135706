/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { addAttribute } from "../../Actions/Form";
import {
    useMutationAPICallMutation,
    useGetAPICallQuery,
} from "../../app/services/account";
import { model } from "../../Models/Media";
import AddTagsSM from "../Common/AddTagsSM";

const Financial = (props) => {
    const theme = useTheme();
    const [mediaId, setMediaId] = React.useState("");
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const mediaList = useGetAPICallQuery(`media/shared/${mediaId}`, {
        //refetchOnMountOrArgChange: true,
        skip: !mediaId,
    });
    const [newRecord, setnewRecord] = React.useState({
        fileName: props.file?.name,
        mediaContentType: "",
        tags: [],
        name: props.file?.name ? props.file?.name?.split(".")[0] : "",
        description: "",
        ID: "",
    });

    const [options, setOptions] = React.useState(model);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        let newData = JSON.parse(JSON.stringify(options));
        if (props.form.editFile && props.form.editFile.ID) {
            
            let currentTags = props.form.editFile.tags;
            newData.tags.value = [];
            if (currentTags.split(";")?.length > 1) {
                currentTags.split(";").map((tag) => {
                    if (tag) {
                        let filter = newData.tags.options.filter(
                            (item) => item.value === tag
                        )[0];
                        if (!filter) {
                            newData.tags.options.push({
                                label: tag,
                                value: tag,
                            });
                        }
                        newData.tags.value.push(tag);
                    }
                });
            }
            if(props.form.accountData.mediaTags){
                
                props.form.accountData.mediaTags.map(item=> {
                    let filteredTag = newData.tags.options.filter(opt=> item === opt.value)[0];
                    if(!filteredTag && item){
                        newData.tags.options.push({
                            label: item,
                            value: item
                        })
                    }
                })
            }
            setOptions(newData);
            setnewRecord(props.form.editFile);
        }else{
            if(props.form.accountData.mediaTags){
                
                props.form.accountData.mediaTags.map(item=> {
                    let filteredTag = newData.tags.options.filter(opt=> item === opt.value)[0];
                    if(!filteredTag && item){
                        newData.tags.options.push({
                            label: item,
                            value: item
                        })
                    }
                })
            }
            setOptions(newData);
        }
    }, []);

    const [updateAccount, { isLoading }] = useMutationAPICallMutation();

    const changeHandler = (event, personal) => {
        let newData = { ...newRecord };
        newData[event.target.name] = event.target.value;
        setnewRecord(newData);
    };

    const saveFiles = async (data) => {
        let input = {};
        Object.keys(data).map((item) => {
            input[item] = {
                value: data[item],
            };
        });
        if (input.name.value && input.description.value) {
            props.addAttribute({
                name: "isLoading",
                value: true,
            });
            let requestData = {
                url: input.ID.value ? `media/${input.ID.value}` : "media",
                method: input.ID.value ? "PUT" : "POST",
                data: {
                    name: input.name.value,
                    originalFileName: input.fileName?.value,
                },
                meta: {
                    additionalInfo: input.description.value,
                    tags: options.tags.value.join(";") + ";",
                    name: input.name.value,
                    originalFileName: input.fileName?.value,
                },
            };
            
            await updateAccount(requestData)
                .unwrap()
                .then((response) => {
                    if (!input.ID.value) {
                        handleSuccess(response);
                    } else {
                        if(response.system?.id && props.setNewMediaCreated){
                            props.setNewMediaCreated(response.system.id)
                        }
                        setTimeout(() => {
                            props.query.refetch();
                            props.hideEdit();
                            props.addAttribute({
                                name: "editFile",
                                value: {},
                            });
                            props.addAttribute({
                                name: "isLoading",
                                value: false,
                            });
                        }, 100);

                    }
                    
                })
                .catch(() => {
                    props.addAttribute({
                        name: "isLoading",
                        value: false,
                    });
                });
           
        }else{
            alert(props.form.language.NAME + ", " + props.form.language.DESCRIPTION_LABEL + " " + props.form.language.MANDATORY_FIELD)
        }
    };

    const handleSuccess = (response) => {
        var x = {};
        try {
            var file = document.getElementById("uploadFile").files[0];
            console.log(file);
            var xhr = new XMLHttpRequest();
            xhr.open("PUT", response.system.signedUrl, true);
            //xhr.setRequestHeader('Content-Type', 'image/jpeg');
            xhr.onload = () => {
                if (xhr.status === 200 || xhr.state === 204) {
                    console.log("success");

                    props.hideEdit();
                    props.addAttribute({
                        name: "editFile",
                        value: {},
                    });
                    setTimeout(() => {
                        if(response.system?.id && props.setNewMediaCreated){
                            props.setNewMediaCreated(response.system.id)
                        }
                        setTimeout(() => {
                            props.query.refetch();
                            props.addAttribute({
                                name: "isLoading",
                                value: false,
                            });
                        }, 100);
                    }, 300);
                } else if (xhr.status === 403) {
                    alert("Not allowed")
                } else {
                    alert("Server Error")
                }
                props.addAttribute({
                    name: "isLoading",
                    value: false,
                });
            };
            xhr.onerror = () => {
                alert("Error uploading file");
                props.addAttribute({
                    name: "isLoading",
                    value: false,
                });
            };
            xhr.send(file);
        } catch (e) {
            alert("Failed to upload image");
            props.query.refetch();
            props.hideEdit();
            props.addAttribute({
                name: "editFile",
                value: {},
            });
            props.addAttribute({
                name: "isLoading",
                value: false,
            });
        }
        
    };

    return (
        <>
            {(isLoading || loading) && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <Dialog
                open={props.addNew}
                style={{ zIndex: 9999 }}
                fullScreen={fullScreen}
                disableEscapeKeyDown={true}
                onClose={() => props.hideEdit()}
                aria-labelledby="responsive-dialog-title"
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <div
                        className="mm-form-container"
                    >
                        <div
                            className="mm-login-container"
                            style={{ boxShadow: "none" }}
                        >
                            <div className="mm-login-header">
                                {props.form.editFile && props.form.editFile.ID
                                    ? props.form.language.EDIT_FILE
                                    : props.form.language.MYFILE_UPLOAD}
                            </div>

                            {(!props.form.editFile ||
                                !props.form.editFile.ID) && (
                                <div>
                                    <div className="login-field-label login-field-label-height">
                                        {props.form.language.MYFILE_SELECTED}
                                    </div>
                                    <div className="login-field-input ">
                                        <div
                                            className="overviewContainer momentio-text-normal"
                                            style={{
                                                margin: "16px 0 0px 0",
                                                width: "100%",
                                                padding: "12.2px 16px",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{ paddingRight: "10px" }}
                                            >
                                                {newRecord.fileName}
                                            </div>
                                            <CheckCircleIcon className="task-completed" />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div>
                                <div
                                    className="login-field-label login-field-label-height"
                                    style={{
                                        width: "100%",
                                        textAlign: "left",
                                        marginBottom: "16px",
                                    }}
                                >
                                    {props.form.language.SELECT_TAG_FILE}
                                </div>
                                <div>
                                    <AddTagsSM
                                        tags={options.tags.options}
                                        selectedTags={options.tags}
                                        updateTagList={(tags) => {
                                            setOptions({
                                                ...options,
                                                tags: tags,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="login-field-label">
                                    {props.form.language.NAME}
                                </div>
                                <div className="login-field-input">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder={props.form.language.NAME}
                                        name="name"
                                        value={newRecord.name}
                                        onChange={(event) =>
                                            changeHandler(event, newRecord)
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="login-field-label login-field-label-height">
                                    {props.form.language.DESCRIPTION_LABEL}
                                </div>
                                <div className="login-field-input">
                                    <textarea
                                        style={{ height: "auto" }}
                                        rows="4"
                                        autoComplete="off"
                                        placeholder={
                                            props.form.language.ADD_DESCIPTION
                                        }
                                        name="description"
                                        value={newRecord.description}
                                        onChange={(event) =>
                                            changeHandler(event, newRecord)
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <button
                                    style={{
                                        textAlign: "center",
                                        width: "46%",
                                        marginTop: "24px",
                                    }}
                                    className="primary-button-blue"
                                    onClick={() => saveFiles(newRecord)}
                                >
                                    {props.form.language.SAVE}
                                </button>
                                <button
                                    style={{
                                        textAlign: "center",
                                        marginTop: "24px",
                                        width: "46%",
                                    }}
                                    className="secondary-button"
                                    onClick={() => props.hideEdit()}
                                >
                                    {props.form.language.CANCEL}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Financial);
