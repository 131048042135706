/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { connect } from 'react-redux';
import { addAttribute } from '../../../../Actions/Form';
import HouseOverview from './HouseOverview';

const House = (props) => {
    const saveEwill = (data) => {
        let input = {};
        Object.keys(data).map((item) => {
            input[item] = {
                value: data[item],
            };
        });
        let json = {
          url: input.ID.value ? `important/${input.ID.value}` : 'important',
            method: input.ID.value ? 'PUT' : 'POST',
            system: {
                id: input.ID.value,
            },
            general: {
                name: input.name ? input.name.value : '',
                importantType: 'house',
                contactID: input.contactID ? input.contactID.value + ';' : '',
            },
            meta: {
                additionalInfo: input.remark ? input.remark.value : '',
                tags: 'tag1;tag2',
            },
            house: {
                rentee: input.remark ? input.remark.value : '',
                houseType: input.houseType ? input.houseType.value : '',
                mortgager: input.mortgager ? input.mortgager.value : '',
                polisNumber: input.polisNumber ? input.polisNumber.value : '',
            },
        };
        props.handleRequest('update', json);
        setaddNew(false);
    };

    const [addNew, setaddNew] = React.useState(false);

    const [newRecord, setnewRecord] = React.useState({
        houseType: '',
        mortgager: '',
        polisNumber: '',
        name: '',
        rentee: '',
        remark: '',
        contactID: '',
        ID: '',
    });

    React.useEffect(() => {
        if (props.form.accountData && props.form.accountData.importantList) {
            let data = props.form.accountData.importantList.filter((item) => {
                return item.house;
            });
            let house = [];
            data.map((item) => {
                house.push({
                    houseType: item.house.houseType,
                    mortgager: item.house.mortgager,
                    name: item.general.name,
                    polisNumber: item.house.polisNumber,
                    contactID: item.general.contactID,
                    remark: item.general.name,
                    rentee: item.house.rentee,
                    ID: item.system.id,
                });
            });
            house.sort(function (x, y) {
                let a = x.houseType,
                    b = y.houseType;
                return a == b ? 0 : a > b ? 1 : -1;
            });
            setpersonalData(house);
        } else {
            setpersonalData([]);
        }
    }, [props.form.accountData && props.form.accountData.importantList]);

    const changeHandler = (event, personal) => {
        let data = personalData;
        let newData = newRecord;
        newData[event.target.name] = event.target.value;
        setnewRecord(newData);
        setpersonalData(JSON.parse(JSON.stringify(data)));
    };
    const [personalData, setpersonalData] = React.useState([]);

    const showAddHouse = (finance) => {
        setaddNew(true);
        if (finance) {
            let newData = {};
            Object.keys(finance).map((item) => {
                newData[item] = finance[item];
            });
            setnewRecord(newData);
            props.addAttribute({
                name: 'selectedHouse',
                value: finance,
            });
        } else {
            setnewRecord({
                houseType: 'rental',
                mortgager: '',
                polisNumber: '',
                name: '',
                rentee: '',
                remark: '',
                contactID: '',
                ID: '',
            });
            props.addAttribute({
                name: 'selectedHouse',
                value: {},
            });
        }
    };
    const hideEdit = () => {
        setaddNew(false);
    };
    return (
      <React.Fragment key={props.key}>
            {addNew === true && (
                <Dialog
                    open={addNew}
                    fullScreen={props.fullScreen}
                    disableEscapeKeyDown={true}
                    onClose={hideEdit}
                    aria-labelledby='responsive-dialog-title'
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                    >
                        <div
                            className='mm-form-container'
                        >
                            <div className='mm-login-container' style={{ boxShadow: 'none' }}>
                                <div className='mm-login-header'>
                                    {props.form.selectedHouse && props.form.selectedHouse.ID
                                        ? props.form.language.HOUSE_EDIT
                                        : props.form.language.HOUSE_ADD}
                                </div>
                                <div>
                                    <div className='login-field-label login-field-label-height'>
                                        {props.form.language.HOUSE_TYPE}
                                    </div>
                                    <div className='login-field-input'>
                                        <select
                                            className='mm-field-input '
                                            autoComplete="off"
                                            placeholder={props.form.language.HOUSE_TYPE}
                                            name={'houseType'}
                                            value={newRecord.houseType}
                                            onChange={(event) => changeHandler(event, newRecord)}
                                        >
                                            <option value=''>
                                                {props.form.language.SELECT_OPTION}
                                            </option>
                                            <option value='rental'>
                                                {props.form.language.HOUSE_RENTAL}
                                            </option>
                                            <option value='private'>
                                                {props.form.language.HOUSE_OWN}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                {newRecord.houseType === 'rental' && (
                                    <div>
                                        <div className='login-field-label login-field-label-height'>
                                            {props.form.language.LANDLORD_NAME}
                                        </div>
                                        <div className='login-field-input'>
                                            <input
                                                type='text'
                                                autoComplete="off"
                                                placeholder={props.form.language.LANDLORD_NAME}
                                                name='rentee'
                                                value={newRecord.rentee}
                                                onChange={(event) =>
                                                    changeHandler(event, newRecord)
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                {newRecord.houseType === 'private' && (
                                    <div>
                                        <div className='login-field-label login-field-label-height'>
                                            {props.form.language.MORGAGE_COMPANY}
                                        </div>
                                        <div className='login-field-input'>
                                            <input
                                                type='text'
                                                autoComplete="off"
                                                placeholder={props.form.language.MORGAGE_COMPANY}
                                                name='mortgager'
                                                value={newRecord.mortgager}
                                                onChange={(event) =>
                                                    changeHandler(event, newRecord)
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                {newRecord.houseType === 'private' && (
                                    <div>
                                        <div className='login-field-label login-field-label-height'>
                                            {props.form.language.POLICY_NUMBER}
                                        </div>
                                        <div className='login-field-input'>
                                            <input
                                                type='text'
                                                autoComplete="off"
                                                placeholder={props.form.language.POLICY_NUMBER}
                                                name='polisNumber'
                                                value={newRecord.polisNumber}
                                                onChange={(event) =>
                                                    changeHandler(event, newRecord)
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                {newRecord.houseType === 'timeShare' && (
                                    <div>
                                        <div className='login-field-label login-field-label-height'>
                                            {props.form.language.CONTACT_PERSON_TITLE}
                                        </div>
                                        <div className='login-field-input'>
                                            <select
                                                className='mm-field-input '
                                                autoComplete="off"
                                                placeholder={
                                                    props.form.language.CONTACT_PERSON_TITLE
                                                }
                                                name={'contactID'}
                                                value={newRecord.contactID}
                                                onChange={(event) =>
                                                    changeHandler(event, newRecord)
                                                }
                                            >
                                                {props.contactList?.map(
                                                        (contact) => {
                                                            if (contact) {
                                                                return (
                                                                    <option
                                                                        value={contact.system.id}
                                                                    >
                                                                        {contact.privateData
                                                                            .firstName +
                                                                            ' ' +
                                                                            contact.privateData
                                                                                .lastName}
                                                                    </option>
                                                                );
                                                            }
                                                        }
                                                    )}
                                            </select>
                                        </div>
                                    </div>
                                )}
                                <div
                                    className='mm-input-submit'
                                    style={{
                                        display: 'flex',
                                        marginBottom: '15px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    
                                    <button
                                        style={{
                                            textAlign: 'center',
                                            width: '150px',
                                            marginTop: '24px',
                                            marginRight: '20px',
                                        }}
                                        className='primary-button-blue'
                                        onClick={() => {
                                            saveEwill(newRecord);
                                            hideEdit();
                                        }}
                                    >
                                        {props.form.language.SAVE}
                                    </button>
                                    <button
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '24px',
                                            
                                        }}
                                        className='secondary-button'
                                        onClick={hideEdit}
                                    >
                                        {props.form.language.CANCEL}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
            {personalData &&
                personalData.length > 0 &&
                personalData.map((data) => {
                    return (
                        <HouseOverview
                        key={`house${data.ID}`}
                            data={data}
                            showAddHouse={() => showAddHouse(data)}
                            handleRequest={(type, data) => props.handleRequest(type, data)}
                            contactList={props.contactList}
                        />
                    );
                })}
            <span
                id={props.type}
                onClick={(event) => {
                    showAddHouse();
                }}
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '8px',
                }}
            >
                <span className=''>
                    <span className='ewill-secondary-alert-sm'>
                        <AddIcon />
                    </span>
                </span>
                <span className='momentio-text-normal' style={{ marginLeft: '15px' }}>
                    {personalData.length > 0
                        ? props.form.language.HOUSE_ADD_MULTIPLE
                        : props.form.language.HOUSE_ADD_SINGLE}
                </span>
            </span>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(House);
