/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// eslint-disable jsx-a11y/anchor-is-valid
// eslint-disable jsx-a11y/alt-text

import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { connect } from "react-redux";
import { Link, useHistory, withRouter, useParams } from "react-router-dom";
import { forgotPassword, setLanguage } from "../../Actions/Form";
import "../../Constants/decryption";
import "../../Constants/encryption";
import cloud from "../../Images/cloud_PNG24.png";
import * as validator from "../../Utility/validations";
import { updateBrowserLaguage } from "../../Utility/Utility";

const Login = (props) => {
  const history = useHistory();
  const { language } = useParams();  
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if(language) {
      updateBrowserLaguage(language, props);
    }
    
  }, []);

  const [loginData, setLoginData] = React.useState({
    Username: {
      value: "",
      validations: { required: true, email: true },
      valid: false,
    },
    Password: {
      value: "",
      validations: { required: true },
      valid: false,
    },
    code: {
      value: "",
      validations: { required: true },
      valid: false,
    },
  });

  const changeHandler = (event) => {
    let newData = JSON.parse(JSON.stringify(loginData));
    newData[event.target.name].value = event.target.value;
    setLoginData(newData);
    validateField(newData);
  };

  const validateField = (newData) => {
    let validationMsg = "";
    // let newData = JSON.parse(JSON.stringify(loginData));

    Object.keys(newData).map((data) => {
      let validationsObj = newData[data].validations;
      if (validationsObj) {
        let validations = Object.keys(newData[data].validations);
        // loginData[data].validations.map(item=>{
        if (validations.length > 0) {
          for (let i = 0; i < validations.length; i++) {
            let response = validator.validate[validations[i]].call(
              this,
              newData[data].value,
              validationsObj[validations[i]]
            );
            newData[data].valid = response.valid;

            if (response.valid === false) {
              validationMsg = validationMsg + "\n" + data + " " + props.form.language[response.msg];
            }
          }
        }
        // })
      }
    });

    setLoginData(newData);
    return {
      validationMsg: validationMsg,
    };
  };
  return (
    <React.Fragment>
      {props.form.isLoading && (
        <div className="overlay-momentio">
          <CircularProgress style={{ width: "100px", height: "100px" }} />
        </div>
      )}

      <div className="fixed-header-container">
        <div className="header-container container">
          <div className="header-title-section-login">
            <div>
            <a href={props.form.language.MOMENTIO_BASE_URL}>{props.form.language.COMPANY_NAME}</a>
            </div>
          </div>
          <div
            className="header-menu-section header-menu-section-login"
            style={{ justifyContent: "flex-end" }}
          >
            <div className="header-menu-item">
              <button className="primary-button-yellow primary-button-register">
                <Link className="header-menu-item" to="/login">
                  {props.form.language.MAIN_MENU_LOGIN}
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="momentioHome">
        <div
          className="content-container container"
          style={{ marginTop: "70px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "inherit",
              //position: "relative",
            }}
          >
            <img className="momentio-cloud-bg" src={cloud} />
            <div className="mm-form-container" style={{ paddingTop: "30px" }}>
              <div className="mm-login-container">
                <div className="mm-login-header">{"Reset Password"}</div>

                {!props.form.isForgotCode ? (
                  <React.Fragment>
                    <div className="login-field-label login-field-label-height">
                      {props.form.language.LOGIN_S2}
                    </div>
                    <div className="login-field-input">
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder={props.form.language.LOGIN_S2}
                        name="Username"
                        value={loginData.Username.value}
                        onChange={changeHandler}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="login-field-label login-field-label-height">
                      {"Code"}
                    </div>
                    <div className="login-field-input">
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder={"Code"}
                        name="code"
                        value={loginData.code.value}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="login-field-label login-field-label-height">
                      {props.form.language.LOGIN_S3}
                    </div>
                    <div className="login-field-input">
                      <input
                        type="password"
                        autoComplete="off"
                        placeholder={props.form.language.LOGIN_S3}
                        name="Password"
                        value={loginData.Password.value}
                        onChange={changeHandler}
                      />
                    </div>
                  </React.Fragment>
                )}

                <div className="mm-input-submit">
                  <button
                    style={{
                      textAlign: "center",
                      width: "360px",
                      marginTop: "24px",
                    }}
                    className="primary-button-blue"
                    onClick={() => {
                      if (
                        props.form.isForgotCode &&
                        loginData.code.value &&
                        loginData.Password.value
                      ) {
                        props.login(loginData, true, history);
                        window.scrollTo(0, 0);
                      } else if (
                        !props.form.isForgotCode &&
                        loginData.Username.value
                      ) {
                        props.login(loginData, false, history);
                        window.scrollTo(0, 0);
                      } else {
                        alert(props.form.language.INVALID_INPUT);
                      }
                    }}
                  >
                    {props.form.language.LOGIN_S4}
                  </button>
                </div>
              </div>
              <div className="mm-form-links">
                <span className="momentio-blog-link">
                  {" "}
                  <Link to="/login">{props.form.language.MAIN_MENU_LOGIN}</Link>
                </span>
                <span className="momentio-get-start-text">{props.form.language.OR_TEXT}</span>
                <span className="momentio-blog-link">
                  <Link to="/register">{props.form.language.LOGIN_S5}</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload, isSubmitCode, history) =>
      dispatch(forgotPassword(payload, isSubmitCode, history)),
      updateLanguage: (payload) => dispatch(setLanguage(payload)),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
