// eslint-disable no-unused-vars

import { isRejectedWithValue } from '@reduxjs/toolkit';

export const rtkQueryErrorLogger = (api) => (next) => (action, state) => {
    const lang = api?.getState()?.form?.language;
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action, lang) && action.meta.arg.endpointName !== 'logout') {
        HandleResponseError(action, lang);
    }
    return next(action);
};

const logout = () => {
    localStorage.clear();
    window.scrollTo(0, 0);
    setTimeout(() => {
        window.location.href = '/login';
    }, 100);
};

export const HandleResponseError = (response, lang) => {
   /* if(response.payload && response.payload.data && response.payload.data.message){
alert(response.payload.data.message)
    }else if (response.payload.status === 404) {
        alert(lang.unableToLoginErrorMessage);
    } else if (response.payload.status === 500 || response.payload.status === 400) {
        alert(lang.ERROR_SAVING);
    } else if (response.payload.status === 403 && response.meta.arg.endpointName === 'login' ) {
        alert(lang.unableToLoginErrorMessage);
    } else if (response.payload.status === 403 && response.meta.arg.endpointName !== 'login') {
        alert(lang.INVALID_SESSION);
    } else if (response.payload.status === 406 || response.payload.status === 401) {
        alert(lang.INVALID_SESSION);
    } else if (response.meta.arg.endpointName === 'login') {
        alert(lang.unableToLoginErrorMessage);
    } else if (response.meta.arg.endpointName !== 'login') {
        alert(lang.ERROR_SAVING);
    } else {
        alert("Server Error");
    }*/
};
