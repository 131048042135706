/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { addAttribute } from "../../../Actions/Form";
import AuthFooter from "../../Common/AuthFooter";
import AuthHeader from "../../Common/AuthHeader";
import SideMenu from "../../Common/SideMenu";
import Contracts from "./Contracts/Contracts";
import AccessWay from "./AcessWay/AccessWay";
import Financial from "./Financial/Financial";
import House from "./House/House";
import Insurance from "./Insurance/Insurance";
import Membership from "./Subscription/Membership";
import PasswordManager from "./Password/PasswordManager";
import SocialMedia from "./SocialMedia/SocialMedia";
import { sortContactAscending } from "../../../Utility/Utility";
import {
    useGetAPICallQuery,
    useMutationAPICallMutation,
} from "../../../app/services/account";
import Debt from "./Debts/Debt";

const useStyles = makeStyles((theme) => ({
    container: {
        boxShadow: "none !important",
    },
}));

const ImportantInfo = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const importantListQuery = useGetAPICallQuery("important");
    const [skip, setSkip] = React.useState(true);
    const contactList = useGetAPICallQuery("contact", { skip: skip });
    const [updateImportantQuery, { isLoading }] = useMutationAPICallMutation();
    const [deleteImportantQuery, { isLoading: isDeleting }] =
        useMutationAPICallMutation();
    const classes = useStyles();

    const handleRequest = async (type, requestData) => {
        if (type === "update") {
            await updateImportantQuery(requestData)
                .unwrap()
                .then((response) => {
                    importantListQuery.refetch();
                });
        } else {
            await deleteImportantQuery({
                url: `important/${requestData}`,
                method: "DELETE",
            })
                .unwrap()
                .then((response) => {
                    importantListQuery.refetch();
                });
        }
    };

    React.useEffect(() => {
        if (contactList.data) {
            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    contactList: sortContactAscending(
                        contactList.data.contactList
                    ),
                },
            });
        }
    }, [contactList.data]);
    React.useEffect(() => {
        if (importantListQuery.data) {
            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    importantList: importantListQuery.data.importantList,
                },
            });
            setSkip(false);
        }
    }, [importantListQuery.data]);

    const EWillModel = {
        name: props.form.language.IMPORTANT_INFO_LABEL,
        category: "importantInfo",
        completed: 0,
        subtitles: [
            {
                name: props.form.language.PASSWORD_LABEL,
                category: "password",
                scroll: 70,
            },
            {
                name: props.form.language.ACCESS_LABEL,
                category: "accessWay",
                scroll: 70 + 80,
            },
            {
                name: props.form.language.MYCONTACT_S7,
                category: "financial",
                scroll: 70 + 160,
            },
            {
                name: props.form.language.DEBT,
                category: "debt",
                scroll: 70 + 240,
            },
            {
                name: props.form.language.HOUSE_LABEL,
                category: "house",
                scroll: 70 + 320,
            },
            {
                name: props.form.language.INSURANCE_LABEL,
                category: "insurance",
                scroll: 70 + 400,
            },
            {
                name: props.form.language.SUBSCRIPTION_LABEL,
                category: "membership",
                scroll: 70 + 480,
            },
            {
                name: props.form.language.CONTRACT_LABEL,
                category: "contract",
                scroll: 70 + 560,
            },
            { name: "Social Media", category: "socialMedia", scroll: 70 + 640 },
        ],
    };

    const [mainSection, setmainSections] = React.useState(EWillModel);

    const handleChange = (panel) => (event, isExpanded) => {
        // window.scrollTo(panel.scroll);
        window.scrollTo(0, panel.scroll);
        props.addAttribute({
            name: "ewillStep",
            value: isExpanded ? panel.category : false,
        });
    };
    const getCompleted = (isLoad) => {
        if (importantListQuery?.data?.importantList) {
            let data = mainSection;
            let importantInfoCompleted = 0;
            let icompletedList = [];
            data.subtitles[0].completed = false;
            data.subtitles.map((title) => {
                importantListQuery.data.importantList.map((imp) => {
                    if (
                        imp[title.category] &&
                        icompletedList.indexOf(title.category) < 0
                    ) {
                        icompletedList.push(title.category);
                        title.completed = true;
                    } else if (
                        !imp[title.category] &&
                        icompletedList.indexOf(title.category) < 0
                    ) {
                        title.completed = false;
                    }
                });
                if (
                    title.category === "socialMedia" &&
                    props.form.accountData?.profile?.settings
                        ?.hasSocialMedia === "no" &&
                        icompletedList.indexOf(title.category) < 0
                ) {
                    icompletedList.push(title.category);
                    title.completed = true;
                }
            });

            if (props.form.accountData) {
                importantInfoCompleted = Math.round(
                    11 * icompletedList.length + importantInfoCompleted
                );
                data.completed = importantInfoCompleted + (importantInfoCompleted > 0 ? 1 : 0);
            }
            if (isLoad) {
                setmainSections(JSON.parse(JSON.stringify(data)));
            } else {
                return data.completed;
            }
        }
        return 0;
    };

    const getContent = (data) => {
        switch (data.category) {
            case "accessWay":
                return (
                    <AccessWay
                        handleRequest={(type, requestData) =>
                            handleRequest(type, requestData)
                        }
                        contactList={contactList.data?.contactList}
                        key={data.category}
                        fullScreen={fullScreen}
                    />
                );
            case "financial":
                return (
                    <Financial
                        handleRequest={(type, requestData) =>
                            handleRequest(type, requestData)
                        }
                        contactList={contactList.data?.contactList}
                        key={data.category}
                        fullScreen={fullScreen}
                    />
                );
            case "debt":
                return (
                    <Debt
                        handleRequest={(type, requestData) =>
                            handleRequest(type, requestData)
                        }
                        contactList={contactList.data?.contactList}
                        key={data.category}
                        fullScreen={fullScreen}
                    />
                );
            case "password":
                return (
                    <PasswordManager
                        handleRequest={(type, requestData) =>
                            handleRequest(type, requestData)
                        }
                        contactList={contactList.data?.contactList}
                        key={data.category}
                        fullScreen={fullScreen}
                    />
                );
            case "house":
                return (
                    <House
                        handleRequest={(type, requestData) =>
                            handleRequest(type, requestData)
                        }
                        contactList={contactList.data?.contactList}
                        key={data.category}
                        fullScreen={fullScreen}
                    />
                );
            case "insurance":
                return (
                    <Insurance
                        handleRequest={(type, requestData) =>
                            handleRequest(type, requestData)
                        }
                        contactList={contactList.data?.contactList}
                        key={data.category}
                        fullScreen={fullScreen}
                    />
                );
            case "membership":
                return (
                    <Membership
                        handleRequest={(type, requestData) =>
                            handleRequest(type, requestData)
                        }
                        contactList={contactList.data?.contactList}
                        key={data.category}
                        fullScreen={fullScreen}
                    />
                );
            case "socialMedia":
                return (
                    <SocialMedia
                        handleRequest={(type, requestData) =>
                            handleRequest(type, requestData)
                        }
                        contactList={contactList.data?.contactList}
                        key={data.category}
                        fullScreen={fullScreen}
                    />
                );
            case "contract":
                return (
                    <Contracts
                        handleRequest={(type, requestData) =>
                            handleRequest(type, requestData)
                        }
                        contactList={contactList.data?.contactList}
                        key={data.category}
                        fullScreen={fullScreen}
                    />
                );
            default:
                return "";
        }
    };

    return (
        <React.Fragment>
            {(importantListQuery.isLoading ||
                importantListQuery.isFetching ||
                isLoading ||
                isDeleting) && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <AuthHeader />
            <div className="momentio-dashboard-container">
                <SideMenu view="mysafe" />
                <div className="auth-content">
                    <div>
                        <div className="auth-content-subheader">
                            <div className="content-one">
                                {mainSection.name}
                            </div>
                            <div className="mm-login-header">
                                {" "}
                                {props.form.language.COMPLETED_TEXT +
                                    " " +
                                    getCompleted() +
                                    "%"}
                            </div>
                        </div>
                        <div className="ewill">
                            {mainSection.subtitles &&
                                mainSection.subtitles.map((subtitle) => {
                                    if (
                                        subtitle.hidden &&
                                        !props.form.showLastwill
                                    ) {
                                        return (
                                            <React.Fragment></React.Fragment>
                                        );
                                    } else {
                                        return (
                                            <Accordion
                                                className={classes.container}
                                                expanded={
                                                    props.form.ewillStep ===
                                                    subtitle.category
                                                }
                                                onChange={handleChange(
                                                    subtitle
                                                )}
                                                key={`accordian-${subtitle.category}`}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel1bh-content"
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                    id={"panel-moment-header"}
                                                >
                                                    <div className="mm-login-header">
                                                        {subtitle.completed ? (
                                                            <CheckCircleIcon className="task-completed" />
                                                        ) : (
                                                            <CheckCircleIcon className="task-not-completed" />
                                                        )}
                                                        {subtitle.name}
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {getContent(subtitle)}
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    }
                                })}
                        </div>
                    </div>
                </div>
            </div>
            <AuthFooter />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ImportantInfo);
