/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */

import Popper from "@material-ui/core/Popper";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React from "react";
import { connect } from "react-redux";
import {
  addAttribute
} from "../../../../Actions/Form";

const FinancialOverview = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const closePopper = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <div onMouseLeave={closePopper}>
        <React.Fragment>
          {props.form.selectedFinance &&
            props.form.selectedFinance.ID === props.data.ID && (
              <Popper open={open} anchorEl={anchorEl}>
                <div
                  className="menu-container"
                  onMouseLeave={() => closePopper(false)}
                  style={{
                    marginLeft: "-175px",
                    marginTop: "0px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="menu-options"
                    onClick={() => {
                      closePopper("bottom-end");
                      props.showAddFinance(props.data);
                    }}
                  >
                    {props.form.language.EDIT}
                  </div>
                  <div
                    className="menu-options"
                    onClick={() => {
                      closePopper("bottom-end");
                      props.handleRequest("delete", props.data.ID);
                      props.addAttribute({
                        name: "selectedFinance",
                        value: {},
                      });
                    }}
                  >
                    {props.form.language.DELETE}
                  </div>
                </div>
              </Popper>
            )}
        </React.Fragment>

        <div className="overviewContainer">
          <div
            className="overviewMenu"
            onClick={(event) => {
              handleClick(event);
              props.addAttribute({
                name: "selectedFinance",
                value: props.data,
              });
            }}
          >
            <MoreHorizIcon className='show-menu-icon' />
          </div>
          <div
            className="momentio-text-bold"
            style={{ marginTop: "-5px", width: "100%" }}
          >
            <div style={{ display: "flex" }} className="input-container-column">
              <div
                className="accordion-content-option-label header24Medium"
                style={{ marginBottom: "10px" }}
              >
                {props.data.financialType === "alimentation"
                  ? props.form.language.ALIMONY_LABEL
                  : props.data.financialType === "bankAccount"
                  ? props.form.language.BANKING_LABEL
                  : props.data.financialType === "invest"
                  ? props.form.language.INVESTMENT_LABEL
                  : ""}
              </div>
            </div>
            {props.data.financialType === "alimentation" && (
              <div style={{ display: "flex" }} className="input-container-column">
                <div className="momentio-text-normal accordion-content-option-label">
                  {props.form.language.CONTACT_PERSON_TITLE}
                </div>
                <div className="momentio-text-bold" style={{ width: "70%" }}>
                {props.contactList?.map((contact) => {
                      if (contact.system.id == props.data.contactID?.replace(";", "")) {
                        return (
                          <span key={`contact${contact.system.id}`}>
                            {contact.privateData.firstName + " " + contact.privateData.lastName}
                          </span>
                        );
                      }
                    })}
                </div>
              </div>
            )}
            {/*props.data.financialType === "alimentation" && (
              <div style={{ display: "flex" }}>
                <div className="momentio-text-normal accordion-content-option-label">
                  Betaalrekening
                </div>
                <div className="momentio-text-bold" style={{ width: "70%" }}>
                  {props.data.paymentAmount}
                </div>
              </div>
            )*/}
            {props.data.financialType === "alimentation" && (
              <div style={{ display: "flex" }} className="input-container-column">
                <div className="momentio-text-normal accordion-content-option-label">
                  {props.form.language.AMOUNT_PER_MONTH}
                </div>
                <div className="momentio-text-bold" style={{ width: "70%" }}>
                  {props.data.paymentAmount}
                </div>
              </div>
            )}

            {props.data.financialType === "invest" && (
              <div style={{ display: "flex" }} className="input-container-column">
                <div className="momentio-text-normal accordion-content-option-label">
                  {props.form.language.INVESTMENT_DESC}
                </div>
                <div className="momentio-text-bold" style={{ width: "70%" }}>
                  {props.data.nameInvestment}
                </div>
              </div>
            )}

            {props.data.financialType === "invest" && (
              <div style={{ display: "flex" }} className="input-container-column">
                <div className="momentio-text-normal accordion-content-option-label">
                  {props.data.financialType === "invest"  ? props.form.language.INVESTMENT_INFO : props.form.language.DESCRIPTION_LABEL}
                </div>
                <div className="momentio-text-bold" style={{ width: "70%" }}>
                  {props.data.remark}
                </div>
              </div>
            )}
            <div style={{ display: "flex" }} className="input-container-column">
              <div className="momentio-text-normal accordion-content-option-label">
                {props.form.language.BANK_NAME}
              </div>
              <div className="momentio-text-bold" style={{ width: "70%" }}>
                {props.data.name}
              </div>
            </div>

            <div style={{ display: "flex" }} className="input-container-column">
              <div className="momentio-text-normal accordion-content-option-label">
                {props.form.language.ACCOUNT_NUMBER}
              </div>
              <div className="momentio-text-bold" style={{ width: "70%" }}>
                {props.data.accountNumber}
              </div>
            </div>
            {props.data.financialType === "bankAccount" && (
              <div style={{ display: "flex" }} className="input-container-column">
                <div className="momentio-text-normal accordion-content-option-label">
                  {props.data.financialType === "invest"  ? props.form.language.INVESTMENT_INFO : props.form.language.DESCRIPTION_LABEL}
                </div>
                <div className="momentio-text-bold" style={{ width: "70%" }}>
                  {props.data.remark}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addAttribute: (payload) => dispatch(addAttribute(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FinancialOverview);
