/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import * as actionTypes from "../Constants/actionTypes";
import { updateObject } from "../Utility/Utility";

const initialState = {
 
};
const reducer = (state = { ...initialState }, action) => {
  let newState = { ...state };
  switch (action.type) {
    
  }
  return newState;
};

export default reducer;
