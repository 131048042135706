/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './Store/Store';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './Container';
import { getRandomKeyValues, setCookie, setCookieInit } from './Utility/Cookies';
import './Constants/encryption';
import './Constants/decryption';
import './CSS/ewill.css';
import './CSS/input.css';
import './CSS/style.css';
import store from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

let persistor = persistStore(store);

// const store = configureStore();
setCookieInit();

const rootElement = document.getElementById('root');
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <App />
            </Router>
        </PersistGate>
    </Provider>,
    rootElement
);
