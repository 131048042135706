import Popper from "@material-ui/core/Popper";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { addAttribute } from "../../Actions/Form";
import { DeleteContactAPI } from "../Safe/actions";
import {
    useGetAPICallQuery,
    useDeleteAPICallMutation,
} from "../../app/services/account";

const ContactOverview = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const [deleteAccount, { isLoading: isDeleting }] =
        useDeleteAPICallMutation();

    const closePopper = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const getContactData = () => {
        return {
            contactType: "",
            firstName: props.data.privateData.firstName,
            lastName: props.data.privateData.lastName,
            countryISOCode: props.data.physicalAddress.countryISOCode,
            city: props.data.physicalAddress.city,
            zipCode: props.data.physicalAddress.zipCode,
            street: props.data.physicalAddress.street,
            phone: props.data.contact.phone,
            email: props.data.contact.email,
            inform: props.data.settings.inform,
            informType: props.data.settings.informType,
            tags: props.data.meta.tags,
            birthDate: props.data.privateData.birthDate ? moment(
                props.data.privateData.birthDate,
                "YYYY-MM-DD"
            ).format("DD-MM-YYYY"): '',
            //countryISOCode: props.data.settings.countryISOCode,
            ID: props.data.system.id,
            isHeir: props.data.settings.isHeir,
            proof: props.data.privateData.proof
        };
    };

    const handleDelete = async () => {
        closePopper("bottom-end");

        await deleteAccount({
            url: `contact/${props.form.editContact.ID}`,
            method: "DELETE",
        })
            .unwrap()
            .then((response) => {
                props.query.refetch();
            });
        props.addAttribute({
            name: "editContact",
            value: {},
        });
    };

    return (
        <React.Fragment>
          {(isDeleting) && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <div onMouseLeave={closePopper}>
                <React.Fragment>
                    {props.form.editContact &&
                        props.form.editContact.ID === props.data.system.id && (
                            <Popper open={open} anchorEl={anchorEl}>
                                <div
                                    className="menu-container"
                                    onMouseLeave={() => closePopper(false)}
                                    style={{
                                        marginLeft: "-210px",
                                        marginTop: "0px",
                                        cursor: "pointer",
                                        width: "auto",
                                        minWidth: "210px",
                                    }}
                                >
                                    <div
                                        className="menu-options"
                                        onClick={() => {
                                            closePopper("bottom-end");
                                            props.showAddContact(
                                                props.type,
                                                getContactData()
                                            );
                                        }}
                                    >
                                        {`${
                                            props.form.language.EDIT
                                        } ${props.addTitle.toLowerCase()}`}
                                    </div>
                                    <div
                                        className="menu-options"
                                        onClick={() => {
                                            handleDelete();
                                        }}
                                    >
                                        {`${
                                            props.form.language.DELETE
                                        }  ${props.addTitle.toLowerCase()}`}
                                    </div>
                                </div>
                            </Popper>
                        )}
                </React.Fragment>

                <div className="personalOverview">
                    <div
                        style={{
                            position: "absolute",
                            right: "10px",
                            marginTop: "5px",
                            cursor: "pointer",
                        }}
                        className=""
                        onClick={(event) => {
                            props.addAttribute({
                                name: "editContact",
                                value: getContactData(),
                            });

                            handleClick(event);
                        }}
                    >
                        <MoreHorizIcon className="show-menu-icon" />
                    </div>
                    <div className="momentio-text-normal contact-profile-icon">
                        <PermIdentityIcon />
                    </div>
                    <div
                        className="momentio-text-bold"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "-5px",
                            width: "100%",
                        }}
                    >
                        <span
                            style={{
                                display: "inline-block",
                                width:
                                    props.informType === "phone"
                                        ? "55%"
                                        : "90%",
                                marginRight: "3%",
                            }}
                        >
                            {props.data.privateData["firstName"] +
                                " " +
                                props.data.privateData["lastName"]}
                        </span>
                        {props.informType === "phone" && (
                            <span className="hideXS">
                                {props.data.contact.phone}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
        DeleteContactAPI: (input, name) =>
            dispatch(DeleteContactAPI(input, name)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactOverview);
