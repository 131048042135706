/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { accountApi } from './services/account'
import moments from "../Reducers/Momentia";
import form from "../Reducers/Form";
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import {rtkQueryErrorLogger} from './thunk/queryLogger';
import {logout1} from "../Actions/Form";
import * as globalDUT from "../Constants/i18n/dut";
import * as globalENU from "../Constants/i18n/enu";
 
const persistConfig = {
  key: 'root',
  storage,
}

const reducers = combineReducers({
  // Add the generated reducer as a specific top-level slice
  [accountApi.reducerPath]: accountApi.reducer,
  momentia: moments,
  form: form,
});

const reducersinit = combineReducers({
  // Add the generated reducer as a specific top-level slice
  // [accountApi.reducerPath]: accountApi.reducer,
  //momentia: moments,
  form: form,
});
const language = navigator.language || window.navigator.language;

const initialState = {
  activeStep: "",
  isLoading: false,
  errors: {},
  currentLanguage: language ? (language.toUpperCase().includes("nl") ? "NL" : "US") : "NL",
  language: language.includes("nl") ? globalDUT : globalENU,
  success: false,
  activeUser: false,
  hideMobileMenu:true,
  isValid: false,
  showLastwill: true
};
const rootReducer = (state, action) => {
  if(action){
    if (action && action.type === "RESET_FORM") {
      return reducersinit({form: state.form}, action)
    }
  }

  return reducers(state, action);
};


const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rtkQueryErrorLogger, accountApi.middleware),
    serializableCheck: false,
})

setupListeners(store.dispatch);

export default store;
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization