import Popper from "@material-ui/core/Popper";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React from "react";
import { connect } from "react-redux";
import { addAttribute } from "../../../../Actions/Form";

const SubscriptionOverview = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const closePopper = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
      <div onMouseLeave={closePopper} key={`subscription${props.data.ID}`}>
        <React.Fragment>
          {props.form.selectedSubscription &&
            props.form.selectedSubscription.ID === props.data.ID && (
              <Popper open={open} anchorEl={anchorEl}>
                <div
                  className="menu-container"
                  onMouseLeave={() => closePopper(false)}
                  style={{
                    marginLeft: "-175px",
                    marginTop: "0px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="menu-options"
                    onClick={() => {
                      closePopper("bottom-end");
                      props.showAddSubscription(props.data);
                    }}
                  >
                    {props.form.language.EDIT}
                  </div>
                  <div
                    className="menu-options"
                    onClick={() => {
                      closePopper("bottom-end");
                      props.handleRequest("delete", props.data.ID);
                      props.addAttribute({
                        name: "selectedSubscription",
                        value: {},
                      });
                    }}
                  >
                    {props.form.language.DELETE}
                  </div>
                </div>
              </Popper>
            )}
        </React.Fragment>

        <div className="overviewContainer">
          <div
            className="overviewMenu"
            onClick={(event) => {
              handleClick(event);
              props.addAttribute({
                name: "selectedSubscription",
                value: props.data,
              });
            }}
          >
            <MoreHorizIcon className='show-menu-icon' />
          </div>
          <div
            className="momentio-text-bold"
            style={{ marginTop: "-5px", width: "100%" }}
          >
            <div style={{ display: "flex" }} className="input-container-column">
              <div
                className="accordion-content-option-label header24Medium"
                style={{ marginBottom: "10px" }}
              >
                {props.data.membershipType === "sport" 
                  ? props.form.language.SPORTS_TITLE
                  : props.data.membershipType === "cultural"
                  ? props.form.language.CULTURAL_TITLE
                  : props.data.membershipType === "cinema"
                  ? props.form.language.CINEMA_TITLE
                  : props.data.membershipType === "other"
                  ? props.form.language.TYPE_OTHER
                  : ""}
              </div>
            </div>

            <div style={{ display: "flex" }} className="input-container-column">
              <div className="momentio-text-normal accordion-content-option-label">
                {props.form.language.MYCONTACT_S10}
              </div>
              <div className="momentio-text-bold momentio-ewill-value">
                {props.data.externalName}
              </div>
            </div>

            <div style={{ display: "flex" }} className="input-container-column">
              <div className="momentio-text-normal accordion-content-option-label">
                {props.form.language.NUMBER_TEXT}
              </div>
              <div className="momentio-text-bold momentio-ewill-value">
                {props.data.externalNumber}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addAttribute: (payload) => dispatch(addAttribute(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionOverview);
