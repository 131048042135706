/*  eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { loginAccount, addAttribute } from "../Actions/Form";
import AuthHeader from "./Common/AuthHeader";
import SideMenu from "./Common/SideMenu";
import AuthFooter from "./Common/AuthFooter";
import { useGetAPICallQuery } from "../app/services/account";
import Grid from "@material-ui/core/Grid";
import { filterContacts } from "./Contacts/ContactTags";
import { LastWishesModel } from "../Models/LastWishes";
import { GetSuggestions } from "./Suggestions";
import { Redirect, useLocation, Link } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Dashboard = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [skipMessage, setSkipMessage] = useState(true);
    const [skipContact, setSkipContact] = useState(true);
    const [skipMedia, setSkipMedia] = useState(true);
    const [skipImp, setSkipImp] = useState(true);
    const [skipLastwishes, setSkipLastwishes] = useState(true);
    const [skipLegacy, setSkipLegacy] = useState(true);

    useEffect(() => {
        if (props.form.accountData?.profile?.limits?.accessList?.message) {
            setSkipMessage(false);
        }
        if (props.form.accountData?.profile?.limits?.accessList?.contact) {
            setSkipContact(false);
        }
        if (props.form.accountData?.profile?.limits?.accessList?.media) {
            setSkipMedia(false);
        }
        if (props.form.accountData?.profile?.limits?.accessList?.important) {
            setSkipImp(false);
        }
        if (props.form.accountData?.profile?.limits?.accessList?.lastwishes) {
            setSkipLastwishes(false);
        }
        if (props.form.accountData?.profile?.limits?.accessList?.legacy) {
            setSkipLegacy(false);
        }
        getPersonalCompleted();
    }, [props.form.accountData]);
    const messageListQuery = useGetAPICallQuery("message", {
        skip: skipMessage,
    });
    const contacts = useGetAPICallQuery("contact", { skip: skipContact });
    const file = useGetAPICallQuery("media", { skip: skipMedia });
    const importantListQuery = useGetAPICallQuery("important", {
        skip: skipImp,
    });
    const legacyQuery = useGetAPICallQuery("legacy", { skip: skipLegacy });
    const recievedmessageListQuery = useGetAPICallQuery("message/shared", {
        skip: skipMessage,
    });

    const [dashboardSuggestions, setDashboardSuggestions] = useState([]);
    const [personalProgress, setpersonalProgress] = useState(0);
    const [importantProgress, setimportantProgress] = useState(0);

    const orderID = useQuery();

    useEffect(() => {
        props.addAttribute({
            name: "dashboardSuggestions",
            value: dashboardSuggestions,
        });
    }, [dashboardSuggestions]);

    const EWillModel = {
        name: props.form.language.IMPORTANT_INFO_LABEL,
        category: "importantInfo",
        completed: 0,
        subtitles: [
            {
                name: props.form.language.PASSWORD_LABEL,
                category: "password",
                scroll: 70,
            },
            {
                name: props.form.language.ACCESS_LABEL,
                category: "accessWay",
                scroll: 70 + 80,
            },
            {
                name: props.form.language.MYCONTACT_S7,
                category: "financial",
                scroll: 70 + 160,
            },
            {
                name: props.form.language.HOUSE_LABEL,
                category: "house",
                scroll: 70 + 240,
            },
            {
                name: props.form.language.INSURANCE_LABEL,
                category: "insurance",
                scroll: 70 + 320,
            },
            {
                name: props.form.language.SUBSCRIPTION_LABEL,
                category: "membership",
                scroll: 70 + 400,
            },
            {
                name: props.form.language.CONTRACT_LABEL,
                category: "contract",
                scroll: 70 + 480,
            },
            { name: "Social Media", category: "socialMedia", scroll: 70 + 540 },
        ],
    };
    const ewillListQuery = useGetAPICallQuery("lastwishes", {
        skip: skipLastwishes,
    });

    const getCompleted = (isLoad) => {
        //last wishes completed
        let data = { ...LastWishesModel };
        let willcompletedList = [];
        let ewillcompleted = 0;
        //data.subtitles[2].completed = false;
        let isFuneralPolicy = "";
        if (
            ewillListQuery.data &&
            ewillListQuery.data &&
            ewillListQuery.data.funeralPolis
        ) {
            willcompletedList.push("lwFuneralPolis");
            isFuneralPolicy = ewillListQuery.data.funeralPolis.recordLastWishes;
            data.subtitles[0].completed = true;

            if (ewillListQuery.data.funeralPolis.recordLastWishes) {
                data.subtitles[1].completed = true;
            }
        }
        if (
            contacts.data &&
            contacts.data.contactList &&
            contacts.data.contactList.length > 0
        ) {
            willcompletedList.push("lwNotification");
            data.subtitles[2].completed = true;
        } else {
            data.subtitles[2].completed = false;
        }
        if (ewillListQuery.data) {
            const lastWishes = ewillListQuery.data;
            if (lastWishes.organisation.organizer) {
                willcompletedList.push("lwOrganisation");
                data.subtitles[3].completed = true;
            } else {
                data.subtitles[3].completed = false;
            }
            if (lastWishes.funeralType.typeOfFuneral) {
                willcompletedList.push("lwFuneralType");
                data.subtitles[4].completed = true;
            } else {
                data.subtitles[4].completed = false;
            }
            if (lastWishes.music.musicChoice) {
                willcompletedList.push("lwMusic");
                data.subtitles[5].completed = true;
            } else {
                data.subtitles[5].completed = false;
            }
            if (lastWishes.speaker.speakerChoice) {
                willcompletedList.push("lwSpeaker");
                data.subtitles[6].completed = true;
            } else {
                data.subtitles[6].completed = false;
            }
            if (lastWishes.transportation.funeralProcessionChoice) {
                willcompletedList.push("lwTransportation");
                data.subtitles[7].completed = true;
            } else {
                data.subtitles[7].completed = false;
            }
            if (lastWishes.carriers.pallBearersChoice) {
                willcompletedList.push("lwCarriers");
                data.subtitles[8].completed = true;
            } else {
                data.subtitles[8].completed = false;
            }
            if (lastWishes.flowers.flowersOnCoffin) {
                willcompletedList.push("lwFlowers");
                data.subtitles[9].completed = true;
            } else {
                data.subtitles[9].completed = false;
            }
            if (lastWishes.meal.cofeeTableChoice) {
                willcompletedList.push("lwMeal");
                data.subtitles[10].completed = true;
            } else {
                data.subtitles[10].completed = false;
            }
            if (lastWishes.memories.remembrancePoems) {
                willcompletedList.push("lwMemories");
                data.subtitles[11].completed = true;
            } else {
                data.subtitles[11].completed = false;
            }
            if (lastWishes.media.allowVideo) {
                willcompletedList.push("lwMedia");
                data.subtitles[12].completed = true;
            } else {
                data.subtitles[12].completed = false;
            }
        }

        if (isFuneralPolicy && isFuneralPolicy === "no") {
            data.completed = 100;
            ewillcompleted = 100;
        } else {
            ewillcompleted = Math.round(
                7.6 * willcompletedList.length + ewillcompleted
            );
            data.completed = ewillcompleted;
        }
        //setDashboardSuggestions((prev) => (...prev, lastWishes: {...prev.lastwishes, percent: ewillcompleted }))
        return ewillcompleted;
    };

    useEffect(() => {
        getImportantCompleted();
        getPersonalCompleted();
        getLastWishesSuggestion();
        getContactSuggestion();
        getContractsSuggestion();
        getMessagesSuggestion();
    }, [
        (importantListQuery?.data?.importantList &&
            contacts?.data?.contactList &&
            file?.data?.mediaList &&
            props.form.accountData?.profile) ||
            messageListQuery?.data?.messageList ||
            recievedmessageListQuery?.data?.messageList,
    ]);
    const getImportantCompleted = () => {
        if (importantListQuery?.data?.importantList) {
            let importantInfoCompleted = 0;
            let icompletedList = [];
            if (importantListQuery?.data?.importantList?.length) {
                EWillModel.subtitles.map((title) => {
                    importantListQuery.data.importantList.map((imp) => {
                        if (
                            imp[title.category] &&
                            icompletedList.indexOf(title.category) < 0
                        ) {
                            icompletedList.push(title.category);
                        }
                        if (
                            title.category === "socialMedia" &&
                            props.form.accountData?.profile?.settings
                                ?.hasSocialMedia === "no" &&
                            icompletedList.indexOf(title.category) < 0
                        ) {
                            icompletedList.push(title.category);
                        }
                    });
                });
            } else {
                if (
                    props.form.accountData?.profile?.settings
                        ?.hasSocialMedia === "no" &&
                    icompletedList.indexOf("socialMedia") < 0
                ) {
                    icompletedList.push("socialMedia");
                }
            }

            importantInfoCompleted = Math.round(
                11 * icompletedList.length + importantInfoCompleted
            );
            importantInfoCompleted =
                importantInfoCompleted + (importantInfoCompleted > 0 ? 1 : 0);
            if (importantInfoCompleted < 100) {
                setDashboardSuggestions((prev) => [
                    ...prev,
                    {
                        name: "important",
                        completed: importantInfoCompleted,
                        message:
                            props.form.language.DASHBOARD_NOTI_IMPORTANT_INFO,
                        title: props.form.language.DASHBOARD_S24,
                        link: "importantInfo",
                    },
                ]);
            }

            setimportantProgress(importantInfoCompleted);
        }
    };

    const getPersonalCompleted = (isLoad) => {
        let personalCompleted = 0;
        let profileData = {};
        let pcompletedList = [];
        if (
            props.form.accountData &&
            props.form.accountData.profile &&
            contacts.data &&
            contacts.data.contactList
        ) {
            profileData = props.form.accountData.profile;
            personalCompleted = personalCompleted + 100 / 7;

            if (
                contacts.data &&
                contacts.data.contactList &&
                contacts.data.contactList.length > 0
            ) {
                contacts.data.contactList.map((contact) => {
                    if (filterContacts("partner", contact.meta.tags)) {
                        //   personalCompleted = personalCompleted + 20;
                        if (pcompletedList.indexOf("partner") < 0) {
                            pcompletedList.push("partner");
                        }
                    } else if (filterContacts("child", contact.meta.tags)) {
                        if (pcompletedList.indexOf("child") < 0) {
                            pcompletedList.push("child");
                        }
                    } else if (filterContacts("parent", contact.meta.tags)) {
                        if (pcompletedList.indexOf("parent") < 0) {
                            pcompletedList.push("parent");
                        }
                    } else if (filterContacts("guardian", contact.meta.tags)) {
                        if (pcompletedList.indexOf("guardian") < 0) {
                            pcompletedList.push("guardian");
                        }
                    } else if (filterContacts("admin", contact.meta.tags)) {
                        if (pcompletedList.indexOf("admin") < 0) {
                            pcompletedList.push("admin");
                        }
                    } else if (filterContacts("testamentary executioner", contact.meta.tags)) {
                        if (pcompletedList.indexOf("testamentary executioner") < 0) {
                            pcompletedList.push("testamentary executioner");
                        }
                    }
                });
            }
            if (profileData && profileData?.settings?.hasPartner === "no") {
                //   personalCompleted = personalCompleted + 20;
                if (pcompletedList.indexOf("partner") < 0) {
                    pcompletedList.push("partner");
                }
            }
            if (profileData && profileData?.settings?.hasChildren === "no") {
                if (pcompletedList.indexOf("child") < 0) {
                    pcompletedList.push("child");
                }
                //  personalCompleted = personalCompleted + 20;
            }
            if (profileData && profileData?.settings?.hasParents === "no") {
                if (pcompletedList.indexOf("parent") < 0) {
                    pcompletedList.push("parent");
                }
                //  personalCompleted = personalCompleted + 20;
            }
            if (profileData && profileData?.settings?.hasGuardian === "no") {
                if (pcompletedList.indexOf("guardian") < 0) {
                    pcompletedList.push("guardian");
                }
            }
            if (profileData && profileData?.settings?.hasCareGivers === "no") {
                if (pcompletedList.indexOf("admin") < 0) {
                    pcompletedList.push("admin");
                }
            }

            personalCompleted =
                (100 / 6) * pcompletedList.length + personalCompleted;
            if (personalCompleted < 100 && profileData?.settings) {
                setDashboardSuggestions((prev) => [
                    ...prev,
                    {
                        name: "personal",
                        completed: parseInt(personalCompleted),
                        title: props.form.language.DASHBOARD_S27,
                        message:
                            props.form.language.DASHBOARD_NOTI_PERSONAL_INFO,
                        link: "personalwish",
                    },
                ]);
            }

            setpersonalProgress(parseInt(personalCompleted));
        }
    };

    const getContactSuggestion = () => {
        if (contacts?.data?.contactList) {
            var addressExists = true;
            var phoneExistis = true;

            contacts.data.contactList.map((item) => {
                if (
                    !item.physicalAddress.city &&
                    !item.physicalAddress.zipCode &&
                    !item.physicalAddress.street
                ) {
                    addressExists = false;
                }
                if (!item.contact.phone) {
                    phoneExistis = false;
                }
            });
            if (contacts?.data?.contactList.length < 1) {
                setDashboardSuggestions((prev) => [
                    ...prev,
                    {
                        name: "contact",
                        link: "allContacts",
                        title: props.form.language.DASHBOARD_S25,
                        message:
                            props.form.language.DASHBOARD_NOTIFICATION_CONTACT,
                    },
                ]);
            }
            if (!addressExists) {
                setDashboardSuggestions((prev) => [
                    ...prev,
                    {
                        name: "contactAddress",
                        title: props.form.language.DASHBOARD_S25,
                        link: "allContacts",
                        message:
                            props.form.language
                                .DASHBOARD_NOTIFICATION_CONTACT_ADDRESS,
                    },
                ]);
            }
            if (!phoneExistis) {
                setDashboardSuggestions((prev) => [
                    ...prev,
                    {
                        name: "contactPhone",
                        title: props.form.language.DASHBOARD_S25,
                        link: "allContacts",
                        message:
                            props.form.language
                                .DASHBOARD_NOTIFICATION_CONTACT_PHONE,
                    },
                ]);
            }
        }
    };

    const getContractsSuggestion = () => {
        var contractsExists = true;
        if (file?.data?.mediaList?.length > 0) {
            file.data.mediaList.map((item) => {
                if (!item.meta.tags.includes("contract")) {
                    contractsExists = false;
                }
            });
        } else if (file?.data?.mediaList?.length === 0) {
            setDashboardSuggestions((prev) => [
                ...prev,
                {
                    name: "file",
                    title: props.form.language.DASHBOARD_S19,
                    link: "files",
                    message: props.form.language.DASHBOARD_NOTIFICATION_FILE,
                },
            ]);
        }
        if (!contractsExists) {
            setDashboardSuggestions((prev) => [
                ...prev,
                {
                    name: "contracts",
                    title: props.form.language.DASHBOARD_S19,
                    link: "files",
                    message:
                        props.form.language.DASHBOARD_NOTIFICATION__FIRST_FILE,
                },
            ]);
        }
    };

    const getLastWishesSuggestion = () => {
        var lastwishesExists = true;
        if (file?.data?.mediaList?.length > 0) {
            file.data.mediaList.map((item) => {
                if (!item.meta.tags.includes("lastwishes")) {
                    lastwishesExists = false;
                }
            });
        }
        if (!lastwishesExists) {
            setDashboardSuggestions((prev) => [
                ...prev,
                {
                    name: "lastwishes",
                    message: props.form.language.DASHBOARD_NOTI_FILE_UPLOAD,

                    title: props.form.language.DASHBOARD_S19,
                    link: "files",
                },
            ]);
        }
    };
    const getMessagesSuggestion = () => {
        if (messageListQuery?.data?.messageList?.length === 0) {
            setDashboardSuggestions((prev) => [
                ...prev,
                {
                    name: "message",
                    title: props.form.language.DASHBOARD_S26,
                    link: "messages",
                    message: props.form.language.DASHBOARD_NOTI_MESSAGE,
                },
            ]);
        }
        if (messageListQuery?.data?.messageList?.length === 1) {
            setDashboardSuggestions((prev) => [
                ...prev,
                {
                    name: "secondmessage",
                    title: props.form.language.DASHBOARD_S26,
                    link: "messages",
                    message: props.form.language.DASHBOARD_NOTI_MESSAGE_SECOND,
                },
            ]);
        }
        if (messageListQuery?.data?.messageList?.length === 2) {
            setDashboardSuggestions((prev) => [
                ...prev,
                {
                    name: "thirdmessage",
                    title: props.form.language.DASHBOARD_S26,
                    link: "messages",
                    message: props.form.language.DASHBOARD_NOTI_MESSAGE_THIRD,
                },
            ]);
        }
    };
    return (
        <React.Fragment>
            {(messageListQuery.isLoading ||
                importantListQuery.isLoading ||
                file.isLoading ||
                contacts.isLoading) && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            {orderID.get("orderResult") && (
                <Redirect
                    to={`/subscriptionresult/?orderResult=${orderID.get(
                        "orderResult"
                    )}`}
                />
            )}
            <AuthHeader />
            <div className="momentio-dashboard-container">
                <SideMenu view="mysafe" />
                <div className="auth-content">
                    <div className="auth-dashboard-subheader">
                        <div className="content-one">
                            {props.form.language.DASHBOARD_S1}
                        </div>
                        <div className="dashboard-description">
                            {props.form.language.DASHBOARD_S2}
                        </div>
                    </div>

                    <div>
                        <Grid container className="momentio-dashboard-grid">
                            <Grid
                                xs="12"
                                sm="12"
                                lg="12"
                                item
                                className="auth-dashboard-subheader"
                            >
                                {props.form?.dashboardSuggestions?.length >
                                    0 && (
                                    <GetSuggestions
                                        suggestions={
                                            props.form?.dashboardSuggestions
                                        }
                                        setDashboardSuggestions={(e) =>
                                            setDashboardSuggestions(e)
                                        }
                                        form={props.form}
                                    />
                                )}
                                {
                                    //getSuggestions(dashboardSuggestions)
                                }
                            </Grid>
                            <Grid
                                xs="12"
                                sm="12"
                                lg="6"
                                item
                                className="auth-dashboard-subheader"
                            >
                                <div className="dashboard-overview-container dashboard-percentage">
                                    <div
                                        className="n_header-2"
                                        style={{
                                            width: "auto",
                                            height: "auto",
                                            paddingBottom: "11px",
                                        }}
                                    >
                                        {props.form.language.PROGRESS}
                                    </div>
                                    <div className="dahsboard-progress">
                                        <div className="n_header-4">
                                            <Link to="/personalwish">
                                                {" "}
                                                {
                                                    props.form.language
                                                        .DASHBOARD_S27
                                                }{" "}
                                            </Link>
                                        </div>

                                        <div
                                            className="n_header-4"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div>{`${personalProgress}%`}</div>
                                            <div className="w3-border">
                                                <div
                                                    className="w3-grey"
                                                    style={{
                                                        width: `${personalProgress}%`,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dahsboard-progress">
                                        <div className="n_header-4">
                                            {props.form.accountData?.profile
                                                ?.limits?.accessList
                                                ?.important ? (
                                                <Link to="/importantInfo">
                                                    {" "}
                                                    {
                                                        props.form.language
                                                            .IMPORTANT_INFO_LABEL
                                                    }{" "}
                                                </Link>
                                            ) : (
                                                props.form.language
                                                    .IMPORTANT_INFO_LABEL
                                            )}
                                        </div>

                                        <div
                                            className="n_header-4"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div>{`${importantProgress}%`}</div>
                                            <div className="w3-border">
                                                <div
                                                    className="w3-grey"
                                                    style={{
                                                        width: `${importantProgress}%`,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dahsboard-progress">
                                        <div className="n_header-4">
                                            {props.form.accountData?.profile
                                                ?.limits?.accessList
                                                ?.lastwishes ? (
                                                <Link to="/lastwishes">
                                                    {" "}
                                                    {
                                                        props.form.language
                                                            .FOOTER_S3
                                                    }{" "}
                                                </Link>
                                            ) : (
                                                props.form.language.FOOTER_S3
                                            )}
                                        </div>

                                        <div
                                            className="n_header-4"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div>{getCompleted() + "%"}</div>
                                            <div className="w3-border">
                                                <div
                                                    className="w3-grey"
                                                    style={{
                                                        width: `${getCompleted()}%`,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dahsboard-progress-last">
                                        <div className="n_header-4">
                                            {props.form.accountData?.profile
                                                ?.limits?.accessList?.legacy ? (
                                                <Link to="/legacy">
                                                    {" "}
                                                    {
                                                        props.form.language
                                                            .LEGACY_TITLE
                                                    }{" "}
                                                </Link>
                                            ) : (
                                                props.form.language.LEGACY_TITLE
                                            )}
                                        </div>

                                        <div
                                            className="n_header-4"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div>
                                                {legacyQuery?.data?.legacyList
                                                    ?.length > 0
                                                    ? "100%"
                                                    : "0%"}
                                            </div>
                                            <div className="w3-border">
                                                <div
                                                    className="w3-grey"
                                                    style={{
                                                        width: `${
                                                            legacyQuery?.data
                                                                ?.legacyList
                                                                ?.length > 0
                                                                ? "100%"
                                                                : "0%"
                                                        }`,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                xs="12"
                                sm="12"
                                lg="6"
                                item
                                className="auth-dashboard-subheader"
                            >
                                <div className="dashboard-overview-container">
                                    <div
                                        className="n_header-2"
                                        style={{
                                            width: "auto",
                                            height: "auto",
                                            paddingBottom: "11px",
                                        }}
                                    >
                                        {props.form.language.YOUR_DATA}
                                    </div>
                                    <div className="dahsboard-progress">
                                        <div className="n_header-4">
                                            {props.form.accountData?.profile
                                                ?.limits?.accessList
                                                ?.contact ? (
                                                <Link to="/allContacts">
                                                    {" "}
                                                    {
                                                        props.form.language
                                                            .DASHBOARD_S25
                                                    }{" "}
                                                </Link>
                                            ) : (
                                                props.form.language
                                                    .DASHBOARD_S25
                                            )}
                                        </div>

                                        <div className="n_header-4">
                                            {contacts?.data?.contactList
                                                ?.length || 0}
                                        </div>
                                    </div>
                                    <div className="dahsboard-progress">
                                        <div className="n_header-4">
                                            {props.form.accountData?.profile
                                                ?.limits?.accessList?.media ? (
                                                <Link to="/files">
                                                    {" "}
                                                    {
                                                        props.form.language
                                                            .DASHBOARD_S19
                                                    }{" "}
                                                </Link>
                                            ) : (
                                                props.form.language
                                                    .DASHBOARD_S19
                                            )}
                                        </div>

                                        <div className="n_header-4">
                                            {file?.data?.mediaList?.length || 0}
                                        </div>
                                    </div>
                                    <div className="dahsboard-progress">
                                        <div className="n_header-4">
                                            {props.form.accountData?.profile
                                                ?.limits?.accessList
                                                ?.message ? (
                                                <Link to="/messages">
                                                    {" "}
                                                    {
                                                        props.form.language
                                                            .SENT_MESSAGE_TITLE
                                                    }{" "}
                                                </Link>
                                            ) : (
                                                props.form.language
                                                    .SENT_MESSAGE_TITLE
                                            )}
                                        </div>

                                        <div className="n_header-4">
                                            {messageListQuery?.data?.messageList
                                                ?.length || 0}
                                        </div>
                                    </div>
                                    <div className="dahsboard-progress-last">
                                        <div className="n_header-4">
                                            {props.form.accountData?.profile
                                                ?.limits?.accessList
                                                ?.message ? (
                                                <Link to="/recievedMessage">
                                                    {" "}
                                                    {
                                                        props.form.language
                                                            .RECIEVED_MESSAGE_TITLE
                                                    }{" "}
                                                </Link>
                                            ) : (
                                                props.form.language
                                                    .RECIEVED_MESSAGE_TITLE
                                            )}
                                        </div>

                                        <div className="n_header-4">
                                            {recievedmessageListQuery?.data
                                                ?.messageList?.length || 0}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <div
                            className="text4 momentio-home-tips"
                            style={{
                                justifyContent: "space-between",
                            }}
                        >
                            {/*messageListQuery.data &&
                                messageListQuery.data.messageList &&
                                messageListQuery.data.messageList.length <
                                    1 && (
                                    <div
                                        className="momentio-home-tips-container sunset-blue-tile"
                                        style={{
                                            width: "30%",
                                        }}
                                    >
                                        <div
                                            className="momentio-tips-content-container"
                                            style={{
                                                padding: "25px 25px 30px 25px",
                                            }}
                                        >
                                            <div
                                                className="MuiTypography-root contacts-table-header MuiTypography-body1"
                                                style={{ marginBottom: "12px" }}
                                            >
                                                {
                                                    props.form.language
                                                        .DASHBOARD_S3
                                                }
                                            </div>
                                            <div className="content-one">
                                                {
                                                    props.form.language
                                                        .DASHBOARD_S4
                                                }
                                            </div>
                                            <div className="n_header-4">
                                                {
                                                    props.form.language
                                                        .DASHBOARD_S5
                                                }{" "}
                                            </div>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "15px",
                                                }}
                                            >
                                                <button
                                                    className="primary-button-yellow primary-button-register"
                                                    style={{
                                                        width: "100%",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {" "}
                                                    <Link
                                                        to="/messages"
                                                        onClick={() => {
                                                            props.addAttribute({
                                                                name: "currentView",
                                                                value: "messages",
                                                            });
                                                            props.addAttribute({
                                                                name: "futureFormPopup",
                                                                value: true,
                                                            });
                                                        }}
                                                    >
                                                        {
                                                            props.form.language
                                                                .DASHBOARD_S6
                                                        }
                                                    </Link>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                                    )*/}
                        </div>
                    </div>
                    <div>
                        <div className="text4 momentio-home-tips"></div>
                    </div>
                </div>
            </div>
            <AuthFooter />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(loginAccount(payload)),
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
