/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import { connect } from 'react-redux';

const Flowers = (props) => {
    const [personalData, setpersonalData] = React.useState({});
    const [isEdit, setEdit] = React.useState(false);
    const saveEwill = () => {
        let json = {
            url: 'lastwishes',
            mode: 'merge',
            method: props.form.accountData.lastwishes.funeralPolis ? 'PUT' : 'POST',
            ...props.form.accountData.lastwishes,
            flowers: {
                dontWant: personalData.dontWant,
                graveFlowerType: personalData.graveFlowerType,
                coffinFlowerType: personalData.coffinFlowerType,
                flowersOnGrave: personalData.flowersOnGrave,
                flowersOnCoffin: personalData.flowersOnCoffin,
                additionalInfo: personalData.additionalInfo,
            },
        };
        props.handleRequest('update', json);
        setEdit(false);
    };
    const deleteEwill = () => {
        let data = {...props.form.accountData.lastwishes};
        delete data.flowers;
        let json = {
            url: 'lastwishes',
            mode: 'overwrite',
            method: 'PUT',
            ...data,
        };
        props.handleRequest('update', json, 'flowers');
    };

    React.useEffect(() => {
        if (
            props.form.accountData &&
            props.form.accountData.lastwishes &&
            props.form.accountData.lastwishes.flowers &&
            props.form.accountData.lastwishes.flowers.flowersOnCoffin
        ) {
            let data = props.form.accountData.lastwishes.flowers;
            setpersonalData(data);
        } else {
            setpersonalData({
                additionalInfo: '',
                dontWant: '',
                graveFlowerType: '',
                coffinFlowerType: '',
                flowersOnGrave: 'yes',
                flowersOnCoffin: 'yes',
            });
            setEdit(true);
        }
    }, [props.form.accountData && props.form.accountData.lastwishes]);

    const changeHandler = (event, personal) => {
        let data = {...personalData};
        data[event.target.name] = event.target.value;
        setpersonalData(data);
    };

    return (
        <React.Fragment>
            {!isEdit && (
                <React.Fragment>
                    <div
                        style={{ display: 'flex' }}
                        className='flex-direction-column input-container-column'
                    >
                        <div className='momentio-text-normal accordion-content-option-label'>
                            {props.form.language.FLOWER_Q1}
                        </div>
                        <div className='momentio-text-bold momentio-ewill-value'>
                            {personalData.flowersOnCoffin === 'yes'
                                ? props.form.language.YES_TEXT
                                : personalData.flowersOnCoffin === 'no'
                                ? props.form.language.NONE_TEXT
                                : personalData.flowersOnCoffin === 'nik'
                                ? props.form.language.NEXT_TO_KIN
                                : ''}
                        </div>
                    </div>
                    {personalData.flowersOnCoffin === 'yes' && (
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.FLOWER_Q2}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.coffinFlowerType}
                            </div>
                        </div>
                    )}

                    <div
                        style={{ display: 'flex' }}
                        className='flex-direction-column input-container-column'
                    >
                        <div className='momentio-text-normal accordion-content-option-label'>
                            {props.form.language.FLOWER_Q3}
                        </div>
                        <div className='momentio-text-bold momentio-ewill-value'>
                            {personalData.flowersOnGrave === 'yes'
                                ? props.form.language.YES_TEXT
                                : personalData.flowersOnGrave === 'no'
                                ? props.form.language.NONE_TEXT
                                : personalData.flowersOnGrave === 'nik'
                                ? props.form.language.NEXT_TO_KIN
                                : ''}
                        </div>
                    </div>
                    {personalData.flowersOnGrave === 'yes' && (
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.FLOWER_Q2}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.graveFlowerType}
                            </div>
                        </div>
                    )}

                    <div
                        style={{ display: 'flex' }}
                        className='flex-direction-column input-container-column'
                    >
                        <div className='momentio-text-normal accordion-content-option-label'>
                            {props.form.language.FLOWER_Q5}
                        </div>
                        <div className='momentio-text-bold momentio-ewill-value'>
                            {personalData.dontWant}
                        </div>
                    </div>

                    <div
                        style={{ display: 'flex' }}
                        className='flex-direction-column input-container-column'
                    >
                        <div className='momentio-text-normal accordion-content-option-label'>
                            {props.form.language.ADDITIONAL_TITLE}
                        </div>
                        <div className='momentio-text-bold momentio-ewill-value'>
                            {personalData.additionalInfo}
                        </div>
                    </div>
                    <div className='secondary-alert-sm' onClick={() => setEdit(true)}>
                        {' '}
                        <i className='fa fa-pencil'></i>
                    </div>
                </React.Fragment>
            )}
            {isEdit && (
                <React.Fragment>
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.FLOWER_Q1}
                        </div>
                        <div className=''>
                            <RadioGroup
                                className='mm-field-input'
                                style={{ flexDirection: 'row' }}
                                name={'flowersOnCoffin'}
                                value={personalData.flowersOnCoffin}
                                onChange={(event) => changeHandler(event, personalData)}
                            >
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio />}
                                    label={props.form.language.YES_TEXT}
                                />

                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio />}
                                    label={props.form.language.NONE_TEXT}
                                />
                                <FormControlLabel
                                    value={'nik'}
                                    control={<Radio />}
                                    label={props.form.language.NEXT_TO_KIN}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                    {personalData.flowersOnCoffin === 'yes' && (
                        <div>
                            <div className='login-field-label login-field-label-height'>
                                {props.form.language.FLOWER_Q2}
                            </div>
                            <div className='login-field-input'>
                                <input
                                    type='text'
                                    autoComplete="off"
                                    placeholder={props.form.language.FLOWER_Q2}
                                    name='coffinFlowerType'
                                    value={personalData.coffinFlowerType}
                                    onChange={(event) => changeHandler(event, personalData)}
                                />
                            </div>
                        </div>
                    )}
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.FLOWER_Q3}
                        </div>
                        <div className=''>
                            <RadioGroup
                                className='mm-field-input'
                                style={{ flexDirection: 'row' }}
                                name={'flowersOnGrave'}
                                value={personalData.flowersOnGrave}
                                onChange={(event) => changeHandler(event, personalData)}
                            >
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio />}
                                    label={props.form.language.YES_TEXT}
                                />
                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio />}
                                    label={props.form.language.NONE_TEXT}
                                />
                                <FormControlLabel
                                    value={'nik'}
                                    control={<Radio />}
                                    label={props.form.language.NEXT_TO_KIN}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                    {personalData.flowersOnGrave === 'yes' && (
                        <div>
                            <div className='login-field-label login-field-label-height'>
                                {props.form.language.FLOWER_Q2}
                            </div>
                            <div className='login-field-input'>
                                <input
                                    type='text'
                                    autoComplete="off"
                                    placeholder={props.form.language.FLOWER_Q2}
                                    name='graveFlowerType'
                                    value={personalData.graveFlowerType}
                                    onChange={(event) => changeHandler(event, personalData)}
                                />
                            </div>
                        </div>
                    )}{' '}
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.FLOWER_Q5}
                        </div>
                        <div className='login-field-input'>
                            <input
                                type='text'
                                autoComplete="off"
                                placeholder={props.form.language.FLOWER_Q5}
                                name='dontWant'
                                value={personalData.dontWant}
                                onChange={(event) => changeHandler(event, personalData)}
                            />
                        </div>
                    </div>
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.ADDITIONAL_TITLE}
                        </div>
                        <div className='login-field-input'>
                            <textarea
                                style={{ height: 'auto' }}
                                rows='4'
                                type='text'
                                autoComplete="off"
                                placeholder={props.form.language.ADDITIONAL_TITLE}
                                name='additionalInfo'
                                value={personalData.additionalInfo}
                                onChange={(event) => changeHandler(event, personalData)}
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            style={{
                                textAlign: 'center',
                                width: '150px',
                                marginTop: '24px',
                                marginRight: '15px',
                            }}
                            className='primary-button-blue'
                            onClick={() => saveEwill(personalData)}
                        >
                            {props.form.language.SAVE}
                        </button>
                        <button
                            style={{
                                textAlign: 'center',
                                width: '150px',
                                marginTop: '24px',
                                backgroundColor: '#F45B5B !important',
                                
                            }}
                            className='primary-button-red'
                            onClick={() => deleteEwill()}
                        >
                            {props.form.language.DELETE}
                        </button>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

export default connect(mapStateToProps)(Flowers);
