import React from "react";

const PremiumSubscription = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <div className="subscription ewill subscription-container">
                <div className="subscription-content-container">
                    <div className="n_header-2"  style={{ marginBottom: "15px" }}>
                        {props.form.language.PERFECT_SUBSCRIPTION}
                    </div>

                    <div className="momentio-home-tick n_header-4 subscription-desc">
                        <span></span>
                        <div>
                            <div>{props.form.language.SUBSCRIPTION_SAFE}</div>
                            <div className="n_paragraph">{`${props.form.language.DASHBOARD_S25} ${props.form.language.SUBSCRIPTION_UNLIMITED}`}</div>
                            <div className="n_paragraph">{`${props.form.language.DASHBOARD_S19} 500 / 5 Gb`}</div>
                        </div>
                    </div>
                    <div className="momentio-home-tick n_header-4 subscription-desc">
                        <span></span>
                        <div className="subscription-content">
                            <div>
                                {props.form.language.SUBSCRIPTION_MEMORIES}
                            </div>
                            <div className="n_paragraph">Max 500</div>
                        </div>
                    </div>
                   

                    <div className="momentio-home-tick subscription-desc">
                        <span></span>
                        <div className="subscription-content">
                            <div>
                                {props.form.language.SUBSCRIPTION_LASTWISH}
                            </div>
                        </div>
                    </div>
                    <div className="momentio-home-tick subscription-desc">
                        <span></span>
                        <div className="subscription-content">
                            <div>{props.form.language.SUBSCRIPTION_LEGACY}</div>
                        </div>
                    </div>
                    <div className="momentio-home-tick subscription-desc">
                        <span></span>
                        <div className="subscription-content">
                            <div>Servicedesk</div>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: "center" }}>
                    <button
                        className="primary-button-blue"
                        onClick={() => {
                            props.addSubscriptionStep1(props.membership, 1);
                        }}
                    >
                        {props.form.language.SUBSCRIPTION_PREMIUM_BUTTON}
                    </button>
                    <div className="n_paragraph"  style={{ paddingTop: "10px" }}>
                        {`${props.form.language.ONE_TIME_TEXT} € ${parseInt(
                            props.membership.data.price
                        )}*`}
                    </div>
                    <div className="n_paragraph">{props.form.language.SUBSCRIPTION_HINT_PREMIUM}</div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default PremiumSubscription;
