/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// eslint-disable no-duplicate-props
export const validate = {
    email: (val) => {
    var valid = true;
      var regExp = /^([\w\.\+]{1,})([^\W])(@)([\w]{1,})(\.[\w]{1,})+$/;
      if (val && !regExp.test(val)) {
        valid =  false;
        return {
          valid: false,
          msg: "INVALID_FIELD"
      };
      }
      return {
          valid: valid,
          msg: ""
      };
    },
    minlength: (val, trueValue) => {
        var valid = true;
      if (val && val.length < trueValue) {
        valid =  false;
      }
      return true;
    },
    maxlength: (val, trueValue) => {
      if (val && val.length > trueValue) {
        return false;
      }
      return true;
    },
    numeric: val => {
      if (val && isNaN(val)) {
        return false;
      }
      return true;
    },
    alphaNumeric: val => {
      var regex = /^[a-z0-9\s]*$/i
      return regex.test(val);
    },
    required: (val, flag) => {
      var valid = true;
      
     
      if (flag === true && (!val || val === "")) {
        return {
          valid: false,
          msg: "MANDATORY_FIELD"
      };
      }
      return {
        valid: true,
        msg: ""
    };
    },
    phoneNum: (val) => {
      if (val) {
        var number = val.split("+64");
        var formattedNumber = "";
        if (number[1]) {
          formattedNumber = number[1].replace(/\b0+/g, '0')
        } else {
          formattedNumber = val.replace(/\b0+/g, '0')
  
        }
        // formattedNumber = 0 + formattedNumber;
        return formattedNumber;
      }
      return val;
  
    }
  };