/*  eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { loginAccount, addAttribute } from "../../Actions/Form";
import AuthHeader from "./../Common/AuthHeader";
import { useLocation } from "react-router-dom";
import SideMenu from "./../Common/SideMenu";
import AuthFooter from "./../Common/AuthFooter";
import { useGetAPICallQuery } from "../../app/services/account";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SubscriptionResult = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const orderID = useQuery();

    const membershipListQuery = useGetAPICallQuery(
        `membershipOrder/${orderID.get("orderResult")}`,
        { skip: !orderID.get("orderResult") }
    );

    const limitQueury = useGetAPICallQuery("account/limit");
    useEffect(() => {
        limitQueury.refetch();
    }, [membershipListQuery.data]);

    useEffect(() => {
        if (localStorage.getItem("profile") && limitQueury.data) {
            let profile = JSON.parse(localStorage.getItem("profile"));
            let profileData = {
                ...profile,
                limits: limitQueury.data.limits,
            };
            localStorage.setItem("profile", JSON.stringify(profileData));
            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    profile: profileData,
                },
            });
        }
    }, [limitQueury.data]);

    const getSubscriptionStatus = (code) => {
        switch (code) {
            case "open":
                return props.form.language.SUBSCRIPTION_RESULT_OPEN;
            case "paid":
                return props.form.language.SUBSCRIPTION_RESULT_PAID;
            case "canceled":
                return props.form.language.SUBSCRIPTION_RESULT_CANCELLED;
            case "failed":
                return props.form.language.SUBSCRIPTION_RESULT_FAILED;
            default:
                return props.form.language.SUBSCRIPTION_RESULT_PAID;
        }
    };

    return (
        <React.Fragment>
            {(limitQueury.isLoading ||
                membershipListQuery.isLoading ||
                props.form.isLoading) && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <AuthHeader />
            <div className="momentio-dashboard-container">
                <SideMenu view="mysafe" />
                <div className="auth-content">
                    <div className="auth-dashboard-subheader">
                        <div className="content-one">
                            {props.form.language.SUBSCRIPTION_RESULT}
                        </div>
                    </div>
                    <div
                        className="dashboard-description"
                        style={{
                            marginTop: "30px",
                            padding: "24px 30px",
                            borderRadius: "24px",
                        }}
                    >
                        {props.form.language.PAYMENT_STATUS}:{" "}
                        {membershipListQuery.data && getSubscriptionStatus(
                            membershipListQuery.data?.membershipOrderList[0]
                                ?.order?.orderState
                        )}
                    </div>
                    <div>
                        <div className="text4 momentio-home-tips"></div>
                    </div>
                </div>
            </div>
            <AuthFooter />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(loginAccount(payload)),
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionResult);
