/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { connect } from 'react-redux';
import { addAttribute } from '../../../../Actions/Form';
import SubscriptionOverview from './SubscriptionOverview';

const Membership = (props) => {
    const [addNew, setaddNew] = React.useState(false);
    const [newRecord, setnewRecord] = React.useState({
        membershipType: '',
        externalNumber: '',
        externalName: '',
        ID: '',
    });

    React.useEffect(() => {
        if (props.form.accountData && props.form.accountData.importantList) {
            let data = props.form.accountData.importantList.filter((item) => {
                return item.membership;
            });
            let membership = [];
            data.map((item) => {
                membership.push({
                    membershipType: item.membership.membershipType,
                    externalNumber: item.membership.membershipNumber,
                    externalName: item.general.name,
                    ID: item.system.id,
                });
            });
            membership.sort(function (x, y) {
                let a = x.membershipType,
                    b = y.membershipType;
                return a == b ? 0 : a > b ? 1 : -1;
            });
            setpersonalData(membership);
        } else {
            setpersonalData([]);
        }
    }, [props.form.accountData && props.form.accountData.importantList]);

    const saveEwill = (data) => {
        let input = {};
        Object.keys(data).map((item) => {
            input[item] = {
                value: data[item],
            };
        });
        let json = {
            url: input.ID.value ? `important/${input.ID.value}` : 'important',
            method: input.ID.value ? 'PUT' : 'POST',
            system: {
                id: input.ID.value,
            },
            general: {
                importantType: 'membership',
                name: input.externalName ? input.externalName.value : '',
                contactID: input.contactID ? input.contactID.value + ';' : '',
            },
            meta: {
                additionalInfo: input.remark ? input.remark.value : '',
                tags: 'tag1;tag2',
            },
            membership: {
                membershipType: input.membershipType ? input.membershipType.value : '',
                membershipNumber: input.externalNumber ? input.externalNumber.value : '',
            },
        };
        props.handleRequest('update', json);
        setaddNew(false);
    };

    const changeHandler = (event, personal) => {
        let data = personalData;
        let newData = newRecord;
        newData[event.target.name] = event.target.value;
        setnewRecord(newData);
        setpersonalData(JSON.parse(JSON.stringify(data)));
    };
    const showAddSubscription = (finance) => {
        setaddNew(true);
        if (finance) {
            let newData = {};
            Object.keys(finance).map((item) => {
                newData[item] = finance[item];
            });
            setnewRecord(newData);
            props.addAttribute({
                name: 'selectedSubscription',
                value: finance,
            });
        } else {
            setnewRecord({
                membershipType: '',
                externalNumber: '',
                externalName: '',
                ID: '',
            });
            props.addAttribute({
                name: 'selectedSubscription',
                value: {},
            });
        }
    };
    const hideEdit = () => {
        setaddNew(false);
    };
    const [personalData, setpersonalData] = React.useState([]);

    return (
        <React.Fragment key={props.key}>
            {addNew === true && (
                <Dialog
                    open={addNew}
                    fullScreen={props.fullScreen}
                    disableEscapeKeyDown={true}
                    onClose={hideEdit}
                    aria-labelledby='responsive-dialog-title'
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                    >
                        <div
                            className='mm-form-container'
                        >
                            <div className='mm-login-container' style={{ boxShadow: 'none' }}>
                                <div className='mm-login-header'>
                                    {props.form.selectedSubscription &&
                                    props.form.selectedSubscription.ID
                                        ? props.form.language.MEMBERSHIP_EDIT
                                        : props.form.language.MEMBERSHIP_ADD}
                                </div>
                                <div>
                                    <div className='login-field-label login-field-label-height'>
                                        Type
                                    </div>
                                    <div className='login-field-input'>
                                        <select
                                            className='mm-field-input '
                                            autoComplete="off"
                                            placeholder={props.form.language.HOUSE_TYPE}
                                            name={'membershipType'}
                                            value={newRecord.membershipType}
                                            onChange={(event) => changeHandler(event, newRecord)}
                                        >
                                            <option value=''>
                                                {props.form.language.SELECT_OPTION}
                                            </option>
                                            <option value='sport'>
                                                {props.form.language.SPORTS_TITLE}
                                            </option>
                                            <option value='cultural'>
                                                {props.form.language.CULTURAL_TITLE}
                                            </option>
                                            <option value='cinema'>
                                                {props.form.language.CINEMA_TITLE}
                                            </option>
                                            <option value='other'>
                                                {props.form.language.TYPE_OTHER}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div>
                                    <div className='login-field-label login-field-label-height'>
                                        {props.form.language.NAME}
                                    </div>
                                    <div className='login-field-input'>
                                        <input
                                            type='text'
                                            autoComplete="off"
                                            placeholder={props.form.language.NAME}
                                            name='externalName'
                                            value={newRecord.externalName}
                                            onChange={(event) => changeHandler(event, newRecord)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className='login-field-label login-field-label-height'>
                                        {props.form.language.NUMBER_TEXT}
                                    </div>
                                    <div className='login-field-input'>
                                        <input
                                            type='text'
                                            autoComplete="off"
                                            placeholder={props.form.language.NUMBER_TEXT}
                                            name='externalNumber'
                                            value={newRecord.externalNumber}
                                            onChange={(event) => changeHandler(event, newRecord)}
                                        />
                                    </div>
                                </div>

                                <div
                                    className='mm-input-submit'
                                    style={{
                                        display: 'flex',
                                        marginBottom: '15px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    
                                    <button
                                        style={{
                                            textAlign: 'center',
                                            width: '150px',
                                            marginTop: '24px',
                                            marginRight: '20px',
                                        }}
                                        className='primary-button-blue'
                                        onClick={() => {
                                            saveEwill(newRecord);
                                            hideEdit();
                                        }}
                                    >
                                        {props.form.language.SAVE}
                                    </button>
                                    <button
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '24px',
                                            
                                        }}
                                        className='secondary-button'
                                        onClick={hideEdit}
                                    >
                                        {props.form.language.CANCEL}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
            {personalData && 
                personalData.length > 0 &&
                personalData.map((data) => {
                    return (
                        <SubscriptionOverview
                            key={`member${data.ID}`}
                            data={data}
                            showAddSubscription={() => showAddSubscription(data)}
                            handleRequest={(type, data) => props.handleRequest(type, data)}
                        />
                    );
                })}
            <span
                id={props.type}
                onClick={(event) => {
                    showAddSubscription();
                }}
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '8px',
                }}
            >
                <span className=''>
                    <span className='ewill-secondary-alert-sm'>
                        <AddIcon />
                    </span>
                </span>
                <span className='momentio-text-normal' style={{ marginLeft: '15px' }}>
                    {personalData.length > 0
                        ? props.form.language.MEMBERSHIP_ADD_MULTIPLE
                        : props.form.language.MEMBERSHIP_ADD_SINGLE}
                </span>
            </span>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Membership);
