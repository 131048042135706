import React from "react";
import { connect } from "react-redux";

const Footer = (props) => {
    return (
        <div
            className="momentio-footer-container"
            style={{ background: "white", marginTop: "-1px", padding: 0 }}
        >
            <div className="footer-separator footer-separator-top"></div>
            <div>
                <div
                    className="text4 container"
                    style={{ marginTop: "34px", justifyContent: "flex-start" }}
                >
                    <div className="momentio-footer-section">
                        <div className="momentio-footer-title">
                            {props.form.language.FOOTER_S1}
                        </div>
                        <div className="momentio-footer-desc">
                            {" "}
                            {props.form.language.FOOTER_S2}
                        </div>
                        <div className="momentio-footer-desc">
                            {" "}
                            {props.form.language.FOOTER_S21}
                        </div>
                    </div>
                    <div className="momentio-footer-section">
                        <div className="momentio-footer-title">
                            {props.form.language.FOOTER_S3}
                        </div>
                        <div className="momentio-footer-desc">
                            {props.form.language.FOOTER_S4}
                        </div>
                        <div className="momentio-footer-desc">
                            {props.form.language.FOOTER_S41}
                        </div>
                    </div>
                    <div className="momentio-footer-section">
                        <div className="momentio-footer-title">
                            {props.form.language.FOOTER_S5}
                        </div>
                        <div className="momentio-footer-desc">
                            {props.form.language.FOOTER_S6}
                        </div>
                        <div className="momentio-footer-desc">
                            {props.form.language.FOOTER_S61}
                        </div>
                    </div>
                    <div className="momentio-footer-section">
                        <div className="momentio-footer-title">
                            {props.form.language.FOOTER_S7}{" "}
                        </div>
                        <div className="momentio-footer-desc">
                            {" "}
                            {props.form.language.FOOTER_S8}
                        </div>
                        <div className="momentio-footer-desc">
                            {" "}
                            {props.form.language.FOOTER_S81}
                        </div>
                        <div className="momentio-footer-desc">
                            {" "}
                            {props.form.language.FOOTER_S82}
                        </div>
                        <div className="momentio-footer-desc">
                            {" "}
                            {props.form.language.FOOTER_S83}
                        </div>
                    </div>
                </div>
            </div>
            <div className="momentio-footer-content">
                <div
                    className="footer-separator"
                    style={{ background: "white" }}
                ></div>
                <div className="text4 momentio-footer-company container">
                    <div style={{ width: "80%" }}>
                        <div className="momentio-footer-company-name">
                            {" "}
                            Momentio
                        </div>
                        <div className="momentio-footer-company-copyright">
                            {" "}
                            {props.form.language.FOOTER_S11}{" "}
                            <span>
                                <a
                                    href={`${props.form.language.MOMENTIO_BASE_URL}/algmenevoorwaarden`}
                                    target="_blank"
                                >
                                    {props.form.language.FOOTER_S10}
                                </a>
                            </span>
                            <span>
                                <a
                                    href={`${props.form.language.MOMENTIO_BASE_URL}/disclaimer`}
                                    target="_blank"
                                >
                                    {props.form.language.FOOTER_S9}
                                </a>
                            </span>
                        </div>
                    </div>
                    <div
                        className=""
                        style={{
                            display: "flex",
                            flexFlow: "row",
                            width: "20%",
                        }}
                    >
                        <div className="media-icon-container">
                            <a
                                href={props.form.language.FOOTER_YOUTUBE_URL}
                                target="_blank"
                                className="display-flex-media"
                            >
                                <i
                                    className="momentio-youtube-link"
                                    title="Youtube"
                                ></i>
                            </a>
                        </div>
                        <div className="media-icon-container">
                            <a
                                href={props.form.language.FOOTER_FACEBOOK_URL}
                                target="_blank"
                                className="display-flex-media"
                            >
                                <i
                                    className="momentio-facebook-link"
                                    title="Facebook"
                                ></i>
                            </a>
                        </div>
                        <div className="media-icon-container">
                            <a
                                href={props.form.language.FOOTER_TWITTER_URL}
                                target="_blank"
                                className="display-flex-media"
                            >
                                <i
                                    className="momentio-insta-link"
                                    title="Twitter"
                                ></i>
                            </a>
                        </div>
                        <div className="media-icon-container">
                            <a
                                href={props.form.language.FOOTER_INSTA_URL}
                                target="_blank"
                                className="display-flex-media"
                            >
                                <i
                                    className="momentio-snapchat-link"
                                    title="Instagram"
                                ></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

export default connect(mapStateToProps)(Footer);
