/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// eslint-disable jsx-a11y/anchor-is-valid
// eslint-disable jsx-a11y/alt-text
// eslint-disable no-useless-escape
// eslint-disable no-useless-concat
// eslint-disable no-duplicate-props
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { addAttribute, logout1, resetForm, setLanguage } from '../../Actions/Form';
import CircularProgress from '@material-ui/core/CircularProgress';
import image13 from '../../Images/Group 22.svg';
import image12 from '../../Images/Group 45.svg';
import image11 from '../../Images/Group 46.svg';
import image10 from '../../Images/Group 47.svg';
import image9 from '../../Images/Group 49.svg';
import image8 from '../../Images/Path 189.svg';
import accountImg from '../../Images/account-menu.svg';
import { useLogoutQuery, accountApi } from '../../app/services/account';
import { updateBrowserLaguage } from "../../Utility/Utility"
import { Link, useHistory, withRouter } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    container: {
        boxShadow: 'none !important',
    },
}));

const Mysafe = (props) => {
    const [skip, setSkip] = React.useState(true);
    const query = useLogoutQuery(props.form?.accountData?.profile?.settings?.username, {
        skip,
    });
    const history = useHistory();
    useEffect(()=>{
        if(query.data || query.error){
            window.scrollTo(0, 0);
            props.resetForm();
            let lang = localStorage.getItem('language');
            localStorage.clear();
            updateBrowserLaguage(lang, props);
            window.location.href = "/login"
        }
    },[query.data || query.error])
  

    const classes = useStyles();
    const handleChange = (event, closeMenu) => {
        props.refresh();
        if (closeMenu) {
            props.addAttribute({
                name: 'hideMobileMenu',
                value: true,
            });
            window.scrollTo(0, 0);
        }
        props.addAttribute({
            name: 'currentView',
            value: event.currentTarget.id ? event.currentTarget.id : event.currentTarget.name,
        });
        props.addAttribute({
            name: 'expanded',
            value:
                event.currentTarget.id === props.form.expanded
                    ? ''
                    : event.currentTarget.attributes['data-expanded']
                    ? event.currentTarget.attributes['data-expanded'].value
                    : event.currentTarget.id,
        });
        history.push(`/${event.currentTarget.id ? event.currentTarget.id : event.currentTarget.name}`)
        
    };

    return (
        <div
            className={
                props.form.hideMobileMenu
                    ? 'side-menu-hidden-header side-menu-header'
                    : 'side-menu-header'
            }
        >
          {(query.isLoading) && (
                <div className='overlay-momentio' style={{zIndex: 9999}}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
            )}
            <div className='sideMenu'>
                <div className='side-menu-width'>
                    <div>
                        <Accordion
                            expanded={props.form.expanded === 'dashboard'}
                            className={props.form.currentView === 'dashboard' ? 'active' : ''}
                        >
                            <AccordionSummary aria-controls='panel1bh-content' id='panel1bh-header'>
                                <img alt="momentio" src={image13} />
                                <button
                                    name='dashboard'
                                    id='dashboard'
                                    type='button'
                                    data-expanded='dashboard'
                                    className={
                                        props.form.currentView === 'dashboard'
                                            ? 'active collapsible-menu'
                                            : 'collapsible-menu'
                                    }
                                    onClick={(event) => handleChange(event, true)}
                                >
                                    <Link to='/dashboard'>{props.form.language.DASHBOARD_S28}</Link>
                                </button>
                            </AccordionSummary>
                        </Accordion>
                        <Accordion
                            expanded={props.form.expanded === 'mysafe'}
                            className={props.form.currentView === 'mysafe' ? 'active' : ''}
                        >
                            <AccordionSummary
                                aria-controls='panel1bh-content'
                                id='panel1bh-header'
                                expandIcon={
                                    <Link to='/mysafe'>
                                        {' '}
                                        <ExpandMoreIcon
                                            name='mysafe'
                                            onClick={(event) => handleChange(event)}
                                            id='mysafe'
                                        />
                                    </Link>
                                }
                            >
                                <img alt="momentio" src={image9} />
                                <button
                                    type='button'
                                    onClick={(event) => handleChange(event, true)}
                                    className={
                                        props.form.currentView === 'mysafe'
                                            ? 'active collapsible-menu'
                                            : 'collapsible-menu'
                                    }
                                    name='mysafe'
                                    data-expanded='mysafe'
                                    id='mysafe'
                                >
                                    <Link to='/mysafe'>{props.form.language.DASHBOARD_S18}</Link>
                                </button>
                            </AccordionSummary>

                            <AccordionDetails>
                                <button
                                    type='button'
                                    name='personalwish'
                                    id='personalwish'
                                    data-expanded='mysafe'
                                    onClick={(event) => handleChange(event, true)}
                                    className={
                                        props.form.currentView === 'personalwish'
                                            ? 'active collapsible-sub-menu'
                                            : 'collapsible-sub-menu'
                                    }
                                >
                                    <Link to='/personalwish'>
                                        {' '}
                                        {props.form.language.DASHBOARD_S27}
                                    </Link>
                                </button>
                                {props.form.accountData?.profile?.limits?.accessList?.important && <button
                                    type='button'
                                    onClick={(event) => handleChange(event, true)}
                                    name='importantInfo'
                                    data-expanded='mysafe'
                                    id='importantInfo'
                                    className={
                                        props.form.currentView === 'importantInfo'
                                            ? 'active collapsible-sub-menu'
                                            : 'collapsible-sub-menu'
                                    }
                                >
                                    <Link to='/importantInfo'>
                                        {' '}
                                        {props.form.language.DASHBOARD_S24}
                                    </Link>
                                </button>}
                                {props.form.accountData?.profile?.limits?.accessList?.media && <button
                                    type='button'
                                    onClick={(event) => handleChange(event, true)}
                                    className={
                                        props.form.currentView === 'files'
                                            ? 'active collapsible-sub-menu'
                                            : 'collapsible-sub-menu'
                                    }
                                    name='files'
                                    id='files'
                                    data-expanded='mysafe'
                                >
                                    <Link to='/files'> {props.form.language.DASHBOARD_S19}</Link>
                                </button>}
                            </AccordionDetails>
                        </Accordion>
                        {props.form.accountData?.profile?.limits?.accessList?.contact && <Accordion
                            expanded={props.form.expanded === 'allContacts'}
                            className={props.form.currentView === 'allContacts' ? 'active' : ''}
                        >
                            <AccordionSummary
                                aria-controls='panel1bh-content'
                                id='panel1bh-header'
                            >
                                <img alt="momentio" src={image10} />
                                <button
                                    type='button'
                                    className={
                                        props.form.currentView === 'allContacts'
                                            ? 'active collapsible-menu'
                                            : 'collapsible-menu'
                                    }
                                    name='allContacts'
                                    id='allContacts'
                                    data-expanded='allContacts'
                                    onClick={(event) => handleChange(event, true)}
                                >
                                    <Link to='/allContacts'>
                                        {' '}
                                        {props.form.language.DASHBOARD_S25}
                                    </Link>
                                </button>
                            </AccordionSummary>

                            {/*<AccordionDetails>
                                <button
                                    type='button'
                                    onClick={(event) => handleChange(event, true)}
                                    className={
                                        props.form.currentView === 'family'
                                            ? 'active collapsible-sub-menu'
                                            : 'collapsible-sub-menu'
                                    }
                                    id='family'
                                    data-expanded='contacts'
                                    name='family'
                                >
                                    <Link to='/family'> {props.form.language.MYCONTACT_S2}</Link>
                                </button>
                                <button
                                    type='button'
                                    name='friends'
                                    id='friends'
                                    data-expanded='contacts'
                                    onClick={(event) => handleChange(event, true)}
                                    className={
                                        props.form.currentView === 'friends'
                                            ? 'active collapsible-sub-menu'
                                            : 'collapsible-sub-menu'
                                    }
                                >
                                    <Link to='/friends'> {props.form.language.MYCONTACT_S3}</Link>
                                </button>
                                <button
                                    type='button'
                                    onClick={(event) => handleChange(event, true)}
                                    className={
                                        props.form.currentView === 'confidant'
                                            ? 'active collapsible-sub-menu'
                                            : 'collapsible-sub-menu'
                                    }
                                    name='confidant'
                                    id='confidant'
                                    data-expanded='contacts'
                                >
                                    <Link to='/emergency'> {props.form.language.MYCONTACT_S1}</Link>
                                </button>
                                </AccordionDetails>*/}
                        </Accordion>}
                        {(props.form.accountData?.profile?.limits?.accessList?.message) && <Accordion
                            expanded={props.form.expanded === 'messages'}
                            className={props.form.currentView === 'messages' ? 'active' : ''}
                        >
                            <AccordionSummary aria-controls='panel1bh-content' id='panel1bh-header'
                            expandIcon={
                                props.form.accountData?.profile?.limits?.accessList?.message ? <Link to='/messages'>
                                        {' '}
                                        <ExpandMoreIcon
                                            name='messages'
                                            id='messages'
                                            data-expanded='messages'
                                            onClick={(event) => handleChange(event)}
                                        />
                                    </Link>
                                    :
                                    <a><ExpandMoreIcon
                                        name='messages'
                                        id='messages'
                                        data-expanded='messages'
                                        onClick={(event) => handleChange(event)}
                                    /></a>
                                }>
                                <img alt="momentio" src={image12} />
                                <button
                                    type='button'
                                    className={
                                        props.form.currentView === 'messages'
                                            ? 'active collapsible-menu'
                                            : 'collapsible-menu'
                                    }
                                    name='messages'
                                    data-expanded='messages'
                                    
                                    onClick={(event) => handleChange(event, true)}
                                >
                                    {props.form.accountData?.profile?.limits?.accessList?.message ? <Link to='/messages'> {props.form.language.DASHBOARD_S26}</Link> : <a>{props.form.language.DASHBOARD_S26}</a>}
                                </button>
                            </AccordionSummary>
                            {<AccordionDetails>
                                {props.form.accountData?.profile?.limits?.accessList?.message && <button
                                    type='button'
                                    onClick={(event) => handleChange(event, true)}
                                    className={
                                        props.form.currentView === 'messages'
                                            ? 'active collapsible-sub-menu'
                                            : 'collapsible-sub-menu'
                                    }
                                    // id='messages'
                                    data-expanded='messages'
                                    name='messages'
                                >
                                    <Link to='/messages'> {props.form.language.SENT_MESSAGE_TITLE}</Link>
                                </button>}
                                <button
                                    type='button'
                                    onClick={(event) => handleChange(event, true)}
                                    className={
                                        props.form.currentView === 'recievedMessage'
                                            ? 'active collapsible-sub-menu'
                                            : 'collapsible-sub-menu'
                                    }
                                    name='recievedMessage'
                                    id='recievedMessage'
                                    data-expanded='messages'
                                >
                                    <Link to='/recievedMessage'> {props.form.language.RECIEVED_MESSAGE_TITLE}</Link>
                                </button>
                                </AccordionDetails>}
                        </Accordion>}
                        {props.form.accountData?.profile?.limits?.accessList?.lastwishes && <Accordion
                            expanded={props.form.expanded === 'lastwishes'}
                            className={props.form.currentView === 'lastwishes' ? 'active' : ''}
                        >
                            <AccordionSummary aria-controls='panel1bh-content' id='panel1bh-header'>
                                <img alt="momentio" src={image8} />
                                <button
                                    type='button'
                                    className={
                                        props.form.currentView === 'lastwishes'
                                            ? 'active collapsible-menu'
                                            : 'collapsible-menu'
                                    }
                                    name='lastwishes'
                                    data-expanded='lastwishes'
                                    id='lastwishes'
                                    onClick={(event) => handleChange(event, true)}
                                >
                                    {' '}
                                    <Link to='/lastwishes'>{props.form.language.FOOTER_S3}</Link>
                                </button>
                            </AccordionSummary>
                        </Accordion>}

                        {props.form.accountData?.profile?.limits?.accessList?.legacy && 
                        <Accordion
                            expanded={props.form.expanded === 'legacy'}
                            className={props.form.currentView === 'legacy' ? 'active' : ''}
                        >
                            <AccordionSummary aria-controls='panel1bh-content' id='panel1bh-header'>
                                <img alt="momentio" src={image11} />
                                <button
                                    type='button'
                                    className={
                                        props.form.currentView === 'legacy'
                                            ? 'active collapsible-menu'
                                            : 'collapsible-menu'
                                    }
                                    name='legacy'
                                    data-expanded='legacy'
                                    id='legacy'
                                    onClick={(event) => handleChange(event, true)}
                                >
                                    <Link to='/legacy'>{props.form.language.LEGACY_TITLE}</Link>
                                </button>
                            </AccordionSummary>
                        </Accordion>}

                        <Accordion
                            expanded={props.form.expanded === 'subscription'}
                            className={props.form.currentView === 'subscription' ? 'active' : ''}
                        >
                            <AccordionSummary aria-controls='panel1bh-content' id='panel1bh-header'
                            /*expandIcon={
                                    <Link to='/subscription'>
                                        {' '}
                                        <ExpandMoreIcon
                                            name='subscription'
                                            id='subscription'
                                            data-expanded='subscription'
                                            onClick={(event) => handleChange(event)}
                                        />
                                    </Link>
                                }*/
                                >
                                <img alt="momentio" src={image12} />
                                <button
                                    type='button'
                                    className={
                                        props.form.currentView === 'subscription'
                                            ? 'active collapsible-menu'
                                            : 'collapsible-menu'
                                    }
                                    name='subscription'
                                    data-expanded='subscription'
                                    
                                    onClick={(event) => handleChange(event, true)}
                                >
                                    <Link to='/subscription'>{props.form.language.SUBSCRIPTION_TEXT}</Link>
                                </button>
                            </AccordionSummary>
                            {/*<AccordionDetails>
                                <button
                                    type='button'
                                    onClick={(event) => handleChange(event, true)}
                                    className={
                                        props.form.currentView === 'subscriptionresult'
                                            ? 'active collapsible-sub-menu'
                                            : 'collapsible-sub-menu'
                                    }
                                    id='subscriptionresult'
                                    data-expanded='subscriptionresult'
                                    name='subscriptionresult'
                                >
                                    <Link to='/subscriptionresult'>Subscription Result</Link>
                                </button>
                                </AccordionDetails>*/}
                        </Accordion>
                        <AccordionSummary aria-controls='panel1bh-content' id='panel1bh-header'>
                            <div  style={{display:'flex', alignItems: 'center'}}>
                                <ContactSupportIcon style={{width: '27px', height: '27px'}} />
                                <button
                                    type='button'
                                    style={{paddingLeft: "10px"}}
                                    className={
                                        props.form.currentView === 'support'
                                            ? 'active collapsible-menu'
                                            : 'collapsible-menu'
                                    }
                                    name='support'
                                    data-expanded='support'
                                    id='support'
                                >
                                    <a href={`${props.form.language.MOMENTIO_BASE_URL}/contact`} target="_blank">{props.form.language.SIDEMENU_SUPPORT}</a>
                                </button>
                            </div>
                        </AccordionSummary>
                        <Accordion
                            expanded={props.form.expanded === 'myAccount'}
                            className={
                                props.form.currentView === 'myAccount'
                                    ? 'active show-flex-sm'
                                    : 'show-flex-sm'
                            }
                        >
                            <AccordionSummary
                                aria-controls='panel1bh-content'
                                id='panel1bh-header'
                                expandIcon={
                                    props.form.accountData?.profile?.limits?.accessList?.account ? <Link to='/profile'>
                                        {' '}
                                        <ExpandMoreIcon
                                            name='myAccount'
                                            onClick={(event) => handleChange(event)}
                                            id='myAccount'
                                        />
                                    </Link> : <a>
                                    <ExpandMoreIcon
                                            name='myAccount'
                                            onClick={(event) => handleChange(event)}
                                            id='myAccount'
                                        />
                                    </a>
                                }
                            >
                                <img alt="momentio" src={accountImg} />
                                <button
                                    type='button'
                                    onClick={(event) => handleChange(event, true)}
                                    className={
                                        props.form.currentView === 'myAccount'
                                            ? 'active collapsible-menu'
                                            : 'collapsible-menu'
                                    }
                                    name='myAccount'
                                    data-expanded='myAccount'
                                    id='myAccount'
                                >
                                    {props.form.accountData?.profile?.limits?.accessList?.message ? <Link to='/profile'>{props.form.language.SIDEMENU_S2}</Link> : <a>{props.form.language.SIDEMENU_S2}</a>}
                                </button>
                            </AccordionSummary>
                            <AccordionDetails>
                                {props.form.accountData?.profile?.limits?.accessList?.account && <button
                                    type='button'
                                    name='profile'
                                    id='profile'
                                    data-expanded='profile'
                                    onClick={(event) => handleChange(event, true)}
                                    className={
                                        props.form.currentView === 'profile'
                                            ? 'active collapsible-sub-menu'
                                            : 'collapsible-sub-menu'
                                    }
                                >
                                    <Link to='/profile'>{props.form.language.LOGIN_S14}</Link>
                                </button>}
                                <button
                                    type='button'
                                    className={
                                        props.form.currentView === 'logout'
                                            ? 'active collapsible-sub-menu'
                                            : 'collapsible-sub-menu'
                                    }
                                    name='logout'
                                    id='logout'
                                    onClick={() => {
                                        setSkip(false);
                                        //props.logout1();
                                    }}
                                >
                                    
                                    {props.form.language.LOGIN_S16}
                                </button>
                                
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
        resetForm: () => dispatch(resetForm()),
        logout1: () => dispatch(logout1()),
        refresh: () => dispatch(accountApi.util.resetApiState()),
        updateLanguage: (payload) => dispatch(setLanguage(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Mysafe);
