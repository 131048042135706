/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import * as validator from "./validations";
import { useParams } from "react-router-dom";

export const updateBrowserLaguage = (language, props) => {
    props.addAttribute({
        name: 'currentLanguage',
        value: language?.toUpperCase() === 'US' ? "US" : "NL",
    });
    window.global =
    language?.toUpperCase()  === 'US'  ? 'globalENU' : 'globalDUT';
    localStorage.setItem('language', language?.toUpperCase()  === 'US' ? "US" : "NL");
    props.updateLanguage(language?.toUpperCase()  === 'US' ? "US" : "NL");
}

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties,
    };
};
export const deepCloneObject = (obj) => {
    let clonedObject = JSON.parse(JSON.stringify(obj));
    return clonedObject;
};

export const getAPIBaseUrl = () => {
    // return process.env.REACT_APP_API_URL;
    return window.location.host === process.env.REACT_APP_URL_PROD ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_TEST;
    //return url;
    
    //if(window.location.host === process.env.REACT_APP_API_URL_TEST || window.location.host === process.env.REACT_APP_API_URL_PROD) {
    //    url = `https://${window.location.host}/${process.env.REACT_APP_API_VERSION}/`
    //} else if (window.location.host === 'localhost:3000' ){
    //    url = `https://api.test.momentio.io/${process.env.REACT_APP_API_VERSION}/`
        //"https://api.test.momentio.io/v1.0.0/"
    //}
};

export const getCurrentLanguage = () => {
    return localStorage.getItem("language");
    //window.global;
};

export const requestFormatDate = (date) => {
    if (date) {
        let newDate = date.split("-");
        if (newDate.length > 2) {
            let month = newDate[1].length < 2 ? "0" + newDate[1] : newDate[1];
            let day = newDate[0].length < 2 ? "0" + newDate[0] : newDate[0];
            let formattedDate = newDate[2] + "-" + month + "-" + day;
            return formattedDate;
        }
        return "";
    }
    return "";
};

export const loadLanguage =() =>{
    let { language } = useParams();
  console.log(language)
  return language;
  }

export const responseFormatDate = (date) => {
    if (date) {
        let newDate = date.split("-");
        if (newDate.length > 2) {
            let formattedDate =
                newDate[2] + "-" + newDate[1] + "-" + newDate[0];
            return formattedDate;
        }
        return "";
    }
    return "";
};

export const logoutHandler = () => {
    let lang = localStorage.getItem('language');
    localStorage.clear();
    window.global = lang === "NL" ? "globalDUT" : "globalENU";
    window.scrollTo(0, 0);
    // window.location.href = "/login";
    setTimeout(() => {
        localStorage.setItem('language', lang);
        window.location.href = "/login";
    }, 100);
};

export const validateField = (newData, language) => {
    let validationMsg = "";
    // let newData = JSON.parse(JSON.stringify(loginData));

    Object.keys(newData).map((data) => {
        let validationsObj = newData[data].validations;
        if (validationsObj && !newData[data].hidden) {
            let validations = Object.keys(newData[data].validations);
            // loginData[data].validations.map(item=>{
            if (validations.length > 0) {
                for (let i = 0; i < validations.length; i++) {
                    let response = validator.validate[validations[i]].call(
                        this,
                        newData[data].value,
                        validationsObj[validations[i]]
                    );
                    newData[data].valid = response.valid;
                    var text = newData[data].text ? newData[data].text : "";
                    if (response.valid === false) {
                        validationMsg =
                            validationMsg +
                            "\n" +
                            language[text] +
                            " " +
                            language[response.msg];
                    }
                }
            }
            // })
        }
    });
    //setcontactData(newData);
    return {
        formatData: newData,
        validationMsg: validationMsg,
    };
};

export const responseHandler = (response, language) => {
    if (response.statusCode == 401) {
        alert(language.INVALID_SESSION);
    } else if (response.statusCode != 200 && response.statusCode != 201) {
        alert(language.ERROR_SAVING);
    } else if (response.data == null) {
        alert(language.ERROR_SAVING);
    }
};

export const entityTagList = (entityList) => {
    let taglist = [];
    if (entityList) {
        entityList.map((entity) => {
            const tags = entity.meta.tags.split(";");
            if (tags.length) {
                tags.map((tag) => {
                    if (tag && taglist.indexOf(tag) < 0) {
                        taglist.push(tag);
                    }
                });
            }
        });
    }

    return taglist;
};
export const sortContactAscending = (data, name) => {
    if (!data) {
        return data;
    }
    var newData = [...data];

    var sortedList = newData.sort((a, b) => {
        let fa = a.privateData.firstName.toLowerCase(),
            fb = b.privateData.firstName.toLowerCase();

        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    });
    return sortedList;
};

export const sortAscending = (data) => {
    if (!data) {
        return data;
    }
    var newData = [...data];

    var sortedList = newData.sort((a, b) => {
        let fa = a.data.name.toLowerCase(),
            fb = b.data.name.toLowerCase();

        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    });
    return sortedList;
};

export const getContactName = (contactId, contactList) => {
    if (contactId && contactList) {
        contactList.map((currentContact, index) => {
            if (contactId == currentContact.system.id) {
                return (
                    currentContact &&
                    currentContact.privateData.firstName +
                        " " +
                        currentContact.privateData.lastName
                );
            } else {
                return "";
            }
        });
    }
    return "";
};

export const UPPERCASE_REGEX = new RegExp(/.*[A-Z]/);
export const LOWERCASE_REGEX = new RegExp(/.*[a-z]/);
export const NUMBER_REGEX = new RegExp(/.*\d/);
export const LENGTH_REGEX = new RegExp(/.{8,}$/);
export const SPECIAL_CHARS_REGEX = new RegExp(
  /.*[!#*$@_%&\\]/
);

export const PASSWORD_VALID_REGEX = new RegExp(
  `^(?=${[
    LENGTH_REGEX.source,
    UPPERCASE_REGEX.source,
    NUMBER_REGEX.source,
    SPECIAL_CHARS_REGEX.source,
    LOWERCASE_REGEX.source
  ].join(")(?=")}).*$`
);
