/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { useMutationAPICallMutation } from "../../app/services/account";

const ChangePassword = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [changePassword, setChangePassword] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const hideChangePassword = () => {
        setChangePassword(false);
        setPassword("");
    };
    const [updateAccount] = useMutationAPICallMutation();
    const savePassword = async () => {
        setLoading(true);
        let requestData = {
            ...props.form.accountData?.profile,
            privateData: {
                ...props.form.accountData?.profile.privateData,
                birthDate: props.form.accountData?.profile?.privateData?.birthDate ? moment(props.form.accountData?.profile.privateData?.birthDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                ) : ''
            },
            settings: {
                ...props.form.accountData?.profile.settings,
                password: password,
            },
            url: "account",
            method: "PUT",
        };
        //requestData.settings.password = password;

        await updateAccount(requestData)
            .unwrap()
            .then((response) => {
                setLoading(false);
                hideChangePassword();
            });
        setLoading(false);
    };

    return (
        <React.Fragment>
            {loading && (
                <div
                    className="overlay-momentio"
                    style={{ zIndex: 1000000000000 }}
                >
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <div className="n_header-3" style={{ marginBottom: "16px" }}>
                {props.form.language.LOGIN_INFORMATION}
            </div>
            <div
                style={{
                    margin: "16px 0 0 0",
                    textAlign: "left",
                    textDecoration: "underline",
                }}
                className="momentio-blog-link"
                onClick={() => setChangePassword(true)}
            >
                {props.form.language.CHANGE_PASSWORD}
            </div>
            <Dialog
                open={changePassword}
                fullScreen={fullScreen}
                disableEscapeKeyDown={true}
                onClose={hideChangePassword}
                aria-labelledby="responsive-dialog-title"
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <div
                        className="mm-form-container"
                    >
                        <div
                            className="mm-login-container"
                            style={{ boxShadow: "none" }}
                        >
                            <div className="mm-login-header">
                                {props.form.language.CHANGE_PASSWORD}
                            </div>

                            <div>
                                <div className="login-field-label login-field-label-height">
                                    {props.form.language.LOGIN_S3}
                                </div>
                                <div className="login-field-input">
                                    <input
                                        type="password"
                                        autoComplete="off"
                                        placeholder={props.form.language.LOGIN_S3}
                                        name="password"
                                        value={password}
                                        onChange={(event) =>
                                            setPassword(event.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                className="mm-input-submit"
                                style={{
                                    display: "flex",
                                    marginBottom: "15px",
                                    justifyContent: "center",
                                }}
                            >
                                
                                <button
                                    style={{
                                        textAlign: "center",
                                        width: "150px",
                                        marginTop: "24px",
                                        marginRight: "20px",
                                    }}
                                    className="primary-button-blue"
                                    onClick={() => {
                                        savePassword();
                                        //hideChangePassword();
                                    }}
                                >
                                    {props.form.language.SAVE}
                                </button>
                                <button
                                    style={{
                                        textAlign: "center",
                                        marginTop: "24px",
                                        
                                    }}
                                    className="secondary-button"
                                    onClick={hideChangePassword}
                                >
                                    {props.form.language.CANCEL}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps)(ChangePassword);
