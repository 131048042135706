/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import Popper from "@material-ui/core/Popper";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React from "react";
import { connect } from "react-redux"; 
import {
  addAttribute
} from "../../../../Actions/Form";

const PasswordOverview = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const closePopper = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <div onMouseLeave={closePopper}>
        <React.Fragment>
          {props.form.selectedPassword &&
            props.form.selectedPassword.ID === props.data.ID && (
              <Popper open={open} anchorEl={anchorEl}>
                <div
                  className="menu-container"
                  onMouseLeave={() => closePopper(false)}
                  style={{
                    marginLeft: "-175px",
                    marginTop: "0px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="menu-options"
                    onClick={() => {
                      closePopper("bottom-end");
                      props.showAddPassword(props.data);
                    }}
                  >
                    {props.form.language.EDIT}
                  </div>
                  <div
                    className="menu-options"
                    onClick={() => {
                      closePopper("bottom-end");
                      props.handleRequest("delete", props.data.ID);
                      props.addAttribute({
                        name: "selectedPassword",
                        value: {},
                      });
                    }}
                  >
                    {props.form.language.DELETE}
                  </div>
                </div>
              </Popper>
            )}
        </React.Fragment>

        <div
          className="overviewContainer"
          style={{
            padding: props.isPreview ? "" : "18px 22px",
            background: props.isPreview
              ? ""
              : "#F1F6F9 0% 0% no-repeat padding-box",
          }}
        >
          {!props.isPreview && (
            <div
              className="overviewMenu"
              onClick={(event) => {
                handleClick(event);
                props.addAttribute({
                  name: "selectedPassword",
                  value: props.data,
                });
              }}
            >
              <MoreHorizIcon className='show-menu-icon' />
            </div>
          )}{" "}
          <div
            className="momentio-text-bold"
            style={{ marginTop: "-5px", width: "100%" }}
          >
            <div
              className="mm-login-header accordion-content-option-label"
              style={{ width: "100%", maxWidth: "unset" }}
            >
              {props.form.language.PASSWORD_Q16}
            </div>
            <div
              style={{
                display: props.isPreview ? "block" : "flex",
                padding: "10px 0",
              }}
              className="input-container-column"
            >
              <div
                className={
                  props.isPreview
                    ? "momentio-text-bold"
                    : "momentio-text-normal accordion-content-option-label"
                }
              >
                {props.form.language.PASSWORD_Q15}
              </div>
              <div
                className={
                  props.isPreview
                    ? "momentio-text-normal accordion-content-option-label"
                    : "momentio-text-bold"
                }
                style={{ width: props.isPreview ? "100%" : "70%" }}
              >
                {props.data.accessType === "passwordManager"
                  ? props.form.language.PASSWORD_MANAGER_OPTION_1
                  : props.data.accessType === "paper"
                  ? props.form.language.PASSWORD_MANAGER_OPTION_2
                  : props.data.accessType === "others"
                  ? props.form.language.FUNERAL_Q18
                  : ""}
              </div>
            </div>

            {props.data.accessType === "passwordManager" && (
              <div
                style={{
                  display: props.isPreview ? "block" : "flex",
                  padding: "10px 0",
                }}
                className="input-container-column"
              >
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-bold"
                      : "momentio-text-normal accordion-content-option-label"
                  }
                >
                  {props.form.language.PASSWORD_Q2}
                </div>
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-normal accordion-content-option-label"
                      : "momentio-text-bold"
                  }
                  style={{ width: props.isPreview ? "100%" : "70%" }}
                >
                  {props.data.name}
                </div>
              </div>
            )}
            {props.data.accessType === "passwordManager" && (
              <div
                style={{
                  display: props.isPreview ? "block" : "flex",
                  padding: "10px 0",
                }}
                className="input-container-column"
              >
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-bold"
                      : "momentio-text-normal accordion-content-option-label"
                  }
                >
                  {props.form.language.PASSWORD_Q4}
                </div>
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-normal accordion-content-option-label"
                      : "momentio-text-bold"
                  }
                  style={{ width: props.isPreview ? "100%" : "70%" }}
                >
                  {props.data.userName}
                </div>
              </div>
            )}
            {props.data.accessType === "passwordManager" && (
              <div
                style={{
                  display: props.isPreview ? "block" : "flex",
                  padding: "10px 0",
                }}
                className="input-container-column"
              >
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-bold"
                      : "momentio-text-normal accordion-content-option-label"
                  }
                >
                  {props.form.language.CONTACT_PERSON_TITLE} 
                </div>
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-normal accordion-content-option-label"
                      : "momentio-text-bold"
                  }
                  style={{ width: props.isPreview ? "100%" : "70%" }}
                >
                  {props.contactList?.map((contact) => {
                      if (contact.system.id == props.data.accessContactID?.replaceAll(";", "")) {
                        return (
                          <span key={`contact${contact.system.id}`}>
                            {contact.privateData.firstName + " " + contact.privateData.lastName}
                          </span>
                        );
                      }
                    })}
                </div>
              </div>
            )}

            {props.data.accessType === "passwordManager" && (
              <div
                style={{
                  display: props.isPreview ? "block" : "flex",
                  padding: "10px 0",
                }}
                className="input-container-column"
              >
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-bold"
                      : "momentio-text-normal accordion-content-option-label"
                  }
                >
                  {props.form.language.ADDITIONAL_TITLE}
                </div>
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-normal accordion-content-option-label"
                      : "momentio-text-bold"
                  }
                  style={{ width: props.isPreview ? "100%" : "70%" }}
                >
                  {props.data.additionalInfo}
                </div>
              </div>
            )}

            {props.data.accessType === "paper" && (
              <div
                style={{
                  display: props.isPreview ? "block" : "flex",
                  padding: "10px 0",
                }}
                className="input-container-column"
              >
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-bold"
                      : "momentio-text-normal accordion-content-option-label"
                  }
                >
                  {props.form.language.PASSWORD_Q7}
                </div>
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-normal accordion-content-option-label"
                      : "momentio-text-bold"
                  }
                  style={{ width: props.isPreview ? "100%" : "70%" }}
                >
                  {props.data.location}
                </div>
              </div>
            )}

            {props.data.accessType === "paper" && (
              <div
                style={{
                  display: props.isPreview ? "block" : "flex",
                  padding: "10px 0",
                }}
                className="input-container-column"
              >
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-bold"
                      : "momentio-text-normal accordion-content-option-label"
                  }
                >
                  {props.form.language.PASSWORD_Q9}
                </div>
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-normal accordion-content-option-label"
                      : "momentio-text-bold"
                  }
                  style={{ width: props.isPreview ? "100%" : "70%" }}
                >
                  {props.data.additionalInfo}
                </div>
              </div>
            )}

            {props.data.accessType === "others" && (
              <div
                style={{
                  display: props.isPreview ? "block" : "flex",
                  padding: "10px 0",
                }}
                className="input-container-column"
              >
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-bold"
                      : "momentio-text-normal accordion-content-option-label"
                  }
                >
                  {props.form.language.PASSWORD_Q12}
                </div>
                <div
                  className={
                    props.isPreview
                      ? "momentio-text-normal accordion-content-option-label"
                      : "momentio-text-bold"
                  }
                  style={{ width: props.isPreview ? "100%" : "70%" }}
                >
                  {props.data.location}
                </div>
              </div>
            )}
            {props.isPreview && (
              <div className="momentio-text-bold" style={{ marginTop: "10px" }}>
                {props.form.language.PASSWORD_PREVIEW}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addAttribute: (payload) => dispatch(addAttribute(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PasswordOverview);
