/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import { connect } from 'react-redux';

const Meals = (props) => {
  const [personalData, setpersonalData] = React.useState({});
  const [isEdit, setEdit] = React.useState(false);
    const saveEwill = () => {
        let json = {
            url: 'lastwishes',
            mode: 'merge',
            method: props.form.accountData.lastwishes.funeralPolis ? 'PUT' : 'POST',
            ...props.form.accountData.lastwishes,
            meal: {
                cofeeTableChoice: personalData.cofeeTableChoice,
                mealChoice: personalData.mealChoice,
                mealPreference: personalData.mealPreference,
                additionalInfo: personalData.additionalInfo,
            },
        };
        props.handleRequest('update', json);
        setEdit(false);
    };
    const deleteEwill = () => {
        let data = {...props.form.accountData.lastwishes};
        delete data.meal;
        let json = {
            url: 'lastwishes',
            mode: 'overwrite',
            method: 'PUT',
            ...data,
        };
        props.handleRequest('update', json, 'meal');
    };

    React.useEffect(() => {
        if (
            props.form.accountData &&
            props.form.accountData.lastwishes &&
            props.form.accountData.lastwishes.meal &&
            props.form.accountData.lastwishes.meal.cofeeTableChoice
        ) {
            let data = props.form.accountData.lastwishes.meal;
            setpersonalData(data);
        } else {
            setpersonalData({
                additionalInfo: '',
                cofeeTableChoice: 'yes',
                mealChoice: 'yes',
                mealPreference: '',
                ID: '',
            });
            setEdit(true);
        }
    }, [props.form.accountData && props.form.accountData.lastwishes]);

    const changeHandler = (event, personal) => {
        let data = {...personalData};
        data[event.target.name] = event.target.value;
        setpersonalData(data);
    };
  
    return (
        <React.Fragment>
            {!isEdit && (
                <React.Fragment>
                    <div
                        style={{ display: 'flex' }}
                        className='flex-direction-column input-container-column'
                    >
                        <div className='momentio-text-normal accordion-content-option-label'>
                            {props.form.language.MEAL_Q1}
                        </div>
                        <div className='momentio-text-bold momentio-ewill-value'>
                            {personalData.cofeeTableChoice === 'yes'
                                ? props.form.language.YES_TEXT
                                : personalData.cofeeTableChoice === 'no'
                                ? props.form.language.NONE_TEXT
                                : personalData.cofeeTableChoice === 'nik'
                                ? props.form.language.NEXT_TO_KIN
                                : ''}
                        </div>
                    </div>

                    <div
                        style={{ display: 'flex' }}
                        className='flex-direction-column input-container-column'
                    >
                        <div className='momentio-text-normal accordion-content-option-label'>
                            {props.form.language.MEAL_Q2}
                        </div>
                        <div className='momentio-text-bold momentio-ewill-value'>
                            {personalData.mealChoice === 'yes'
                                ? props.form.language.YES_TEXT
                                : personalData.mealChoice === 'no'
                                ? props.form.language.NONE_TEXT
                                : personalData.mealChoice === 'nik'
                                ? props.form.language.NEXT_TO_KIN
                                : ''}
                        </div>
                    </div>
                    {personalData.mealChoice === 'yes' && (
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.MEAL_Q3}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.mealPreference}
                            </div>
                        </div>
                    )}

                    <div
                        style={{ display: 'flex' }}
                        className='flex-direction-column input-container-column'
                    >
                        <div className='momentio-text-normal accordion-content-option-label'>
                            {props.form.language.ADDITIONAL_TITLE}
                        </div>
                        <div className='momentio-text-bold momentio-ewill-value'>
                            {personalData.additionalInfo}
                        </div>
                    </div>
                    <div className='secondary-alert-sm' onClick={() => setEdit(true)}>
                        {' '}
                        <i className='fa fa-pencil'></i>
                    </div>
                </React.Fragment>
            )}
            {isEdit && (
                <React.Fragment>
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.MEAL_Q1}
                        </div>
                        <div className=''>
                            <RadioGroup
                                className='mm-field-input'
                                style={{ flexDirection: 'row' }}
                                name={'cofeeTableChoice'}
                                value={personalData.cofeeTableChoice}
                                onChange={(event) => changeHandler(event, personalData)}
                            >
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio />}
                                    label={props.form.language.YES_TEXT}
                                />

                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio />}
                                    label={props.form.language.NONE_TEXT}
                                />
                                <FormControlLabel
                                    value={'nik'}
                                    control={<Radio />}
                                    label={props.form.language.NEXT_TO_KIN}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.MEAL_Q2}
                        </div>
                        <div className=''>
                            <RadioGroup
                                className='mm-field-input'
                                style={{ flexDirection: 'row' }}
                                name={'mealChoice'}
                                value={personalData.mealChoice}
                                onChange={(event) => changeHandler(event, personalData)}
                            >
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio />}
                                    label={props.form.language.YES_TEXT}
                                />
                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio />}
                                    label={props.form.language.NONE_TEXT}
                                />
                                <FormControlLabel
                                    value={'nik'}
                                    control={<Radio />}
                                    label={props.form.language.NEXT_TO_KIN}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                    {personalData.mealChoice === 'yes' && (
                        <div>
                            <div className='login-field-label login-field-label-height'>
                                {props.form.language.MEAL_Q3}
                            </div>
                            <div className='login-field-input'>
                                <input
                                    type='text'
                                    autoComplete="off"
                                    placeholder={props.form.language.MEAL_Q3}
                                    name='mealPreference'
                                    value={personalData.mealPreference}
                                    onChange={(event) => changeHandler(event, personalData)}
                                />
                            </div>
                        </div>
                    )}{' '}
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.ADDITIONAL_TITLE}
                        </div>
                        <div className='login-field-input'>
                            <textarea
                                style={{ height: 'auto' }}
                                rows='4'
                                autoComplete="off"
                                type='text'
                                placeholder={props.form.language.ADDITIONAL_TITLE}
                                name='additionalInfo'
                                value={personalData.additionalInfo}
                                onChange={(event) => changeHandler(event, personalData)}
                            />
                        </div>
                    </div>
                    <div>
                        
                        <button
                            style={{
                                textAlign: 'center',
                                width: '150px',
                                marginTop: '24px',
                            }}
                            className='primary-button-blue'
                            onClick={() => saveEwill(personalData)}
                        >
                            {props.form.language.SAVE}
                        </button>
                        <button
                            style={{
                                textAlign: 'center',
                                width: '150px',
                                marginTop: '24px',
                                backgroundColor: '#F45B5B !important',
                                marginLeft: '15px',
                            }}
                            className='primary-button-red'
                            onClick={() => deleteEwill()}
                        >
                            {props.form.language.DELETE}
                        </button>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps)(Meals);
