/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// eslint-disable jsx-a11y/anchor-is-valid
// eslint-disable jsx-a11y/alt-text

import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter, useParams, useHistory } from 'react-router-dom';
import { addAttribute, loginAccount, setLanguage } from '../../Actions/Form';
import { useLoginMutation } from '../../app/services/account';
import '../../Constants/decryption';
import '../../Constants/encryption';
import cloud from '../../Images/cloud_PNG24.png';
import { validateField, updateBrowserLaguage } from '../../Utility/Utility';
import { setAppCookie } from '../../Utility/Cookies';
import moment from 'moment';

const Login = (props) => {
    const history = useHistory();
    const { language } = useParams();    
    React.useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            let lang = localStorage.getItem('language') ? localStorage.getItem('language'): 'NL';
            localStorage.clear();
            updateBrowserLaguage(language ? language : lang, props);
        }, 100);
            
    }, []);

    const [loginData, setLoginData] = React.useState({
        Username: {
            value: '',
            validations: { required: true },
            valid: false,
            text: 'LOGIN_S2',
        },
        Password: {
            value: '',
            validations: { required: true },
            valid: false,
            text: 'LOGIN_S3',
        },
    });

    const [login, { isLoading, isError }] = useLoginMutation();

    const changeHandler = async (event) => {
        let newData = JSON.parse(JSON.stringify(loginData));
        newData[event.target.name].value = event.target.value;
        //let validation = await validateField(newData, props.form.language);
        setLoginData(newData);
    };

    async function submit(newData) {
        let isFormValid = await validateField(newData, props.form.language);
        setLoginData(isFormValid.formatData);
        if (isFormValid) {
            let isValid = true;
            Object.keys(loginData).map((data) => {
                if (loginData[data].valid === false) {
                    isValid = false;
                }
            });
            if (isValid) {
                let data = {
                    username: loginData.Username.value,
                    password: loginData.Password.value,
                };
                await login(data)
                    .unwrap()
                    .then((response) => {
                        handleSuccess(response);
                    });
            } else {
                alert(isFormValid.validationMsg);
            }
        }
    }

    const handleSuccess = (response) => {
        props.addAttribute({
            name: 'currentLanguage',
            value: response.settings.languageISOCode.toUpperCase(),
        });
        window.global =
            response.settings.languageISOCode.toUpperCase() === 'NL' ? 'globalDUT' : 'globalENU';
        localStorage.setItem('language', response.settings.languageISOCode);
        
        localStorage.setItem('token', response.token);
        localStorage.setItem('readOnlyMode', response.readOnlyMode);
        setAppCookie(response.token)
        props.updateLanguage(response.settings.languageISOCode);
        //document.cookie = `token=${response.token}`;  
        let profileData = {
            ...response,
            privateData: {
                ...response.privateData,
                birthDate: response.privateData.birthDate ?moment(response.privateData.birthDate, 'YYYY-MM-DD').format(
                    'DD-MM-YYYY'
                ): '',
            },
        }
        localStorage.setItem('profile', JSON.stringify(profileData));
        props.addAttribute({
            name: 'accountData',
            value: {
                profile: {
                    ...profileData,
                    
                },
            },
        });
        props.addAttribute({
            name: 'token',
            value: response.token,
        });
        window.scrollTo(0, 0);
        setTimeout(() => {
            props.addAttribute({
                name: 'activeUser',
                value: true,
            });
            if (response.limits.membership.membershipID === 0) {
                history.push("subscription");
            }
        }, 100);
    };
    return (
        <React.Fragment>
            {isLoading && (
                <div className='overlay-momentio'>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
            )}
            {props.form.activeUser && <Redirect to='/dashboard' />}

            <div className='fixed-header-container'>
                <div className='header-container container'>
                    <div className='header-title-section-login'>
                        <div>
                            <a href={props.form.language.MOMENTIO_BASE_URL}>{props.form.language.COMPANY_NAME}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='momentioHome'>
                <div className='content-container container' style={{ marginTop: '70px' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: 'inherit',
                            //position: "relative",
                        }}
                    >
                        <img className='momentio-cloud-bg' src={cloud} />
                        <div className='mm-form-container' style={{ paddingTop: '30px' }}>
                            <div className='mm-login-container'>
                                <div className='mm-login-header'>
                                    {props.form.language.LOGIN_S1}
                                </div>
                                <div className='login-field-label login-field-label-height'>
                                    {props.form.language.LOGIN_S2}
                                </div>
                                <div className='login-field-input'>
                                    <input
                                        type='text'
                                        placeholder={props.form.language.LOGIN_S2}
                                        name='Username'
                                        value={loginData.Username.value}
                                        onChange={changeHandler}
                                    />
                                </div>
                                <div className='login-field-label login-field-label-height'>
                                    {props.form.language.LOGIN_S3}
                                </div>
                                <div className='login-field-input'>
                                    <input
                                        type='password'
                                        placeholder={props.form.language.LOGIN_S3}
                                        name='Password'
                                        value={loginData.Password.value}
                                        onChange={changeHandler}
                                    />
                                </div>
                                <div className='mm-input-submit'>
                                    <button
                                        style={{
                                            textAlign: 'center',
                                            width: '360px',
                                            marginTop: '24px',
                                        }}
                                        className='primary-button-blue'
                                        type='submit'
                                        onClick={() => submit(loginData)}
                                    >
                                        {props.form.language.MAIN_MENU_LOGIN}
                                    </button>
                                </div>
                            </div>
                            <div className='mm-form-links'>
                                <span className='momentio-blog-link' style={{ margin: '0 7px' }}>
                                    {' '}
                                    <Link to='/resetpassword'>
                                        {' '}
                                        {props.form.language.LOGIN_S4}
                                    </Link>{' '}
                                </span>
                                <div>
                                    <span className='momentio-get-start-text'>
                                        {props.form.language.LOGIN_S18}
                                    </span>
                                    <span className='momentio-blog-link'>
                                        <Link to='/register'>{props.form.language.LOGIN_S5}</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(loginAccount(payload)),
        addAttribute: (payload) => dispatch(addAttribute(payload)),
        updateLanguage: (payload) => dispatch(setLanguage(payload)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
