/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import { Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import TextSnippetOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import update from "immutability-helper";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { addAttribute, loginAccount } from "../../Actions/Form";
import AuthFooter from "../Common/AuthFooter";
import AuthHeader from "../Common/AuthHeader";
import SideMenu from "../Common/SideMenu";
import FutureMessage from "./FutureMessage";
import {
    useGetAPICallQuery,
    useMutationAPICallMutation,
    accountApi,
} from "../../app/services/account";
import moment from "moment";
import { getTagLabel } from "../Contacts/ContactTags";
import MessagePreview from "./PreviewRecieved";
import { entityTagList, sortAscending } from "../../Utility/Utility";
import { model } from "../../Models/Media";

const RecievedMessages = (props) => {
    const messageListQuery = useGetAPICallQuery("message/shared", {skip: !props.form.accountData?.profile?.limits?.accessList?.shared});

    const trustListQuery = useGetAPICallQuery("trust", {skip: !props.form.accountData?.profile?.limits?.accessList?.trust});
    const [openPreview, setOpenPreview] = React.useState(false);
    const [previewData, setPreviewData] = React.useState({});

    const [category, setCategory] = React.useState("");
    const [media, setMedia] = React.useState([]);

    const getSharedMediaURL = async (row) => {
        if (row.data.mediaID) {
            let split = row.data.mediaID.split(";");
            if (split.length > 0) {
                split.map((item) => {
                    if (item) {
                        //let x = props.getAPICall(`media/shared/${item}`);

                        func1(row, item);
                    }
                });
            }
        }
    };

    const func1 = async (row, item) => {
        var x = await props.getAPICall(`media/shared/${item}`);
        console.log(media);

        if (x.data) {
            x.data.mediaList.map((list, index) => {
                const url = list.system.signedUrl;
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                // the filename you want
                const attribute = document.createAttribute("download");
                //a.setAttributeNode(attribute)
                a.download = list.data.originalFileName;
                a.target = "_blank";
                //a.setAttribute("download");
                document.body.appendChild(a);
                a.click();
            });
        }
    };

    React.useEffect(() => {
        if (messageListQuery.data && !props.form.futureFormPopup) {
            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    messageRecieved: messageListQuery.data.messageList,
                },
            });
        }
    }, [messageListQuery.data]);

    const handleChange = (panel) => (event, isExpanded) => {
        //window.scrollTo(0, 100);
        props.addAttribute({
            name: "currentMessage",
            value: isExpanded ? panel.system.id : false,
        });
    };
    const [updateTrustQuery, { isLoading }] = useMutationAPICallMutation();

    const handleRequest = async (requestData) => {
        let req = { ...requestData };
        await updateTrustQuery(req)
            .unwrap()
            .then((response) => {
                messageListQuery.refetch();
                trustListQuery.refetch();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateTrust = (trust, action) => {
        let json = {
            url: "trust",
            method: "PUT",
            username: trust.request.receiverEmail,
            request: { ...trust.request },
            settings: {
                ...trust.settings,
                trustStatus: action,
                changeType: "system",
            },
        };

        handleRequest(json);
    };

    const getTrusts = () => {
        const currentTrust = trustListQuery.data?.trustList.filter(
            (item) => item.settings.trustStatus === "new"
        )[0];
        if (currentTrust) {
            return (
                <div
                    style={{
                        padding: "24px 30px",
                        background: "white",
                        borderRadius: "24px",
                        marginBottom: "32px",
                    }}
                >
                    <Grid
                        xs="12"
                        sm="12"
                        lg="12"
                        container
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Grid xs="12" sm="12" md="6" lg="7" item>
                            <div
                                className="n_header-3"
                                style={{
                                    width: "auto",
                                    height: "auto",
                                }}
                            >
                                {`${props.form.language.MESSAGE_RECIEVED_FROM} ${currentTrust.request.requestEmail}`}
                            </div>
                            <div className="n_paragraph">
                                {`${props.form.language.MESSAGE_ACCEPTANCE} ${currentTrust.request.requestEmail} ${props.form.language.MESSAGE_ACCEPTANCE_SUFFIX}`}
                            </div>
                        </Grid>
                        <Grid
                            xs="12"
                            sm="12"
                            md="6"
                            lg="5"
                            item
                            style={{ textAlign: "right", paddingLeft: "5px" }}
                        >
                            <button
                                className="primary-button-blue"
                                onClick={() =>
                                    updateTrust(currentTrust, "accept")
                                }
                            >
                                {props.form.language.ACCEPT_MESSAGE_TEXT}
                            </button>
                            <span
                                className="add-new-attachment"
                                style={{ paddingLeft: "24px" }}
                                onClick={() =>
                                    updateTrust(currentTrust, "reject")
                                }
                            >
                                {props.form.language.DECLINE_MESSAGE}
                            </span>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    };

    return (
        <React.Fragment>
            {(messageListQuery.isLoading ||
                messageListQuery.isFetching ||
                isLoading) && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}

            <AuthHeader />
            <div className="momentio-dashboard-container">
                <SideMenu view="mysafe" />
                {openPreview && (
                    <MessagePreview
                        setOpenPreview={() => setOpenPreview(false)}
                        row={previewData}
                    />
                )}
                {!openPreview && (
                    <div className="auth-content">
                        <div className="auth-content-subheader">
                            <div className="content-one">
                                {props.form.language.RECIEVED_MESSAGE_TITLE}
                            </div>
                        </div>
                        <Grid xs="12" sm="12" lg="12" item>
                            {getTrusts()}
                        </Grid>

                        <div className="messages ewill">
                            <Accordion className="contactAccordion hideXS">
                                <AccordionSummary
                                    aria-controls="panel1bh-content"
                                    id="panel-moment-header"
                                    disabled={true}
                                    expandIcon={
                                        <ExpandMoreIcon
                                            style={{ visibility: "hidden" }}
                                            disabled={true}
                                        />
                                    }
                                >
                                    <Typography
                                        className="contacts-table-header"
                                        style={{
                                            paddingLeft: "20px !important",
                                        }}
                                    >
                                        <span>
                                            {props.form.language.FUTURE_MESSAGE_S6.toUpperCase()}
                                        </span>
                                    </Typography>
                                    <Typography className="contacts-table-header">
                                        <span>
                                            {
                                                props.form.language
                                                    .DELIVERY_MOMENT
                                            }
                                        </span>
                                    </Typography>
                                </AccordionSummary>
                            </Accordion>
                            {messageListQuery &&
                                messageListQuery.data &&
                                messageListQuery.data.messageList.map((row) => {
                                    if (
                                        row.meta.tags.includes(category) ||
                                        category === ""
                                    )
                                        return (
                                            <Accordion
                                                expanded={
                                                    props.form
                                                        .currentMessage ===
                                                    row.system.id
                                                }
                                                onChange={handleChange(row)}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                    className="header-container-sm"
                                                >
                                                    <Typography className="contact-summary-header header-sm">
                                                        <span className="contactListSection mm-login-header message-title-sm">
                                                            {row.data.title}
                                                        </span>
                                                    </Typography>
                                                    <Typography className="contact-summary-header header-sm hideXS">
                                                        <span className="contactListSection message-delivery-sm">
                                                            <span className="">
                                                                {row.data
                                                                    .messageDate
                                                                    ? `${moment(
                                                                          row
                                                                              .data
                                                                              .messageDate,
                                                                          "YYYY-MM-DD"
                                                                      ).format(
                                                                          "DD-MM-YYYY"
                                                                      )}`
                                                                    : props.form
                                                                          .language
                                                                          .FUTURE_MESSAGE_DATE_UNDECIDED}
                                                            </span>
                                                        </span>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    style={{
                                                        padding:
                                                            "33px 32px 32px 32px",
                                                    }}
                                                >
                                                    <div className="edit-message-icon recieved-messages">
                                                        <button
                                                            className="primary-button-yellow"
                                                            style={{
                                                                marginRight:
                                                                    "10px",
                                                            }}
                                                            onClick={() => {
                                                                setPreviewData(
                                                                    row
                                                                );
                                                                setOpenPreview(
                                                                    true
                                                                );
                                                                window.scrollTo(
                                                                    0,
                                                                    0
                                                                );
                                                            }}
                                                        >
                                                            {
                                                                props.form
                                                                    .language
                                                                    .VIEW_MESSAGE
                                                            }
                                                        </button>
                                                        <span
                                                            className="add-new-attachment"
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                            onClick={() => {
                                                                getSharedMediaURL(
                                                                    row
                                                                );
                                                            }}
                                                        >
                                                            {
                                                                props.form
                                                                    .language
                                                                    .DOWNLOAD_MEDIA
                                                            }
                                                        </span>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                })}
                        </div>
                    </div>
                )}
            </div>
            <AuthFooter />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(loginAccount(payload)),
        addAttribute: (payload) => dispatch(addAttribute(payload)),
        getAPICall: (payload) =>
            dispatch(accountApi.endpoints.getAPICall.initiate(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RecievedMessages);
