/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

export const setCookieInit = () =>{
    let iv = getRandomKeyValues(16);
    let newKey = getRandomKeyValues(32);

    setCookie("newEncryptionKey", newKey, null);
    setCookie("newIV", iv, null);
}

export const setAppCookie = (token) => {
    var currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes()+30);
    document.cookie = "token="+ token + "; expires="+ currentDate.toUTCString() +"; path=/;";
}

export const setCookie = (cookieName, cookieValue, expireDays) => {
    let date = new Date();
    let expires = "";
    if(expireDays != null){
        date.setTime(date.getTime() + (expireDays*24*60*60*1000));
        expires = ";expires="+ date.toUTCString();
    }
    document.cookie = cookieName + "=" + cookieValue + expires + ";path=/";
}

export const deleteCookie =(cookieName)=> {
    let date = new Date();
    date.setTime(date.getTime() - 100);
    let expires = "expires="+ date.toUTCString();
    let cookieValue = "delete";
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

export const getCookie=(cookieName)=> {
    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookieList = decodedCookie.split(';');
    for(let i = 0; i <cookieList.length; i++) {
        let cookie = cookieList[i];
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) == 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return "";
}

export const getCookieByName = (cname) =>{
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
}

export const  getRandomKeyValues=(valueLength)=>{
    let result = "";
    return result;
  }