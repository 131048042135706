export const EWillModel = [
  {
    name: "Testament",
    category: "Testament",
    completed: 0,
    subtitles: [
      { name: "Notaris", category: "Notaris" },
      { name: "Executeur", category: "Executeur" },
      { name: "Woning en bezittingen",category: "Woning"  },
      { name: "Onderneming",category: "Onderneming"  },
    ],
  },
];
