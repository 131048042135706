import { combineReducers } from "redux";
import moments from "./Momentia";
import form from "./Form";

const rootReducer = combineReducers({
  momentia: moments,
  form: form
});

export default rootReducer;
