/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { connect } from "react-redux";
import { addAttribute } from "../../Actions/Form";
import {
    useGetAPICallQuery,
    useMutationAPICallMutation,
} from "../../app/services/account";
import { LastWishesModel } from "../../Models/LastWishes";
import AuthFooter from "../Common/AuthFooter";
import AuthHeader from "../Common/AuthHeader";
import SideMenu from "../Common/SideMenu";
import Carriers from "./Steps/Carriers";
import Flowers from "./Steps/Flowers";
import FuneralPolicy from "./Steps/FuneralPolicy";
import FuneralType from "./Steps/FuneralType";
import Meals from "./Steps/Meals";
import Media from "./Steps/Media";
import Memories from "./Steps/Memories";
import Music from "./Steps/Music";
import NotificationOverview from "./Steps/NotificationOverview";
import Organisation from "./Steps/Organisation";
import { sortContactAscending } from "../../Utility/Utility";
import Speaker from "./Steps/Speaker";
import Transportation from "./Steps/Transportation";
import RecordPolicy from "./Steps/RecordPolicy";

const useStyles = makeStyles((theme) => ({
    container: {
        boxShadow: "none !important",
    },
}));

const LastWishes = (props) => {
    const classes = useStyles();
    const [mainSection, setmainSections] = React.useState(LastWishesModel);
    const ewillListQuery = useGetAPICallQuery("lastwishes");
    const [skip, setSkip] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    const contactList = useGetAPICallQuery("contact", { skip: skip });
    const [updateLastWishQuery, { isLoading }] = useMutationAPICallMutation();

    React.useEffect(() => {
        if (!ewillListQuery.isLoading && !ewillListQuery.error) {
            setSkip(false);
        }
    }, [ewillListQuery]);

    React.useEffect(() => {
        getCompleted(true, mainSection, props);
    }, [ewillListQuery.data]);

    const getContent = (data) => {
        switch (data.category) {
            case "lwFuneralPolis":
                return (
                    <FuneralPolicy
                        success={success}
                        handleRequest={(type, requestData, deleteType) =>
                            handleRequest(type, requestData, deleteType)
                        }
                    />
                );
            case "lwRecordPolis":
                return (
                    <RecordPolicy
                        success={success}
                        handleRequest={(type, requestData, deleteType) =>
                            handleRequest(type, requestData, deleteType)
                        }
                    />
                );
            case "lwFuneralType":
                return (
                    <FuneralType
                        success={success}
                        handleRequest={(type, requestData, deleteType) =>
                            handleRequest(type, requestData, deleteType)
                        }
                    />
                );
            case "lwNotification":
                return (
                    <NotificationOverview
                        contactList={contactList}
                        success={success}
                        handleRequest={(type, requestData, deleteType) =>
                            handleRequest(type, requestData, deleteType)
                        }
                    />
                );
            case "lwMusic":
                return (
                    <Music
                        success={success}
                        handleRequest={(type, requestData, deleteType) =>
                            handleRequest(type, requestData, deleteType)
                        }
                    />
                );
            case "lwOrganisation":
                return (
                    <Organisation
                        success={success}
                        contactList={contactList}
                        handleRequest={(type, requestData, deleteType) =>
                            handleRequest(type, requestData, deleteType)
                        }
                    />
                );
            case "lwSpeaker":
                return (
                    <Speaker
                        success={success}
                        contactList={contactList}
                        handleRequest={(type, requestData, deleteType) =>
                            handleRequest(type, requestData, deleteType)
                        }
                    />
                );
            case "lwTransportation":
                return (
                    <Transportation
                        success={success}
                        contactList={contactList}
                        handleRequest={(type, requestData, deleteType) =>
                            handleRequest(type, requestData, deleteType)
                        }
                    />
                );
            case "lwCarriers":
                return (
                    <Carriers
                        success={success}
                        contactList={contactList}
                        handleRequest={(type, requestData, deleteType) =>
                            handleRequest(type, requestData, deleteType)
                        }
                    />
                );
            case "lwFlowers":
                return (
                    <Flowers
                        success={success}
                        handleRequest={(type, requestData, deleteType) =>
                            handleRequest(type, requestData, deleteType)
                        }
                    />
                );
            case "lwMeal":
                return (
                    <Meals
                        success={success}
                        handleRequest={(type, requestData, deleteType) =>
                            handleRequest(type, requestData, deleteType)
                        }
                    />
                );
            case "lwMemories":
                return (
                    <Memories
                        success={success}
                        handleRequest={(type, requestData, deleteType) =>
                            handleRequest(type, requestData, deleteType)
                        }
                    />
                );
            case "lwMedia":
                return (
                    <Media
                        success={success}
                        handleRequest={(type, requestData, deleteType) =>
                            handleRequest(type, requestData, deleteType)
                        }
                    />
                );
            default:
                return <></>;
        }
    };

    const handleRequest = async (type, requestData, deleteType) => {
        let req = { ...requestData };
        if (deleteType) {
            delete req[deleteType];
        }
        await updateLastWishQuery(req)
            .unwrap()
            .then((response) => {
                ewillListQuery.refetch();
                setSuccess(true);
            })
            .catch((error) => {
                console.log(error);
                setSuccess(false);
            });
    };

    const handleChange = (panel) => (event, isExpanded) => {
        window.scrollTo(0, panel.scroll);
        props.addAttribute({
            name: "ewillStep",
            value: isExpanded ? panel.category : false,
        });
    };

    React.useEffect(() => {
        if (contactList.data) {
            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    contactList: sortContactAscending(
                        contactList.data.contactList
                    ),
                },
            });
        }
    }, [contactList.data]);
    React.useEffect(() => {
        if (ewillListQuery.data) {
            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    lastwishes: ewillListQuery.data,
                },
            });
        }
    }, [ewillListQuery.data]);

    const getCompleted = (isLoad, mainSection, props) => {
        let data = { ...mainSection };
        let willcompletedList = [];
        let ewillcompleted = 0;
        //data.subtitles[2].completed = false;
        let isFuneralPolicy = "";
        if (
            ewillListQuery.data &&
            ewillListQuery.data &&
            ewillListQuery.data.funeralPolis
        ) {
            willcompletedList.push("lwFuneralPolis");
            isFuneralPolicy = ewillListQuery.data.funeralPolis.recordLastWishes;
            data.subtitles[0].completed = true;

            if(ewillListQuery.data.funeralPolis.recordLastWishes) {
                data.subtitles[1].completed = true;
            }
        }
        

        if (
            props.form.accountData &&
            props.form.accountData.contactList &&
            props.form.accountData.contactList.length > 0
        ) {
            willcompletedList.push("lwNotification");
            data.subtitles[2].completed = true;
        } else {
            data.subtitles[2].completed = false;
        }
        if (ewillListQuery.data) {
            const lastWishes = ewillListQuery.data;
            if (lastWishes.organisation.organizer) {
                willcompletedList.push("lwOrganisation");
                data.subtitles[3].completed = true;
            } else {
                data.subtitles[3].completed = false;
            }
            if (lastWishes.funeralType.typeOfFuneral) {
                willcompletedList.push("lwFuneralType");
                data.subtitles[4].completed = true;
            } else {
                data.subtitles[4].completed = false;
            }
            if (lastWishes.music.musicChoice) {
                willcompletedList.push("lwMusic");
                data.subtitles[5].completed = true;
            } else {
                data.subtitles[5].completed = false;
            }
            if (lastWishes.speaker.speakerChoice) {
                willcompletedList.push("lwSpeaker");
                data.subtitles[6].completed = true;
            } else {
                data.subtitles[6].completed = false;
            }
            if (lastWishes.transportation.funeralProcessionChoice) {
                willcompletedList.push("lwTransportation");
                data.subtitles[7].completed = true;
            } else {
                data.subtitles[7].completed = false;
            }
            if (lastWishes.carriers.pallBearersChoice) {
                willcompletedList.push("lwCarriers");
                data.subtitles[8].completed = true;
            } else {
                data.subtitles[8].completed = false;
            }
            if (lastWishes.flowers.flowersOnCoffin) {
                willcompletedList.push("lwFlowers");
                data.subtitles[9].completed = true;
            } else {
                data.subtitles[9].completed = false;
            }
            if (lastWishes.meal.cofeeTableChoice) {
                willcompletedList.push("lwMeal");
                data.subtitles[10].completed = true;
            } else {
                data.subtitles[10].completed = false;
            }
            if (lastWishes.memories.remembrancePoems) {
                willcompletedList.push("lwMemories");
                data.subtitles[11].completed = true;
            } else {
                data.subtitles[11].completed = false;
            }
            if (lastWishes.media.allowVideo) {
                willcompletedList.push("lwMedia");
                data.subtitles[12].completed = true;
            } else {
                data.subtitles[12].completed = false;
            }
        }

        if (ewillListQuery.data) {
            if (
                isFuneralPolicy &&
                (isFuneralPolicy === "no")
            ) {
                data.completed = 100;
                
            } else {
                ewillcompleted = Math.round(
                    7.6 * willcompletedList.length + ewillcompleted
                );
                data.completed = ewillcompleted;
            }
            if (isLoad) {
                setmainSections(data);
            }
            return data.completed;
        } else {
            return 0;
        }
    };

    return (
        <React.Fragment>
            {(isLoading ||
                ewillListQuery.isLoading ||
                ewillListQuery.isFetching ||
                contactList.isLoading ||
                contactList.isFetching) && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <AuthHeader />
            <div className="momentio-dashboard-container">
                <SideMenu view="mysafe" />
                <div className="auth-content">
                    <div>
                        <div className="auth-content-subheader">
                            <div className="content-one">
                                {props.form.language[mainSection.name]}
                            </div>
                            <div className="mm-login-header">
                                {" "}
                                {props.form.language.COMPLETED_TEXT +
                                    " " +
                                    getCompleted(false, mainSection, props) +
                                    "%"}
                            </div>
                        </div>
                        <div className="ewill">
                            {mainSection.subtitles &&
                                mainSection.subtitles.map((subtitle) => {
                                    if (
                                        subtitle.hidden &&
                                        !props.form.showLastwill
                                    ) {
                                        return (
                                            <React.Fragment></React.Fragment>
                                        );
                                    } else {
                                        return (
                                            <Accordion
                                                className={classes.container}
                                                expanded={
                                                    props.form.ewillStep ===
                                                    subtitle.category
                                                }
                                                onChange={handleChange(
                                                    subtitle
                                                )}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel1bh-content"
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                    id={"panel-moment-header"}
                                                >
                                                    <div className="mm-login-header">
                                                        {subtitle.completed ? (
                                                            <CheckCircleIcon className="task-completed" />
                                                        ) : (
                                                            <CheckCircleIcon className="task-not-completed" />
                                                        )}
                                                        <div>
                                                            {
                                                                props.form
                                                                    .language[
                                                                    subtitle
                                                                        .name
                                                                ]
                                                            }
                                                        </div>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {getContent(subtitle)}
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    }
                                })}
                        </div>
                    </div>
                </div>
            </div>
            <AuthFooter />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LastWishes);
