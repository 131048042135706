/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { addAttribute } from "../../Actions/Form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";

const FilterTags = (props) => {
    const getTags = (item) => {
        let isSelected = props.selectedTags.value?.indexOf(item.value) > -1;
        return (
            <li key={item.value}>
                <Chip
                    clickable={true}
                    label={
                        props.form.language[item.label]
                            ? props.form.language[item.label]
                            : item.label
                    }
                    onClick={() => {
                        let tags = props.selectedTags.value
                            ? [...props.selectedTags.value]
                            : [];
                        if (tags.indexOf(item.value) < 0) {
                            tags.push(item.value);
                        } else {
                            tags.splice(tags.indexOf(item.value), 1);
                        }
                        props.updateTagList({
                            ...props.selectedTags,
                            value: tags,
                        });
                    }}
                    className={
                        isSelected
                            ? "momentio-tags selected-tags"
                            : "momentio-tags"
                    }
                />
            </li>
        );
    };

    return (
        <>
            {props.tags &&
                props.tags.map((item) => {
                    return getTags(item);
                })}

        </>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FilterTags)
);
