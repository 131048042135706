/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { addAttribute } from "../../Actions/Form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";
import FilterTags from "./FilterTags";

const AddTags = (props) => {
    const [newTag, setNewTag] = React.useState("");

    return (
        <div>
             <div style={{ marginBottom: "20px", marginLeft: "-5px" }}>
                <FilterTags
                    tags={props.tags}
                    selectedTags={props.selectedTags}
                    updateTagList={(tag) => props.updateTagList(tag)}
                />
            </div>

            <div className="login-field-label login-field-label-height">
                {props.form.language.CREATE_TAG_LABEL}
            </div>
            <div
                className="login-field-input"
                style={{ alignItems: "end", marginBottom: "64px" }}
            >
                <input
                    type="text"
                    placeholder="Max 20 characters"
                    name="name"
                    autoComplete="off"
                    value={newTag}
                    maxLength={20}
                    onChange={(event) => setNewTag(event.target.value)}
                />
                <button
                    className="primary-button-blue add-tag-btn"
                    onClick={() => {
                        let tags = props.selectedTags.value
                            ? props.selectedTags.value
                            : [];
                        if (tags.indexOf(newTag) < 0 && newTag !== "") {
                            tags.push(newTag);
                            // props.updateTagList(newTag);
                            props.updateTagList({
                                ...props.selectedTags,
                                options: [
                                    ...props.selectedTags.options,
                                    { label: newTag, value: newTag },
                                ],
                                value: tags,
                            });
                            setNewTag("");
                        } else if (newTag !== "") {
                            alert(props.form.language.TAG_EXISTS);
                        }
                    }}
                >
                    {" "}
                    {props.form.language.SAVE}
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddTags)
);
