/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { connect } from "react-redux";
import { addAttribute } from "../../../Actions/Form";
import { EWillModel } from "../../../Models/EWill";
import AuthFooter from "../../Common/AuthFooter";
import AuthHeader from "../../Common/AuthHeader";
import SideMenu from "../../Common/SideMenu";
import Contacts from "../../Safe/PersonalInformation/Contacts";
import Personal from "../../Safe/PersonalInformation/Personal";
import AccessWay from "../../Safe/ImportantInformation/AcessWay/AccessWay";
import { triggersave } from "../../../Actions/actions";
import Carriers from "./Carriers";
import Financial from "../../Safe/ImportantInformation/Financial/Financial";
import Flowers from "./Flowers";
import FuneralPolicy from "./FuneralPolicy";
import FuneralType from "./FuneralType";
import House from "../../Safe/ImportantInformation/House/House";
import Insurance from "../../Safe/ImportantInformation/Insurance/Insurance";
import Meals from "./Meals";
import Media from "./Media";
import Membership from "../../Safe/ImportantInformation/Subscription/Membership";
import Memories from "./Memories";
import Music from "./Music";
import NotificationOverview from "./NotificationOverview";
import Organisation from "./Organisation";
import SocialMedia from "../../Safe/ImportantInformation/SocialMedia/SocialMedia";
import Speaker from "./Speaker";
import Transportation from "./Transportation";

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: "none !important",
  },
}));

const Ewill = (props) => {
  const saveEwill = (data) => {
    let input = {};
    data.controls.map((item) => {
      input[item.name] = item;
    });
    switch (data.category) {
      default:
        input.ID = data.ID;
        props.triggersave(data.category, input);
        break;
    }
  };

  const others = (subtitle, ms) => {
    return (
      <React.Fragment>
        {subtitle.controls &&
          subtitle.controls.map((control) => {
            if (control.type === "text" && !control.hidden) {
              return (
                <div>
                  <div className="login-field-label login-field-label-height">
                    {control.label}
                  </div>
                  <div className="login-field-input">
                    <input
                      type={control.type}
                      placeholder={control.label}
                      autoComplete="off"
                      name={control.name}
                      value={control.value}
                      onChange={(event) =>
                        changeHandler(event, ms.category, subtitle.category)
                      }
                    />
                  </div>
                </div>
              );
            } else if (control.type === "select" && !control.hidden) {
              return (
                <div>
                  <div className="login-field-label login-field-label-height">
                    {control.label}
                  </div>
                  <div className="login-field-input">
                    <select
                      className="mm-field-input "
                      placeholder={control.label}
                      autoComplete="off"
                      name={control.name}
                      value={control.value}
                      onChange={(event) =>
                        changeHandler(event, ms.category, subtitle.category)
                      }
                    >
                      {control.options.length > 0 &&
                        control.options.map((option) => {
                          if (option) {
                            return (
                              <option value={option.value}>
                                {option.label}
                              </option>
                            );
                          }
                        })}
                    </select>
                  </div>
                </div>
              );
            } else if (control.type === "radio" && !control.hidden) {
              return (
                <div>
                  <div className="login-field-label login-field-label-height">
                    {control.label}
                  </div>
                  <div className="">
                    <RadioGroup
                      className="mm-field-input"
                      style={{ flexDirection: "row" }}
                      name={control.name}
                      value={control.value}
                      onChange={(event) =>
                        changeHandler(event, ms.category, subtitle.category)
                      }
                    >
                      {control.options.map((option) => {
                        return (
                          <FormControlLabel
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        );
                      })}
                    </RadioGroup>
                  </div>
                </div>
              );
            } else {
              return "";
            }
          })}
        <button
          style={{
            textAlign: "center",
            width: "150px",
            marginTop: "24px",
          }}
          className="primary-button-blue"
          name={subtitle.category}
          onClick={() => saveEwill(subtitle)}
        >
          {props.form.language.SAVE}
        </button>
      </React.Fragment>
    );
  };
  const getContent = (data, ms) => {
    switch (data.category) {
      case "personal":
        return <Personal />;
      case "accessWay":
        return <AccessWay />;
      case "financial":
        return <Financial />;
      case "lwFuneralPolis":
        return <FuneralPolicy />;
      case "house":
        return <House />;
      case "insurance":
        return <Insurance />;
      case "lwFuneralType":
        return <FuneralType />;
      case "lwNotification":
        return <NotificationOverview />;
      case "lwMusic":
        return <Music />;
      case "lwOrganisation":
        return <Organisation />;
      case "socialMedia":
        return <SocialMedia />;
      case "membership":
        return <Membership />;
      case "lwSpeaker":
        return <Speaker />;
      case "lwTransportation":
        return <Transportation />;
      case "lwCarriers":
        return <Carriers />;
      case "lwFlowers":
        return <Flowers />;
      case "lwMeal":
        return <Meals />;
      case "lwMemories":
        return <Memories />;
      case "lwMedia":
        return <Media />;
      case "partner":
      case "child":
      case "parent":
      case "guardian":
        return <Contacts type={data.category} />;
      default:
        // input.ID = data.ID;
        // props.triggersave(data.category, input);
        return others(data, ms);
    }
  };

  const classes = useStyles();
  React.useEffect(() => {
    let data = mainSection;
    setmainSections(JSON.parse(JSON.stringify(data)));
  }, [props.form.accountData]);

  const changeHandler = (event, category, subCategory) => {
    let data = mainSection;
    data.map((section) => {
      if (section.category === category && section.subtitles) {
        section.subtitles.map((subtitles) => {
          if (subtitles.controls && subtitles.category === subCategory) {
            subtitles.controls.map((control) => {
              if (event.target.name === control.name) {
                control.value = event.target.value;
                if (
                  subtitles.category === "lwFuneralTypeInfo" &&
                  event.target.name === "funeralWay" &&
                  event.target.value === "burried"
                ) {
                  subtitles.controls[2].hidden = false;
                  subtitles.controls[3].hidden = true;
                } else if (
                  subtitles.category === "lwFuneralTypeInfo" &&
                  event.target.name === "funeralWay" &&
                  event.target.value !== "burried"
                ) {
                  subtitles.controls[2].hidden = true;
                  subtitles.controls[3].hidden = false;
                }

                if (
                  subtitles.category === "lwMusicInfo" &&
                  event.target.name === "musicChoice" &&
                  event.target.value === "yes"
                ) {
                  subtitles.controls[1].hidden = false;
                } else if (
                  subtitles.category === "lwMusicInfo" &&
                  event.target.name === "musicChoice" &&
                  event.target.value !== "yes"
                ) {
                  subtitles.controls[1].hidden = true;
                }

                if (
                  subtitles.category === "funeralPolicy" &&
                  event.target.name === "funeralPolicy" &&
                  event.target.value === "yes"
                ) {
                  subtitles.controls[1].hidden = false;
                  subtitles.controls[2].hidden = false;
                } else if (
                  subtitles.category === "funeralPolicy" &&
                  event.target.name === "funeralPolicy" &&
                  event.target.value !== "yes"
                ) {
                  subtitles.controls[1].hidden = true;
                  subtitles.controls[2].hidden = true;
                }
              }
            });
          }
        });
      }
    });
    setmainSections(JSON.parse(JSON.stringify(data)));
  };
  const [mainSection, setmainSections] = React.useState(EWillModel);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    props.addAttribute({
      name: "ewillStep",
      value: isExpanded ? panel : false,
    });
  };
  return (
    <React.Fragment>
      {props.form.isLoading && (
        <div className="overlay-momentio">
          <CircularProgress style={{ width: "100px", height: "100px" }} />
        </div>
      )}
      <AuthHeader />
      <div className="momentio-dashboard-container">
        <SideMenu view="mysafe" />
        <div className="auth-content">
          <div
            className="auth-content-subheader"
          >
            <div className="content-one">{props.form.language.SIDEMENU_S1}</div>
            <div className="mm-login-header"> {"completed 0%"}</div>
          </div>
          <div style={{ padding: "0 15px", paddingBottom: "35px" }} className="momentio-text-bold">
            Op dit moment wordt er nog gewerkt aan de module nalatenschap. Deze
            module is geen onderdeel van de POC.
          </div>
          {mainSection.map((ms) => {
            return (
              <div>
                <div className="ewill">
                  {ms.subtitles &&
                    ms.subtitles.map((subtitle) => {
                      if (subtitle.hidden && !props.form.showLastwill) {
                        return <React.Fragment></React.Fragment>;
                      } else {
                        return (
                          <Accordion
                            className={classes.container}
                            expanded={
                              props.form.ewillStep === subtitle.category
                            }
                            onChange={handleChange(subtitle.category)}
                          >
                            <AccordionSummary
                              aria-controls="panel1bh-content"
                              expandIcon={<ExpandMoreIcon />}
                              id="panel-moment-header"
                            >
                              <div className="mm-login-header">
                                {subtitle.completed ? (
                                  <CheckCircleIcon className="task-completed" />
                                ) : (
                                  <CheckCircleIcon className="task-not-completed" />
                                )}
                                {subtitle.name}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              {getContent(subtitle, ms)}
                            </AccordionDetails>
                          </Accordion>
                        );
                      }
                    })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <AuthFooter />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addAttribute: (payload) => dispatch(addAttribute(payload)),
    triggersave: (name, input) => dispatch(triggersave(name, input)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Ewill);
