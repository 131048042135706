import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import { addAttribute } from "../../Actions/Form";
import TextSnippetOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const MessagePreview = (props) => {

    const getFileType = (desc) => {
        let format = desc.split(".");
        return format.length > 0 ? format[format.length - 1].toLowerCase() : "";
    };
    const getMediaList = (mediaListID) => {
        if (props.form.accountData && props.form.accountData.mediaList) {
            let splitMedia = mediaListID.split(";");
            let currMediaList = [];
            splitMedia.map((item) => {
                if (item) {
                    currMediaList.push(item);
                }
            });
            return (
                <Grid container spacing={2}>
                    {currMediaList.length > 0 &&
                        currMediaList.map((item) => {
                            let currentMedia =
                                props.form.accountData.mediaList.filter(
                                    (media) => item == media.system.id
                                )
                                    ? props.form.accountData.mediaList.filter(
                                          (media) => item == media.system.id
                                      )[0]
                                    : {};
                            let fileType =
                                currentMedia && currentMedia.data
                                    ? getFileType(
                                          currentMedia.data.originalFileName
                                      )
                                    : "";

                            return (
                                <Grid item xs={12} md={12} lg={12}>
                                    <div>
                                        {currentMedia && currentMedia.data && (
                                            <React.Fragment>
                                                <div
                                                    className="message-image-container"
                                                    style={{
                                                        height:
                                                            fileType ===
                                                                "mp3" ||
                                                            fileType ===
                                                                "cpm" ||
                                                            fileType ===
                                                                "ogg" ||
                                                            fileType ===
                                                                "wav" ||
                                                            fileType ===
                                                                "aiff" ||
                                                            fileType ===
                                                                "acc" ||
                                                            fileType ===
                                                                "wma" ||
                                                            fileType === "alac"
                                                                ? "auto"
                                                                : "450px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    {fileType &&
                                                    (fileType === "png" ||
                                                        fileType === "jpg" ||
                                                        fileType === "jpeg" ||
                                                        fileType === "svg" ||
                                                        fileType === "gif") ? (
                                                        <img
                                                            style={{
                                                                height: "450px",
                                                                borderRadius:
                                                                    "8px",
                                                                maxWidth:
                                                                    "100%",
                                                            }}
                                                            src={
                                                                currentMedia
                                                                    ?.system
                                                                    ?.signedUrl
                                                            }
                                                            alt=""
                                                        />
                                                    ) : fileType === "mp3" ||
                                                      fileType === "cpm" ||
                                                      fileType === "ogg" ||
                                                      fileType === "wav" ||
                                                      fileType === "aiff" ||
                                                      fileType === "acc" ||
                                                      fileType === "m4a" ||
                                                      fileType === "wma" ||
                                                      fileType === "alac" ? (
                                                        <audio
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            controls
                                                        >
                                                            <source
                                                                src={
                                                                    currentMedia
                                                                        ?.system
                                                                        ?.signedUrl
                                                                }
                                                                type="audio/ogg"
                                                            />
                                                            Your browser does
                                                            not support the
                                                            audio element.
                                                        </audio>
                                                    ) : fileType === "mp4" ||
                                                      fileType === "mov" ||
                                                      fileType === "avi" ||
                                                      fileType === "flv" ||
                                                      fileType === "wmv" ||
                                                      fileType === "webm" ||
                                                      fileType === "mpg" ||
                                                      fileType === "mp2" ||
                                                      fileType === "mpeg" ||
                                                      fileType === "mpe" ||
                                                      fileType === "mpv" ||
                                                      fileType === "ogg" ||
                                                      fileType === "swf" ? (
                                                        <video
                                                            controls
                                                            style={{
                                                                height: "450px",
                                                                borderRadius:
                                                                    "8px",
                                                                maxWidth:
                                                                    "100%",
                                                                width: "inherit",
                                                            }}
                                                        >
                                                            <source
                                                                src={
                                                                    currentMedia
                                                                        ?.system
                                                                        ?.signedUrl
                                                                }
                                                                type="video/mp4"
                                                            />
                                                            Your browser does
                                                            not support HTML
                                                            video.
                                                        </video>
                                                    ) : (
                                                        <TextSnippetOutlinedIcon
                                                            style={{
                                                                height: "450px",
                                                                borderRadius:
                                                                    "8px",
                                                                maxWidth:
                                                                    "100%",
                                                                width: "inherit",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    className="message-image-desc"
                                                    style={{
                                                        marginBottom: "45px",
                                                        paddingLeft: 0,
                                                    }}
                                                >
                                                    <div className="mm-login-header">
                                                        {" "}
                                                        {currentMedia.data.name}
                                                    </div>
                                                    <div className="momentio-text-normal">
                                                        {
                                                            currentMedia.meta
                                                                .additionalInfo
                                                        }
                                                    </div>
                                                    <div className="momentio-text-normal"></div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </Grid>
                            );
                        })}
                    {currMediaList.length === 0 && (
                        <div style={{ padding: "10px" }}></div>
                    )}
                </Grid>
            );
        }
    };
    return (
        <div className="auth-content messagePreview">
            <div className="auth-content-subheader ">
                <div className="header-title-section-login">
                    {props.form.language.MOMENTIO_LEGACY}
                </div>
            </div>
            <div className="messages ewill">
                <div>
                    <div className="n_paragraph" style={{position: "relative"}}>
                        <span
                            className="show-flex-sm preview-back-icon"
                            onClick={() => props.setOpenPreview()}
                        >
                            <ArrowBackIcon />
                        </span>
                        <div>{` From: ${props.form.accountData.profile?.privateData?.firstName} ${props.form.accountData.profile?.privateData?.lastName}`}</div>
                    </div>
                    <div className="content-one">{props.row.data.title} </div>

                    <div className="header24Medium" style={{whiteSpace: "pre-wrap"}}>
                        {props.row.data.message}
                    </div>
                </div>

                {getMediaList(props.row.data.mediaID)}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MessagePreview)
);
