import React from "react";
import HomePage from "./Components/HomePage";
import { Switch, Route, useParams } from "react-router-dom";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import { withRouter } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import Mysafe from "./Components/Safe/Mysafe";
import Files from "./Components/Safe/Files";
import Confidant from "./Components/Contacts/Confidant";
import Family from "./Components/Contacts/Family";
import Friends from "./Components/Contacts/Friends";
import AllContacts from "./Components/Contacts/AllContacts";
import EWill from "./Components/LastWishes/Steps/EWill";
import AllMessage from "./Components/Messages/AllMessage";
import PersonalInfo from "./Components/Safe/PersonalInformation/PersonalInfo";
import ImportantInfo from "./Components/Safe/ImportantInformation/ImportantInfo";
import Private from "./Components/Contacts/Private";
import Others from "./Components/Contacts/Others";
import Healthcare from "./Components/Contacts/Healthcare";
import Financial from "./Components/Contacts/Financial";
import Lastwishes from "./Components/LastWishes/Lastwishes";
import Notification from "./Components/Alerts/Notification";
import Profile from "./Components/Profile/Profile";
import ResetPassword from "./Components/Login/ResetPassword";
import MessagePreview from "./Components/Messages/Preview";
import RecievedMessages from "./Components/Messages/RecievedMessages";
import Subscription from "./Components/Subscription/Subscription";
import SubscriptionResult from "./Components/Subscription/SubscriptionResult";
import AllLegacy from "./Components/Legacy/AllLegacy";

const App = (props) => {
    return (
        <Switch>
            <Route
                exact
                path="/personalwish"
                component={() => <PersonalInfo />}
            />
            <Route
                exact
                path="/notifications"
                component={() => <Notification />}
            />
            <Route exact path="/profile" component={() => <Profile />} />
            <Route
                exact
                path="/importantInfo"
                component={() => <ImportantInfo />}
            />
            <Route exact path="/private" component={() => <Private />} />
            <Route exact path="/others" component={() => <Others />} />
            <Route exact path="/healthcare" component={() => <Healthcare />} />
            <Route exact path="/financial" component={() => <Financial />} />
            <Route exact path="/lastwishes" component={() => <Lastwishes />} />
            <Route
                exact
                path={["/", "/login", "/login/:language"]}
                component={() => <Login />}
            />
            <Route
                exact
                path={["/resetpassword", "/resetpassword/:language"]}
                component={() => <ResetPassword />}
            />
            <Route
                exact
                path={["/register", "/register/:language"]}
                component={() => <Register />}
            />
            <Route
                exact
                path="/recievedMessage"
                component={() => <RecievedMessages />}
            />
            <Route exact path="/dashboard" component={() => <Dashboard />} />
            <Route exact path="/mysafe" component={() => <Mysafe />} />
            <Route exact path="/files" component={() => <Files />} />
            <Route exact path="/emergency" component={() => <Confidant />} />
            <Route exact path="/family" component={() => <Family />} />
            <Route exact path="/friends" component={() => <Friends />} />
            <Route
                exact
                path="/allContacts"
                component={() => <AllContacts />}
            />
            <Route exact path="/ewill" component={() => <EWill />} />
            <Route exact path="/messages" component={() => <AllMessage />} />
            <Route exact path="/preview" component={() => <MessagePreview />} />
            <Route
                exact
                path="/subscription"
                component={() => <Subscription />}
            />
            <Route exact path="/legacy" component={() => <AllLegacy />} />
            <Route
                exact
                path={["/subscriptionresult", "/subscriptionresult/:orderID"]}
                component={() => <SubscriptionResult />}
            />
            {localStorage.token && (
                <Route
                    path={["/", "/?:orderID"]}
                    exact
                    component={() => <Dashboard />}
                />
            )}
            {/* <Route exact path="/index.html" component={() => <HomePage />} />
            <Route exact path="/" component={() => <HomePage />} /> */}
        </Switch>
    );
};

export default withRouter(App);
