import * as actionTypes from "../Constants/actionTypes";
import { updateObject } from "../Utility/Utility";
import * as globalDUT from "../Constants/i18n/dut";
import * as globalENU from "../Constants/i18n/enu";
//import moment from "moment-timezone";


//console.log(moment().tz("America/Los_Angeles").zoneAbbr())

const language = navigator.language || window.navigator.language;

const initialState = {
  activeStep: "",
  isLoading: false,
  errors: {},
  currentLanguage: language ? (language.toUpperCase().includes("nl") ? "NL" : "US") : "NL",
  language: language.includes("nl") ? globalDUT : globalENU,
  success: false,
  activeUser: false,
  hideMobileMenu:true,
  isValid: false,
  showLastwill: true
};
const reducer = (state = { ...initialState }, action) => {
  let newState = { ...state };
  switch (action.type) {
    case actionTypes.RESET_FORM:
      let init = {
        activeStep: "",
        isLoading: false,
        errors: {},
        //currentLanguage: language.includes("nl") ? "NL" : "US",
        //language: language.includes("nl") ? globalDUT : globalENU,
        success: false,
        activeUser: false,
        isValid: false,
        hideMobileMenu:true,
        isSessionExpired: false,
        showLastwill: true
      };
      return init;
    case actionTypes.SET_LANGUAGE:
      return updateObject(newState, { currentLanguage: action.payload, language: action.payload?.toLowerCase()?.includes("nl") ? globalDUT : globalENU });
    case actionTypes.ACTIVE_USER:
      return updateObject(newState, { activeUser: action.payload });
    case actionTypes.ACTIVE_STATE:
      return updateObject(newState, { activeStep: action.payload });
    case actionTypes.UPDATE_STATE:
      return updateObject(newState, {
        [action.payload.name]: action.payload.value,
      });
      case actionTypes.ADD_ATTRIBUTE: 
      return updateObject(newState, { [action.payload.name]: action.payload.value })
    case actionTypes.LOGIN_FAILED:
      return updateObject(newState, { errors: { msg: action.payload } });
    case actionTypes.LOGIN_SUCCESS:
      return updateObject(newState, { success: action.payload });
      case "logOut":
        return  updateObject(newState);
      case actionTypes.IS_LOADING:
        return updateObject(newState, { isLoading: action.payload });
    default:
      return newState;
  }
};

export default reducer;
