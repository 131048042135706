/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

    function getIV(aesIVValue){
      //  const aesIVInput = document.getElementById("aesIVValue");
        let iv = aesIVValue;
        let ivBuf = new ArrayBuffer(iv.length); // 2 bytes for each char
        let ivBufView = new Uint8Array(ivBuf);
        for (let i = 0, strLen = iv.length; i < strLen; i++) {
            ivBufView[i] = iv.charCodeAt(i);
        }
        iv = ivBuf;
        return iv;
    }

    function getDataToDecrypt(aesDecryptInput){
      //  const aesDecryptInput = document.getElementById("aesDecryptInput");
        let encryptedData = aesDecryptInput;
        encryptedData = window.atob(encryptedData);
        let buf = new ArrayBuffer(encryptedData.length); // 2 bytes for each char
        let bufView = new Uint8Array(buf);
        for (let i = 0, strLen = encryptedData.length; i < strLen; i++) {
            bufView[i] = encryptedData.charCodeAt(i);
        }
       return buf;
    }

    function getAesKey(aesKeyValue){
       // const aesKeyValue = document.getElementById("aesKeyValue");
        let aesKey = aesKeyValue;

        let buf = new ArrayBuffer(aesKey.length); // 2 bytes for each char
        let bufView = new Uint8Array(buf);
        for (let i = 0, strLen = aesKey.length; i < strLen; i++) {
            bufView[i] = aesKey.charCodeAt(i);
        }
        return buf;
    }

    function setDecryptedValue(value){
        const aesDecryptOutputValue = document.getElementById("aesDecryptOutput");
        aesDecryptOutputValue.value = value;
        console.log(value)
    }

    function setEncryptedValue(value){
        const aesEncryptOutputValue = document.getElementById("aesEncryptOutput");
        aesEncryptOutputValue.value = value;
    }

    export async function encryptMessage(key, aesIVValue, aesKeyValue,  input) {

        var dec = new TextDecoder(); // always utf-8
        var enc = new TextEncoder(); // always utf-8

      //  let aesEncryptInputTemp = document.getElementById("aesEncryptInput");
        let data = input;
        data = enc.encode(data);

        // IV
        let iv = getIV(aesIVValue);

        // Key
        let importKey = getAesKey(aesKeyValue);

        let newKey =  await window.crypto.subtle.importKey(
            "raw",
            importKey,
            {
                name: "AES-CBC",
            },
            true,
            ["encrypt", "decrypt"]
        );

        let encrypted = await window.crypto.subtle.encrypt(
            {
                name: "AES-CBC",
                iv
            },
            newKey,
            data
        ); 
        encrypted = btoa(String.fromCharCode.apply(null, new Uint8Array(encrypted)))
      //  setEncryptedValue(encrypted);
        return encrypted;
    }

    export async function decryptMessage(key, aesIVValue, aesKeyValue, aesDecryptInput) {
        var dec = new TextDecoder(); // always utf-8
        var enc = new TextEncoder(); // always utf-8

        // IV
        let iv = getIV(aesIVValue);

        // Data
        let data = getDataToDecrypt(aesDecryptInput);

        // Key
        let importKey = getAesKey(aesKeyValue);

        let newKey =  await window.crypto.subtle.importKey(
            "raw",
            importKey,
            {
                name: "AES-CBC",
            },
            true,
            ["encrypt", "decrypt"]
        );

        let decrypted = await window.crypto.subtle.decrypt(
            {
                name: "AES-CBC",
                iv,
            },
            newKey,
            data
        );
        decrypted = dec.decode(decrypted);
       // setDecryptedValue(decrypted);
        return decrypted;
    }

    /*
    Generate an encryption key, then set up event listeners
    on the "Encrypt" and "Decrypt" buttons.
    */
    window.crypto.subtle.generateKey(
        {
            name: "AES-CBC",
            length: 256
        },
        true,
        ["encrypt", "decrypt"]
    )

