/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// eslint-disable jsx-a11y/anchor-is-valid
// eslint-disable jsx-a11y/alt-text
// eslint-disable react/jsx-no-target-blank

import {
    Paper,
    Popper,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import React from "react";
import { connect } from "react-redux";
import {
    addAttribute,
    AddContactAPI,
    AddProfileAPI,
    loginAccount,
} from "../../Actions/Form";
import { DeleteContactAPI } from "./actions";
import AuthFooter from "../Common/AuthFooter";
import AuthHeader from "../Common/AuthHeader";
import SideMenu from "../Common/SideMenu";
import AddFile from "./AddFile";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import TextSnippetOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import update from "immutability-helper";
import {
    useGetAPICallQuery,
    useDeleteAPICallMutation,
} from "../../app/services/account";
import { getTagLabel } from "../Contacts/ContactTags";
import { model } from "../../Models/Media";
import { entityTagList, sortAscending, sortContactAscending } from "../../Utility/Utility";

const Files = (props) => {
    const getDate = (date) => {
        let newDate = new Date(date);
        return (
            newDate.getDate() +
            "-" +
            parseInt(newDate.getMonth() + 1) +
            "-" +
            newDate.getFullYear()
        );
    };
    const mediaList = useGetAPICallQuery("media");
    const [deleteAccount, { isLoading }] = useDeleteAPICallMutation();
    const contactList = useGetAPICallQuery("contact", {skip: !props.form.accountData?.profile?.limits?.accessList?.contact});
    const messageListQuery = useGetAPICallQuery("message", {skip: !props.form.accountData?.profile?.limits?.accessList?.message});
    React.useEffect(() => {
        if (mediaList.data && mediaList.data?.mediaList && contactList.data?.contactList && !props.isMessage) {
            let oldMedia = [...mediaList.data?.mediaList];
            let oldContact = contactList.data?.contactList;

            if (props.form.selectedMessage || props.isMessage) {
                let split = props.form.selectedMessage.data.mediaID.split(";");
                if (split.length > 0) {
                    split.map((item) => {
                        if (item) {
                            mediaList.data.mediaList.map((media, index) => {
                                if (
                                    parseInt(item) === parseInt(media.system.id)
                                ) {
                                    oldMedia = update(oldMedia, {
                                        [index]: {
                                            $set: { ...media, checked: true },
                                        },
                                    });
                                }
                            });
                        }
                    });
                }

                let contactSplit =
                    props.form.selectedMessage.delivery.contactID.split(";");
                if (contactSplit.length > 0 && contactList.data?.contactList) {
                    contactSplit.map((item) => {
                        if (item) {
                            contactList.data.contactList.map(
                                (contact, index) => {
                                    if (
                                        parseInt(item) ===
                                        parseInt(contact.system.id)
                                    ) {
                                        oldContact = update(oldContact, {
                                            [index]: {
                                                $set: {
                                                    ...contact,
                                                    checked: true,
                                                },
                                            },
                                        });
                                    }
                                }
                            );
                        }
                    });
                }
            }

            let contactTags = entityTagList(sortContactAscending(oldContact));
            let mediaTags = entityTagList(oldMedia);
            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    mediaList: sortAscending(oldMedia),
                    contactList: sortContactAscending(oldContact),
                    contactTags: contactTags,
                    mediaTags: mediaTags,
                },
            });
        }
    }, [mediaList.data?.mediaList]);

    const handleDelete = async (ID) => {
        await deleteAccount({
            url: `media/${ID}`,
            method: "DELETE",
        })
            .unwrap()
            .then((response) => {
                mediaList.refetch();
                props.addAttribute({
                    name: "editFile",
                    value: {},
                });
            });
    };
    const hideEdit = () => {
        props.addAttribute({
            name: "editFile",
            value: {},
        });
        setaddNew(false);
        setFile(null);
    };
    const [addNew, setaddNew] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
        setaddNew(true);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [category, setCategory] = React.useState("");

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const closePopper = () => {
        setAnchorEl(null);
    };

    const changeHandler = (event) => {
        setCategory(event.target.value);
    };

    const open = Boolean(anchorEl);

    const getDescription = (desc) => {
        let format = desc.split("/");
        return format.length > 0 ? format[format.length - 1] : "";
    };

    const getFileType = (desc) => {
        let format = desc.split(".");
        return format.length > 0 ? format[format.length - 1].toLowerCase() : "";
    };

    const getMediaList = (currentMedia) => {
        let fileType = getFileType(currentMedia.data.originalFileName);
        return (
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ padding: "15px ​24px 0px 0" }}
                >
                    <div className="message-grid">
                        <div className="message-image-container">
                            {fileType &&
                            (fileType === "png" ||
                                fileType === "jpg" ||
                                fileType === "jpeg" ||
                                fileType === "svg" ||
                                fileType === "gif") ? (
                                <img
                                    src={currentMedia?.system?.signedUrl}
                                    alt=""
                                />
                            ) : fileType === "mp3" ||
                              fileType === "cpm" ||
                              fileType === "ogg" ||
                              fileType === "wav" ||
                              fileType === "aiff" ||
                              fileType === "acc" ||
                              fileType === "m4a" ||
                              fileType === "wma" ||
                              fileType === "alac" ? (
                                <audio
                                    style={{ height: "100px", width: "160px" }}
                                    controls
                                >
                                    <source
                                        src={currentMedia?.system?.signedUrl}
                                        type="audio/ogg"
                                    />
                                    Your browser does not support the audio
                                    element.
                                </audio>
                            ) : fileType === "mp4" ||
                              fileType === "mov" ||
                              fileType === "avi" ||
                              fileType === "flv" ||
                              fileType === "wmv" ||
                              fileType === "webm" ||
                              fileType === "mpg" ||
                              fileType === "mp2" ||
                              fileType === "mpeg" ||
                              fileType === "mpe" ||
                              fileType === "mpv" ||
                              fileType === "ogg" ||
                              fileType === "swf" ? (
                                <video width="160" height="160" controls>
                                    <source
                                        src={currentMedia?.system?.signedUrl}
                                        type="video/mp4"
                                    />
                                    Your browser does not support HTML video.
                                </video>
                            ) : (
                                <TextSnippetOutlinedIcon />
                            )}
                        </div>
                        <div
                            className="message-image-desc"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                {/*<div className="momentio-text-bold">
                                    {" "}
                                    {getDescription(
                                        currentMedia.data.originalFileName
                                    )}
                                    </div>*/}
                                <div className="momentio-text-normal">
                                    {" "}
                                    {currentMedia.meta.additionalInfo}
                                </div>
                            </div>
                            <div
                                className=""
                                onClick={(event) => {
                                    props.addAttribute({
                                        name: "editFile",
                                        value: {
                                            name: currentMedia.data.name,
                                            description:
                                                currentMedia.meta
                                                    .additionalInfo,
                                            ID: currentMedia.system.id,
                                            fileName:
                                                currentMedia.data
                                                    .originalFileName,
                                            tags: currentMedia.meta.tags,
                                        },
                                    });
                                    handleClick(event);
                                }}
                            >
                                <MoreHorizIcon className="show-menu-icon" />
                            </div>
                            {props.form.editFile &&
                                props.form.editFile.ID ===
                                    currentMedia.system.id && (
                                    <Popper
                                        open={open}
                                        anchorEl={anchorEl}
                                        style={{ zIndex: 100 }}
                                    >
                                        <div
                                            className="menu-container"
                                            onMouseLeave={() =>
                                                closePopper(false)
                                            }
                                            style={{
                                                marginLeft: "-175px",
                                                marginTop: "0px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <div
                                                className="menu-options"
                                                onClick={() => {
                                                    setaddNew(true);
                                                    closePopper("bottom-end");
                                                }}
                                            >
                                                {props.form.language.EDIT}
                                            </div>
                                            <div
                                                className="menu-options"
                                                onClick={() => {
                                                    closePopper("bottom-end");
                                                    handleDelete(
                                                        props.form.editFile.ID
                                                    );
                                                }}
                                            >
                                                {props.form.language.DELETE}
                                            </div>
                                            <div className="menu-options">
                                                <a
                                                    style={{ color: "inherit" }}
                                                    href={
                                                        currentMedia?.system
                                                            ?.signedUrl
                                                    }
                                                    target="_blank"
                                                    download
                                                >
                                                    Download
                                                </a>
                                            </div>
                                        </div>
                                    </Popper>
                                )}
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    };

    const getSharedWithList = (mediaId) => {

        let sharedContact = "";
        if(messageListQuery.data && messageListQuery.data.messageList) {
            messageListQuery.data.messageList.map((msg) => {
                let splitMedia = msg.data.mediaID.split(";");
    
                splitMedia.map((item) => {
                    if (item == mediaId) {
                        sharedContact = msg.delivery.contactID;
                    }
                });
            });
        }
        
        return (
            <>
                {sharedContact && contactList.data?.contactList &&
                    sharedContact.split(";").map((item) => {
                        if (item) {
                            let contact = contactList.data.contactList.filter(
                                (contact) => contact.system.id == item
                            )
                                ? contactList.data.contactList.filter(
                                      (contact) => contact.system.id == item
                                  )[0]
                                : "";
                            if (contact) {
                                return (
                                    <span className="shared-file" title={`${contact.privateData.firstName} ${contact.privateData.lastName}`}>
                                        {`${contact.privateData.firstName
                                            .slice(0, 1)
                                            .toUpperCase()}${contact.privateData.lastName
                                            .slice(0, 1)
                                            .toUpperCase()}`}
                                    </span>
                                );
                            }
                        }
                    })}
            </>
        );

        //return sharedContact
    };

    const handleChange = (id) => (event, isExpanded) => {
        //window.scrollTo(0, 100);
        props.addAttribute({
            name: "currentMedia",
            value: isExpanded ? id : false,
        });
    };

    return (
        <React.Fragment>
            {addNew && (
                <AddFile
                    hideEdit={() => hideEdit()}
                    addNew={addNew}
                    file={file}
                    query={mediaList}
                />
            )}
            {(isLoading || mediaList.isLoading 
            || mediaList.isFetching
            ) && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <AuthHeader />
            <div className="momentio-dashboard-container">
                <SideMenu view="mysafe" />
                <div className="auth-content">
                    <div className="auth-content-subheader">
                        <div className="content-one">
                            {props.form.language.DASHBOARD_S19}
                        </div>
                        <input
                            style={{ display: "none" }}
                            type="file"
                            autoComplete="off"
                            id="uploadFile"
                            onChange={handleOnChange}
                        />
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                            className="files-container-column"
                        >
                            <div className="login-field-input">
                                <select
                                    className="mm-field-input "
                                    value={category}
                                    autoComplete="off"
                                    onChange={(event) => changeHandler(event)}
                                    style={{
                                        margin: "0 24px 0 0",
                                        width: "200px",
                                    }}
                                >
                                    <option value="">
                                        {
                                            props.form.language
                                                .MYFILE_FILTER_CATEGORY
                                        }
                                    </option>

                                    {props.form.accountData &&
                                        props.form.accountData.mediaTags &&
                                        props.form.accountData.mediaTags.map(
                                            (opt) => {
                                                let filteredTags =
                                                    model.tags.options.filter(
                                                        (item) =>
                                                            item.value === opt
                                                    )[0];
                                                return (
                                                    <option value={opt}>
                                                        {filteredTags
                                                            ? props.form
                                                                  .language[
                                                                  filteredTags
                                                                      .label
                                                              ]
                                                            : opt}
                                                    </option>
                                                );
                                            }
                                        )}
                                </select>
                            </div>
                            <button
                                className="primary-button-blue hideSM"
                                style={{ textAlign: "center" }}
                                onClick={() => {
                                    props.addAttribute({
                                        name: "editFile",
                                        value: {},
                                    });
                                    document
                                        .getElementById("uploadFile")
                                        .click();
                                }}
                            >
                                {props.form.language.MYFILE_S1}
                            </button>
                            <span
                                className="primary-alert-blue-sm showSM"
                                onClick={() => {
                                    props.addAttribute({
                                        name: "editFile",
                                        value: {},
                                    });
                                    document
                                        .getElementById("uploadFile")
                                        .click();
                                }}
                                style={{ marginTop: "-2px" }}
                            >
                                <AddIcon />
                            </span>
                        </div>
                    </div>

                    <div
                        style={{
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            borderRadius: "24px",
                            width: "100%",
                        }}
                    >
                        <div className="messages ewill">
                            <Accordion className="contactAccordion hideXS">
                                <AccordionSummary
                                    aria-controls="panel1bh-content"
                                    id="panel-moment-header"
                                    disabled={true}
                                    expandIcon={
                                        <ExpandMoreIcon
                                            style={{ visibility: "hidden" }}
                                            disabled={true}
                                        />
                                    }
                                >
                                    <Typography
                                        className="contacts-table-header"
                                        style={{
                                            paddingLeft: "20px !important",
                                        }}
                                    >
                                        <span>
                                            {props.form.language.DESCRIPTION_LABEL.toUpperCase()}
                                        </span>
                                    </Typography>
                                    <Typography className="contacts-table-header">
                                        {props.form.language.CATEGORY_LABEL.toUpperCase()}
                                    </Typography>
                                    {/*<Typography className="contacts-table-header">
                                        {props.form.language.ADDED_ON}
                                    </Typography>*/}
                                    <Typography className="contacts-table-header">
                                        {props.form.language.SHARED_WITH}
                                    </Typography>
                                </AccordionSummary>
                            </Accordion>
                            {props.form.accountData &&
                                props.form.accountData.mediaList &&
                                props.form.accountData.mediaList.map((row) => {
                                    if (
                                        row.meta.tags.includes(category) ||
                                        category === ""
                                    )
                                        return (
                                            <Accordion
                                                expanded={
                                                    props.form.currentMedia ===
                                                    row.system.id
                                                }
                                                onChange={handleChange(
                                                    row.system.id
                                                )}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                    className="header-container-sm"
                                                >
                                                    <Typography className="contact-summary-header header-sm">
                                                        <span className="contactListSection mm-login-header">
                                                            {row.data.name}
                                                        </span>
                                                    </Typography>
                                                    <Typography className="contact-summary-header hideXS">
                                                        <span className="contactListSection">
                                                            {getTagLabel(
                                                                row.meta.tags,
                                                                props,
                                                                model.tags
                                                                    .options
                                                            )}
                                                        </span>
                                                    </Typography>
                                                    {/*<Typography className="contact-summary-header hideXS">
                                                        <span className="contactListSection">
                                                            {row.expireDateTime
                                                                ? getDate(
                                                                      row.expireDateTime
                                                                  )
                                                                : ""}
                                                        </span>
                                                                </Typography>*/}
                                                    <Typography className="contact-summary-header hideXS">
                                                        <span
                                                            className="contactListSection"
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            {getSharedWithList(
                                                                row.system.id
                                                            )}
                                                        </span>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    style={{
                                                        padding:
                                                            "33px 32px 32px 32px",
                                                    }}
                                                >
                                                    {getMediaList(row)}
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                })}
                        </div>
                    </div>
                </div>
            </div>
            <AuthFooter />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(loginAccount(payload)),
        addAttribute: (payload) => dispatch(addAttribute(payload)),
        AddContactAPI: (payload, type) =>
            dispatch(AddContactAPI(payload, type)),
        AddProfileAPI: (payload, type) =>
            dispatch(AddProfileAPI(payload, type)),
        DeleteContactAPI: (input, name) =>
            dispatch(DeleteContactAPI(input, name)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Files);
