/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { addAttribute, AddMessageAPI } from "../../Actions/Form";
import SelectMedia from "./SelectMedia";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    useGetAPICallQuery,
    useMutationAPICallMutation,
} from "../../app/services/account";
import SelectContact from "./SelectContact";
import update from "immutability-helper";
import MessageAttachments from "./MessageAttachments";
import { validateField, sortAscending, sortContactAscending } from "../../Utility/Utility";
import SelectTags from "./SelectTags";
import moment from "moment";

const AddMessage = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [updateMessage, { isLoading }] = useMutationAPICallMutation();
    const contactList = useGetAPICallQuery("contact");
    const mediaList = useGetAPICallQuery("media");
    const [contactData, setcontactData] = React.useState({
        contacts: {
            value: [],
            // validations: { required: true },
            valid: true,
        },
        messageContentType: {
            value: [],
            // validations: { required: true },
            options: [
                {
                    label: "CONTACT_TYPE_FINANCIAL",
                    value: "financial",
                },
                {
                    label: "MESSAGE_PERSONAL",
                    value: "personal",
                },
                {
                    label: "MESSAGE_INSTRUCTION",
                    value: "instruction",
                },
                {
                    label: "MESSAGE_FAREWELL",
                    value: "farewell",
                },
            ],
            valid: true,
        },
        attachments: {
            value: [],
            // validations: { required: true },
            valid: true,
        },
        name: {
            value: "",
            validations: { required: true },
            valid: false,
            text: "FUTURE_MESSAGE_S6",
        },
        content: {
            value: "",
            validations: { required: true },
            valid: false,
            text: "FUTURE_MESSAGE_S8",
        },
        deliveryDate: {
            value: "",
            valid: true,
        },
        deliveryMonth: {
            value: "",
            valid: true,
        },
        deliveryYear: {
            value: "",
            valid: true,
        },
        deliveryTimeDecided: {
            value: "",
            valid: true,
        },
    });

    const formatdaymonth = (value) => {
        if(value.toString().length > 1) {
            return value;
        }else {
            return "0" + value;
        }
    }
    React.useEffect(() => {
        if (!props.form.openFutureMedia && !props.form.openFutureContact  && contactList.data && mediaList.data) {
            let oldMedia = mediaList.data?.mediaList;
            let oldContact = contactList.data?.contactList;
            if(props.form.accountData.mediaList){
                props.form.accountData.mediaList.map(item => {
                    mediaList.data.mediaList.map((media, index) => {
                        if (
                            parseInt(item.system.id) === parseInt(media.system.id) && item.checked
                        ) {
                            oldMedia = update(oldMedia, {
                                [index]: {
                                    $set: { ...media, checked: true },
                                },
                            });
                        }
                    });
                })
            }
            
           if(props.form.accountData.contactList){
            props.form.accountData.contactList.map(item => {
                contactList.data.contactList.map(
                    (contact, index) => {
                        if (
                            parseInt(item.system.id) ===
                            parseInt(contact.system.id)
                            && item.checked
                        ) {
                            oldContact = update(oldContact, {
                                [index]: {
                                    $set: {
                                        ...contact,
                                        checked: true,
                                    },
                                },
                            });
                        }
                    }
                );
            })
           }
            
            
            if (props.form.selectedMessage) {
                const date = props.form.selectedMessage.data.messageDate
                    ? new Date(props.form.selectedMessage.data.messageDate)
                    : "";
                let tags = [];
                let options = contactData.messageContentType.options;
                let tagsSplit = props.form.selectedMessage.meta.tags.split(";");
                if (tagsSplit.length > 0) {
                    tagsSplit.map((tag) => {
                        if (tag) {
                            let filter =
                                contactData.messageContentType.options.filter(
                                    (item) => item.value === tag
                                )[0];
                            if (!filter) {
                                options.push({
                                    label: tag,
                                    value: tag,
                                });
                            }
                            tags.push(tag);
                        }
                    });
                }
                if (props.form.accountData.messageTags) {
                    props.form.accountData.messageTags.map((item) => {
                        let filteredTag = options.filter(
                            (opt) => item === opt.value
                        )[0];
                        if (!filteredTag && item) {
                            options.push({
                                label: item,
                                value: item,
                            });
                        }
                    });
                }

                setcontactData({
                    ...contactData,
                    messageContentType: {
                        ...contactData.messageContentType,
                        options: options,
                        value: tags,
                    },
                    name: {
                        ...contactData.name,
                        value: props.form.selectedMessage.data.title,
                    },
                    content: {
                        ...contactData.content,
                        value: props.form.selectedMessage.data.message,
                    },
                    deliveryYear: {
                        ...contactData.deliveryYear,
                        value: date ? date.getFullYear() : "",
                    },
                    deliveryMonth: {
                        ...contactData.deliveryMonth,
                        value: date ? formatdaymonth(date.getMonth() + 1) : "",
                    },
                    deliveryDate: {
                        ...contactData.deliveryMonth,
                        value: date ? formatdaymonth(date.getDate()) : "",
                    },
                });

                let split = props.form.selectedMessage.data.mediaID.split(";");
                if (split.length > 0) {
                    split.map((item) => {
                        if (item) {
                            mediaList.data.mediaList.map((media, index) => {
                                if (
                                    parseInt(item) === parseInt(media.system.id)
                                ) {
                                    oldMedia = update(oldMedia, {
                                        [index]: {
                                            $set: { ...media, checked: true },
                                        },
                                    });
                                }
                            });
                        }
                    });
                }

                let contactSplit =
                    props.form.selectedMessage.delivery.contactID.split(";");
                if (contactSplit.length > 0) {
                    contactSplit.map((item) => {
                        if (item) {
                            contactList.data.contactList.map(
                                (contact, index) => {
                                    if (
                                        parseInt(item) ===
                                        parseInt(contact.system.id)
                                    ) {
                                        oldContact = update(oldContact, {
                                            [index]: {
                                                $set: {
                                                    ...contact,
                                                    checked: true,
                                                },
                                            },
                                        });
                                    }
                                }
                            );
                        }
                    });
                }
            } else {
                if (props.form.accountData.messageTags) {
                    let options = contactData.messageContentType.options;
                    props.form.accountData.messageTags.map((item) => {
                        let filteredTag = options.filter(
                            (opt) => item === opt.value
                        )[0];
                        if (!filteredTag && item) {
                            options.push({
                                label: item,
                                value: item,
                            });
                        }
                    });
                    setcontactData({
                        ...contactData,
                        messageContentType: {
                            ...contactData.messageContentType,
                            options: options,
                        },
                    });
                }
            }
            console.log("00000000000")
            props.addAttribute({
                name: "accountData",
                value: {
                    ...props.form.accountData,
                    mediaList: sortAscending(oldMedia),
                    contactList: sortContactAscending(oldContact),
                },
            });
        }
    }, [
        props.form.selectedMessage ||
            props.contact ||
            mediaList.data ||
            contactList.data,
    ]);

    const hidefutureFormPopup = () => {
        props.addAttribute({
            name: "futureFormPopup",
            value: false,
        });
        props.addAttribute({
            name: "editFutureMessage",
            value: {},
        });
        props.addAttribute({
            name: "openFutureTags",
            value: false,
        });

        props.addAttribute({
            name: "openFutureContact",
            value: false,
        });

        props.addAttribute({
            name: "openFutureMedia",
            value: false,
        });

        let oldMedia = mediaList.data?.mediaList;
        let oldContact = contactList.data?.contactList;
        if (props.form.accountData.mediaList) {
            props.form.accountData.mediaList.map((item, index) => {
                oldMedia = update(oldMedia, {
                    [index]: {
                        $set: { ...item, checked: false },
                    },
                });
            });
        }

        if (props.form.accountData.contactList) {
            props.form.accountData.contactList.map((item, index) => {
                oldContact = update(oldContact, {
                    [index]: {
                        $set: {
                            ...item,
                            checked: false,
                        },
                    },
                });
            });
        }
        props.addAttribute({
            name: "accountData",
            value: {
                ...props.form.accountData,
                mediaList: sortAscending(oldMedia),
                contactList: sortContactAscending(oldContact),
            },
        });

        props.addAttribute({
            name: "selectedMessage",
            value: null,
        });
    };

    const setTags = () => {
        if (props.form.accountData.messageTags) {
            let options = contactData.messageContentType.options;
            props.form.accountData.messageTags.map((item) => {
                let filteredTag = options.filter(
                    (opt) => item === opt.value
                )[0];
                if (!filteredTag && item) {
                    options.push({
                        label: item,
                        value: item,
                    });
                }
            });
            return {
                ...contactData.messageContentType,
                options: options,
            };
        } else {
            return contactData.messageContentType;
        }
    };

    const changeHandler = async (event) => {
        let newData = JSON.parse(JSON.stringify(contactData));
        if (event.currentTarget.name === "Agreement") {
            newData[event.currentTarget.name].value =
                event.currentTarget.checked;
        } else if (event.currentTarget.name === "Country") {
            newData[event.currentTarget.name].value = event;
        } else if (newData[event.currentTarget.name]) {
            newData[event.currentTarget.name].value = event.currentTarget.value;
        }

        //let validation = await validateField(newData, props.form.language);
        setcontactData(newData);
    };

    async function submit(newData, action) {
        let isFormValid = await validateField(newData, props.form.language);
        setcontactData(isFormValid.formatData);
        let isValid = true;
        Object.keys(contactData).map((data) => {
            if (contactData[data].valid === false) {
                isValid = false;
            }
        });
        if (isValid) {
            let selectedMediaList = props.form.accountData.mediaList.filter(
                (data) => data.checked === true
            );
            let mediaListID = ";";
            if (selectedMediaList.length > 0) {
                selectedMediaList.map((media) => {
                    mediaListID = mediaListID + media.system.id + ";";
                });
            }

            let selectedContactList = props.form.accountData.contactList.filter(
                (data) => data.checked === true
            );
            let contactListID = ";";
            if (selectedContactList.length > 0) {
                selectedContactList.map((contact) => {
                    contactListID = contactListID + contact.system.id + ";";
                });
            }

            let requestData = {
                url: props.form.selectedMessage?.system?.id
                    ? `message/${props.form.selectedMessage.system.id}`
                    : "message",
                method: props.form.selectedMessage && props.form.selectedMessage?.system?.id ? "PUT" : "POST",
                meta: {
                    tags: contactData.messageContentType.value.join(";") + ";",
                    additionalInfo: "additional info",
                },
                data: {
                    messageType: "memory",
                    title: contactData.name.value,
                    message: contactData.content.value,
                    mediaID: mediaListID,
                },
                location: {
                    countryISOCode: "NL",
                },
                delivery: {
                    contactID: contactListID,
                },
            };
            let diff = "";
            var now = true;
            var today = true;
            if (
                contactData.deliveryYear.value &&
                contactData.deliveryMonth.value &&
                contactData.deliveryDate.value
            ) {
                diff = `${contactData.deliveryYear.value}-${contactData.deliveryMonth.value}-${contactData.deliveryDate.value}`;
                
                requestData.data.messageDate = `${contactData.deliveryYear.value}-${contactData.deliveryMonth.value}-${contactData.deliveryDate.value}`;
            }
            if(diff){
                now = moment(
                    diff
                ).isSameOrAfter(moment().format('YYYY-MM-DD'), 'date');
            }

            if(now){
                await updateMessage(requestData)
                .unwrap()
                .then(async (response) => {
                    if(props.messageListQuery){
                        props.messageListQuery.refetch();
                    }
                    
                    await hidefutureFormPopup();
                });
            }else{
                alert(props.form.language.FUTURE_DATE_VALIDATION)
            }
            
            
        } else {
            alert(isFormValid.validationMsg);
        }
    }

    const handleDelete = (chipToDelete, type) => () => {
        let media = props.form.accountData.mediaList;
        let contact = props.form.accountData.contactList;
        if (type === "media" && media) {
            props.form.accountData.mediaList.map((item, index) => {
                if (
                    parseInt(item.system.id) ===
                    parseInt(chipToDelete.system.id)
                ) {
                    media = update(media, {
                        [index]: { $set: { ...item, checked: false } },
                    });
                }
            });
        } else if(contact) {
            props.form.accountData.contactList.map((item, index) => {
                if (
                    parseInt(item.system.id) ===
                    parseInt(chipToDelete.system.id)
                ) {
                    contact = update(contact, {
                        [index]: { $set: { ...item, checked: false } },
                    });
                }
            });
        }
        props.addAttribute({
            name: "accountData",
            value: {
                ...props.form.accountData,
                mediaList: sortAscending(media),
                contactList: sortContactAscending(contact),
            },
        });
    };

    const getName = (name) => {
        switch (name) {
            case "emergency":
                return props.form.language.CONTACT_TYPE_EMERGENCY;
            case "private":
                return props.form.language.CONTACT_TYPE_PRIVATE;
            case "family":
                return props.form.language.CONTACT_TYPE_FAMILY;
            case "healthcare":
                return props.form.language.CONTACT_TYPE_HEALTHCARE;
            case "financial":
                return props.form.language.CONTACT_TYPE_FINANCIAL;
            case "friend":
                return props.form.language.CONTACT_TYPE_FRIEND;
            case "other":
                return props.form.language.TYPE_OTHER;

            default:
                break;
        }
    };

    const openFutureMedia = (prop) => {
        props.addAttribute({
            name: "openFutureMedia",
            value: props.form.openFutureMedia ? false : true,
        });
    };

    const openFutureContact = (prop) => {
        props.addAttribute({
            name: "openFutureContact",
            value: props.form.openFutureContact ? false : true,
        });
    };

    const openFutureTags = (prop) => {
        props.addAttribute({
            name: "openFutureTags",
            value: props.form.openFutureTags ? false : true,
        });
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <React.Fragment>
            {(props.form.isLoading ||
                isLoading ||
                contactList.isLoading ||
                mediaList.isLoading) && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <Dialog
                open={props.form.futureFormPopup}
                fullScreen={fullScreen}
                onClose={hidefutureFormPopup}
                aria-labelledby="responsive-dialog-title"
                disableEscapeKeyDown={true}
            >
                {!props.form.openFutureContact &&
                    !props.form.openFutureMedia &&
                    !props.form.openFutureTags && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                position: "relative",
                            }}
                        >
                            <div
                                className="mm-form-container"
                            >
                                <div
                                    className="mm-login-container"
                                    style={{
                                        boxShadow: "none",
                                        paddingTop: "10px",
                                    }}
                                >
                                    <div
                                        style={{
                                            font: "normal normal 600 18px/64px Raleway",
                                            letterSpacing: "1.8px",
                                            color: "#23315580",
                                        }}
                                    >
                                        {`${
                                            props.form.language.QUESTION_LABEL
                                        } ${parseInt(value) + 1} ${
                                            props.form.language
                                                .QUESTION_LABEL_OF
                                        } 3`}
                                    </div>
                                    <Tabs
                                        style={{
                                            height: "16px",
                                            minHeight: "unset",
                                        }}
                                        value={value}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        onChange={handleChange}
                                    >
                                        <Tab
                                            style={{
                                                height: "16px",
                                                minHeight: "unset",
                                                borderRadius: "8px 0px 0px 8px",
                                                background:
                                                    "#6BCE90 0% 0% no-repeat padding-box",
                                                width: "32%",
                                                minWidth: "unset",
                                            }}
                                            label=""
                                        />
                                        <Tab
                                            style={{
                                                height: "16px",
                                                minHeight: "unset",
                                                borderRadius: "0",
                                                opacity:
                                                    value === 1 || value === 2
                                                        ? 1
                                                        : 0.1,
                                                background:
                                                    value === 1 || value === 2
                                                        ? "#6BCE90 0% 0% no-repeat padding-box"
                                                        : "#233155 0% 0% no-repeat padding-box",

                                                marginLeft: "4PX",
                                                width: "32%",
                                                minWidth: "unset",
                                            }}
                                            label=""
                                        />
                                        <Tab
                                            style={{
                                                height: "16px",
                                                minHeight: "unset",
                                                borderRadius:
                                                    " 0px 8px 8px 0px ",
                                                opacity: value === 2 ? 1 : 0.1,
                                                background:
                                                    value === 2
                                                        ? "#6BCE90 0% 0% no-repeat padding-box"
                                                        : "#233155 0% 0% no-repeat padding-box",

                                                marginLeft: "4PX",
                                                width: "32%",
                                                minWidth: "unset",
                                            }}
                                            label=""
                                        />
                                    </Tabs>
                                    {value === 0 && (
                                        <div>
                                            <div
                                                className="mm-login-header"
                                                style={{ paddingTop: "20px" }}
                                            >
                                                {
                                                    props.form.language
                                                        .FUTURE_MESSAGE_S1
                                                }
                                            </div>
                                            <div className="login-field-label login-field-label-height">
                                                {
                                                    props.form.language
                                                        .FUTURE_MESSAGE_S6
                                                }
                                            </div>
                                            <div className="login-field-input">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder={
                                                        props.form.language
                                                            .FUTURE_MESSAGE_S7
                                                    }
                                                    name="name"
                                                    value={
                                                        contactData.name.value
                                                    }
                                                    onChange={changeHandler}
                                                />
                                            </div>
                                            <div className="login-field-label login-field-label-height">
                                                {
                                                    props.form.language
                                                        .FUTURE_MESSAGE_S8
                                                }
                                            </div>
                                            <div className="login-field-input">
                                                <textarea
                                                    style={{ height: "auto" }}
                                                    rows="8"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder={
                                                        props.form.language
                                                            .FUTURE_MESSAGE_S9
                                                    }
                                                    name="content"
                                                    value={
                                                        contactData.content
                                                            .value
                                                    }
                                                    onChange={changeHandler}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {value === 1 && (
                                        <MessageAttachments
                                            openFutureContact={
                                                openFutureContact
                                            }
                                            openFutureMedia={openFutureMedia}
                                            openFutureTags={openFutureTags}
                                            handleDelete={(value, type) =>
                                                handleDelete(value, type)
                                            }
                                            tags={
                                                setTags()
                                            }
                                            updateTagList={(tag) => {
                                                setcontactData({
                                                    ...contactData,
                                                    messageContentType: tag,
                                                });
                                            }}
                                        />
                                    )}
                                    {value === 2 && (
                                        <div className="message-delivery-container">
                                            <div className="login-field-label login-field-label-height">
                                                {
                                                    props.form.language
                                                        .FUTURE_MESSAGE_S10
                                                }
                                            </div>
                                            <div className="" style={{display: "flex", justifyContent:"space-between", marginTop: "20px"}}>
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder="DD"
                                                    label="DD"
                                                    //autoComplete="off"
                                                    style={{width: '30%'}}
                                                    name="deliveryDate"
                                                    value={
                                                        contactData.deliveryDate
                                                            .value
                                                    }
                                                    onChange={changeHandler}
                                                />
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    style={{width: '30%'}}
                                                    placeholder="MM"
                                                    label="MM"
                                                    // autoComplete="off"
                                                    name="deliveryMonth"
                                                    value={
                                                        contactData
                                                            .deliveryMonth.value
                                                    }
                                                    onChange={changeHandler}
                                                />
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    label="YYYY"
                                                    style={{width: '30%'}}
                                                    placeholder="YYYY"
                                                    // autoComplete="off"
                                                    name="deliveryYear"
                                                    value={
                                                        contactData.deliveryYear
                                                            .value
                                                    }
                                                    onChange={changeHandler}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className="mm-input-submit"
                                        style={{ display: "flex" }}
                                    >
                                        {value !== 2 && (
                                            <button
                                                style={{
                                                    textAlign: "center",
                                                    width: "360px",
                                                    marginTop: "24px",
                                                }}
                                                className="primary-button-blue"
                                                onClick={async () => {
                                                    let isFormValid =
                                                        await validateField(
                                                            contactData,
                                                            props.form.language
                                                        );
                                                    setcontactData(
                                                        isFormValid.formatData
                                                    );

                                                    let isValid = true;
                                                    Object.keys(
                                                        contactData
                                                    ).map((data) => {
                                                        if (
                                                            !contactData[data]
                                                                .hidden &&
                                                            contactData[data]
                                                                .valid === false
                                                        ) {
                                                            isValid = false;
                                                        }
                                                    });
                                                    if (isValid) {
                                                        setValue(
                                                            (prev) => prev + 1
                                                        );
                                                    } else {
                                                        alert(
                                                            isFormValid.validationMsg
                                                        );
                                                    }
                                                }}
                                            >
                                                {`${props.form.language.MESSAGE_STEP} ${
                                                    parseInt(value) + 2
                                                }`}
                                            </button>
                                        )}

                                        {value === 2 && (
                                            <button
                                                style={{
                                                    textAlign: "center",
                                                    width: "360px",
                                                    marginTop: "24px",
                                                }}
                                                className="primary-button-blue"
                                                onClick={() => {
                                                    submit(
                                                        contactData,
                                                        "insert"
                                                    );
                                                }}
                                            >
                                                {
                                                    props.form.language
                                                        .SAVE_MESSAGE
                                                }
                                            </button>
                                        )}
                                        <button
                                            style={{
                                                textAlign: "center",
                                                width: "250px",
                                                marginTop: "24px",
                                                marginLeft: "15px",
                                            }}
                                            className="secondary-button"
                                            onClick={hidefutureFormPopup}
                                        >
                                            {props.form.language.CANCEL}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                {!props.form.openFutureContact &&
                    props.form.openFutureMedia && (
                        <SelectMedia openFutureMedia={openFutureMedia} />
                    )}
                {props.form.openFutureContact &&
                    !props.form.openFutureMedia && (
                        <SelectContact
                            data={props.form.accountData.contactList.filter(item=>item.contact.email)}
                            openFutureContact={() => openFutureContact()}
                        />
                    )}
                {props.form.openFutureTags && (
                    <SelectTags
                        tags={contactData.messageContentType.options}
                        selectedTags={contactData.messageContentType}
                        openFutureTags={() => openFutureTags()}
                        updateTagList={(tags) => {
                            setcontactData({
                                ...contactData,
                                messageContentType: tags,
                            });
                        }}
                    />
                )}
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        AddMessageAPI: (payload, type, isEdit) =>
            dispatch(AddMessageAPI(payload, type, isEdit)),
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddMessage)
);
