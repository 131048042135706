export const EWillPersonal = {
    controls: [
        {
            seq: 0,
            name: "firstName",
            label: "LOGIN_S7",
            type: "text",
            value: "",
            mandatory: true
        },
        {
            seq: 1,
            name: "lastName",
            label: "LOGIN_S8",
            type: "text",
            value: "",
            mandatory: true
        },
        {
            name: "street",
            label: "MYCONTACT_S13",
            type: "text",
            value: "",
        },
        {
            seq: 2,
            name: "zipCode",
            label: "POSTCODE",
            type: "text",
            value: "",
        },
        {
            seq: 3,
            label: "MYCONTACT_S17",
            name: "city",
            type: "text",
            value: "",
        },
        { seq: 4, label: "MYCONTACT_S11", name: "email", type: "text", value: "", mandatory: true },
        {
            seq: 5,
            label: "MYCONTACT_S16",
            name: "phone",
            type: "text",
            value: "",
            mandatory: true
        },
        {
            seq: 6,
            label: "MYCONTACT_S19",
            name: "placeOfBirth",
            type: "text",
            value: "",
        },
        {
            seq: 7,
            label: "MYCONTACT_S14",
            name: "birthDate",
            placeholder: "DD-MM-YYYY",
            type: "text",
            value: "",
        },
        {
            label: "MYCONTACT_S21",
            name: "martialState",
            type: "select",
            options: [
                { label: "SINGLE", value: "single" },
                { label: "LIVING_TOGETHER", value: "living together" },
                { label: "REGISTERED", value: "registered" },
                { label: "MARRIED", value: "married" },
            ],
        },
        {
            seq: 9,
            name: "donorCodicil",
            label: "MYCONTACT_S22",
            type: "select",
            options: [
                { label: "DONORCODICIL_YES", value: "yes" },
                { label: "DONORCODICIL_NO", value: "no" },
                { label: "DONORCODICIL_FAMILY", value: "family decides" },
                { label: "DONORCODICIL_CONTACT", value: "contact decides" },
            ],
        },
        { seq: 10, name: "religion", label: "MYCONTACT_S24", type: "text" },
        {
            seq: 11,
            label: "MYCONTACT_S23",
            name: "languageISOCode",
            type: "language",
            value: "",
        },
        {
            seq: 12,
            label: "MYCONTACT_S18",
            name: "countryISOCode",
            type: "country",
            value: "",
            mandatory: true
        },
        {
            seq: 13,
            label: "READONLY_PASSWORD",
            name: "readOnlyPassword",
            type: "password",
            value: ""
        }
    ],
}
