/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { addAttribute } from "../../../Actions/Form";
//import { responseFormatDate } from "../../Utility/Utility";
import AddContact from "../../Contacts/AddContact";
import { useGetAPICallQuery } from "../../../app/services/account";
import ContactOverview from "../../Contacts/ContactOverview";

const NotificationOverview = (props) => {

  const contactList = useGetAPICallQuery("contact");

  const [menu, setMenu] = React.useState([
    {
      name: "personal",
      label: props.form.language.TESTAMENT_CART,
    },
    {
      name: "phone",
      label: props.form.language.TELEPHONE_TEXT,
    },
    {
      name: "post",
      label: props.form.language.CARD_TEXT,
    },
    {
      name: "email",
      label: props.form.language.MYCONTACT_S11,
    },
    {
      name: "none",
      label: props.form.language.DO_NOT_INFORM,
    },
  ]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const closePopper = () => {
    setAnchorEl(null);
  };

  const getContactData = (contact) => {
    return {
        contactType: "",
        firstName: contact.privateData.firstName,
        lastName: contact.privateData.lastName,
        countryISOCode: contact.physicalAddress.countryISOCode,
        city: contact.physicalAddress.city,
        zipCode: contact.physicalAddress.zipCode,
        street: contact.physicalAddress.street,
        phone: contact.contact.phone,
        email: contact.contact.email,
        inform: contact.settings.inform,
        informType: contact.settings.informType,
        tags: contact.meta.tags,
        birthDate: contact.privateData.birthDate ? moment(
          contact.privateData.birthDate,
            "YYYY-MM-DD"
        ).format("DD-MM-YYYY"): '',
        //countryISOCode: props.data.settings.countryISOCode,
        ID: contact.system.id,
        isHeir: contact.settings.isHeir,
        proof: contact.privateData.proof
    };
};

  const open = Boolean(anchorEl);
  const showAddContact = (type, contact, currentView) => {
    // setcurrentType(type);
    if (currentView) {
      setSelectedAdd(currentView.name);
    }
    if (contact) {
      setTimeout(() => {
        props.addAttribute({
          name: "editContact",
          value: getContactData(contact),
        });
        props.addAttribute({
          name: "contactFormPopup",
          value: true,
        });
      }, 100);
    } else {
      props.addAttribute({
        name: "editContact",
        value: {},
      });
      props.addAttribute({
        name: "contactFormPopup",
        value: true,
      });
    }
  };

  const hideAddContact = () => {
    props.addAttribute({
      name: "contactFormPopup",
      value: false,
    });
    props.addAttribute({
      name: "editContact",
      value: {},
    });
  };

    const exportToCSV = (csvData, fileName) => {
      console.log(csvData)
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const getContactsToDownload = (item) => {
      let contactsToDownload = [];
      props.form.accountData &&
              props.form.accountData.contactList &&
              props.form.accountData.contactList.map((data) => {
                contactsToDownload.push({
                  [props.form.language.MYCONTACT_S10]: `${data.privateData.firstName} ${data.privateData.lastName}`,
                  [props.form.language.MYCONTACT_S11]: data.contact.email,
                  [props.form.language.MYCONTACT_S14]: data.privateData.birthDate ? moment(data.privateData.birthDate, "YYYY-MM-DD").format("DD-MM-YYYY"): '',
                  [props.form.language.MYCONTACT_S12]: data.contact.phone,
                  [props.form.language.MYCONTACT_S13]: data.physicalAddress.street + data.physicalAddress.city + data.physicalAddress.zipCode,
                  [props.form.language.CONTACT_INFORM_TYPE]: data.settings.informType ? menu.find(item=>item.name === data.settings.informType )?.label : '',
                })
              });
              return contactsToDownload;
    }

  const [selectedAdd, setSelectedAdd] = React.useState("");
  return (
    <React.Fragment>
      {props.form.contactFormPopup && (
        <AddContact
          contactRole={props.type}
          open={props.form.contactFormPopup}
          hideAddContact={hideAddContact}
          informType={selectedAdd} 
          addTitle={props.form.language.CONTACT_LABEL}
          query={contactList}
        />
      )}

      {menu.map((item) => {
        let contactsToDownload = [];
        return (
          <div onMouseLeave={closePopper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "95%",
                alignItems: "center",
                marginBottom: '10px',
              }}
              className="notification-buttons"
            >
            <div className="header24Medium">
              {item.label}
            </div>
            {(item.name === "personal") && (
                <button
                  className="primary-button-yellow"
                  onClick={()=> exportToCSV(getContactsToDownload(item), `Momentio Contacts`)}
                >
                  {props.form.language.DOWNLOAD_CONTACT}
                </button>
              )}
            </div>
            {props.form.accountData &&
              props.form.accountData.contactList &&
              props.form.accountData.contactList.map((data) => {
                if (data.settings.informType === item.name) {
                  return (
                    <ContactOverview
                      informType={data.informType}
                      showAddContact={() => showAddContact(props.type, data)}
                      data={data}
                      type={props.type}
                      addTitle={props.form.language.CONTACT_LABEL}
                    />
                  );
                }
              })}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "95%"
              }}
              className="notification-buttons"
            >
              <span
                id={props.type}
                onClick={(event) => {
                  // if(event.currentTarget.id === props.type ){
                  showAddContact(props.type, null, item);
                  //  }
                }}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span className="" style={{ margin: "16px 0px 16px 0" }}>
                  <span
                    // style={{ position: "absolute", right: "45px" }}
                    className="ewill-secondary-alert-sm"
                    // onClick={() => setEdit(true)}
                  >
                    <AddIcon />
                  </span>
                </span>
                <span
                  className="momentio-text-normal"
                  style={{ marginLeft: "15px" }}
                >
                  {props.form.language.NOTIFICATION_ADD}
                </span>
              </span>
              
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addAttribute: (payload) => dispatch(addAttribute(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationOverview);
