/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

const MySubscription = (props) => {
    const getSubscriptionName = () => {
        if (props.form.accountData?.profile?.limits?.membership?.membershipID) {
            const membershipID =
                props.form.accountData?.profile?.limits?.membership
                    ?.membershipID;
            switch (membershipID) {
                case 2:
                    return props.form.language.BASIC_SUBSCRIPTION;
                case 3:
                    return props.form.language.BETTER_SUBSCRIPTION;
                case 4:
                    return props.form.language.PERFECT_SUBSCRIPTION;
                default:
                    return props.form.language.FREE_SUBSCRIPTION;
            }
        }
        return props.form.language.FREE_SUBSCRIPTION;
    };
    return (
        <React.Fragment>
            <div className="n_header-3" style={{ marginBottom: "16px" }}>
                {props.form.language.MY_SUBSCRIPTION}
            </div>
            <div>
                
                <div
                    style={{ display: "flex" }}
                    className="input-container-column"
                >
                    <div
                        style={{ maxWidth: "165px" }}
                        className="momentio-text-normal accordion-content-option-label"
                    >
                        {props.form.language.MEMBER_SINCE}
                    </div>
                    <div className="momentio-text-bold momentio-ewill-value">
                        {props.form.accountData?.profile?.memberSince
                            ? moment(
                                  props.form.accountData?.profile.memberSince,
                                  "YYYY-MM-DD"
                              ).format("DD-MM-YYYY")
                            : ""}
                    </div>
                </div>

                <div
                    style={{ display: "flex" }}
                    className="input-container-column"
                >
                    <div
                        style={{ maxWidth: "165px" }}
                        className="momentio-text-normal accordion-content-option-label"
                    >
                        {props.form.language.Subscribe}
                    </div>
                    <div className="momentio-text-bold momentio-ewill-value">
                        {getSubscriptionName()}
                    </div>
                </div>

                <div
                    style={{ display: "flex" }}
                    className="input-container-column"
                >
                    <div
                        style={{ maxWidth: "165px" }}
                        className="momentio-text-normal accordion-content-option-label"
                    >
                        {props.form.language.VALID_UNTIL}
                    </div>
                    <div className="momentio-text-bold momentio-ewill-value">
                        {props.form.accountData?.profile?.limits?.membership
                            ?.membershipEndDate
                            ? moment(
                                  props.form.accountData?.profile?.limits
                                      ?.membership?.membershipEndDate,
                                  "YYYY-MM-DD"
                              ).format("DD-MM-YYYY")
                            : ""}
                        *
                    </div>
                </div>

                <div
                    style={{ display: "flex" }}
                    className="input-container-column"
                >
                    <div
                        style={{ maxWidth: "165px" }}
                        className="momentio-text-normal accordion-content-option-label"
                    >
                        {props.form.language.ACCOUNT_EXPIRY}
                    </div>
                    <div className="momentio-text-bold momentio-ewill-value">
                        {props.form.accountData?.profile?.limits?.membership
                            ?.expireDate
                            ? moment(
                                  props.form.accountData?.profile?.limits
                                      ?.membership?.expireDate,
                                  "YYYY-MM-DD"
                              ).format("DD-MM-YYYY")
                            : ""}
                        **
                    </div>
                </div>
                <button
                    className="primary-button-blue upgrade-subscription-btn"
                >
                    <Link to="/subscription">
                        {props.form.language.UPGRADE_ACCOUNT}
                    </Link>
                </button>

                <div className="n_caption" style={{ margin: "24px 0" }}>
                    {props.form.language.VALID_UNTIL_DISCLAIMER}
                </div>
                <div className="n_caption">
                    {props.form.language.ACCOUNT_EXPIRY_DISCLAIMER}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps)(MySubscription);
