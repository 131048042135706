//Translation in Dutch(dut)


//Login Section
export const LOGIN_S1 = "Log in op jouw account";
export const LOGIN_S2 = "E-mailadres";
export const LOGIN_S3 = "Wachtwoord";
export const LOGIN_S4 = "Wachtwoord opnieuw instellen";
export const LOGIN_S5 = "Maak account aan";
export const LOGIN_S6 = "Naam";
export const LOGIN_S7 = "Voornaam";
export const LOGIN_S8 = "Achternaam";
export const LOGIN_S9 = "Selecteer taal";
export const LOGIN_S10 = "Ik ga akkoord met de";
export const LOGIN_S11 = "We delen je gegevens nooit met derden. Jouw data is veilig bij ons!";
export const LOGIN_S18 = "of";
export const LOGIN_S12 = "Ik heb al een account";
export const LOGIN_S13 = "Telefoonnummer";
export const LOGIN_S14 = "Mijn profiel";
export const LOGIN_S15 = "Instellingen";
export const LOGIN_S16 = "Afmelden";
export const LOGIN_S17 = "Selecteer je land";
export const LOGIN_S19 = "gebruikersvoorwaarden";
export const READONLY_PASSWORD = "Wachtwoord voor executeur";
export const AGREEMENT_LABEL = "Acceptatie gebruikersvoorwaarden";
export const OR_TEXT = "of";

//Create account - Maak account
//Wie ben jij?
//E-mailadres
//Wachtwoord
//telefoonnummer
//Selecteer je taal
//Selecteer je land
//Ik ga akkoord met de algemene voorwaarden
//LET OP! We zullen uw gegevens nooit met iemand delen of verkopen, het is veilig bij ons!
//Maak account
//ik heb al een account



//Menu
export const SIDEMENU_S1 = "Nalatenschap";
export const SIDEMENU_S2 = "Account";
export const SIDEMENU_SUPPORT = "Support";


//Dashboard
export const DASHBOARD_S1 = "Welkom op je persoonlijke pagina";
export const DASHBOARD_S2 = "Op deze plek vind je een overzicht van je gegevens en nuttige tips om direct aan de slag te gaan.";



//Are we stil using this?
export const DASHBOARD_S3 = "NU BEGINNEN";
export const DASHBOARD_S4 = "Stuur je eerste herinneringen naar de toekomst.";
export const DASHBOARD_S5 = "Het bericht kun je op elk moment nog bewerken en/of verwijderen.";
export const DASHBOARD_S6 = "Start de wizard";
export const DASHBOARD_S7 = "TO DO";
export const DASHBOARD_S8 = "We hebben vier korte vragen die we je graag even willen stellen.";
export const DASHBOARD_S9 = "Na deze vragen hebben we een beter beeld van jouw persoonlijke situatie. Dan hoeven we je in het vervolg geen vragen te stellen die niet van toepassing zijn op jouw situatie.";
export const DASHBOARD_S10 = "Contact toevoegen";
export const DASHBOARD_S11 = "Wat is een vertrouweling?";
export const DASHBOARD_S12 = "Waarom is dit nodig?";
export const DASHBOARD_S13 = "Vul je account verder aan met belangrijke gegevens";
export const DASHBOARD_S14 = "Duis aute irure dolor in reprehend erit in voluptate velit esse cillum dolore eu fugiat sed do.";
export const DASHBOARD_S15 = "Mijn account aanvullen";
export const DASHBOARD_S16 = "Waarom is dit nodig?";
export const DASHBOARD_S17 = "Wat gebeurt er met mijn data?";
//Are we stil using this?



//Kluis intro pagina - Mysafe intro page
export const MYSAFE_S1 = "Een bestand uploaden";
export const MYSAFE_S2 = "Sla belangrijke bestanden veilig op in je kluis zodat jij en nabestaanden er altijd bij kunnen.";
export const MYSAFE_S3 = "Leg je persoonlijke situatie in slechts een paar minuten vast.";
export const MYSAFE_S4 = "Een wens aanmaken";
export const MYSAFE_S5 = "Laat je nabestaanden niet achter met lorum ipsum. Duis aute irure dolor in reprehend erit in voluptate velit esse cillum dolore eu fugiat sed do.";
export const MYSAFE_S6 = "Account toevoegen";
export const MYSAFE_S7 = "Zorg ervoor dat nabestaanden noodzakelijke informatie kunnen vinden als wachtwoorden, inzicht in abonnementen en contracten en gebruik van social media.";
export const MYSAFE_S8 = "Gegevens aanvullen";



//Mijn Kluis - MYSAFE
export const DASHBOARD_S18 = "Mijn kluis";
export const DASHBOARD_S19 = "Bestanden";
export const DASHBOARD_S20 = "Accounts";
export const DASHBOARD_S21 = "Wensen";
export const DASHBOARD_S22 = "Belangrijk";
export const DASHBOARD_S23 = "Persoonlijke situatie";
export const DASHBOARD_S24 = "Belangrijke gegevens";
export const DASHBOARD_S25 = "Contacten";
export const DASHBOARD_S26 = "Herinneringen";
export const DASHBOARD_S27 = "Persoonlijke situatie";
export const DASHBOARD_S28 = "Dashboard";

//Persoonlijke gegevens / Personal information
export const PERSONAL_INFO_LABEL = "Persoonlijke gegevens";
export const PERSONAL_INFO_KIDS = "Kinderen";
export const PERSONAL_INFO_GUARDIANS = "Voogd";
export const NO_GUARDIANS_MESSAGE = "Ik heb geen voogd";
export const SINGLE_GUARDIANS_MESSAGE = "Voeg voogd toe";
export const MULTIPLE_GUARDIANS_MESSAGE = "Voeg nog een voogd toe";
export const NO_ADMIN_MESSAGE = "Ik heb geen bewindvoerder";
export const SINGLE_ADMIN_MESSAGE = "Voeg bewindvoerder toe";
export const MULTIPLE_ADMIN_MESSAGE = "Voeg nog een bewindvoerder toe";
export const NO_PATNER_MESSAGE = "Ik heb geen partner";
export const SINGLE_PARTNER_MESSAGE = "Voeg partner toe";
export const MULTIPLE_PARTNER_MESSAGE = "Voeg nog een partner toe";
export const CREATE_TAG_LABEL = "Maak zelf categorie aan";
export const NO_CHILD_MESSAGE = "Ik heb geen kinderen";
export const SINGLE_CHILD_MESSAGE = "Voeg kind toe";
export const MULTIPLE_CHILD_MESSAGE = "Voeg nog een kind toe";
export const NO_PARENTS_MESSAGE = "Ik heb geen ouders meer";
export const SINGLE_PARENTS_MESSAGE = "Voeg ouder toe";
export const MULTIPLE_PARENTS_MESSAGE = "Voeg nog een ouder toe";
export const NUMBER_TEXT = "Nummer";
export const NO_EXECUTER_MESSAGE = 'Ik heb geen executeur';
export const NO_SOCIAL_MEDIA = "Ik heb geen account"
export const SINGLE_EXECUTER_MESSAGE = "Voeg executeur toe";
export const MULTIPLE_EXECUTER_MESSAGE = "Voeg nog een executeur toe";

//Marital Status
export const SINGLE = "Alleenstaand";
export const LIVING_TOGETHER = "Samenwonend";
export const REGISTERED = "Geregistreerd partnerschap";
export const MARRIED = "Getrouwd";

//Donorcodicil
export const DONORCODICIL_YES = "Ja, ik ben een geregistreerde donor";
export const DONORCODICIL_NO = "Nee, ik heb aangegeven geen donor te worden";
export const DONORCODICIL_FAMILY = "Mijn partner of familie beslist na mijn overlijden";
export const DONORCODICIL_CONTACT = "Ik wijs één persoon aan die beslist na mijn overlijden";

export const REGISTRATION_CODE_ERROR= "Voer de registratiecode in";
export const IMPORTANT_INFO_LABEL  = "Belangrijke gegevens"
export const PASSWORD_LABEL = "Wachtwoorden"
export const STRONG_PASSWORD_ERROR = "Gebruik een sterk wachtwoord (minimaal 8 karakters) of zin met min 1 hoofdletter, kleine letter, cijfer en leesteken";
export const START_WIZARD = "Start de wizard";
export const STOP_WIZARD = "Stoppen met de wizard";
export const ADD_ITEM = "Voeg een item toe";
export const PASSWORD_INFO = "Het is belangrijk dat je laat weten waar jouw wachtwoorden zijn opgeslagen zodat nabestaanden toegang kunnen krijgen tot jouw computer en telefoon om noodzakelijke zaken te kunnen regelen. Met een handige wizard kun je deze gegevens invullen.";
export const PASSWORD_SAVE_DATA = "Opslaan en gegevens controleren";
export const PASSWORD_Q1 = "Gegevens van de wachtwoord manager";
export const PASSWORD_Q2 = "Naam van de wachtwoord manager";
export const PASSWORD_Q3 = "voer hier de naam in";
export const PASSWORD_Q4 = "Wat is de gebruikersnaam?";
export const PASSWORD_Q5 = "Voer hier je gebruikersnaam in";
export const PASSWORD_Q6 = "Ik heb het ergens opgeschreven";
export const PASSWORD_Q7 = "Waarin heb je het genoteerd?";
export const PASSWORD_Q8 = "Bijvoorbeeld computer of schrift";
export const PASSWORD_Q9 = " Waar kan het worden gevonden?";
export const PASSWORD_Q10 = "Bijvoorbeeld kast of in de kluis";
export const PASSWORD_Q11 = "Ik heb de gegevens op een andere manier opgeschreven";
export const PASSWORD_Q12 = "Vertel hier hoe je dit hebt gedaan";
export const PASSWORD_Q13 = "Bijvoorbeeld een locatie, pincode van je telefoon of wachtwoord van de computer om toegang tot de wachtwoord manager te kunnen krijgen.";
export const PASSWORD_Q14 = "Bijvoorbeeld opgeslagen in je Google account";
export const PASSWORD_Q15 = "Waar heb je wachtwoorden opgeslagen?";
export const PASSWORD_Q16 = "Je ingevulde gegevens";
export const PASSWORD_Q17 = "Contactpersoon voor wachtwoorden";
export const PASSWORD_PREVIEW = "Klik op opslaan als de gegevens juist zijn ingevuld. Als je achteraf iets wilt wijzigen kan je rechts klikken op de 3 puntjes.";
export const PASSWORD_MANAGER_OPTION_1 = "In een wachtwoord manager";
export const PASSWORD_MANAGER_OPTION_2 = "Ik heb het opgeschreven";

export const ACCESS_LABEL= "Toegangsmiddelen"


export const FINANCIAL_ADD_SINGLE = "Voeg financiële gegevens toe";
export const FINANCIAL_ADD = "Financieel toevoegen";
export const FINANCIAL_EDIT = "Bewerk financieel";

export const BANKING_LABEL="Bankrekening"
export const BANK_NAME="Banknaam"
export const ACCOUNT_NUMBER="Bankrekening"

export const ALIMONY_LABEL="Alimentatie"
export const CONTACT_PERSON_TITLE = "Contactpersoon"
export const AMOUNT_PER_MONTH = "Bedrag per maand";
export const AMOUNT_PER="Bedrag per"

export const INVESTMENT_LABEL="Investering"
export const INVESTMENT_DESC="Omschrijving investering"
export const INVESTMENT_INFO="Informatie investering"

export const HOUSE_ADD = "Huis toevoegen";
export const HOUSE_ADD_MULTIPLE = "Nog een huis toevoegen";
export const HOUSE_ADD_SINGLE = "Voeg huis toe";
export const HOUSE_EDIT = "Bewerk huis";

export const DEBT = "Schulden";
export const DEBT_TEXT = "Heeft u schulden?";
export const DEBT_COMMENT = "Toelichting";
export const DEBT_ADD = "Schulden toevoegen";
export const DEBT_ADD_MULTIPLE = "Nog een schulden toevoegen";
export const DEBT_ADD_SINGLE = "Voeg schulden toe";
export const DEBT_EDIT = "Bewerk schulden";

export const INSURANCE_LABEL= "Verzekeringen"
export const INSURANCE_Q1 = "Verzekeringstype";
export const INSURANCE_ADD = "Verzekeringen toevoegen";
export const INSURANCE_EDIT = "Bewerk verzekeringen";
export const INSURANCE_ADD_MULTIPLE = "Voeg nog een verzekering toe";
export const INSURANCE_ADD_SINGLE = "Voeg verzekeringen toe";

export const SUBSCRIPTION_LABEL= "Abonnement"
export const MEMBERSHIP_EDIT = "Bewerk abonnementen";
export const MEMBERSHIP_ADD = "Abonnementen toevoegen";
export const MEMBERSHIP_ADD_MULTIPLE = "Voeg abonnementen toe";
export const MEMBERSHIP_ADD_SINGLE = "Nog een abonnement toevoegen";

export const SPORTS_TITLE = "Sport"
export const CULTURAL_TITLE = "Cultureel"
export const CINEMA_TITLE = "Bioscoop"
export const ADDITIONAL_TITLE = "Aanvullende informatie"

export const POLICY_NAME = "Polisnaam"
export const POLICY_NUMBER = "Polisnummer"
export const POLICY_INSURER= "Verzekeraar"
export const POLICY_CAR= "Auto"
export const POLICY_LIFE= "Levensverzekering"
export const POLICY_TRAVEL= "Reis"
export const POLICY_GLASS= "Glas"
export const POLICY_LIABILITY= "Aansprakelijkheidsverzekering"
export const POLICY_FIRE= "Brand"

export const HOUSE_TYPE = "Soort huis";
export const HOUSE_LABEL = "Huis";
export const HOUSE_RENTAL="Huurhuis"
export const HOUSE_OWN ="Koophuis"
export const LANDLORD_NAME="Naam verhuurder"
export const MORGAGE_COMPANY="Hypotheekverstrekker"

export const LOCATION_LABEL = "Locatie"

export const CONTRACT_LABEL = "Contracten";
export const CONTRACT_ADD_MULTIPLE = "Nog een contract toevoegen";
export const CONTRACT_ADD = "Voeg contract toe";
export const CONTRACT_EDIT = "Bewerk contract";
export const CONTRACT_NUMBER = "Contractnummer";
export const CONTRACT_CUSTOMER_NUM = "Klantnummer";
export const NAME = "Naam";
export const IS_APPLICABLE = "Indien van toepassing";
export const SELECT_OPTION = "Selecteer";
export const SELECT_OPTION_PROPERTY = "Bezittingen";
export const SELECT_OPTION_LEGAL = "Juridisch";
export const SELECT_OPTION_SERVICE = "Diensten";

export const POLICY_LABEL = "Polis";
export const TYPE_OTHER = "Overige";
export const POSTCODE = "Postcode";

export const SOCIAL_MEDIA_ADD_ANOTHER = "Nog een social media account toevoegen"
export const SOCIAL_MEDIA_ADD_SINGLE= "Voeg social media toe"
export const SOCIAL_MEDIA_Q1 = "Gebruikersnaam"
export const SOCIAL_MEDIA_Q2= "Welke social media platformen gebruik je?"
export const SOCIAL_MEDIA_EDIT = "Bewerk social Media"
export const SOCIAL_MEDIA_ADD = "Social Media toevoegen"

export const EXTRA_INFO = "Extra informatie";

//Bestanden
export const MYFILE_S1 = "Bestand toevoegen";
export const MYFILE_FILTER_CATEGORY = "Categorie";
export const FILTER_CONTACT = "Contacten";
export const MYFILE_SELECTED = "Geselecteerd bestand";
export const MYFILE_UPLOAD = "Bestand uploaden";
export const CATEGORY_LABEL = "Categorie";
export const DESCRIPTION_LABEL = "Omschrijving";
export const ADDED_ON = "TOEGEVOEGD OP";
export const SHARED_WITH = "GEDEELD MET";
export const EDIT_FILE = "Bewerk bestand";

//Contacten
export const MYCONTACT_S1 = "Vertrouwelingen";
export const MYCONTACT_S2 = "Familie";
export const MYCONTACT_S3 = "Vrienden";
export const MYCONTACT_S4 = "Groep aanmaken";
export const MYCONTACT_S5 = "Alle contacten";
export const MYCONTACT_S6 = "Prive";
export const MYCONTACT_S7 = "Financieel";
export const MYCONTACT_S8 = "Gezondheid";
export const MYCONTACT_S9 = "Overige";
export const MYCONTACT_S10 = "Naam";
export const MYCONTACT_S11 = "Emailadres";
export const MYCONTACT_S12 = "Tel";
export const MYCONTACT_S13 = "Adres";
export const MYCONTACT_S14 = "Geboortedatum";
export const MYCONTACT_S15 = "Contact rol";
export const MYCONTACT_S16 = "Telefoonnummer";
export const MYCONTACT_S17 = "Woonplaats";
export const MYCONTACT_S18 = "Land";
export const MYCONTACT_S19  = "Geboorteplaats";
export const MYCONTACT_S20 = "BSN nummer"
export const MYCONTACT_S21= "Burgelijke staat"
export const MYCONTACT_S22= "Donorcodicil"
export const MYCONTACT_S23= "Taal"
export const MYCONTACT_S24 = "Godsdienst"
export const MYCONTACT_S25 = "Executeur";
export const MYCONTACT_S26 = "Opmerking";
export const CANCEL = "Annuleer";
export const SAVE = "Opslaan";
export const EDIT_CONTACT = "Bewerk contact";
export const ADD_DESCIPTION = "voeg korte omschrijving toe";

//Herinneringen
export const FUTURE_MESSAGE_S1 = "Maak Herinnering";
export const FUTURE_MESSAGE_S2 = "Ontvanger";
export const FUTURE_MESSAGE_S3 = "Selecteer contact";
export const FUTURE_MESSAGE_S4 = "Voeg een foto, video of ander bestand toe";
export const FUTURE_MESSAGE_S5 = "Selecteer bijlage";
export const FUTURE_MESSAGE_S6 = "Titel herinnering";
export const FUTURE_MESSAGE_S7 = "Titel";
export const FUTURE_MESSAGE_S8 = "Jouw bericht";
export const FUTURE_MESSAGE_S9 = "Wat maakt dit moment zo speciaal ...";
export const FUTURE_MESSAGE_S10 = "Wanneer wil je de herinnering delen?";
export const FUTURE_MESSAGE_S11 = "Berichttype";

export const FUTURE_MESSAGE_DATE_UNDECIDED = "-";
export const SENT_MESSAGE_TITLE = "Gedeelde Herinneringen";
export const RECIEVED_MESSAGE_TITLE = "Ontvangen Herinneringen";
export const MESSAGE_FROM = "Van";
export const VIEW_MESSAGE = "Herinneringen bekijken";
export const DELETE_MESSAGE = "Herinneringen verwijderen";
export const DOWNLOAD_MEDIA = "Media downloaden";
export const NOTIFICATION_S1 = "Meldingen";

export const MESSAGE_ACCEPTANCE= "Je kan alle berichten van";
export const MESSAGE_ACCEPTANCE_SUFFIX = "bekijken na acceptatie"
export const MESSAGE_RECIEVED_FROM = "Je hebt een bericht van";
export const ACCEPT_MESSAGE_TEXT = "Bericht accepteren";
export const DECLINE_MESSAGE = "Afwijzen";
export const PERSONAL_MESSAGE = "Persoonlijk bericht";

export const PREVIEW_TEXT = "Toon Herinnering";
export const PREVIEW_TEXT_LEGACY = "Toon nalatenschap";
export const LEGACY_TITLE_TEXT = "Titel nalatenschap";
export const MOMENTIO_LEGACY = "Een Momentio Nalatenschap";
export const FUTURE_DATE_VALIDATION = "Vul een datum in de toekomst in";

export const QUESTION_LABEL = "VRAAG";
export const SAVE_GOTO = "Opslaan en naar vraag 2";
export const MESSAGE_STEP = "Naar vraag";
export const ADD_TAGS = "Voeg een categorie toe";
export const SELECT_TAG = "Selecteer een categorie";
export const SELECT_TAG_FILE = "Voeg categorie toe aan dit bestand";
export const SELECT_TAG_CONTACT = "Voeg categorie toe aan deze contactpersoon";
export const TAG_EXISTS = "Categorie is al in gebruik";
export const QUESTION_LABEL_OF = "van";
export const PREVIEW_MESSAGE_TITLE = "Momentio herinnering";
export const PREVIEW_DELIVERY_TEXT = "Levering";
export const PREVIEW_FROM_TEXT = "Van";

//Contacten
export const DELETE = "Verwijder";
export const EDIT = "Bewerk";
export const ADD = "Toevoegen";
export const CONTACT_LABEL = "contact";
export const CONTACT_LABEL_CAPS = "Contact";
export const CONTACT_TYPE_EMERGENCY = "Noodgevallen";
export const CONTACT_TYPE_PRIVATE = "Prive";
export const CONTACT_TYPE_FAMILY = "Familie";
export const CONTACT_TYPE_HEALTHCARE = "Gezondheid";
export const CONTACT_TYPE_FINANCIAL = "Financieel";
export const CONTACT_TYPE_FRIEND = "Vriend";
export const CONTACT_INFORM_TEXT = "Informeren bij overlijden";
export const CONTACT_INFORM_TYPE = "Informeren bij overlijden";
export const CONTACT_INFORM_TYPE_PHONE = "Ja, telefonisch";
export const CONTACT_INFORM_TYPE_EMAIL = "Ja, via e-mail";
export const CONTACT_INFORM_TYPE_POST = "Ja, via de post";
export const CONTACT_INFORM_TYPE_PERSONAL = "Ja, persoonlijk";
export const CONTACT_ROLE_TYPE_PARTNER = "Partner";
export const CONTACT_ROLE_TYPE_PARENT = "Ouder";
export const CONTACT_ROLE_TYPE_CHILD = "Kind";
export const CONTACT_ROLE_TYPE_GUARDIAN = "Voogd";
export const CONTACT_ROLE_TYPE_ADMIN = "Bewindvoerder";
export const CONTACT_ROLE_TYPE_TRUSTEE = "Trustee";
export const CONTACT_ROLE_TYPE_EMPLOYER = "Werkgever";
export const CONTACT_ROLE_TYPE_CONSULT = "Belastingconsulent";
export const CONTACT_ROLE_TYPE_GP = "Huisarts";
export const CONTACT_ROLE_TYPE_PSYCH = "Psycholoog";
export const CONTACT_ROLE_TYPE_SPEAKER = "Spreker uitvaart";
export const CONTACT_ROLE_TYPE_NOTARY = "Notaris";
export const CONTACT_ROLE_TYPE_EXECUTIONAR = "Executeur";
export const CONTACT_ROLE_TYPE_COMPANY = "UItvaartondernemer";
export const CONTACT_ROLE_TYPE_MUNICIPAL = "Gemeente";
export const CONTACT_ROLE_TYPE_CLERK = "Geestelijke";
export const NONE_TEXT = "Nee";
export const YES_TEXT = "Ja";

//Laatste wensen
export const FUNERAL_POLICY = "Uitvaartpolis";
export const RECORD_POLICY = "Leg laatste wensen vast";
export const FUNERAL_TYPE = "Soort uitvaart";
export const CONTRACT = "Contract";
export const FUNERAL_POLICY_Q1 = "Heb je een uitvaartpolis?";
export const FUNERAL_POLICY_Q4 = "Naam uitvaartorganisatie";
export const FUNERAL_POLICY_Q2 = "Ik leg graag mijn laatste wensen in Momentio vast";
export const FUNERAL_POLICY_Q3 = "Ik leg mijn laatste (nog) niet in Momentio vast";
export const FUNERAL_POLICY_Q5 = "Wil je jouw laatste wensen in Momentio vastleggen?";
export const FUNERAL_Q1 = "Type uitvaart";
export const FUNERAL_Q2 = "Openbaar";
export const FUNERAL_Q3 = "Besloten kring";
export const FUNERAL_Q4 = "Met enige vorm van militaire eer";
export const FUNERAL_Q5 = "Wil je begraven of gecremeerd worden?";
export const FUNERAL_Q6 = "Begraven";
export const FUNERAL_Q7 = "Bijzetten";
export const FUNERAL_Q8 = "Gecremeerd";
export const FUNERAL_Q9 = "Waar wil je begraven worden?";
export const FUNERAL_Q10 = "Stichtingsgraf";
export const FUNERAL_Q11 = "Rijksgraf";
export const FUNERAL_Q12 = "Particulier graf";
export const FUNERAL_Q13 = "Waar wil je gecremeerd worden?";
export const FUNERAL_Q14 = "Wat dient er met de as te gebeuren?";
export const FUNERAL_Q15 = "Verstrooiing";
export const FUNERAL_Q16 = "Kist";
export const FUNERAL_Q17 = "Standaard";
export const FUNERAL_Q18 = "Anders";
export const FUNERAL_Q20 = "uitvaart";

export const NOTIFICATION_LABEL = "Kennisgeving";
export const NOTIFICATION_Q1 = "Berichtgeving";
export const NOTIFICATION_Q2 = "Rouwkaart";
export const NOTIFICATION_Q3 = "Tekst rouwkaart";
export const NOTIFICATION_Q4 = "Advertentie";
export const NOTIFICATION_Q5 = "Waar plaatsen?";
export const NOTIFICATION_ADD = "Nog een persoon toevoegen";
export const DOWNLOAD_CONTACT = "Download adressen bestand";
export const NEXT_TO_KIN = "Keuze nabestaanden"

export const SPEAKER_LABEL = "Sprekers";
export const SPEAKER_Q1= "Mogen er mensen spreken?"
export const SPEAKER_Q2= "Wie"
export const SPEAKER_Q3 = "Zijn de personen op de hoogte gesteld?"

export const TRANSPORT_LABEL = "Vervoer";
export const TRANPORT_Q1 ="Wil je een rouwstoet ?"
export const TRANPORT_Q2 ="Wat voor vervoersmiddel?"
export const TRANPORT_Q3 = "Wil je volgauto’s";
export const TRANPORT_Q4 = "Aantal auto's"
export const TRANPORT_Q5 = "Wie mogen er in de volgauto's"

export const ORGANISATION_LABEL = "Organisatie";
export const ORGANISATION_Q1 = "Ik wil de uitvaart toevertrouwen aan";
export const ORGANISATION_Q2 = "Plechtigheid";
export const ORGANISATION_Q3 = "Naam van de kerk";
export const ORGANISATION_Q4 = "Opbaring";
export const ORGANISATION_Q5 = "Kist tijdens opbaring";
export const ORGANISATION_Q6 = "Condoleance";
export const ORGANISATION_Q7 = "Nabestaanden bepalen";
export const ORGANISATION_Q8 = "Register";
export const ORGANISATION_Q10 = "Gesloten";
export const ORGANISATION_Q11 = "Open";
export const ORGANISATION_Q12 = "In rouwcentrum";
export const ORGANISATION_Q13 = "Uitvaartvereniging";
export const ORGANISATION_Q14 = "Geestelijke";
export const ORGANISATION_Q15 = "Condoleance register";
export const ORGANISATION_Q16 = "Persoonlijke relatie";
export const ORGANISATION_Q17 = "Keuze uitvaart";

export const MUSIC_LABEL = "Muziek";
export const MUSIC_Q1 = "Wil je muziek op de begrafenis";
export const MUSIC_Q2 = "Welke muziek";
export const MUSIC_Q3 = "Live muziek";

export const MEMORIES_LABEL = "Herinnering";
export const MEMORIES_Q1 = "Herinneringsprentjes";
export const MEMORIES_Q2 = "Je kan indien gewenst zelf de teksten voor gedichten opslaan in uw kluis.";

export const MEDIA_LABEL = "Media";
export const MEDIA_Q1 = "Video opname toegestaan?";
export const MEDIA_Q2 = "Foto’s toegestaan";

export const MEAL_LABEL = "Maaltijd";
export const MEAL_Q1 = "Wil je een Koffietafel?";
export const MEAL_Q2 = "Maaltijd voor/na uitvaart";
export const MEAL_Q3 = "Voorkeur maaltijd";

export const FLOWER_LABEL = "Bloemen";
export const FLOWER_Q1 = "Wil je bloemen op de kist?";
export const FLOWER_Q2 = "Wat voor soort bloemen?";
export const FLOWER_Q3 = "Wil je bloemen bij het graf?";
export const FLOWER_Q5 = "Wat wil je absoluut niet?";

export const CARRIER_LABEL = "Dragers";
export const CARRIER_Q1 = "Wil je dragers?";
export const CARRIER_Q2 = "Ja, van de uitvaartonderneming";
export const CARRIER_Q3 = "Ja, door nabestaanden";
export const CARRIER_Q4 = "Namen van de dragers";
export const CARRIER_Q5 = "Zijn de personen op de hoogte?";
export const CARRIER_Q6 = "Bijzonderheden";

export const ACCESS_EDIT = "Bewerk sleutel";
export const ACCESS_ADD = "Sleutel toevoegen";
export const ACCESS_ADD_MULTIPLE = "Nog een sleutel toevoegen";
export const ACCESS_ADD_SINGLE = "Voeg sleutel toe";
export const MANDATORY_FIELD = "is verplicht veld";
export const INVALID_FIELD= "onjuist formaat";
export const INVALID_TEXT= "Onjuist";
export const ADD_TEXT = "toevoegen";
export const EDIT_TEXT= "Bewerk";
export const REGISTER_NOTE = "LET OP!";

export const IMAGE_TEXT = "Afbeelding";

export const SAVE_SELECTION  = "Selectie opslaan"
export const SAVE_MESSAGE  = "Opslaan";
export const DELIVERY_MOMENT = "DATUM";
export const MESSAGE_FOR = "Voor wie is het";
export const MESSAGE_DESC = "Bericht";
export const MESSAGE_ATTACHMENT = "Bijlagen";

export const MESSAGE_PERSONAL = "Persoonlijk";
export const MESSAGE_INSTRUCTION = "Instructie";
export const MESSAGE_FAREWELL = "Afscheid";

export const TESTAMENT_CART = "Persoonlijk en kaart";
export const TELEPHONE_TEXT = "Telefonisch";
export const CARD_TEXT = "Kaart";
export const DO_NOT_INFORM = "Niet informeren";

export const COMPLETED_TEXT = 'Afgerond';

//error messages
export const BAD_REQUEST = "Ongeldig verzoek";
export const ACCOUNT_BLOCKED = "Je account is geblokkeerd";
export const ACCOUNT_NOT_FOUND = "Je account is niet gevonden";
export const ACCOUNT_INACTIVE = "Je account is niet actief";
export const INCORRECT_CREDENTIAL = "Je gebruikersnaam en/of wachtwoord is onjuist";
export const INCORRECT_CREDENTIAL_READONLY = "Wachtwoord is onjuist"
export const INCORRECT_CODE = "Code is onjuist";
export const NO_MEMBERSHIP = "Je account heeft geen abonnement";
export const CODE_SENDING_ERROR = "Onmogelijk om code te verzenden of te genereren";
export const UNKNOWN_ERROR = "Er is iets misgegaan";
export const INVALID_SESSION_ERROR = "Sessie verlopen";
export const LIMIT_REACHED = "Niet geaccepteerd, limiet is bereikt";
export const NO_LAST_WISHES ="Geen laatste wensen gevonden";
export const FORBIDDEN_ERROR = "Onmogelijk, laatste wens data bestaat reeds";
export const OBJECT_NOT_FOUND = "Object niet gevonden";
export const NOT_FOUND = "Niet gevonden";
export const ERROR_GENERAL = "Fout, laad de pagina opnieuw en begin opnieuw";
export const INVALID_INPUT = "Ongeldige invoer, probeer het opnieuw";
export const READ_ONLY_ERROR_MESSAGE = "Je hebt alleen leesrechten en kan geen bewerkingen uitvoeren"
export const ERROR_SAVING = "Fout: Kan niet opslaan"
export const ERROR_DELETE  = "Fout: Kan niet worden verwijderd"
export const RegisterError = "Probleem met registreren, probeer het later nogmaals";
export const RegisterErrorEmailExists = "Email adres bestaat al, gebruik wachtwoord vergeten om het opnieuw in te stellen";
export const INVALID_SESSION = "Je bent automatisch uitgelogd, log opnieuw in om verder te gaan"
export const RegisterErrorIncorrectCode = "Incorrecte activatie code.";
export const RegisterErrorPhoneExists = "Telefoonnummer is al in gebruik";
export const unableToLoginErrorMessage = "Login mislukt, probeer nogmaals met correcte gegevens";
export const unableToMagicLoginErrorMessage = "Login mislukt, probeer in te loggen met email en wachtwoord";

//Dashbaord Notifications
export const DASHBOARD_NOTIFICATION__FIRST_FILE = "Hartelijk dank voor het uploaden van je eerste bestanden. Wij raden je aan om belangrijke bestanden (zoals contracten) op te slaan in de digitale kluis.";
export const DASHBOARD_NOTIFICATION_CONTACT_PHONE = "Sommige contactpersonen hebben nog geen telefoonnummer. Als er nabestaanden gebeld moeten worden helpt het als je telefoonnummers invult."
export const DASHBOARD_NOTIFICATION_CONTACT_ADDRESS = "Sommige contactpersonen hebben nog geen (post)adres. Ontzorg je nabestaanden en vul de ontbrekende gegevens in."
export const DASHBOARD_NOTI_FILE_UPLOAD = "Naast alle informatie is het mogelijk om bestanden zoals brieven, foto’s of video’s veilig op te slaan."
export const DASHBOARD_NOTI_IMPORTANT_INFO = "Vul belangrijke informatie volledig aan tot 100%";
export const DASHBOARD_NOTI_PERSONAL_INFO = "Vul persoonlijke informatie volledig aan tot 100%.";
export const NEXT_TIP = "Volgende Tip"
export const UPDATE_NOW = "Toevoegen"
export const DASHBOARD_NOTIFICATION_CONTACT = "Je hebt nog geen contactpersonen toegevoegd. Wij raden je aan om te starten met contactpersonen toevoegen."
export const DASHBOARD_NOTIFICATION_FILE = "Je digitale kluis is nog leeg. Wij raden je aan te starten met het uploaden van belangrijke bestanden. Vergeet niet om labels toe te voegen aan de bestanden om ze eenvoudig terug te kunnen vinden."
export const DASHBOARD_NOTI_MESSAGE ="Je hebt nog geen herinnering voor de toekomst gemaakt. Deel mooie herinneringen en maak een bericht voor een belangrijk moment."
export const DASHBOARD_NOTI_MESSAGE_SECOND ="Gefeliciteerd met het aanmaken van je eerste bericht! Toekomstige berichten kan je eenvoudig aanmaken; als concept zonder geadresseerde of afleverdatum."
export const DASHBOARD_NOTI_MESSAGE_THIRD = "Geweldig! Je staat op het punt je derde bericht te versturen. Voor nog meer inspiratie zie onze handleiding en blog."
export const YOUR_DATA = "Jouw informatie"
export const PROGRESS = "Voortgang"

//Legacy
export const LEGACY_BENIFICIARY = "Begunstigde";
export const LEGACY_TITLE = "Nalatenschap";
export const SEND_LEGACY = "Nalatenschap toevoegen";
export const LEGACY_FOR = "Wie is de begunstigde?";
export const LEGACY_FILTER_CATEGORY = "Categorie";
export const LEGACY_DESC_TITLE = "Wat wil je nalaten?"

//Abonnement
export const SUBSCRIPTION_RESULT = "Status abonnement";
export const SUBSCRIPTION_RESULT_OPEN = "Open";
export const SUBSCRIPTION_RESULT_PAID = "Betaald";
export const SUBSCRIPTION_RESULT_CANCELLED= "Geannuleerd";
export const SUBSCRIPTION_RESULT_FAILED= "mislukt";
export const PAYMENT_STATUS = "Betaling";
export const Subscribe = "Abonnement";
export const ANNUAL_FEE = "Jaarlijks"
export const CURRENCY = "€"
export const SUBSCRIPTION_TEXT = "Abonnement";
export const CURRENT_SUBSCRIPTION = "Huidig abonnement"
export const SUBSCRIPTION_ONE = "Abonnement 1"
export const SUBSCRIPTION_TWO = "Abonnement 2"
export const SUBSCRIPTION_THREE = "Abonnement 3"
export const SUBSCRIPTION_FOUR = "Abonnement 4"
export const PLACE_ORDER = "Bestellen"
export const MYSUBSCRIPTION = "Mijn abonnement";
export const UPGRADE_ACCOUNT = "Abonnement aanpassen";
export const BASIC_SUBSCRIPTION = "Basis geregeld";
export const BETTER_SUBSCRIPTION = "Beter geregeld";
export const PERFECT_SUBSCRIPTION = "Perfect geregeld";
export const FREE_SUBSCRIPTION = "PROEF ABONNEMENT";
export const READONLY_SUBSCRIPTION = "READ ONLY";
export const EXPIRY_SUBSCRIPTION = "VERLOOPT OVER";
export const EXPIRY_SUBSCRIPTION_WEEKS = "DAGEN";
export const POPULAR_SUBSCRIPTION = "MEEST GEKOZEN";
export const SUBSCRIPTION_SAFE = "Kluis";
export const SUBSCRIPTION_MEMORIES = "Herinneringen";
export const SUBSCRIPTION_LASTWISH = "Laatste wensen";
export const SUBSCRIPTION_LEGACY = "Nalatenschap";
export const SUBSCRIPTION_SUPPORT = "Ondersteuning nabestaanden";
export const SUBSCRIPTION_UNLIMITED = "onbeperkt";
export const SUBSCRIPTION_MESSAGE = "Kies 1 van onderstaande abonnementen om direct aan de slag te gaan. Je kan tussentijds ook een abonnement upgraden.Met elkaar zorgen voor we voor elkaar.";
export const SUBSCRIPTION_BASIC_BUTTON = "Kies voor basis geregeld";
export const SUBSCRIPTION_BETTER_BUTTON = "Kies voor goed geregeld";
export const SUBSCRIPTION_PREMIUM_BUTTON = "Kies voor perfect geregeld";
export const SUBSCRIPTION_PAYMENT_HINT = "Betaling per kwartaal";
export const SUBSCRIPTION_PAYMENT_HINT_PREMIUM = "Betaling eenmalig, na 10 jaar € 50 per jaar";
export const SUBSCRIPTION_HINT_PREMIUM = "*Na 5 jaar € 75 jaarlijks";
export const ONE_TIME_TEXT = "Eenmalig";

export const MEMBER_SINCE = "Lid sinds";
export const VALID_UNTIL = "Geldig tot";
export const ACCOUNT_EXPIRY = "Verloop account";

export const VALID_UNTIL_DISCLAIMER = "De 'geldig tot' datum geeft aan tot wanneer het abonnement is betaald. In deze period kan de account houder het platform normaal gebruiken. Na het verlopen van deze datum kan de accounthouder het platform nog tijdelijk in leesmode gebruiken. Na verlenging van het abonnement schuift de 'geldig tot' datum weer een jaar op."

export const ACCOUNT_EXPIRY_DISCLAIMER = "De 'verloop datum' is de periode tussen het verlopen van het abonnement ('geldig tot') en het verwijderen van het account. Na de 'verloop datum' is het account niet meer beschikbaar en wordt alle data verwijderd, inclusief de berichten die in de toekomst afgeleverd moeten worden.";

//Login/Register/Password reset
export const LOGIN_INFORMATION = "Aanmeldgegevens";
export const CHANGE_PASSWORD = "Wachtwoord wijzigen";
export const CHANGE_EMAIL = "E-mailadres wijzigen";
export const DOWNLOAD_ACCOUNT = "Download of verwijder je account"
export const DOWNLOAD_ACCOUNT_TEXT = "Hier kun je een archief met al je gegevens downloaden of de eerste stap zetten om je account te verwijderen."

export const CHANGE_NEXTTOKIN = 'Change next to kin';
export const REGISTER_EMAIL_HEADER = "Je hebt een e-mail ontvangen!";
export const REGISTER_SMS_HEADER = "Je hebt een sms ontvangen!";
export const REGISTER_SMS_RESEND = "Verstuur sms opnieuw";
export const REGISTER_EMAIL_RESEND = "Verstuur e-mail opnieuw";
export const REGISTER_CODE_RESEND = "Geen code ontvangen?";
export const REGISTER_EMAIL_HELP = "We willen graag je e-mailadres verifiëren. Daarom hebben we een code gestuurd naar "
export const REGISTER_SMS_HELP = "We willen graag je telefoonnummer verifiëren. Daarom hebben we een code gestuurd naar "
export const REGISTER_EMAIL_PLACEHOLDER = "Vul de verificatiecode in";
export const REGISTER_SMS_BUTTON = "Verifiëren telefoonnummer";
export const REGISTER_EMAIL_BUTTON = "Verifiëren emailadres";
export const REGISTRATION_SUCCESS  = "Registratie succesvol. Je kunt nu inloggen op uw account"

export const PASSWORD_CHANGED = "Wachtwoord is veranderd"
export const LOGGED_IN_AS = "INGELOGD ALS";

//Website
export const COMPANY_NAME = "Momentio";
export const MAIN_MENU_S1 = "Herinneringen";
export const MAIN_MENU_S2 = "E-testament";
export const MAIN_MENU_S3 = "Doneer";
export const MAIN_MENU_S4 = "Over ons";
export const MAIN_MENU_S5 = "Aanmelden";
export const MAIN_MENU_LOGIN = "Inloggen";
export const HOME_PAGE_S1 = "Uw herinneringen voor altijd in goede handen?";
export const HOME_PAGE_S2 = "Begin nu met vastleggen";
export const HOME_PAGE_S3 = "Of ontdek eerst";
export const HOME_PAGE_S4 = "hoe het werkt";
export const HOME_PAGE_S5 = "HOE WERKT MOMENTIO";
export const HOME_PAGE_S6 = "Momentio is een veilig plek voor uw waardevolle herinneringen en documenten die u nu en in de toekomst met uw dierbaren kunt delen. Minimaliseer de administratieve last en laat uw nabestaande zich concentreren op de herinnering aan u.";
export const HOME_PAGE_S7 = "Leg uw archief aan";
export const HOME_PAGE_S8 = "In uw archief legt u die dingen vast die belangrijk zijn voor uw naasten. Voor het geval u er straks niet meer bent bijvoorbeeld. Denk hierbij aan:";
export const HOME_PAGE_S9 = "Video’s";
export const HOME_PAGE_S10 = "Foto’s";
export const HOME_PAGE_S11 = "Geluidsopnames";
export const HOME_PAGE_S12 = "En nog veel meer…";
export const HOME_PAGE_S13 = "Archief aanleggen";
export const HOME_PAGE_S14 = "Creëer uw contactenlijst";
export const HOME_PAGE_S15 = "Hier staan de contactgegevens van de door u gedefinieerde belangrijke contactpersonen. U bepaalt wie en wanneer toegang krijgt tot uw archief, wensen of kluis. Denk hierbij aan:";
export const HOME_PAGE_S16 = "Uw partner";
export const HOME_PAGE_S17 = "Uw kinderen";
export const HOME_PAGE_S18 = "Uw bewindvoerder";
export const HOME_PAGE_S19 = "Uw notaris";
export const HOME_PAGE_S20 = "En nog veel meer…";
export const HOME_PAGE_S21 = "Uw contactenlijst";
export const HOME_PAGE_S22 = "Deel uw herinneringen";
export const HOME_PAGE_S23 = "U kiest zelf wie en wanneer een (digitale) herinnering ontvangt. Zo kunt u in de toekomst dierbare herinneringen en boodschappen delen. Denk hierbij aan:";
export const HOME_PAGE_S24 = "Een verjaardagsboodschap";
export const HOME_PAGE_S25 = "Uw testament";
export const HOME_PAGE_S26 = "Die ene leuke foto samen";
export const HOME_PAGE_S27 = "Een persoonlijke brief";
export const HOME_PAGE_S28 = "En nog veel meer…";
export const HOME_PAGE_S29 = "Uw archief delen";
export const HOME_PAGE_S30 = "WAT VINDEN ANDEREN";
export const HOME_PAGE_S31 = "‘Dankzij Momentio kan ik mijn vrouw straks toch nog een bloementje sturen.’";
export const HOME_PAGE_S32 = "– Bart van Achtereeckte";
export const HOME_PAGE_S33 = "UIT ONZE BLOG";
export const HOME_PAGE_S34 = "De laatste ontwikkelingen";
export const HOME_PAGE_S35 = "TIPS & TRICS";
export const HOME_PAGE_S36 = "Leef jij je leven alsof iedere dag de laatste is?";
export const HOME_PAGE_S37 = "Met elke stap die je zet, laat je iets achter en maak je een herinnering voor de mensen om je heen. Hoe zorg jij voor de mensen die je lief zijn? Nu? En straks als je er niet meer bent? Maak nu gebruik van het portaal en het herinneringen dashboard en leg bijvoorbeeld een boodschap vast voor het slagen van uw kleindochter...";
export const HOME_PAGE_S38 = "HOE WERKT DAT";
export const HOME_PAGE_S39 = "Jongeren bespreken uitvaartwensen meestal niet...";
export const HOME_PAGE_S40 = "Ruim de helft van de jongeren heeft nog nooit nagedacht over hun eigen uitvaart. En als zij wel hebben nagedacht over hun uitvaartwensen, worden deze meestal niet met anderen besproken. Slechts 21% heeft zijn of haar ideeën besproken met ouders of vrienden. Minder dan 1% heeft de uitvaartwensen vastgelegd op papier, op de computer of bij een uitvaartverzekeraar. Nu is er eindelijk een platform om deze wensen laagdrempelig vast te leggen...Momentio.";
export const HOME_PAGE_S41 = "PRODUCT";
export const HOME_PAGE_S42 = "Social media na je overlijden: Verwijderen of juist niet?";
export const HOME_PAGE_S43 = "85 procent van de Nederlanders tussen de 18 en 30 jaar heeft (nog) niet geregeld wat er met hun social media-accounts gebeurt na overlijden. Er zijn dan ook veel mogelijkheden. Alle accounts laten bestaan, verwijderen, tijdelijk nog laten bestaan, een laatste persoonlijke update laten plaatsen door een nabestaande... Weet jij het al?";
export const HOME_PAGE_S44 = "Naar onze blog";
export const HOME_PAGE_S45 = "AANMELDEN";
export const HOME_PAGE_S46 = "Begin vandaag nog met het aanmaken van mooie herinneringen en vastleggen van belangrijke informatie.";
export const HOME_PAGE_S47 = "Meld je nu aan ";
export const HOME_PAGE_S48 = "Bekijk eerst onze";
export const HOME_PAGE_S49 = "pakketten";
export const HOME_PAGE_S50 = "Een waardevol archief aanleggen en delen met uw naasten.";
export const HOME_PAGE_S51 = "Testament";
export const HOME_PAGE_S52 = "Bekijk meer reacties";

//Footer
export const FOOTER_S1 = "Herinneringen";
export const FOOTER_S2 = "Herinneringen vastleggen";
export const FOOTER_S3 = "Laatste wensen";
export const FOOTER_S4 = "Uw laatste wensen";
export const FOOTER_S5 = "E-testament";
export const FOOTER_S6 = "Mijn testament";
export const FOOTER_S7 = "Momentio";
export const FOOTER_S8 = "Onze filosofie";
export const FOOTER_S9 = "Disclaimer";
export const FOOTER_S10 = "Algemene voorwaarden";
export const FOOTER_S11 = "© Copyright 2023";
export const FOOTER_SUPPORT = "Support";
export const FOOTER_S21 = "Toekomst berichten dierbaren";
export const FOOTER_S41 = "Uw digitale kluis";
export const FOOTER_S61 = "Uw notaris";
export const FOOTER_S81 = "Donaties";
export const FOOTER_S82 = "Veelgestelde vragen";
export const FOOTER_S83 = "Contact";

export const FOOTER_FACEBOOK_URL = "https://www.facebook.com/momentio.nl";
export const FOOTER_YOUTUBE_URL = "https://www.youtube.com/@Momentio_nl";
export const FOOTER_TWITTER_URL = "https://x.com/momentio_nl";
export const FOOTER_INSTA_URL = "https://www.instagram.com/momentio.nl";

export const MOMENTIO_BASE_URL = "https://www.momentio.nl";
