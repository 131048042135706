import React from "react";
import { connect } from "react-redux";
import { loginAccount } from "../../Actions/Form";
import AuthHeader from "../Common/AuthHeader";
import SideMenu from "../Common/SideMenu";
import AuthFooter from "../Common/AuthFooter";
import { Link } from "react-router-dom";

const Mysafe = (props) => {

  return (
    <React.Fragment>
      <AuthHeader />
      <div className="momentio-dashboard-container">
        <SideMenu view="mysafe" />
        <div className="auth-content" style={{width: "100%"}}>
          <div className="auth-content-subheader"><div className="content-one">
            {props.form.language.DASHBOARD_S18}
          </div></div>
          
          <div
            className={"momentio-my-safe-wrapper"}
          >
            <div
              className="momentio-mysafe-container"
              style={{ height: "inherit" }}
            >
              <div className="momentio-tips-content-container myfiles-content-container">
                <div className="mm-login-header">
                  {props.form.language.DASHBOARD_S23}
                </div>
                <div className="momentio-tips-content-desc">
                  {props.form.language.MYSAFE_S3}
                </div>
                <div
                  style={{
                    width: "100%",
                    marginTop: "16PX",
                    height: "55px",
                  }}
                >
                  <button
                    className="secondary-button"
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      bottom: "32px",
                    }}
                  >
                    <Link to="/personalwish"> {props.form.language.MYSAFE_S8}</Link>
                  </button>
                </div>
              </div>
            </div>

            <div
              className="momentio-mysafe-container"
              style={{ height: "inherit" }}
            >
              <div className="momentio-tips-content-container myfiles-content-container">
                <div className="mm-login-header">
                  {props.form.language.DASHBOARD_S24}
                </div>
                <div className="momentio-tips-content-desc">
                  {props.form.language.MYSAFE_S7}
                </div>
                <div
                  style={{
                    width: "100%",
                    marginTop: "16PX",
                    height: "55px",
                  }}
                >
                  {props.form.accountData?.profile?.limits?.accessList?.important && <button
                    className="secondary-button"
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      bottom: "32px",
                    }}
                  >
                    <Link to="/importantInfo"> {props.form.language.MYSAFE_S8}</Link>
                  </button> }
                </div>
              </div>
            </div>
            <div
              className="momentio-mysafe-container"
              style={{ height: "inherit" }}
            >
              <div className="momentio-tips-content-container myfiles-content-container">
                <div className="mm-login-header">
                  {props.form.language.DASHBOARD_S19}
                </div>
                <div className="momentio-tips-content-desc">
                  {props.form.language.MYSAFE_S2}
                </div>
                <div
                  style={{
                    width: "100%",
                    marginTop: "16PX",
                    height: "55px",
                  }}
                >
                  {props.form.accountData?.profile?.limits?.accessList?.media && <button
                    className="primary-button-blue"
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      bottom: "32px",
                    }}
                  >
                    <Link to="/files"> {props.form.language.MYFILE_S1}</Link>
                  </button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AuthFooter />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => dispatch(loginAccount(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Mysafe);
