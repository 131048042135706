/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import { connect } from 'react-redux';
import { addAttribute } from '../../../Actions/Form';

const RecordPolicy = (props) => {
    const [personalData, setpersonalData] = React.useState({});
    const [isEdit, setEdit] = React.useState(false);

    React.useEffect(() => {
        if (
            props.form.accountData &&
            props.form.accountData.lastwishes &&
            props.form.accountData.lastwishes.funeralPolis
        ) {
            let data = props.form.accountData.lastwishes.funeralPolis;
            if (data.recordLastWishes === 'no') {
                props.addAttribute({
                    name: 'showLastwill',
                    value: false,
                });
            } else {
                props.addAttribute({
                    name: 'showLastwill',
                    value: true,
                });
            }
            setpersonalData(data);
        } else {
            setpersonalData({
                recordLastWishes: 'yes',
                ID: '',
            });
            props.addAttribute({
                name: 'showLastwill',
                value: false,
            });
            setEdit(true);
        }
    }, [props.form.accountData && props.form.accountData.lastwishes]);

    const changeHandler = (event, personal) => {
        let data = { ...personalData };
        data[event.target.name] = event.target.value;
        setpersonalData(data);
    };

    const saveEwill = () => {
      if(props.form.accountData){
        let lastwishes =  props.form.accountData.lastwishes ? props.form.accountData.lastwishes: {}
        let json = {
          url: 'lastwishes',
          method: props.form.accountData?.lastwishes?.funeralPolis ? 'PUT' : 'POST',
          mode: 'merge',
          ...lastwishes,
          funeralPolis: {
            ...props.form.accountData?.lastwishes?.funeralPolis,
            recordLastWishes: personalData.recordLastWishes,

          },
      };
      props.handleRequest('update', json);
      setEdit(false);
      }
        
    };
    const deleteEwill = () => {
        let json = {
            url: 'lastwishes',
            mode: 'overwrite',
            method: 'PUT',
        };
        props.handleRequest('update', json, 'funeralPolis');
    };
    return (
        <React.Fragment>
            {!isEdit && (
                <React.Fragment>
                    <div
                        style={{ display: 'flex' }}
                        className='flex-direction-column input-container-column'
                    >
                        <div className='momentio-text-normal accordion-content-option-label'>
                            {props.form.language.FUNERAL_POLICY_Q5}
                        </div>
                        <div className='momentio-text-bold momentio-ewill-value'>
                            {personalData.recordLastWishes === 'yes'
                                ? props.form.language.YES_TEXT
                                : props.form.language.NONE_TEXT}
                        </div>
                    </div>

                    <div className='secondary-alert-sm' onClick={() => setEdit(true)}>
                        {' '}
                        <i className='fa fa-pencil'></i>
                    </div>
                </React.Fragment>
            )}
            {isEdit && (
                <React.Fragment>
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.FUNERAL_POLICY_Q5}
                        </div>
                        <div className=''>
                            <RadioGroup
                                className='mm-field-input'
                                style={{ flexDirection: 'row' }}
                                name={'recordLastWishes'}
                                value={personalData.recordLastWishes}
                                onChange={(event) => changeHandler(event, personalData)}
                            >
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio />}
                                    label={props.form.language.YES_TEXT}
                                />
                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio />}
                                    label={props.form.language.NONE_TEXT}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                    <div>
                        <button
                            style={{
                                textAlign: 'center',
                                width: '150px',
                                marginTop: '24px',
                            }}
                            className='primary-button-blue'
                            onClick={() => saveEwill()}
                        >
                            {props.form.language.SAVE}
                        </button>
                        {/* <button
                            style={{
                                textAlign: 'center',
                                width: '150px',
                                marginTop: '24px',
                                backgroundColor: '#F45B5B',
                                marginLeft: '15px',
                            }}
                            className='primary-button-red'
                            onClick={() => deleteEwill()}
                        >
                            {props.form.language.DELETE}
                        </button> */}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RecordPolicy);
