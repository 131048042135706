import Popper from "@material-ui/core/Popper";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React from "react";
import { connect } from "react-redux";
import { addAttribute } from "../../../../Actions/Form";

const ContractOverview = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const closePopper = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <div onMouseLeave={closePopper}>
        <React.Fragment>
          {props.form.selectedContract &&
            props.form.selectedContract.ID === props.data.ID && (
              <Popper open={open} anchorEl={anchorEl}>
                <div
                  className="menu-container"
                  onMouseLeave={() => closePopper(false)}
                  style={{
                    marginLeft: "-175px",
                    marginTop: "0px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="menu-options"
                    onClick={() => {
                      closePopper("bottom-end");
                      props.showAddContract(props.data);
                    }}
                  >
                    {props.form.language.EDIT}
                  </div>
                  <div
                    className="menu-options"
                    onClick={() => {
                      closePopper("bottom-end");
                      props.handleRequest("delete", props.data.ID);
                      props.addAttribute({
                        name: "selectedContract",
                        value: {},
                      });
                    }}
                  >
                    {props.form.language.DELETE}
                  </div>
                </div>
              </Popper>
            )}
        </React.Fragment>

        <div className="overviewContainer">
          <div
            className="overviewMenu"
            onClick={(event) => {
              handleClick(event);
              props.addAttribute({
                name: "selectedContract",
                value: props.data,
              });
            }}
          >
            <MoreHorizIcon className='show-menu-icon' />
          </div>
          <div
            className="momentio-text-bold"
            style={{ marginTop: "-5px", width: "100%" }}
          >
            <div style={{ display: "flex" }}  className="input-container-column">
              <div
                className="accordion-content-option-label header24Medium"
                style={{ marginBottom: "10px" }}
              >
                {props.data.contractType === "property"
                  ? props.form.language.SELECT_OPTION_PROPERTY
                  : props.data.contractType === "financial"
                  ? props.form.language.MYCONTACT_S7
                  : props.data.contractType === "legal"
                  ? props.form.language.SELECT_OPTION_LEGAL
                  : props.data.contractType === "services"
                  ? props.form.language.SELECT_OPTION_SERVICE
                  : props.data.contractType === "other"
                  ? props.form.language.TYPE_OTHER
                  : ""}
              </div>
            </div>

            <div style={{ display: "flex" }} className="input-container-column">
              <div className="momentio-text-normal accordion-content-option-label">
                {props.form.language.MYCONTACT_S10}
              </div>
              <div className="momentio-text-bold momentio-ewill-value">
                {props.data.name}
              </div>
            </div>

            <div style={{ display: "flex" }} className="input-container-column">
              <div className="momentio-text-normal accordion-content-option-label">
                {props.form.language.CONTRACT_CUSTOMER_NUM}
              </div>
              <div className="momentio-text-bold momentio-ewill-value">
                {props.data.customerNumber}
              </div>
            </div>
            <div style={{ display: "flex" }} className="input-container-column">
              <div className="momentio-text-normal accordion-content-option-label">
                {props.form.language.CONTRACT_NUMBER}
              </div>
              <div className="momentio-text-bold momentio-ewill-value">
                {props.data.contractNumber}
              </div>
            </div>
            <div style={{ display: "flex" }} className="input-container-column">
              <div className="momentio-text-normal accordion-content-option-label">
                {props.form.language.EXTRA_INFO}
              </div>
              <div className="momentio-text-bold momentio-ewill-value">
                {props.data.additionalInfo}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addAttribute: (payload) => dispatch(addAttribute(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContractOverview);
