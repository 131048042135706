import React from "react";

const BasicSubscription = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <div className="subscription ewill subscription-container">
                <div className="subscription-content-container">
                    <div className="n_header-2"  style={{ marginBottom: "15px" }}>
                        {props.form.language.BASIC_SUBSCRIPTION}
                    </div>

                    <div className="momentio-home-tick n_header-4 subscription-desc">
                        <span></span>
                        <div className="subscription-content">
                            <div>{props.form.language.SUBSCRIPTION_SAFE}</div>
                            <div className="n_paragraph">{`${props.form.language.DASHBOARD_S25} 10`}</div>
                            <div className="n_paragraph">{`${props.form.language.DASHBOARD_S19} 20 / 50mb`}</div>
                        </div>
                    </div>
                    <div className="momentio-home-tick n_header-4 subscription-desc">
                        <span></span>
                        <div className="subscription-content">
                            <div>
                                {props.form.language.SUBSCRIPTION_MEMORIES}
                            </div>
                            <div className="n_paragraph">Max 5</div>
                        </div>
                    </div>
                    <div className="momentio-home-tick subscription-desc">
                        <span></span>
                        <div className="subscription-content">
                            <div>
                                {props.form.language.SUBSCRIPTION_LASTWISH}
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ textAlign: "center" }}>
                    <button
                        className="primary-button-blue"
                        onClick={() => {
                            props.addSubscriptionStep1(props.membership, 1);
                        }}
                    >
                        {props.form.language.SUBSCRIPTION_BASIC_BUTTON}
                    </button>
                    <div className="n_paragraph" style={{ paddingTop: "10px" }}>
                        {`${props.form.language.ANNUAL_FEE} € ${parseInt(
                            props.membership.data.price
                        )}`}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default BasicSubscription;
