
// eslint-disable jsx-a11y/anchor-is-valid
// eslint-disable jsx-a11y/alt-text
import React from "react";
import ReactFlagsSelect from "react-flags-select";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setLanguage } from "../../Actions/Form";

const Header = (props) => {
  const changeLanguage = (code) => {
    props.updateLanguage(code);
    window.global = code === "NL" ? "globalDUT" : "globalENU";
    localStorage.setItem('language', code);
    
  };
  const [openMenu, setOpen] = React.useState(false);
  return (
    <div className="fixed-header-container">
      <div className="header-container container">
        <div className="header-title-section">
          <div>
            <Link className="header-title-item" to={props.form.language.MOMENTIO_BASE_URL}>
              {props.form.language.COMPANY_NAME}
            </Link>
          </div>
        </div>
        <div className="header-menu-section-sm">
          <div className="header-menu-item menu-item-sm">
            <button
              className="secondary-button-sm menu-button"
              onClick={() => setOpen(!openMenu)}
            >
              <span className="menu-button-icon"></span>Menu
            </button>
          </div>
          {openMenu && (
            <div
              className="menu-wrapper"
              style={{ marginTop: "44px" }}
              onClick={() => setOpen(false)}
            >
              <div
                className="menu-container"
                style={{ paddingLeft: "0px", right: 0, marginTop: "0" }}
              >
                <div className="menu-options">
                  <Link to="/register">{props.form.language.MAIN_MENU_S5}</Link>
                </div>
                <div className="menu-options">
                  <Link to="/login">{props.form.language.MAIN_MENU_LOGIN}</Link>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="header-menu-section">
          <div>
            <a className="header-menu-item">{props.form.language.MAIN_MENU_S1}</a>
          </div>
          <div>
            <a className="header-menu-item">{props.form.language.MAIN_MENU_S2}</a>
          </div>
          <div>
            <a className="header-menu-item">{props.form.language.MAIN_MENU_S3}</a>
          </div>
          <div>
            <a className="header-menu-item">{props.form.language.MAIN_MENU_S4}</a>
          </div>

          <div className="header-menu-item">
            <button className="secondary-button-sm">
              {" "}
              <Link className="header-menu-item" to="/register">
                {props.form.language.MAIN_MENU_S5}
              </Link>
            </button>
          </div>
          <Link className="header-menu-item" to="/login">
            {props.form.language.MAIN_MENU_LOGIN}
          </Link>
          <div className="language-select">
            <ReactFlagsSelect
              selected={props.form.currentLanguage}
              countries={["NL", "US"]}
              customLabels={{ NL: "Netherlands", US: "English" }}
              name="Country"
              onSelect={changeLanguage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateLanguage: (payload) => dispatch(setLanguage(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
