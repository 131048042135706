import React from "react";
import { connect } from "react-redux";
import AuthFooter from "../Common/AuthFooter";
import AuthHeader from "../Common/AuthHeader";
import SideMenu from "../Common/SideMenu";
import ContactSection from "./ContactSection";

const Mysafe = (props) => {
  return (
    <React.Fragment>
      <AuthHeader />
      <div className="momentio-dashboard-container">
        <SideMenu view="mysafe" />
        <div className="auth-content">
          <ContactSection
            contactType="healthcare"
            title={props.form.language.MYCONTACT_S8}
            addTitle={props.form.language.MYCONTACT_S8}
          />
        </div>
      </div>
      <AuthFooter />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};

export default connect(mapStateToProps)(Mysafe);
