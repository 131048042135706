export const model = {
    tags: {
        value: [],
        valid: true,
        text: 'MYCONTACT_S15',
        options: [
            {
                label: 'MYCONTACT_S7',
                value: 'financial',
            },
            {
                label: 'HOUSE_LABEL',
                value: 'house',
            },
            {
                label: 'POLICY_LABEL',
                value: 'polis',
            },
            {
                label: 'CONTRACT',
                value: 'contract',
            },
            {
                label: 'FOOTER_S3',
                value: 'lastwishes',
            },
            {
                label: 'SIDEMENU_S1',
                value: 'will',
            },
        ]
    }
};
