/*  eslint-disable array-callback-return */
import React from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { loginAccount, addAttribute } from "../../Actions/Form";
import AuthHeader from "./../Common/AuthHeader";
import SideMenu from "./../Common/SideMenu";
import AuthFooter from "./../Common/AuthFooter";
import Grid from "@material-ui/core/Grid";
import {
    useGetAPICallQuery,
    useMutationAPICallMutation,
} from "../../app/services/account";
import BasicSubscription from "./BasicSubscription";
import MediumSubscription from "./MediumSubscription";
import PremiumSubscription from "./PremiumSubscription";

const Subscription = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const membershipListQuery = useGetAPICallQuery("membership");

    const [addSubscription, { isLoading }] = useMutationAPICallMutation();

    const addSubscriptionStep1 = (data, count) => {
        props.addAttribute({
            name: "isLoading",
            value: true,
        });
        let requestData = {};
        let url = count === 1 ? "membershipOrder" : "mollie";
        if (count === 1) {
            requestData = {
                method: "POST",
                membershipID: data.system.id,
                languageISOCode: props.form?.accountData?.profile?.settings
                    ? props.form?.accountData?.profile?.settings
                          ?.languageISOCode
                    : "NL",
                url: url,
            };
            callSubscription(requestData, 1);
        } else if (count === 2) {
            requestData = {
                method: "POST",
                internalReferenceID: data.orderObject.internalReferenceID,
                amount: data.orderObject.amount,
                iso4217Currency: data.orderObject.iso4217Currency,
                name: data.orderObject.name,
                iso15897Locale: data.orderObject.iso15897Locale,
                description: data.orderObject.description,
                url: url,
            };
            callSubscription(requestData, 2);
        }
    };

    const callSubscription = async (requestData, count) => {
        await addSubscription(requestData)
            .unwrap()
            .then((response) => {
                console.log(response);
                if (count === 1) {
                    addSubscriptionStep1(response, 2);
                } else if (count === 2) {
                    // addSubscriptionStep1(response, 3)
                    window.location.href = response.checkoutUrl;
                }

                if (count === 2) {
                    props.addAttribute({
                        name: "isLoading",
                        value: false,
                    });
                }
            })
            .catch(() => {
                props.addAttribute({
                    name: "isLoading",
                    value: false,
                });
            });
    };

    const getSubscriptionContent = (id, membership) => {
        switch (id) {
            case 2:
                return (
                    <BasicSubscription
                        membership={membership}
                        form={props.form}
                        addSubscriptionStep1={(id, member) =>
                            addSubscriptionStep1(id, member)
                        }
                    />
                );
            case 3:
                return (
                    <MediumSubscription
                        membership={membership}
                        form={props.form}
                        addSubscriptionStep1={(id, member) =>
                            addSubscriptionStep1(id, member)
                        }
                    />
                );
            case 4:
                return (
                    <PremiumSubscription
                        membership={membership}
                        form={props.form}
                        addSubscriptionStep1={(id, member) =>
                            addSubscriptionStep1(id, member)
                        }
                    />
                );
            default:
                return "";
        }
    };

    const getSubscriptionName = () => {
        if (props.form.accountData?.profile?.limits?.membership?.membershipID) {
            const membershipID =
                props.form.accountData?.profile?.limits?.membership
                    ?.membershipID;
            switch (membershipID) {
                case 2:
                    return props.form.language.BASIC_SUBSCRIPTION.toUpperCase();
                case 3:
                    return props.form.language.BETTER_SUBSCRIPTION.toUpperCase();
                case 4:
                    return props.form.language.PERFECT_SUBSCRIPTION.toUpperCase();
                default:
                    return props.form.language.FREE_SUBSCRIPTION.toUpperCase();
            }
        }
        return props.form.language.FREE_SUBSCRIPTION;
    };

    return (
        <React.Fragment>
            {(membershipListQuery.isLoading ||
                isLoading ||
                props.form.isLoading) && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <AuthHeader />
            <div className="momentio-dashboard-container">
                <SideMenu view="mysafe" />

                <div className="auth-content">
                    <div
                        className="auth-content-subheader"
                        style={{ marginBottom: "30px" }}
                    >
                        <div className="content-one">
                            {props.form.language.SUBSCRIPTION_TEXT}
                        </div>
                        <div className="n_intro">
                            <span>{`${props.form.language.CURRENT_SUBSCRIPTION}: `}</span>
                            <span className="n_header-3">
                                {getSubscriptionName()}
                            </span>
                        </div>
                    </div>
                    <div
                        className="n_paragraph"
                        style={{ marginBottom: "50px" }}
                    >
                        {props.form.language.SUBSCRIPTION_MESSAGE}
                    </div>
                    <div>
                        {membershipListQuery.data?.membershipList.length >
                            0 && (
                            <Grid container spacing={3}>
                                {/* {membershipListQuery.data?.membershipList.map(
                                (membership) => {
                                    if(membership.system.id > 1){
                                        return (
                                            <Grid xs="12" sm="12" md="12" lg="4" xl="4" item>
                                               {getSubscriptionContent(membership.system.id, membership)}
                                               <BasicSubscription membership={membership} form={props.form} addSubscriptionStep1={(id, member)=>addSubscriptionStep1(id, member)}/>
                                            </Grid>
                                        );
                                    }
                                   
                                }
                            )} */}
                                <Grid
                                    xs="12"
                                    sm="12"
                                    md="12"
                                    lg="4"
                                    xl="4"
                                    item
                                >
                                    <BasicSubscription
                                        membership={membershipListQuery.data?.membershipList.find(
                                            (item) => item.data.name === "Basic"
                                        )}
                                        form={props.form}
                                        addSubscriptionStep1={(id, member) =>
                                            addSubscriptionStep1(id, member)
                                        }
                                    />
                                </Grid>
                                <Grid
                                    xs="12"
                                    sm="12"
                                    md="12"
                                    lg="4"
                                    xl="4"
                                    item
                                >
                                    <MediumSubscription
                                        membership={membershipListQuery.data?.membershipList.find(
                                            (item) => item.data.name === "Better"
                                        )}
                                        form={props.form}
                                        addSubscriptionStep1={(id, member) =>
                                            addSubscriptionStep1(id, member)
                                        }
                                    />
                                </Grid>
                                <Grid
                                    xs="12"
                                    sm="12"
                                    md="12"
                                    lg="4"
                                    xl="4"
                                    item
                                >
                                    <PremiumSubscription
                                        membership={membershipListQuery.data?.membershipList.find(
                                            (item) => item.data.name === "Perfect"
                                        )}
                                        form={props.form}
                                        addSubscriptionStep1={(id, member) =>
                                            addSubscriptionStep1(id, member)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </div>
            </div>
            <AuthFooter />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(loginAccount(payload)),
        addAttribute: (payload) => dispatch(addAttribute(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
