/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// eslint-disable jsx-a11y/anchor-is-valid
// eslint-disable jsx-a11y/alt-text
// eslint-disable no-useless-escape
// eslint-disable no-useless-concat
// eslint-disable no-duplicate-props
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { connect } from 'react-redux';

const Organisation = (props) => {
    const [personalData, setpersonalData] = React.useState({});
    const [isEdit, setEdit] = React.useState(false);
    const [personName, setPersonName] = React.useState([]);

    const saveEwill = () => {
        let mediaListID = ';';
        if (personName.length > 0) {
            personName.map((media) => {
                mediaListID = mediaListID + media.system.id + ';';
            });
        }
        let json = {
            url: 'lastwishes',
            mode: 'merge',
            method: props.form.accountData.lastwishes.funeralPolis ? 'PUT' : 'POST',
            ...props.form.accountData.lastwishes,
            organisation: {
                additionalInfo: personalData.additionalInfo,
                contactID: mediaListID,
                organizer: personalData.organizer,
                ceremony: personalData.ceremony,
                lyingState: personalData.lyingState,
                location: personalData.location,
                coffinState: personalData.coffinState,
                condolanceType: personalData.condolanceType,
            },
        };
        props.handleRequest('update', json);
        setEdit(false);
    };
    const deleteEwill = () => {
        let data = {...props.form.accountData.lastwishes};
        delete data.organisation;
        let json = {
            url: 'lastwishes',
            mode: 'overwrite',
            method: 'PUT',
            ...data,
        };
        props.handleRequest('update', json, 'organisation');
    };

    React.useEffect(() => {
        if (
            props.form.accountData &&
            props.form.accountData.lastwishes &&
            props.form.accountData.lastwishes.organisation &&
            props.form.accountData.lastwishes.organisation.organizer
        ) {
            let data = props.form.accountData.lastwishes.organisation;
            if (data && data.contactID) {
                let contact = data.contactID.split(';');
                if (contact.length > 0) {
                    let person = [];
                    contact.map((item) => {
                        if (
                            item &&
                            item !== ';' &&
                            props.form.accountData?.contactList?.length > 0
                        ) {
                            let x = props.form.accountData.contactList.filter(
                                (con) => con.system.id == item
                            );
                            person.push(x[0]);
                        }
                    });
                    setPersonName(person);
                }
            }
            setpersonalData(data);
        } else {
            setPersonName([]);
            setpersonalData({
                organizer: '',
                ceremony: '',
                lyingState: '',
                location: '',
                coffinState: '',
                condolanceType: '',
                contactID: '',
                additionalInfo: '',
            });
            setEdit(true);
        }
    }, [props.form.accountData && props.form.accountData.lastwishes]);

    const changeHandler = (event, personal) => {
        let data = {...personalData};
        data[event.target.name] = event.target.value;
        setpersonalData(data);
    };

    return (
        <React.Fragment>
            {!isEdit && (
                <React.Fragment>
                    <div>
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.ORGANISATION_Q1}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData['organizer'] === 'contact'
                                    ? personName.map((item) => {
                                          if (item) {
                                              return (
                                                  <span style={{ marginRight: '10px' }}>
                                                      {item.privateData.firstName +
                                                          ' ' +
                                                          item.privateData.lastName}
                                                  </span>
                                              );
                                          }
                                      })
                                    : personalData['organizer'] === 'company'
                                    ? props.form.language.ORGANISATION_Q14
                                    : personalData['organizer'] === 'association'
                                    ? props.form.language.ORGANISATION_Q13
                                    : ''}
                            </div>
                        </div>
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.ORGANISATION_Q2}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.ceremony === 'yes'
                                    ? props.form.language.YES_TEXT
                                    : personalData.ceremony === 'no'
                                    ? props.form.language.NONE_TEXT
                                    : personalData.ceremony === 'nik'
                                    ? props.form.language.ORGANISATION_Q7
                                    : ''}
                            </div>
                        </div>
                        {personalData.ceremony === 'yes' && (
                            <div
                                style={{ display: 'flex' }}
                                className='flex-direction-column input-container-column'
                            >
                                <div className='momentio-text-normal accordion-content-option-label'>
                                    {props.form.language.ORGANISATION_Q3}
                                </div>
                                <div className='momentio-text-bold momentio-ewill-value'>
                                    {personalData.location}
                                </div>
                            </div>
                        )}
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.ORGANISATION_Q4}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.lyingState === 'centre'
                                    ? props.form.language.ORGANISATION_Q12
                                    : personalData.lyingState === 'home'
                                    ? props.form.language.HOUSE_LABEL
                                    : personalData.lyingState === 'nik'
                                    ? props.form.language.NEXT_TO_KIN
                                    : ''}
                            </div>
                        </div>

                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.ORGANISATION_Q5}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.coffinState === 'open'
                                    ? props.form.language.ORGANISATION_Q11
                                    : personalData.coffinState === 'closed'
                                    ? props.form.language.ORGANISATION_Q10
                                    : ''}
                            </div>
                        </div>

                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.ORGANISATION_Q6}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.condolanceType === 'yes'
                                    ? props.form.language.YES_TEXT
                                    : personalData.condolanceType === 'no'
                                    ? props.form.language.NONE_TEXT
                                    : personalData.condolanceType === 'register'
                                    ? props.form.language.ORGANISATION_Q8
                                    : personalData.condolanceType === 'nik'
                                    ? props.form.language.ORGANISATION_Q7
                                    : ''}
                            </div>
                        </div>
                        <div
                            style={{ display: 'flex' }}
                            className='flex-direction-column input-container-column'
                        >
                            <div className='momentio-text-normal accordion-content-option-label'>
                                {props.form.language.ADDITIONAL_TITLE}
                            </div>
                            <div className='momentio-text-bold momentio-ewill-value'>
                                {personalData.additionalInfo}
                            </div>
                        </div>
                    </div>
                    <div
                        className='secondary-alert-sm'
                        onClick={() => {
                            setEdit(true);
                        }}
                    >
                        {' '}
                        <i className='fa fa-pencil'></i>
                    </div>
                </React.Fragment>
            )}
            {isEdit && (
                <React.Fragment>
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.ORGANISATION_Q1}
                        </div>
                        <div className='login-field-input'>
                            <select
                                className='mm-field-input '
                                id='contactType'
                                name='organizer'
                                autoComplete="off"
                                value={personalData['organizer']}
                                onChange={(event) => changeHandler(event, personalData)}
                            >
                                <option value=''>{props.form.language.SELECT_OPTION}</option>
                                <option value={'company'}>
                                    {props.form.language.ORGANISATION_Q14}
                                </option>
                                <option value={'contact'}>
                                    {props.form.language.ORGANISATION_Q16}
                                </option>
                                <option value={'association'}>
                                    {props.form.language.ORGANISATION_Q13}
                                </option>
                                <option value={'company'}>
                                    {props.form.language.CONTACT_ROLE_TYPE_COMPANY}
                                </option>
                            </select>
                        </div>
                    </div>
                    {personalData.organizer === 'contact' && (
                        <div>
                            <div className='login-field-label login-field-label-height'>
                                {props.form.language.CONTACT_PERSON_TITLE}
                            </div>
                            <div className='login-field-input'>
                                <Autocomplete
                                    multiple
                                    autoComplete="off"
                                    size='small'
                                    options={
                                        props.form.accountData && props.form.accountData.contactList
                                            ? props.form.accountData.contactList
                                            : []
                                    }
                                    getOptionLabel={(option) =>
                                        option
                                            ? option.privateData?.firstName +
                                              ' ' +
                                              option.privateData?.lastName
                                            : ''
                                    }
                                    onChange={(event, values) => setPersonName(values)}
                                    defaultValue={personName}
                                    renderInput={(params) => (
                                        <TextField {...params} variant='standard' />
                                    )}
                                />
                            </div>
                        </div>
                    )}
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.ORGANISATION_Q2}
                        </div>
                        <div className=''>
                            <RadioGroup
                                className='mm-field-input'
                                style={{ flexDirection: 'row' }}
                                name={'ceremony'}
                                value={personalData.ceremony}
                                onChange={(event) => changeHandler(event, personalData)}
                            >
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio />}
                                    label={props.form.language.YES_TEXT}
                                />

                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio />}
                                    label={props.form.language.NONE_TEXT}
                                />
                                <FormControlLabel
                                    value={'nik'}
                                    control={<Radio />}
                                    label={props.form.language.ORGANISATION_Q7}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                    {personalData.ceremony === 'yes' && (
                        <div>
                            <div className='login-field-label login-field-label-height'>
                                {props.form.language.ORGANISATION_Q3}
                            </div>
                            <div className='login-field-input'>
                                <input
                                    type='text'
                                    placeholder={props.form.language.ORGANISATION_Q3}
                                    name='location'
                                    autoComplete="off"
                                    value={personalData.location}
                                    onChange={(event) => changeHandler(event, personalData)}
                                />
                            </div>
                        </div>
                    )}
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.ORGANISATION_Q4}
                        </div>
                        <div className=''>
                            <RadioGroup
                                className='mm-field-input'
                                style={{ flexDirection: 'row' }}
                                name={'lyingState'}
                                value={personalData.lyingState}
                                onChange={(event) => changeHandler(event, personalData)}
                            >
                                <FormControlLabel
                                    value={'centre'}
                                    control={<Radio />}
                                    label={props.form.language.ORGANISATION_Q12}
                                />

                                <FormControlLabel
                                    value={'home'}
                                    control={<Radio />}
                                    label={props.form.language.HOUSE_LABEL}
                                />
                                <FormControlLabel
                                    value={'nik'}
                                    control={<Radio />}
                                    label={props.form.language.NEXT_TO_KIN}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.ORGANISATION_Q5}
                        </div>
                        <div className=''>
                            <RadioGroup
                                className='mm-field-input'
                                style={{ flexDirection: 'row' }}
                                name={'coffinState'}
                                value={personalData.coffinState}
                                onChange={(event) => changeHandler(event, personalData)}
                            >
                                <FormControlLabel
                                    value={'open'}
                                    control={<Radio />}
                                    label={props.form.language.ORGANISATION_Q11}
                                />

                                <FormControlLabel
                                    value={'closed'}
                                    control={<Radio />}
                                    label={props.form.language.ORGANISATION_Q10}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                    <div>
                        <div className='login-field-label login-field-label-height'>
                            {props.form.language.ORGANISATION_Q6}
                        </div>
                        <div className=''>
                            <RadioGroup
                                className='mm-field-input'
                                style={{ flexDirection: 'row' }}
                                name={'condolanceType'}
                                value={personalData.condolanceType}
                                onChange={(event) => changeHandler(event, personalData)}
                            >
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio />}
                                    label={props.form.language.YES_TEXT}
                                />

                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio />}
                                    label={props.form.language.NONE_TEXT}
                                />
                                <FormControlLabel
                                    value={'register'}
                                    control={<Radio />}
                                    label={props.form.language.ORGANISATION_Q8}
                                />
                                <FormControlLabel
                                    value={'nik'}
                                    control={<Radio />}
                                    label={props.form.language.ORGANISATION_Q7}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className='login-field-label login-field-label-height'>
                                {props.form.language.ADDITIONAL_TITLE}
                            </div>
                            <div className='login-field-input'>
                                <textarea
                                    style={{ height: 'auto' }}
                                    rows='4'
                                    type='text'
                                    autoComplete="off"
                                    placeholder={props.form.language.ADDITIONAL_TITLE}
                                    name='additionalInfo'
                                    value={personalData.additionalInfo}
                                    onChange={(event) => changeHandler(event, personalData)}
                                />
                            </div>
                        </div>

                        <button
                            style={{
                                textAlign: 'center',
                                width: '150px',
                                marginTop: '24px',
                            }}
                            className='primary-button-blue'
                            onClick={() => saveEwill(personalData)}
                        >
                            {props.form.language.SAVE}
                        </button>
                        <button
                            style={{
                                textAlign: 'center',
                                width: '150px',
                                marginTop: '24px',
                                backgroundColor: '#F45B5B !important',
                                marginLeft: '15px',
                            }}
                            className='primary-button-red'
                            onClick={() => deleteEwill()}
                        >
                            {props.form.language.DELETE}
                        </button>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

export default connect(mapStateToProps)(Organisation);
